import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

const LessonAudioContent = ({ audioContent }) => {
	if (!audioContent) return null;
	return (
		<div id="previewComponentID" key={audioContent.audio}>
			<div>
				<h3>
					Audio Content
				</h3>
			</div>
			<div className='my-4'>
				<ReactAudioPlayer
					src={audioContent?.audio ? audioContent?.audio[0].media_url : null}
					controls
					className="w-100"
				/>
			</div>
			<div className='mb-4'>
				<h3>
					{audioContent?.title}
				</h3>
				<p className='mt-2'>
					{audioContent?.description}
				</p>
			</div>

		</div>
	);
};

export default LessonAudioContent;

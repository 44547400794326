import React from 'react';
// import { FaTrophy } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { AssignmentCard } from '../../Assignment/components/AssignmentCard';

function QuizStartCard({ quiz, startQuiz, currentChapter }) {
	return (
		<AssignmentCard>
			<div className="assignment-left">
				<h3 className="text-secondary">{currentChapter} Quiz</h3>

				<small className="d-block assignment-description w-75">
				</small>
				<Button style={{ margin: '0.5rem 0' }} className="start-btn mb-4" onClick={startQuiz} disabled={!quiz?.length}>
					Start Now
				</Button>

				<div className="mt-2">
					<small className="d-block">
						<img src="/assets/icons/QuizBlue.svg" className="mr-1" alt="" /> {quiz?.length} questions total
					</small>
					{/* <small className="d-block">
						<img src="/assets/icons/certificateBlue.svg" className="mr-1" alt="" /> score above 40 and earn a
						certificate
					</small> */}
				</div>
			</div>
			<div className="assignment-right">
				<h4>Instructions</h4>
				<ul>
					<li>Do not use Search Engine.</li>
					<li>You can check your previous answers and make changes.</li>
					<li>You may exit the assignment anytime.</li>
				</ul>
			</div>
		</AssignmentCard>
	);
}

export default QuizStartCard;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

//redux
// import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
// import { setUiError } from '../_redux/slices/ui';
import Login from '../auth/Login';

const PrivateRoute = ({ component: Component, role, user, isLoggedIn, access, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (!isLoggedIn) {
				return <Login />;
			} else if (user?.need_pwd_change) {
				return <Redirect to="/change-password/first" />;
			} else {
				return <Component {...props} />;
			}
		}}
	/>
);

const AuthRoute = ({ component: Component, isLoggedIn, ...rest }) => (
	<Route {...rest} render={(props) => (!isLoggedIn === true ? <Component {...props} /> : <Redirect to="/" />)} />
);

PrivateRoute.propTypes = {
	isLoggedIn: PropTypes.bool,
};

AuthRoute.propTypes = {
	isLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	user: state.user.user,
	isLoggedIn: state.user.isLoggedIn,
});

const ContainerCreater = connect(mapStateToProps);

const FirstConnectedComponent = ContainerCreater(PrivateRoute);
const SecondConnectedComponent = ContainerCreater(AuthRoute);

export { FirstConnectedComponent as PrivateRoute, SecondConnectedComponent as AuthRoute };

import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { useState } from "react";
import Card from "../../components/Cards";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import PaginationComp from "../../components/Pagination/PaginationComp";

import MoviePlaceholder from "../../assets/images/@knowledge_products/movie_placeholder.jpg";

const ContentList = ({ url, path }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState(false);
  const [contentList, setContentList] = useState(null);
  const [contentCount, setContentCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const getContentList = async () => {
      setLoading(true);
      let res = await fetchWrapper.get(`/${url}/?offset=${(page - 1) * 10}`);
      let results = res?.data?.results ?? [];
      let moviePlaceholder = null;
      if (url === "documentary") {
        moviePlaceholder = MoviePlaceholder;
      }
      let newResults = results.map((result) => ({
        id: result.id,
        title: result.title,
        media_url:
          result.media["profile_image"] ||
          result.media["banner_image"] ||
          moviePlaceholder,
        ...result,
      }));

      setContentList(newResults);
      setContentCount(res?.data?.count);
      setLoading(false);
    };
    try {
      getContentList();
    } catch (error) {}
  }, [url, page]);

  return (
    <div>
      <div className="d-flex justify-content-end pr-3 mb-3">
        <img
          src="/assets/icons/list.svg"
          className="pointer mr-1"
          onClick={() => setList(true)}
          alt=""
        />

        <img
          src="/assets/icons/grid.svg"
          className="pointer"
          onClick={() => setList(false)}
          alt=""
        />
      </div>
      {console.log(contentList, "contentList")}
      <Card
        list={list}
        dataList={contentList}
        imageField="media_url"
        Content={CardBody}
        path={{ url: path, withId: true }}
        loading={loading}
      />

      <PaginationComp total={contentCount} page={page} setPage={setPage} />
    </div>
  );
};

export default ContentList;

const CardBody = ({ data }) => {
  return (
    <StyledCardBody>
      <h4 className="text-primary">{data?.title}</h4>
      <p className="created-at">{data?.created_at?.split("T")[0]}</p>
      <p className="description">{data?.short_description}</p>
    </StyledCardBody>
  );
};

const StyledCardBody = styled.div`
  .description {
    & * {
      font-size: 1rem !important;
      line-height: 1.5;
    }
    height: 5.5em;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }
  .created-at {
    font-size: 0.8rem;
    font-weight: 500;
    color: #aaa;
  }
`;

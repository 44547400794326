import React from "react";
import { Accordion, Card } from "react-bootstrap";
import styled from "styled-components";
import { FaChevronDown } from "react-icons/fa";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";

const EnrolledResults = ({ courseSlug }) => {
  const [results, setResults] = React.useState(null);
  React.useEffect(() => {
    try {
      const getResults = async () => {
        const response = await fetchWrapper.get(
          `/course/${courseSlug}/quiz-result/`
        );
        setResults(response?.data);
      };
      getResults();
    } catch (error) {
      console.log(error);
    }
  }, [courseSlug]);

  return (
    <Container>
      {!results?.attempts?.length && (
        <>
          <Card>
            <Card.Header>
              <h4>Results</h4>
            </Card.Header>
          </Card>
          <Accordion>
            {results?.attempts?.map((chapter, index) => (
              <Card key={index}>
                <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                  <div className="d-flex justify-content-between align-items-center pointer">
                    <h4>{chapter.chapter__title}</h4>
                    <FaChevronDown />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index + 1}>
                  <Card.Body className="p-3 pb-0">
                    {chapter?.data?.map((attempt, i) => (
                      <div
                        key={i}
                        className="d-flex justify-content-between align-items-center mb-3"
                      >
                        <p>Attempt {attempt.attempt}</p>
                        <small>{attempt.created_at.split("T")[0]}</small>
                        <p className="text-danger">
                          {Math.round(
                            (attempt.obtained_score / attempt.total_score) * 100
                          )}
                          %
                        </p>
                        <img src="/assets/icons/taskView.svg" alt="" />
                      </div>
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </>
      )}
    </Container>
  );
};

export default EnrolledResults;

const Container = styled.div`
  box-shadow: 0px 6px 30px rgba(64, 83, 145, 0.25);

  .card {
    margin: 0;
    .card-body {
      background-color: #f2f2f2;
      font-weight: bold;
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        text-align: justify;
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const EnrollHeader = ({ course, hideProgress }) => {
  const history = useHistory();
  const progress = useSelector((state) => state.course.progress);
  const courseProgress = progress?.course_progress
    ? Math.round(progress?.course_progress)
    : 0;

  return (
    <Header>
      <nav>
        <div
          className="d-flex align-items-center pointer"
          onClick={() => history.push("/courselist/1")}
        >
          <img className="mr-4" src="/assets/icons/LeftArrow.svg" alt="" />
          <h3 className="sm-hidden" style={{ marginBottom: 0 }}>
            {course?.title}
          </h3>
        </div>

        {!hideProgress && (
          <div className="d-flex align-items-center">
            <div style={{ width: "50px", height: "50px" }}>
              <CircularProgressbar
                className="circularprogressbar"
                value={courseProgress}
                text={`${courseProgress}%`}
              />
            </div>
            <span
              className="ml-2"
              style={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "25px",
                color: "#333333",
              }}
            >
              Course Progress
            </span>
          </div>
        )}
      </nav>
    </Header>
  );
};

export default EnrollHeader;

const Header = styled.div`
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    width: 80%;
    margin: auto;
    a {
      &:hover {
        color: black;
      }
    }
  }
  width: 100%;
  box-shadow: 0px 0px 8px 5px #2460b920;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
  .circularprogressbar {
    .CircularProgressbar-path {
      stroke: #27ae60;
    }
    .CircularProgressbar-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 30px !important;
      fill: #333333;
    }
  }
  @media screen and (max-width: 768px) {
    nav {
      max-width: 100vh;
      * {
        margin: 0;
        padding: 0;
      }
    }
    .sm-hidden {
      display: none;
    }
  }
`;

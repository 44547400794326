import { combineReducers } from '@reduxjs/toolkit';
import courseData from './courseData';
import uiReducer from './ui';
import users from './user';
import courseList from './courseList';
import course from './course';
import thematicAreas from './thematicAreas';
import localReducer from './local';
import review from './review';
import notes from './notes';
import courseGroup from './coursesByThematicAreas';
import resources from './resources';

const rootReducer = combineReducers({
	courseData: courseData,
	thematicAreas: thematicAreas,
	ui: uiReducer,
	user: users,
	courseList: courseList,
	course: course,
	local: localReducer,
	review: review,
	notes: notes,
	courseGroup: courseGroup,
	resources: resources,
});

export default rootReducer;

import React from "react";

function ChapterBuildNav({ tab, activeTab, handleActiveTabChange }) {
  return (
    <div
      className={
        activeTab === tab ? `nav-item nav-link active` : `nav-item nav-link`
      }
      onClick={() => handleActiveTabChange(tab)}
    >
      {tab}
    </div>
  );
}

export default ChapterBuildNav;

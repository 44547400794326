import { useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";

const Divider = styled.span`
  margin-right: 1rem;
  margin-left: 0.2rem;
  font-size: 18px;
`;
const TimeInput = styled.input`
  text-align: center;
  font-size: 20px;
`;

const Time = ({
  placeholder,
  id,
  isValidate,
  validation,
  disabled,
  defaultValue,
  register,
  setValue,
}) => {
  const [hh, setHh] = useState(0);
  const [mm, setMm] = useState(0);
  const [ss, setSs] = useState(0);

  useEffect(() => {
    if (defaultValue) {
      const [hh, mm, ss] = defaultValue.split(":");
      setHh(hh);
      setMm(mm);
      setSs(ss);
    }
  }, [defaultValue])

  const handleChangeHh = (e) => {
    if (e.target.value.length > 2) return;
    setHh(e.target.value);
    setValue(id, `${e.target.value}:${mm}:${ss}`);
  };
  const handleChangeMm = (e) => {
    if (e.target.value.length > 2) return;
    setMm(e.target.value);
    setValue(id, `${hh}:${e.target.value}:${ss}`);
  };
  const handleChangeSs = (e) => {
    if (e.target.value.length > 2) return;
    setSs(e.target.value);
    setValue(id, `${hh}:${mm}:${e.target.value}`);
  };
  return (
    <div>
      <TimeInput
        type="number"
        value={hh}
        onChange={handleChangeHh}
        placeholder="00"
        min="0"
        max="99"
        disabled={disabled}
      />
      <Divider>hr(s)</Divider>
      <TimeInput
        type="number"
        value={mm}
        onChange={handleChangeMm}
        placeholder="00"
        min="0"
        max="59"
        disabled={disabled}
      />
      <Divider>min(s)</Divider>
      <TimeInput
        type="number"
        value={ss}
        onChange={handleChangeSs}
        placeholder="00"
        min="0"
        max="59"
        disabled={disabled}
      />
      <Divider>second(s)</Divider>
      <input type="text" {...register(id)} hidden readOnly />
    </div>
  );
};

export default Time;

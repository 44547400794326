import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  local: 'en-US'
};

// A slice

const localSlice = createSlice({
  name: 'local',
  initialState,
  reducers: {
    setLocal: (state, action) => {
      state.local = action.payload;
    }
  }
});

const { setLocal } = localSlice.actions;

export const localSelector = (state) => state.local;

const localReducer = localSlice.reducer;

export default localReducer;

// Actions
export const handleLocalChange = (local) => async (dispatch) => {
  dispatch(setLocal(local));
};

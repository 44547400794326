import React, { useEffect } from 'react';
import { BsDot } from 'react-icons/bs';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getChaptersFromCourse } from '../../_redux/slices/course';
import ChapterCard from './ChapterCard';
import styled from 'styled-components';

function CourseContent({ totalLesson, courseSlug }) {
	const chapters = useSelector((state) => state.course.chapters);
	// const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	useEffect(() => {
		if (courseSlug) {
			dispatch(getChaptersFromCourse(courseSlug));
		}
	}, [courseSlug, dispatch]);

	return (
		<StyledCourseContent className="mt-5">
			<h3 className="mb-2" style={{ color: '#2f80ed' }}>
				Course Content
			</h3>
			<div className="d-flex justify-content-between acc-sub-header">
				<div className="d-flex align-items-center">
					<p className="mr-2 accordion-header">
						<BsDot /> {chapters.length} chapters
					</p>
					<p className="mr-2 accordion-header">
						<BsDot /> {totalLesson} lessons
					</p>
				</div>
				<p className="expand-all">Expand All</p>
			</div>

			<Accordion defaultActiveKey="0" className="mt-3 custom-accordion custom_box_shadow">
				{chapters.map((chapter) => (
					<ChapterCard key={chapter.id} chapter={chapter} />
				))}
			</Accordion>
		</StyledCourseContent>
	);
}

export default CourseContent;

const StyledCourseContent = styled.div`
	.accordion-header {
		font-family: 'Roboto' !important;
		font-weight: 400 !important;
		font-size: 16px !important;
		line-height: 22px !important;
		color: #828282 !important;
	}
	.expand-all {
		font-family: 'Roboto' !important;
		font-style: normal;
		font-weight: 400;
		font-size: 16px !important;
		line-height: 22px;
		color: #2460b9;
		cursor: pointer;
	}
	@media screen and (max-width: 1024px) {
		.acc-sub-header {
			flex-direction: column;
			gap: 1rem;
		}
	}
`;

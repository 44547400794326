import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { FormGroup } from "../../../../../../components/FormGroup/FormGroup";
import {
  handleChapterDelete,
  handleChapterEdit,
} from "../../../../../../_redux/slices/courseData";
import { setUiError } from "../../../../../../_redux/slices/ui";

import { AccordionHeader } from "../_components/AccordionHeader";

import { FaSave, FaTrashAlt } from "react-icons/fa";
import Loading from "../../../../../../components/Loading";

const StyledChapter = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 6px 6px 10px 0px #dbdbdb;
`;
function Chapter({ chapter, index }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await dispatch(
        handleChapterEdit({
          ...chapter,
          title: data.title,
          order: index,
        })
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(setUiError("Chapter saved failed!"));
    }
  };

  return (
    <StyledChapter>
      <Accordion defaultActiveKey="0">
        <AccordionHeader title={chapter.title} />

        <Accordion.Collapse eventKey="0">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <br />
            <Row>
              <Col md={12}>
                <FormGroup
                  type="text"
                  label="Title"
                  placeholder="Enter Chapter Title"
                  id="title"
                  register={register}
                  error={errors}
                  defaultValue={chapter.title}
                  //   disabled={isLoading}
                  required={true}
                />
              </Col>
              <Col md={12} className="mt-4 border-top pt-3">
                <div className="d-flex justify-content-between">
                  <Button variant="success" type="submit">
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <span>
                        <FaSave className="mr-2" />
                        Save
                      </span>
                    )}
                  </Button>

                  <Button
                    variant="outline-danger"
                    onClick={() => dispatch(handleChapterDelete(chapter))}
                  >
                    <FaTrashAlt className="mr-2" /> Delete
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Accordion.Collapse>
      </Accordion>
    </StyledChapter>
  );
}

export default Chapter;

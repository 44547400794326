import React, { Fragment } from 'react';
import LessonHeader from '../../Layout/LessonLayout/LessonHeader';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCourseAssignment } from '../../_redux/slices/course';
import AssignmentStartCard from './components/AssignmentStartCard';
import { useState } from 'react';
import Question from '../../components/Question/Question';
// import { slugToTitle } from '../../_helpers/_methods';
import styled from 'styled-components';
import Footer from '../../Layout/Footer';
import Sidebar from './components/Sidebar';
import AssignmentResult from './components/AssignmentResult/AssignmentResult';
import AssignmentResultBanner from './components/AssignmentResult/AssignmentResultBanner';

const Assignment = () => {
	const { courseSlug } = useParams();
	const dispatch = useDispatch();
	const assignments = useSelector((state) => state.course.assignments);
	const [question, setQuestion] = useState(null);
	const [questionIndex, setQuestionIndex] = useState(null);
	console.log(assignments, 'assignments');
	useEffect(() => {
		if (courseSlug) {
			dispatch(getCourseAssignment(courseSlug));
		}
	}, [courseSlug, dispatch]);

	useEffect(() => {
		if (assignments?.length) {
			setQuestion(assignments[questionIndex]);
		}
	}, [questionIndex, assignments]);

	const handleQuestionIndexCounterChange = (action) => {
		if (action === 'next') {
			let quizCount = Number(questionIndex);
			quizCount++;
			setQuestionIndex(quizCount);
		}
	};

	const startAssignment = () => {
		setQuestionIndex(0);
	};

	const hasAssignmentEnded = Number(questionIndex) > assignments?.length - 1;
	console.log(hasAssignmentEnded, 'hasAssignmentEnded');
	return (
		<>
			<LessonHeader />
			<Container>
				<Sidebar courseSlug={courseSlug} />
				<div className="content">
					{questionIndex === null ? (
						<AssignmentStartCard assignments={assignments} startAssignment={startAssignment} />
					) : (
						<div>
							<div id="previewComponentID">
								<div className="w-100">
									{hasAssignmentEnded ? (
										<AssignmentResultBanner />
									) : (
										// <AssignmentResult />
										<Fragment>
											{question ? (
												<StyledQuestionContainer>
													{/* <div className="quiz-result-main-box no-bg">
                            <div className="course-title">
                              Course:{" "}
                              <span className="bold">
                                {slugToTitle(courseSlug)}
                              </span>
                            </div>
                          </div> */}
													<div className="question-header">
														<h3>QUESTION 1 OF 40:</h3>
													</div>
													<Question
														question={question}
														handleQuestionIndexCounterChange={handleQuestionIndexCounterChange}
														isAssignment={true}
													/>
												</StyledQuestionContainer>
											) : (
												// <AssignmentStartCard assignments={assignments} startAssignment={startAssignment} />
												<AssignmentResult />
											)}
										</Fragment>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</Container>
			<Footer />
		</>
	);
};

export default Assignment;

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-template-areas: 'sidebar content';
	column-gap: 1rem;
	min-height: 90vh;
	.content {
		grid-area: 'content';
		padding: 2rem 0;
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-template-areas: 'content';
		.assignment-sidebar {
			display: none !important;
		}
	}
`;

const StyledQuestionContainer = styled.div`
	// .no-bg {
	// 	background-color: transparent;
	// }
	// .course-title,
	// .chapter-title {
	// 	text-align: center;
	// 	.bold {
	// 		font-weight: 600;
	// 		color: #6a6a6a;
	// 	}
	// }
	// .course-title {
	// 	font-size: 28px;
	// }
	// .chapter-title {
	// 	font-size: 24px;
	// }
	width: 80%;
	margin: auto;
	margin-top: 1rem;
	box-shadow: 0px 6px 15px rgba(64, 83, 145, 0.25);
	padding: 2rem;
	@media screen and (max-width: 1024px) {
		width: 100%;
		box-shadow: none;
		padding: 1rem;
	}
`;

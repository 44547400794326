import React from 'react';
import { Button } from 'react-bootstrap';
import { AssignmentCard } from './AssignmentCard';

function AssignmentStartCard({ assignments, startAssignment }) {
	let assignmentLength = assignments?.length;

	return (
		<AssignmentCard>
			<div className="assignment-left">
				<h3>Java Course for Beginners</h3>
				<h4 className="text-secondary">Assignment</h4>

				<small className="d-block assignment-description w-75">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, eaque rerum hic quis amet quia incidunt
					suscipit asperiores atque reprehenderit.
				</small>
				<Button
					style={{ margin: '0.5rem 0' }}
					className="start-btn mb-4"
					onClick={startAssignment}
					disabled={!assignmentLength}
				>
					Start Now
				</Button>

				<div className="mt-2">
					<small className="d-block">
						<img src="/assets/icons/QuizBlue.svg" className="mr-1" alt="" /> 40 questions total
					</small>
					<small className="d-block">
						<img src="/assets/icons/certificateBlue.svg" className="mr-1" alt="" /> score above 40 and earn a
						certificate
					</small>
				</div>
			</div>
			<div className="assignment-right">
				<h4>Instructions</h4>
				<ul>
					<li>Do not use Search Engine.</li>
					<li>You can check your previous answers and make changes.</li>
					<li>You may exit the assignment anytime.</li>
				</ul>
			</div>
			<div className="sm-visible">
				<Button
					style={{ margin: '0.5rem 0', width: '100%' }}
					className="start-btn mb-4"
					onClick={startAssignment}
					disabled={!assignmentLength}
				>
					<span className="w-100">Start Now</span>
				</Button>
			</div>
		</AssignmentCard>
	);
}

export default AssignmentStartCard;

import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

//
import SuccessStoriesTable from '../../../../components/@SuccessStories/SuccessStoriesTable';
import SuccessStoryForm from './SuccessStoryForm';

/**
 *
 */
export default function SuccessStories() {
	const [showForm, setShowForm] = useState(false);
	const [successStory, setSuccessStory] = useState();
	const [showPreview, setShowPreview] = useState(false);
	const userRole = useSelector((state) => state?.user?.user?.active_role);

	const handleShowForm = () => {
		setShowForm(true);
	};
	const handleHideForm = () => {
		setShowForm(false);
		setSuccessStory(null);
	};

	const handleSetEditSuccessStoryData = (data) => {
		setSuccessStory(data);
		setShowForm(true);
	};

	const handleShowPreview = (data) => {
		setSuccessStory(data);
		setShowForm(true);
		setShowPreview(true);
	};

	return (
		<div className="main-content-inner">
			<Row>
				<Col md={10}>
					<h2 className="main-title mb-2">Success Stories</h2>
				</Col>

				<Col md={2} className="d-flex justify-content-center align-items-center">
					{!showForm && ['superuser', 'cd_admin'].includes(userRole) && (
						<Button onClick={handleShowForm}>Add Success Story</Button>
					)}
				</Col>
			</Row>

			<Row>
				{showForm ? (
					<SuccessStoryForm
						hideForm={handleHideForm}
						successStory={successStory}
						showPreview={showPreview}
						setShowPreview={setShowPreview}
					/>
				) : (
					<SuccessStoriesTable
						handleSetEditSuccessStoryData={handleSetEditSuccessStoryData}
						handleShowPreview={handleShowPreview}
					/>
				)}
			</Row>
		</div>
	);
}

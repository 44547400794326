import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import FormPassword from './FormPassword';
import { FormattedMessage } from 'react-intl';
import {
	fileSizeValidator,
	fileTypeValidator,
	isRequiredValidator,
	maxFilesValidator,
} from '../../_helpers/validationFunctions';
import FormError from '../FormError';
import { StyledFileInput, StyledEditorContainer, StyledFormGroup, StyledFormLabel } from '../Reuseables';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaFileAlt } from 'react-icons/fa';
import Time from './Time';

export const FormGroup = ({
	id,
	errorName,
	label,
	type,
	fileType,
	info,
	register,
	setValue,
	disabled,
	defaultValue,
	error,
	placeholder,
	options,
	onChange,
	required,
	lessThan,
	acceptedFormats = ['jpg', 'jpeg', 'png', 'mp4', 'mkv', 'pdf', 'doc', 'docx', 'ppt', 'pptx'],
	isMulti,
	isValidate = true,
	maxSelected,
	editorStyle,
	formattedLabelId = undefined,
	checkboxLabel,
	className, maxlength,
	value,
	checked,
}) => {
	let validation = { validate: {} };
	let innerComponent;
	if (required) {
		if (type === 'file') {
			validation.validate.required = (files) => isRequiredValidator(defaultValue, files, errorName, id);
		} else {
			validation.required = `${errorName || id} is required.`;
		}
	}
	if (lessThan) {
		validation.validate.lessThan = (files) => lessThan && fileSizeValidator(Boolean(defaultValue), lessThan, files);
	}
	if (type === "file" && acceptedFormats?.length) {
		validation.validate.acceptedFormats = (files) => fileTypeValidator(Boolean(defaultValue), acceptedFormats, files);
	}
	if (maxSelected) {
		validation.validate.maxSelected = (files) => maxFilesValidator(Boolean(defaultValue), maxSelected, files);
	}

	switch (type) {
		case 'text':
		case 'email':
		case 'number':
		case 'url':
			innerComponent = (
				<Form.Control
					type={type}
					placeholder={placeholder}
					{...register(id, isValidate && validation)}
					onChange={(e) => {
						register(id).onChange(e);
						if (onChange) {
							onChange(e);
						}
					}}
					maxlength={maxlength}
					disabled={disabled}
					defaultValue={defaultValue}
				/>
			);
			break;
		case 'password':
			innerComponent = (
				<FormPassword
					placeholder={placeholder}
					register={register}
					id={id}
					isValidate={isValidate}
					validation={validation}
					disabled={disabled}
					defaultValue={defaultValue}
				/>
			);
			break;
		case 'textarea':
			innerComponent = (
				<Form.Control
					as={type}
					placeholder={placeholder}
					{...register(id, validation)}
					disabled={disabled}
					defaultValue={defaultValue}
					rows={3}
					maxlength={maxlength}
				/>
			);
			break;
		case 'checkbox':
			innerComponent = (
				<Fragment>
					<div className="d-flex align-items-center">
						<Form.Check id={id} type="checkbox" {...register(id)} defaultChecked={checked} />
						{checkboxLabel && <label htmlFor={id}>{checkboxLabel}</label>}
					</div>
				</Fragment>
			);
			break;
		case 'radio':
			innerComponent = (
				<Fragment>
					<div className="d-flex align-items-center">
						<Form.Check
							type="radio"
							value={value}
							id={`id-${value}`}
							name={id}
							{...register(id)}
							defaultChecked={checked}
							checked={checked}
							disabled={disabled}
							onClick={(e) => {
								register(`id-${value}`)?.onChange(e);
								if (onChange) {
									onChange(value);
								}
							}}
						/>
						{checkboxLabel && <label htmlFor={`id-${value}`}>{checkboxLabel}</label>}
					</div>
				</Fragment>
			);
			break;
		case 'file':
			innerComponent = (
				<Fragment>
					{defaultValue && (
						<div>
							{fileType === 'audio' ? (
								<Fragment>
									{typeof defaultValue === 'string' ? (
										<audio controls controlsList="nodownload">
											<source src={`${defaultValue}`} type="audio/mp3" />
										</audio>
									) : (
										Array.from(defaultValue).map((audio) => (
											<div
												style={{
													padding: '1rem',
													border: '1px solid gray',
													borderRadius: 5,
													minWidth: 200,
													display: 'inline-block',
													margin: '12px 10px 12px 0',
												}}
											>
												<FaFileAlt />
												<span className="ml-2">{audio.name || audio.file_name}</span>
											</div>
										))
									)}
								</Fragment>
							) : (
								<Fragment>
									{typeof defaultValue === 'string' ? (
										<img
											src={defaultValue}
											className="my-2"
											style={{
												width: '50px',
												height: '50px',
												objectFit: 'cover',
												borderRadius: '50%',
											}}
											alt=""
										/>
									) : (
										defaultValue.map((imgSrc) => (
											<img
												src={imgSrc}
												className="my-2"
												style={{
													width: '50px',
													height: '50px',
													objectFit: 'cover',
													borderRadius: '50%',
												}}
												alt=""
											/>
										))
									)}
								</Fragment>
							)}
						</div>
					)}
					<StyledFileInput>
						<label className="drag-text">
							<Form.Control
								type="file"
								accept={acceptedFormats?.reduce((acc, cur) => (acc += cur + ','), '')}
								multiple={isMulti}
								disabled={disabled}
								{...register(id, validation)}
								onChange={(e) => {
									register(id).onChange(e);
									if (onChange) {
										onChange(e);
									}
								}}
								hidden
							/>
							<h3>{placeholder ? placeholder : "Drag and drop a file"}</h3>
						</label>
					</StyledFileInput>
				</Fragment>
			);
			break;
		case 'select':
			innerComponent = (
				<Fragment>
					<Select
						key={defaultValue}
						options={options}
						defaultValue={options.filter((option) => option.value === defaultValue)}
						onChange={(e) => onChange(e)}
						isDisabled={disabled}
						isMulti={isMulti}
					/>
					<input type="text" {...register(id, validation)} readOnly hidden defaultValue={defaultValue} />
				</Fragment>
			);
			break;
		case 'editor':
			innerComponent = (
				<StyledEditorContainer style={editorStyle}>
					<Editor
						wrapperClassName="demo-wrapper"
						editorClassName="demo-editor"
						editorState={defaultValue}
						onEditorStateChange={(e) => onChange(e)}
						readOnly={disabled}

					/>
				</StyledEditorContainer>
			);
			break;
		case 'time':
			innerComponent = (
				<Time
					placeholder={placeholder}
					register={register}
					setValue={setValue}
					id={id}
					isValidate={isValidate}
					validation={validation}
					disabled={disabled}
					defaultValue={defaultValue}
				/>
			);
			break;
		default:
			innerComponent = <div>Invalid Component</div>;
	}
	return (
		<StyledFormGroup className={className}>
			{label && (
				<StyledFormLabel>
					{formattedLabelId ? (
						<FormattedMessage id={formattedLabelId}>
							{(formattedLabel) => <span className="label-text">{formattedLabel}</span>}
						</FormattedMessage>
					) : (
						<span className="label-text">{label}</span>
					)}

					{info && <span className="info">{info}</span>}
					{isMulti && <span className="isMulti">multiple</span>}
				</StyledFormLabel>
			)}

			{innerComponent}
			{error && <FormError error={error[id]} />}
		</StyledFormGroup>
	);
};

FormGroup.propTypes = {
	// register: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	info: PropTypes.string,
	type: PropTypes.string.isRequired,
	fileType: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	error: PropTypes.object,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	lessThan: PropTypes.number,
	acceptedFormats: PropTypes.array,
	isMulti: PropTypes.bool,
	isValidate: PropTypes.bool,
};

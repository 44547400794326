import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { useHistory } from 'react-router-dom';

const QuizResultBanner = ({ chapterSlug, courseSlug }) => {
	const [quizResult, setQuizResult] = useState(null);
	const history = useHistory();
	useEffect(() => {
		try {
			const getQuizResult = async () => {
				if (chapterSlug) {
					const response = await fetchWrapper.get(`chapter/${chapterSlug}/quiz-result/`);
					setQuizResult(response.data);
				}
			};

			getQuizResult();
		} catch (error) {
			console.log(error);
		}

	}, [chapterSlug]);

	console.log(quizResult);

	return (
		<StyledQuizResultBanner>
			<h2 className="text-center my-4">Well Done!</h2>
			<div className="d-flex justify-content-center mb-4">
				<img src="/assets/icons/FilledStar.svg" alt="" />
				<img src="/assets/icons/FilledStar.svg" alt="" />
				<img src="/assets/icons/FilledStar.svg" alt="" />
				<img src="/assets/icons/FilledStar.svg" alt="" />
				<img src="/assets/icons/OutlineStar.svg" alt="" />
			</div>
			<div className="result">
				<img src="/assets/images/celebrate.svg" alt="" />
				<div className="marks d-flex flex-column">
					<div className="optained">
						You have obtained
					</div>
					<div className='d-flex'>
						<h1 className="mr-2">{quizResult?.score}</h1>
						<h3>
							<h3>Marks</h3>
							<h3>out of {quizResult?.total_score}</h3>
						</h3>
					</div>
				</div>
			</div>
			<div style={{ width: 'max-content', margin: 'auto' }}>
				<img src="/assets/images/thumbsup.svg" alt="" />
			</div>

			<Button
				className="mx-auto my-4"
				onClick={() => history.push(`/courses/${courseSlug}/enrolled/${chapterSlug}/quiz/preview`)}
			>
				Check Your Answer
			</Button>
		</StyledQuizResultBanner>
	);
};

export default QuizResultBanner;

const StyledQuizResultBanner = styled.div`
	.result {
		width: max-content;
		margin: auto;
		position: relative;
		.marks {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			h1 {
				font-family: 'Poppins' !important;
				font-style: normal;
				font-weight: 500 !important;
				font-size: 80px !important;
				line-height: 67px !important;
				color: #00c48c;
			}
			h3 {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 500;
				font-size: 24px;
				line-height: 1;
				color: #333333;
			}
			.optained{
				font-size: 20px;
				margin-bottom: 8px;
			}
		}
	}
`;

import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { v4 as uuidv4 } from 'uuid';
import { setUiMessage } from './ui';
import { ErrorMessageHandler } from '../../_helpers/_methods';

export const initialState = {
	course: {},
	chapters: [],
	questions: {},
	optionset: {},
	assignment_questions: [],
	assignment_optionset: {},
	certificate: {},
};

const courseDataSlice = createSlice({
	name: 'courseData',
	initialState,
	reducers: {
		setCourse: (state, action) => {
			state.course = { ...state.course, ...action.payload };
		},
		setChapter: (state, action) => {
			state.chapters = [...action.payload];
		},
		addChapter: (state, action) => {
			state.chapters = [...state.chapters, action.payload];
		},
		editChapter: (state, action) => {
			let editTempChapters = [...state.chapters];
			let editChapterIndex = editTempChapters.findIndex((chapter) => chapter.id === action.payload.id);
			editTempChapters[editChapterIndex] = {
				...editTempChapters[editChapterIndex],
				...action.payload,
			};
			state.chapters = editTempChapters;
		},
		deleteChapter: (state, action) => {
			let deleteTempChapters = [...state.chapters];
			let editChapterIndex = deleteTempChapters.findIndex((chapter) => chapter.id === action.payload.id);
			deleteTempChapters.splice(editChapterIndex, 1);
			state.chapters = deleteTempChapters;
		},
		addLesson: (state, action) => {
			let lesson = action.payload;
			if (lesson?.chapter) {
				let chapterId = lesson.chapter;
				let lessonTempChapters = [...state.chapters];
				let lessonTempChapterIndex = lessonTempChapters.findIndex((chapter) => chapter.id === chapterId);
				let lessonUpdatedChapter = lessonTempChapters[lessonTempChapterIndex];
				lessonUpdatedChapter.lessons = [...(lessonUpdatedChapter.lessons || []), lesson];
				lessonTempChapters[lessonTempChapterIndex] = lessonUpdatedChapter;

				state.chapters = lessonTempChapters;
			}
		},

		editLesson: (state, action) => {
			const newLesson = action.payload;
			const chapterId = newLesson.chapter;

			let editLessonTempChapters = [...state.chapters];
			let editLessonChapterIndex = editLessonTempChapters.findIndex((chapter) => chapter.id === chapterId);
			let lessonTempChapter = editLessonTempChapters[editLessonChapterIndex];
			let editLessonIndex = lessonTempChapter.lessons.findIndex((lesson) => lesson.id === newLesson.id);
			lessonTempChapter.lessons[editLessonIndex] = newLesson;

			editLessonTempChapters[editLessonChapterIndex] = lessonTempChapter;
			state.chapters = editLessonTempChapters;
		},

		deleteLesson: (state, action) => {
			const chapterId = action.payload.chapterId;
			const newLesson = action.payload.lesson;

			let deleteLessonTempChapters = [...state.chapters];
			let deleteLessonChapterIndex = deleteLessonTempChapters.findIndex((chapter) => chapter.id === chapterId);
			let lessonTempChapter = deleteLessonTempChapters[deleteLessonChapterIndex];
			let deleteLessonIndex = lessonTempChapter.lessons.findIndex((lesson) => lesson.id === newLesson.id);
			lessonTempChapter.lessons.splice(deleteLessonIndex, 1);

			deleteLessonTempChapters[deleteLessonChapterIndex] = lessonTempChapter;
			state.chapters = deleteLessonTempChapters;
		},
		reset: (state, action) => {
			state.chapters = [];
			state.course = {};
		},

		addQuizQuestion: (state, action) => {
			let tempQuestions = { ...state.questions };
			tempQuestions[action.payload.chapterId] = [
				...(state.questions[action.payload.chapterId] || []),
				action.payload.data,
			];
			state.questions = tempQuestions;
		},
		addQuestionOption: (state, action) => {
			let tempOptions = { ...state.optionset };
			tempOptions[action.payload.questionId] = [
				...(state.optionset[action.payload.questionId] || []),
				...action.payload.data,
			];
			state.optionset = tempOptions;
		},
		replaceQuizQuestions: (state, action) => {
			let tempQuestions = { ...state.questions };
			tempQuestions[action.payload.chapterId] = action.payload.data;
			state.questions = tempQuestions;
			state.optionset = {};
		},
		replaceQuestionOptions: (state, action) => {
			let tempOptions = { ...state.optionset };
			tempOptions[action.payload.questionId] = action.payload.data;
			state.optionset = tempOptions;
		},
		addAssignmentQuestion: (state, action) => {
			let tempAssignmentQuestions = [...state.assignment_questions];
			tempAssignmentQuestions = [...(state.assignment_questions || []), action.payload];
			state.assignment_questions = tempAssignmentQuestions;
		},
		addAssignmentQuestionOption: (state, action) => {
			let tempAssignmentOptions = { ...state.assignment_optionset };
			tempAssignmentOptions[action.payload.questionId] = [
				...(state.assignment_optionset[action.payload.questionId] || []),
				...action.payload.data,
			];
			state.assignment_optionset = tempAssignmentOptions;
		},
		replaceAssignmentQuestions: (state, action) => {
			let tempAssignQuestions = [...state.assignment_questions];
			tempAssignQuestions = action.payload;
			state.assignment_questions = tempAssignQuestions;
		},
		replaceAssignmentQuestionOptions: (state, action) => {
			let tempAssignmentOptions = { ...state.assignment_optionset };
			tempAssignmentOptions[action.payload.questionId] = action.payload.data;
			state.assignment_optionset = tempAssignmentOptions;
		},
		setCertificate: (state, action) => {
			state.certificate = {
				...state.certificate,
				...action.payload,
			};
		},
		resetCertificate: (state, action) => {
			state.certificate = {};
		},
	},
});

// Actions generated from the slice
const {
	setCourse,
	setChapter,
	addChapter,
	editChapter,
	deleteChapter,
	addLesson,
	editLesson,
	deleteLesson,
	addQuizQuestion,
	addQuestionOption,
	replaceQuizQuestions,
	replaceQuestionOptions,
	addAssignmentQuestion,
	addAssignmentQuestionOption,
	replaceAssignmentQuestions,
	replaceAssignmentQuestionOptions,
	setCertificate,
	resetCertificate,
	reset,
} = courseDataSlice.actions;

// export user selector to get the slice in any component
export const courseDataSelector = (state) => state.courseData;

// export The reducer
const courseDataReducer = courseDataSlice.reducer;

export default courseDataReducer;

export const handleChapterSet = (courseSlug) => async (dispatch) => {
	try {
		let response = await fetchWrapper.get(`course/${courseSlug}/chapters/`);
		dispatch(setChapter(response.data));
		return response.data;
	} catch (error) {
		ErrorMessageHandler(error);
	}
};

export const handleLessonSet = (chapterSlug) => async (dispatch) => {
	try {
		let response = await fetchWrapper.get(`chapter/${chapterSlug}/lessons/`);
		response.data.forEach((lesson) => {
			dispatch(addLesson(lesson));
		});
		return response.data;
	} catch (error) {
		ErrorMessageHandler(error);
	}
};

export const handleQuizSet = (chapterSlug) => async (dispatch) => {
	try {
		let response = await fetchWrapper.get(`chapter/${chapterSlug}/quiz/`);
		const questionsArray = response.data;
		const chapterId = response.data[0]?.chapter;
		if (chapterId) {
			let optionSet = {},
				questionsSet = [];

			questionsArray.forEach((question, index) => {
				let questionData = {
					id: question.id,
					question: question.question,
					timeframe: question.timeframe,
					hint: question.hint,
					explanation: question.explanation,
					quiz_type: question.quiz_type,
					marks: question.marks,
				};
				questionsSet.push(questionData);
				optionSet[question.id] = question.option_set;
			});

			await dispatch(replaceQuizQuestions({ chapterId: chapterId, data: questionsSet }));

			Object.keys(optionSet).forEach((questionId) => {
				dispatch(
					replaceQuestionOptions({
						questionId: questionId,
						data: optionSet[questionId],
					})
				);
			});
		}
	} catch (error) {
		ErrorMessageHandler(error);
	}
};

export const handleChapterAdd = (courseId) => async (dispatch) => {
	try {
		let chapter = {
			course: courseId,
			title: `Chapter Name`,
		};
		let response = await fetchWrapper.post('chapter/', chapter);
		dispatch(setUiMessage('Chapter created successfully!'));
		dispatch(addChapter(response.data));
	} catch (error) {
		ErrorMessageHandler(error);
	}
};

export const handleChapterEdit = (chapter) => async (dispatch) => {
	try {
		let response = await fetchWrapper.put(`chapter/${chapter.slug}/`, chapter);
		dispatch(editChapter(response.data));
		dispatch(setUiMessage('Chapter saved successfully!'));
	} catch (error) {
		ErrorMessageHandler(error);
	}
};
export const handleChapterDelete = (chapter) => async (dispatch) => {
	try {
		await fetchWrapper.delete(`chapter/${chapter.slug}/`);
		dispatch(deleteChapter(chapter));
		dispatch(setUiMessage('Chapter deleted successfully!'));
	} catch (error) {
		ErrorMessageHandler(error);
	}
};

export const handleLessonAdd = (chapterId) => async (dispatch) => {
	try {
		let lesson = {
			title: `Lesson Name`,
			chapter: chapterId,
			attachment: [],
		};
		let response = await fetchWrapper.post('lesson/', lesson);
		dispatch(addLesson(response.data));
		dispatch(setUiMessage('Lesson created Successfully!'));
		return response.data;
	} catch (error) {
		ErrorMessageHandler(error);
	}
};
export const handleLessonEdit = (lesson) => async (dispatch) => {
	try {
		let response = await fetchWrapper.put(`lesson/${lesson.slug}/`, lesson);
		dispatch(editLesson(response.data));
		dispatch(setUiMessage('Lesson saved Successfully!'));
		window.scrollTo(0, 0);
	} catch (error) {
		ErrorMessageHandler(error);
	}
};

export const handleLessonDelete =
	({ chapterId, lesson }) =>
	async (dispatch) => {
		try {
			console.log(lesson.slug);
			await fetchWrapper.delete(`lesson/${lesson.slug}/`);
			dispatch(deleteLesson({ chapterId, lesson }));
			dispatch(setUiMessage('Lesson deleted successfully!'));
		} catch (error) {
			ErrorMessageHandler(error);
		}
	};

export const handleCreateQuiz = (chapterSlug) => async (dispatch) => {
	try {
		let data = {
			quiz_data: [
				{
					question: 'What is your Question?',
					timeframe: null,
					hint: 'this is a hint',
					explanation: 'This is an explanation',
					quiz_type: 'multiple_true',
					marks: 5,
					options: [
						{
							id: uuidv4(),
							option: 'Yes',
							is_correct: true,
							point: 5,
						},
						{
							id: uuidv4(),
							option: 'No',
							is_correct: false,
							point: 5,
						},
					],
				},
			],
		};
		let response = await fetchWrapper.post(`chapter/${chapterSlug}/quiz/`, data);
		const question = response.data[0];
		const chapterId = question.chapter;

		let questionData = {
			id: question.id,
			question: question.question,
			timeframe: question.timeframe,
			hint: question.hint,
			explanation: question.explanation,
			quiz_type: question.quiz_type,
			marks: question.marks,
		};

		dispatch(addQuizQuestion({ chapterId: chapterId, data: questionData }));
		dispatch(
			addQuestionOption({
				questionId: questionData.id,
				data: question.option_set,
			})
		);
		dispatch(setUiMessage('Quiz created Successfully!'));
		return questionData;
	} catch (error) {
		ErrorMessageHandler(error);
	}
};

export const handleAddQuizQuestion = (chapterId) => async (dispatch) => {
	try {
		// default questionData
		let questionId = uuidv4();
		let questionData = {
			id: questionId,
			question: 'What is your question?',
			timeframe: 87494604.79676366,
			hint: 'This is a hint',
			explanation: 'This is an explanation',
			quiz_type: 'single_true',
			marks: 5,
		};
		let optionData = [
			{
				id: uuidv4(),
				option: 'Yes',
				is_correct: true,
			},
			{
				id: uuidv4(),
				option: 'No',
				is_correct: false,
			},
		];

		dispatch(addQuizQuestion({ chapterId: chapterId, data: questionData }));
		dispatch(addQuestionOption({ questionId: questionId, data: optionData }));
		return questionData;
	} catch (error) {
		console.log(error);
		ErrorMessageHandler(error);
	}
};

export const handleAddQuestionOption = (questionId) => async (dispatch) => {
	try {
		// default questionData
		let option_data = [
			{
				id: uuidv4(),
				option: 'Yes',
				is_correct: false,
			},
		];

		dispatch(
			addQuestionOption({
				questionId: questionId,
				data: option_data,
			})
		);
		return option_data;
	} catch (error) {
		ErrorMessageHandler(error);
	}
};

export const handleSaveQuiz = (data, chapterSlug) => async (dispatch) => {
	try {
		let response = await fetchWrapper.put(`chapter/${chapterSlug}/quiz/`, data);
		const questionsArray = response.data;
		const chapterId = response.data[0].chapter;

		let optionSet = {},
			questionsSet = [];

		questionsArray.forEach((question, index) => {
			let questionData = {
				id: question.id,
				question: question.question,
				timeframe: question.timeframe,
				hint: question.hint,
				explanation: question.explanation,
				quiz_type: question.quiz_type,
				marks: question.marks,
			};
			questionsSet.push(questionData);
			optionSet[question.id] = question.option_set;
		});

		await dispatch(replaceQuizQuestions({ chapterId: chapterId, data: questionsSet }));

		Object.keys(optionSet).forEach((questionId) => {
			dispatch(
				replaceQuestionOptions({
					questionId: questionId,
					data: optionSet[questionId],
				})
			);
		});
		dispatch(setUiMessage('Quiz saved successfully!'));
		window.scrollTo(0, 0);
	} catch (error) {
		ErrorMessageHandler(error);
	}
};

export const handleCreateAssignment = (courseSlug) => async (dispatch) => {
	try {
		let data = {
			assignment_data: [
				{
					question: 'What is your Question?',
					timeframe: null,
					hint: 'this is a hint',
					explanation: 'This is an explanation',
					quiz_type: 'multiple_true',
					marks: 5,
					options: [
						{
							id: uuidv4(),
							option: 'Yes',
							is_correct: true,
						},
						{
							id: uuidv4(),
							option: 'No',
							is_correct: false,
						},
					],
				},
			],
		};
		const question = data.assignment_data[0];
		let questionData = {
			id: uuidv4(),
			question: question.question,
			timeframe: question.timeframe,
			hint: question.hint,
			explanation: question.explanation,
			quiz_type: question.quiz_type,
			marks: question.marks,
		};

		dispatch(addAssignmentQuestion(questionData));
		dispatch(
			addAssignmentQuestionOption({
				questionId: questionData.id,
				data: question.options,
			})
		);
		return questionData;
	} catch (err) {}
};
export const handleAddAssignmentQuestion = () => async (dispatch) => {
	try {
		let questionId = uuidv4();
		let questionData = {
			id: questionId,
			question: 'What is your question?',
			timeframe: 87494604.79676366,
			hint: 'This is a hint',
			explanation: 'This is an explanation',
			quiz_type: 'single_true',
			marks: 5,
		};
		let optionData = [
			{
				id: uuidv4(),
				option: 'Yes',
				is_correct: true,
			},
			{
				id: uuidv4(),
				option: 'No',
				is_correct: false,
			},
		];
		dispatch(addAssignmentQuestion(questionData));
		dispatch(
			addAssignmentQuestionOption({
				questionId: questionData.id,
				data: optionData,
			})
		);
		return questionData;
	} catch (error) {
		console.log(error);
		ErrorMessageHandler(error);
	}
};

export const handleAddAssignmentQuestionOption = (questionId) => async (dispatch) => {
	try {
		// default questionData
		let option_data = [
			{
				id: uuidv4(),
				option: 'Yes',
				is_correct: false,
			},
		];

		dispatch(
			addAssignmentQuestionOption({
				questionId: questionId,
				data: option_data,
			})
		);
		return option_data;
	} catch (error) {
		ErrorMessageHandler(error);
	}
};

export const handleAssignmentSet = (courseSlug) => async (dispatch) => {
	try {
		let response = await fetchWrapper.get(`course/${courseSlug}/assignment/`);
		console.log(response, 'response');
		let assignmentsQuestion = response?.data?.assignment_data || [];
		assignmentsQuestion = assignmentsQuestion.map((question) => {
			let newQues = { ...question };
			newQues.quiz_type = 'long_answer';
			return newQues;
		});
		let mcqsQuestions = response.data.quiz_data || [];
		const questionsArray = [...assignmentsQuestion, ...mcqsQuestions];

		let optionSet = {},
			questionsSet = [];

		questionsArray.forEach((question, index) => {
			let questionData = {
				id: question.id,
				question: question.question,
				timeframe: question.timeframe,
				hint: question.hint,
				explanation: question.explanation,
				quiz_type: question.quiz_type,
				marks: question.marks,
			};
			questionsSet.push(questionData);
			if (question.option_set) {
				optionSet[question.id] = question.option_set;
			}
		});

		await dispatch(replaceAssignmentQuestions(questionsSet));

		Object.keys(optionSet).forEach((questionId) => {
			dispatch(
				replaceAssignmentQuestionOptions({
					questionId: questionId,
					data: optionSet[questionId],
				})
			);
		});
	} catch (error) {
		console.log(error, 'error saving Assignment');
		ErrorMessageHandler(error);
	}
};

export const handleSaveAssignment = (assignmentData, courseSlug) => async (dispatch) => {
	try {
		let assignment_data = [];
		let quiz_data = [];
		let data = assignmentData.questions;
		data.forEach((value) => {
			if (value.quiz_type === 'long_answer') {
				assignment_data.push(value);
			} else {
				quiz_data.push(value);
			}
		});

		let body = {
			// title: assignmentData.title,
			// short_description: assignmentData.short_description,
			assignment_data: assignment_data,
			quiz_data: quiz_data,
		};
		let response = await fetchWrapper.post(`course/${courseSlug}/assignment/`, body);
		let assignmentsQuestion = response.data?.assignments || [];
		assignmentsQuestion = assignmentsQuestion.map((question) => {
			let newQues = { ...question };
			newQues.quiz_type = 'long_answer';
			return newQues;
		});
		let mcqsQuestions = response.data.mcqs || [];
		const questionsArray = [...assignmentsQuestion, ...mcqsQuestions];

		let optionSet = {},
			questionsSet = [];

		questionsArray.forEach((question, index) => {
			let questionData = {
				id: question.id,
				question: question.question,
				timeframe: question.timeframe,
				hint: question.hint,
				explanation: question.explanation,
				quiz_type: question.quiz_type,
				marks: question.marks,
			};
			questionsSet.push(questionData);
			if (question.option_set) {
				optionSet[question.id] = question.option_set;
			}
		});

		await dispatch(replaceAssignmentQuestions(questionsSet));

		Object.keys(optionSet).forEach((questionId) => {
			dispatch(
				replaceAssignmentQuestionOptions({
					questionId: questionId,
					data: optionSet[questionId],
				})
			);
		});
		dispatch(setUiMessage('Assignment saved successfully!'));
		window.scrollTo(0, 0);
	} catch (error) {
		console.log(error, 'error saving Assignment');
		ErrorMessageHandler(error);
	}
};

export const handleCourseSet = (course) => (dispatch) => {
	dispatch(setCourse(course));
};
export const handleCreateCertificate = () => (dispatch) => {
	let certificate = {
		has_certificate: true,
	};
	dispatch(setCertificate(certificate));
};
export const handleRemoveCertificate = () => (dispatch) => {
	dispatch(resetCertificate());
};

export const handleGetChapterByCourseSlug = (courseSlug) => async (dispatch) => {
	await fetchWrapper.get(`course/${courseSlug}/chapters/`);
};

export const handleCourseReset = () => (dispatch) => {
	dispatch(reset());
};

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
// import { HiOutlineFolderDownload } from 'react-icons/hi';
// import Header from '../../Layout/Header';
import {
	Link,
	useParams,
	// useHistory
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCourse, enrollParticipant } from '../../_redux/slices/course';
import YoutubeEmbedPreview from '../../components/YoutubeEmbed/YoutubeEmbedPreview';
import { createMarkup } from '../../_helpers/_methods';
import CourseContent from './CourseContent';
import { ThematicAreaBadge } from '../../components/ThematicAreaBadge';
// import NotEnrolledSidebar from './SideBar/NotEnrolledSidebar';
// import EnrolledSidebar from './SideBar/EnrolledSidebar';
import { getUserEnrolledCourse } from '../../_redux/slices/user';
import styled from 'styled-components';
// import Footer from '../../Layout/Footer';
import Layout from '../../Layout/Layout';
import Loading from '../../components/Loading';
import Reviews from './Reviews';
import { useHistory } from 'react-router-dom';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import StarRate from '../../components/StarRate';

export const SingleCoursePage = ({ match }) => {
	return (
		<Layout style={{ width: '80%', margin: 'auto' }} withBack={true}>
			<SingleCourse match={match} />
		</Layout>
	);
};

const SingleCourse = ({ match }) => {
	const history = useHistory();
	const { courseSlug } = useParams();
	const course = useSelector((state) => state.course.course);
	const loading = useSelector((state) => state.course.loading);
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const [isEnrolled, setIsEnrolled] = useState(false);
	const [rating, setRating] = useState({});

	useLayoutEffect(() => {
		dispatch(getCourse(courseSlug));
		if (user) {
			dispatch(getUserEnrolledCourse());
		}
	}, [courseSlug, dispatch, user]);

	useLayoutEffect(() => {
		if (course?.enrolled) {
			setIsEnrolled(true);
		} else {
			setIsEnrolled(false);
		}
	}, [course?.enrolled]);

	useLayoutEffect(() => {
		if (match.path !== '/dashboard/courses/course/:courseSlug') {
			if (isEnrolled) {
				history.push(`${match.url}/enrolled`);
			}
		}
	}, [isEnrolled, history, match.url, match.path]);


	const handleEnroll = () => {
		if (['participant', 'resource_person'].includes(user?.active_role)) {
			dispatch(enrollParticipant(courseSlug));
		} else {
			localStorage.setItem('lastLink', `/courses/${courseSlug}`);
			history.push('/login');
		}
	};

	useEffect(() => {
		const getCourseRating = async () => {
			try {
				const response = await fetchWrapper.get(`course/${courseSlug}/rating/`);
				const data = response.data;
				setRating(data);
			}
			catch (error) {
				console.log(error)
			}
		}
		getCourseRating();
	}, [courseSlug]);

	const thematicAreas = course?.thematic_area;
	return (
		<div className="full-width">
			{loading ? (
				<div style={{ height: '100vh' }}>
					<Loading fontSize="5rem" color="#2f80ed" />
				</div>
			) : (
				<StyledContainer fluid style={{ padding: '0' }}>
					<CourseHeader>
						<div className="course-header-container">
							<div className="course-header-content mr-2">
								<h2 className="course-title">{course?.title}</h2>
								<p
									className="text-justify short-desc"
								>
									{course?.short_description}
								</p>
								<small>Course By: {course?.created_by?.username}</small>
								<StarRate rating={rating} />
								<div className='mt-2'>
									{thematicAreas?.map((thematicArea) => (
										<ThematicAreaBadge key={thematicArea.id}>{thematicArea.title}</ThematicAreaBadge>
									))}
								</div>
								<div className="bg-white floating-card hidden-md-up">
									{course?.introductory_video ? (
										<YoutubeEmbedPreview youtubeLink={course?.introductory_video} />
									) : (
										<img
											src={
												course?.thumbnail_image || '/assets/images/placeholder.jpg'
											}
											alt=""
											className="course-inner-img floating-card-img"
										/>
									)}
									<div className="p-4">
										{
											['participant', 'resource_person'].includes(user?.active_role) &&
											<Link to={`${match.url}/enrolled`}>
												<Button className="w-100 my-2" onClick={handleEnroll}>
													<span className="w-100 text-center">Enroll Now</span>
												</Button>
											</Link>
										}
										<div className="course-include">
											<p style={{ color: '#333' }}>This course includes</p>
											<div className="d-flex">
												<img className="mr-1" src="/assets/icons/video.svg" alt="" />
												<small>{course?.video_count} Videos</small>
											</div>
											<div className="d-flex">
												<img className="mr-1" src="/assets/icons/paper.svg" alt="" />
												<small>{course?.document_count} Articles</small>
											</div>
											<div className="d-flex">
												<img className="mr-1" src="/assets/icons/downloadableResource.svg" alt="" />
												<small>{course?.document_count} Downloadable Resources</small>
											</div>
											{/* <div className="d-flex">
												<img className="mr-1" src="/assets/icons/certificate.svg" alt="" />
												<small>Certificate of Completion</small>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</CourseHeader>
					<Content>
						<div className="width">
							{!course?.meta?.objective?.length && <div className="course-feature">
								<h3>What you'll learn</h3>
								<ul className="course-feature-list">
									{course?.meta?.objective?.map((v, i) => (
										<li key={i}>{v}</li>
									))}
								</ul>
							</div>}

							<div>
								<CourseContent totalLesson={course?.lessons_count} courseSlug={courseSlug} />
							</div>
							<div className="mt-5 ">
								<h3 className="mb-2" style={{ color: '#2f80ed' }}>
									Description
								</h3>
								<p className="text-justify" dangerouslySetInnerHTML={createMarkup(course?.long_description)}></p>
							</div>
							<StyledReviews style={{ padding: '1rem 0' }}>
								<h3 className="mb-2" style={{ color: '#2f80ed' }}>
									Recent Reviews
								</h3>
								<Reviews isEnrolled={isEnrolled} courseSlug={courseSlug} />
							</StyledReviews>
						</div>
					</Content>
					<div className="bg-white floating-card-main">
						{course?.introductory_video ? (
							<YoutubeEmbedPreview youtubeLink={course?.introductory_video} />
						) : (
							<div className="floating-card-img">
								<img
									src={course?.thumbnail_image || '/assets/images/placeholder.jpg'}
									alt=""
									className="course-inner-img"
								/>
							</div>
						)}
						<div className="p-4">
							{
								['participant', 'resource_person'].includes(user?.active_role) &&
								<Link to={`${match.url}/enrolled`}>
									<Button className="w-100 my-2" onClick={handleEnroll}>
										<span className="w-100 text-center">Enroll Now</span>
									</Button>
								</Link>
							}
							<div className="course-include">
								<p style={{ color: '#333' }}>This course includes</p>
								<div className="d-flex">
									<img className="mr-1" src="/assets/icons/video.svg" alt="" />
									<small>{course?.video_count} Videos</small>
								</div>
								<div className="d-flex">
									<img className="mr-1" src="/assets/icons/paper.svg" alt="" />
									<small>{course?.lessons_count} Articles</small>
								</div>
								<div className="d-flex">
									<img className="mr-1" src="/assets/icons/downloadableResource.svg" alt="" />
									<small>{course?.document_count} Downloadable Resources</small>
								</div>
								{/* <div className="d-flex">
									<img className="mr-1" src="/assets/icons/certificate.svg" alt="" />
									<small>Certificate of Completion</small>
								</div> */}
							</div>
						</div>
					</div>
				</StyledContainer>
			)}
		</div>
	);
};

export default SingleCourse;

const CourseHeader = styled.div`
	position: relative;

	.course-header-container {
		width: 80%;
		margin: auto;
		display: flex;
		justify-content: flex-start;
		position: relative;
	}
	background-color: #2460b9;
	padding: 1rem;
	.course-header-content {
		.short-desc {
			width: 60%;
			a,
			p,
			span {
				font-family: 'Poppins', sans-serif !important;
				font-style: normal !important;
				font-weight: 400 !important;
				font-size: 16px !important;
				line-height: 22px !important;
				background-color: transparent !important;
				text-decoration: none !important;
				color: white !important;
			}
			margin-bottom: 1rem;
		}
		h2,
		p,
		small {
			color: white;
		}
		small {
			font-family: 'Roboto', sans-serif !important;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
		}
		h2 {
			font-family: 'Poppins', sans-serif !important;
			font-style: normal;
			font-weight: 500;
			font-size: 40px;
			line-height: 56px;
		}
	}
	.floating-card {
		position: fixed;
		width: 27.5vw;
		right: 5vw;
		top: 25vh;
		z-index: 2;
		box-shadow: 0px 0px 16px 8px #2460b920;
		padding: 0.125rem;
		overflow: hidden;
		&-img {
			width: 100%;
			aspect-ratio: 4 / 3;
			overflow: hidden;
			object-fit: cover;
		}
		.course-include {
			p,
			small {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 2;
				color: #4f4f4f;
			}
		}
	}

	@media screen and (max-width: 1024px) {
		.floating-card {
			width: 30vmax;
		}
		@media screen and (max-width: 768px) {
			width: 100%;
			padding: 0;
			box-shadow: 0 5px 5px 5px #2460b920;
			margin-bottom: 2rem;
			background-color: white;
			.course-header-container {
				width: 100%;
				display: grid;
				grid-template-columns: 100%;
				grid-template-areas: 'float' 'content';
			}
			.course-header-content {
				/* grid-area: content; */
				display: grid;
				grid-template-columns: 1fr;
				grid-template-areas: 'float' 'content';
				padding: 0 2rem;
				padding-bottom: 2rem;
				h2,
				p,
				small {
					width: 100%;
					color: #333;
				}
				.course-title {
					font-weight: 500;
					font-size: 18px !important;
					line-height: 1.5;
				}
				.short-desc {
					width: 100%;
					*,
					a,
					p,
					span {
						color: #333 !important;
						font-size: 16px !important;
						line-height: 1.5;
					}
				}
			}
			.floating-card {
				grid-area: float;
				width: 100%;
				position: relative;
				top: 0;
				right: 0;
				box-shadow: none;
				.course-include {
					display: none;
				}
			}
		}
	}
`;

const Content = styled.div`
	display: flex;
	width: 80%;
	margin: auto;
	padding: 1rem;

	.course-feature {
		/* width: 60%; */
		margin-top: 0.5rem;
		background-color: #f6fafd;
		padding: 1rem;
		border: 1px solid #333333;
		border-radius: 0.5rem;
		line-height: 2;
		ul {
			margin-top: 0.5rem;
		}
		li {
			list-style: inside;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			text-align: justify;
			color: #333333;
			margin: 0.5rem;
		}
	}
	.width {
		width: 60%;
	}
	@media screen and (max-width: 1024px) {
		.course-feature {
			width: 40%;
		}
	}
	@media screen and (max-width: 768px) {
		width: 100%;
		padding: 0 2rem;
		.course-feature {
			width: 100%;
		}
		.width {
			width: 100%;
		}
	}
`;

const StyledReviews = styled.div`
	/* width: 60%; */
	padding: 1rem;
	@media screen and (max-width: 768px) {
		width: 100%;
		padding: 0 2rem;
	}
`;

const StyledContainer = styled(Container)`
	position: relative;
	/* height: 100vh; */
	/* overflow: auto; */
	.floating-card-main {
		z-index: 1;
		position: fixed;
		width: 27.5vw;
		top: 16vh;
		right: 5rem;
		box-shadow: 0px 0px 16px 8px #2460b920;
		padding: 0.125rem;
		overflow: hidden;
		&-img {
			width: 100%;
			height: 15vmax;
		}
		.course-include {
			p,
			small {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 2;
				color: #4f4f4f;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.floating-card-main {
			display: none;
		}
	}
`;

import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React from 'react';
import { useState, Fragment } from 'react';
import { useEffect } from 'react';
import { FormGroup } from './FormGroup/FormGroup';

function EditorInput({ label, id, info, defaultValue, errors, register, setValue, formattedLabelId }) {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const onChangeHandler = (e) => {
		setEditorState(e);
		setValue(id, draftToHtml(convertToRaw(e.getCurrentContent())));
	};
	useEffect(() => {
		if (defaultValue) {
			const contentBlock = htmlToDraft(defaultValue);
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const editorState = EditorState.createWithContent(contentState);
				setEditorState(editorState);
				setValue(id, draftToHtml(convertToRaw(editorState.getCurrentContent())));
			}
		}
	}, [setValue, defaultValue, id]);
	return (
		<Fragment>
			<FormGroup
				id={id}
				label={label}
				info={info}
				type="editor"
				// disabled={isLoading}
				placeholder="Aa"
				defaultValue={editorState}
				error={errors}
				onChange={onChangeHandler}
				formattedLabelId={formattedLabelId}
			/>
			<input type="text" {...register(id)} hidden readOnly />
		</Fragment>
	);
}

export default EditorInput;

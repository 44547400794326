import styled from "styled-components";

export const StyledChapterBuilder = styled.div``;
export const ChapterBuilderContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;
export const ChapterBar = styled.div`
  width: 420px;
  border-right: 1px solid gray;
`;
export const StyledChapterBox = styled.div`
  border: 1px solid gray;
  border-radius: 5px;
  margin: 0 0.8rem 0.8rem 0;
  padding: 00.5rem;
  h4 {
    margin-bottom: 0.8rem;
  }
`;

export const StyledChapterActions = styled.div`
  margin-top: 0.4rem;
`;
export const StyledLessonBox = styled.div`
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 0.8rem;
  padding: 0.5rem;
  cursor: pointer;
`;

export const MainArea = styled.div`
  flex: 1;
  padding: 0 0.8rem;
`;

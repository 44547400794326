import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import {
  handleAddQuizQuestion,
  handleSaveQuiz,
} from "../../../../../../_redux/slices/courseData";

import { FaPlus, FaSave } from "react-icons/fa";
import QuestionContent from "../../../../../../components/QuestionContent";
// import QuestionContent from "./QuestionContent";

const StyledHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;
const StyledQuestion = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 6px 6px 10px 0px #dbdbdb;
`;
function Quiz({ chapter, chapterId, quiz, handleQuizSubmitting }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      handleQuizSubmitting(true);
      let ObjValues = Object.values(data.question);

      let tempQuizData = ObjValues.map(quiz => {
        let tempOptions = quiz.options.map(option => ({ ...option, point: quiz.marks }));
        quiz.options = tempOptions;
        return quiz;
      })
      let body = {
        quiz_data: tempQuizData,
      };
      await dispatch(handleSaveQuiz(body, chapter.slug));
      handleQuizSubmitting(false);
    } catch (error) {
      handleQuizSubmitting(false);
      console.log(error, "quiz save error");
    }
  };
  const handleAddQuestion = () => {
    dispatch(handleAddQuizQuestion(chapterId));
  };

  return (
    <Fragment>
      <Form key={chapterId} onSubmit={handleSubmit(onSubmit)}>
        <StyledHead>
          <Button variant="success" type="submit">
            <FaSave className="mr-2" />
            Save
          </Button>
        </StyledHead>
        <Row>
          {quiz.map((question, index) => {
            const fieldName = `question[${question.id}]`;
            return (
              <Col key={question.id} md={12}>
                <StyledQuestion>
                  <QuestionContent
                    index={index + 1}
                    chapterId={chapterId}
                    question={question}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    clearErrors={clearErrors}
                    fieldName={fieldName}
                  />
                </StyledQuestion>
              </Col>
            );
          })}
        </Row>
        <Button variant="primary" onClick={handleAddQuestion}>
          <FaPlus className="mr-2" />
          Add Question
        </Button>
        <hr />
        <Button variant="success" type="submit">
          <FaSave className="mr-2" />
          Save
        </Button>
      </Form>
    </Fragment>
  );
}

export default Quiz;

export const isRequiredValidator = (isValid, files, errorName, id) => {
  let hasFileSelected = files[0]?.name;
  if (isValid || hasFileSelected) {
    return true;
  }
  return `${errorName || id} is required`;
};

export const fileSizeValidator = (isValid, size, files) => {
  let fileSize = size * 1024 * 1024;
  if (isValid && !files[0]?.size) {
    return true;
  }
  let result = true;
  for (let i = 0; i < files.length; i++) {
    if (files[i]?.size > fileSize) {
      result = `File Size Exceeded! Max file size is ${size}MB`;
      break;
    }
  }
  return result;
};

export const fileTypeValidator = (isValid, validTypes, files) => {
  if (isValid && !files[0]?.type) {
    return true;
  }
  let result = true;
  for (let i = 0; i < files.length; i++) {
    if (!validTypes.includes(files[i]?.type)) {
      result = `Invalid File type! Only ${validTypes.map(
        (type) => `${type} `
      )} are allowed`;
      break;
    }
  }
  return result;
};

export const maxFilesValidator = (isValid, maxFiles, files) => {
  if (isValid && !files?.length) {
    return true;
  }
  let result = true;
  console.log(files.length);
  if (files.length > maxFiles) {
    result = `Max ${maxFiles} files are allowed`;
  }
  return result;
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../../Layout/Layout";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { numFormatter } from "../../_helpers/_methods";

const AboutUs = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const getStats = async () => {
      try {
        let response = await fetchWrapper.get("home/");
        setData(response?.data);
      } catch (error) {}
    };
    getStats();
  }, []);

  return (
    <Layout>
      <AboutUsContainer>
        <div className="landing">
          <div className="landing-header">
            <h1 className="text-primary">
              Helping Provincial and Local Government
            </h1>
            <h1 className="text-primary"> Officers to Grow Better...</h1>
          </div>
          <img src="/assets/images/aboutus.svg" alt="" />
        </div>
        <div className="missions d-flex align-items-center justify-content-between">
          <img src="/assets/images/mission.png" alt="" />
          <div className="w-50">
            <h1 className=" text-primary mb-4">Our Mission</h1>
            <ul>
              <li> To compile the training materials from events conducted</li>

              <li>
                {" "}
                To revise and review the knowledge gained from trainings{" "}
              </li>

              <li> To manage/store resource materials in systematic way</li>

              <li> For proper evaluation of participants after session</li>

              <li>To prepare training report </li>
            </ul>
          </div>
        </div>
        <div className="numbers">
          <h1 className="text-center text-white numbers-title">
            KMS at a glance
          </h1>
          <div className="d-flex justify-content-between align-items-center">
            <div className="number">
              <h1 className="text-white">
                {numFormatter(data?.total_resource_person)}
              </h1>
              <hr />
              <h3 className="text-white">Instructors</h3>
            </div>
            <div className="number">
              <h1 className="text-white">
                {numFormatter(data?.total_courses)}
              </h1>
              <hr />
              <h3 className="text-white">Total Courses</h3>
            </div>
            <div className="number">
              <h1 className="text-white">
                {numFormatter(data?.total_particpant)}
              </h1>
              <hr />
              <h3 className="text-white">Active Learners</h3>
            </div>
          </div>
        </div>
        <div className="story">
          <h1 className="text-primary text-center story-title">Our Story</h1>
          <div className="d-flex">
            <div>
              <img src="/assets/images/video.png" alt="" />
            </div>
            <div>
              <p className="text-justify">
                KMS (Knowledge Management System) is the platform (digital
                library) for participants to learn contents on different
                thematic areas provided by Resource person and
                controlled/managed by admin registered in CDMIS.
              </p>
              <p>
                KMS is a technology that lets resource persons to add resources
                on their preferred topics by uploading videos, PowerPoint
                presentations, PDFs, Doc files, jpeg files, audio, and other
                information providers which has to be approved by admin to get
                published.
              </p>
            </div>
          </div>
        </div>
      </AboutUsContainer>
    </Layout>
  );
};

export default AboutUs;

const AboutUsContainer = styled.div`
  min-height: 90vh;
  margin-inline: auto;
  padding-block: 2rem;
  h1 {
    font-size: 48px !important;
    line-height: 1.32 !important;
  }
  .landing {
    display: flex;
    align-items: center;
    padding-block: 4rem;
    padding-inline: 10vw;
    .landing-header {
      width: 50%;
    }
  }
  .missions {
    padding-inline: 10vw;
    padding-block: 4rem;
    ul li {
      color: #333;
      list-style-position: outside;
      list-style-type: disc;
    }
  }
  .numbers {
    background-color: #2f80ed;
    padding-inline: 10vw;
    padding-block: 48px;
    &-title {
      margin-bottom: 48px;
    }
    .number {
      width: 25%;
      h1 {
        font-size: 48px !important;
      }
      hr {
        background-color: white;
        height: 2px;
      }
    }
  }
  .story {
    padding-inline: 10vw;
    padding-block: 4rem;
    &-title {
      margin-bottom: 48px;
    }
    .d-flex {
      gap: 2.5rem;
      div {
        width: 50%;
      }
    }
    p {
      margin-block-end: 1rem;
    }
  }
`;

import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaPlayCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Layout from '../../Layout/Admin/Layout';

const Dashboard = () => {
	return (
		<Layout>
			<div className="welcome-jumbotron">
				<h3>Welcome Participant</h3>
				<h2>Dashboard</h2>
			</div>
			<div className="main-content-inner">
				<Container>
					<div className="dashboard-stats">
						<Row>
							<Col md={8}>
								<Card className="mb-3">
									<Card.Header className="d-flex justify-content-between">
										<div className="media-body">
											<h4 className="card-title">Recent Courses</h4>
										</div>

										<div className="media-right">
											<Link to="" className="btn btn-sm btn-primary">
												My courses
											</Link>
										</div>
									</Card.Header>

									<ul className="list-group list-group-fit mb-0">
										<li className="list-group-item">
											<Row>
												<Col md={2}>
													<img
														src="http://103.69.126.169/assets/gal3-9e4f42518d549365f0fedb62e34621c91b3d165d9e8b2fa48ccd26945df8b15e.jpg"
														alt="course"
														className="course-img rounded"
													/>
												</Col>
												<Col md={7}>
													<div className="d-flex">
														<div className="flex">
															<strong>शहरी तथा वस्ती विकास, भवन आचार संहिता</strong>

															<p className="text-muted text-small">
																There are many variations of passages of Lorem Ipsum available, but the majority have
																suffered alteration in some form, by injected humour, or randomised words which don't
																look even slightly believable.
															</p>
														</div>
													</div>
												</Col>
												<Col md={3}>
													<div className="d-flex align-items-center mb-2">
														<div className="progress" style={{ width: '100px' }}>
															<div
																className="progress-bar bg-warning"
																role="progressbar"
																style={{ width: '25%' }}
																aria-valuenow="25"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
														<small className="text-muted ml-1">25%</small>
													</div>

													<p className="mr-2 mb-1">Enrolled: Oct 1, 2021</p>

													<Button variant="success" className="btn-sm">
														Continue <FaPlayCircle />
													</Button>
												</Col>
											</Row>
										</li>
										<li className="list-group-item">
											<Row>
												<Col md={2}>
													<img
														src="http://103.69.126.169/assets/gal5-9716ab657f64b1c98c529cf7e4f8fcc12ff02f44bcd8c53961613a8f78be1d60.jpg
                          "
														alt="course"
														className="course-img rounded"
													/>
												</Col>
												<Col md={7}>
													<div className="d-flex">
														<div className="flex">
															<strong>कर र राजस्व व्यवस्थापन</strong>

															<p className="text-muted text-small">
																There are many variations of passages of Lorem Ipsum available, but the majority have
																suffered alteration in some form, by injected humour, or randomised words which don't
																look even slightly believable.
															</p>
														</div>
													</div>
												</Col>
												<Col md={3}>
													<div className="d-flex align-items-center mb-2">
														<div className="progress" style={{ width: '100px' }}>
															<div
																className="progress-bar bg-warning"
																role="progressbar"
																style={{ width: '75%' }}
																aria-valuenow="75"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
														<small className="text-muted ml-1">75%</small>
													</div>
													<p className="mr-2">Enrolled: Sep 23, 2021</p>

													<Button variant="success" className="btn-sm ">
														Continue <FaPlayCircle />
													</Button>
												</Col>
											</Row>
										</li>
										<li className="list-group-item">
											<Row>
												<Col md={2}>
													<img
														src="http://103.69.126.169/assets/gal1-e5ce6993d93d43397e0d528d1b0b0e2efadd0ab129f621848043244e565173d6.jpg"
														alt="course"
														className="course-img rounded"
													/>
												</Col>
												<Col md={7}>
													<div className="d-flex">
														<div className="flex">
															<strong>नेतृत्व विकास र सशक्तिकरण</strong>

															<p className="text-muted text-small">
																There are many variations of passages of Lorem Ipsum available, but the majority have
																suffered alteration in some form, by injected humour, or randomised words which don't
																look even slightly believable.
															</p>
														</div>
													</div>
												</Col>
												<Col md={3}>
													<div className="d-flex align-items-center mb-2">
														<div className="progress" style={{ width: '100px' }}>
															<div
																className="progress-bar bg-success"
																role="progressbar"
																style={{ width: '100%' }}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
														<small className="text-muted ml-1">100%</small>
													</div>
													<p className="mr-2">Enrolled: Sep 27, 2021</p>

													<Button variant="success" className="btn-sm">
														Continue <FaPlayCircle />
													</Button>
												</Col>
											</Row>
										</li>
									</ul>
								</Card>
								<Card>
									<Card.Header className="d-flex justify-content-between">
										<div className="media-body">
											<h4 className="card-title">Quizzes</h4>
										</div>
										<div className="media-right">
											<Link to="" className="btn btn-sm btn-primary">
												Quiz results
											</Link>
										</div>
									</Card.Header>

									<ul className="list-group mb-0">
										<li className="list-group-item">
											<div className="d-flex justify-content-between align-items-center">
												<div className="media-body">
													<Link to="" className="text-body">
														<strong>Title of quiz goes here?</strong>
													</Link>
													<div className="d-flex align-items-center">
														<small className="text-uppercase mr-2">Course</small>
														<Link to="" className="text-primary">
															Basics of HTML
														</Link>
													</div>
												</div>
												<div className="media-right text-center d-flex align-items-center">
													<span className="mr-3">Good</span>
													<h4 className="mb-0">5.8</h4>
												</div>
											</div>
										</li>

										<li className="list-group-item">
											<div className="d-flex justify-content-between align-items-center">
												<div className="media-body">
													<Link to="" className="text-body">
														<strong>Directives &amp; Routing</strong>
													</Link>
													<div className="d-flex align-items-center">
														<small className="text-uppercase mr-2">Course</small>
														<Link to="" className="text-primary">
															Angular in Steps
														</Link>
													</div>
												</div>
												<div className="media-right text-center d-flex align-items-center">
													<span className="mr-3">Great</span>
													<h4 className="mb-0 text-success">9.8</h4>
												</div>
											</div>
										</li>

										<li className="list-group-item">
											<div className="d-flex justify-content-between align-items-center">
												<div className="media-body">
													<Link to="" className="text-body">
														<strong>Responsive &amp; Retina</strong>
													</Link>
													<div className="d-flex align-items-center">
														<small className="text-uppercase mr-2">Course</small>
														<Link to="" className="text-primary">
															Bootstrap Foundations
														</Link>
													</div>
												</div>
												<div class="media-right text-center d-flex align-items-center">
													<span class="mr-3">Failed</span>
													<h4 class="mb-0 text-danger">2.8</h4>
												</div>
											</div>
										</li>
									</ul>
								</Card>
							</Col>
							<Col md={4}>
								<Card>
									<Card.Header className="d-flex justify-content-between">
										<div className="media-body">
											<h4 className="card-title">Thematic Areas</h4>
										</div>
										<div className="media-right">
											<Link to="" className="btn btn-sm btn-primary">
												View All
											</Link>
										</div>
									</Card.Header>
									<ul className="list-group list-group-fit mb-0">
										<li className="list-group-item">
											<div className="d-flex justify-content-between align-items-center">
												<div className="media-body">
													<Link to="" className="text-body">
														<strong>संघीय शासन प्रणाली र स्थानीय शासन</strong>
													</Link>
												</div>
												<div class="media-right">
													<div class="text-center">
														<kbd class="bg-primary">15</kbd>
													</div>
												</div>
											</div>
										</li>
										<li className="list-group-item">
											<div className="d-flex justify-content-between align-items-center">
												<div className="media-body">
													<Link to="" className="text-body">
														<strong>नेतृत्व विकास र सशक्तिकरण</strong>
													</Link>
												</div>
												<div class="media-right">
													<div class="text-center">
														<kbd class="bg-primary">15</kbd>
													</div>
												</div>
											</div>
										</li>
										<li className="list-group-item">
											<div className="d-flex justify-content-between align-items-center">
												<div className="media-body">
													<Link to="" className="text-body">
														<strong>सार्वजनिक वित्त व्यवस्थापन</strong>
													</Link>
												</div>
												<div class="media-right">
													<div class="text-center">
														<kbd class="bg-primary">15</kbd>
													</div>
												</div>
											</div>
										</li>
										<li className="list-group-item">
											<div className="d-flex justify-content-between align-items-center">
												<div className="media-body">
													<Link to="" className="text-body">
														<strong>सूचना प्रविधि र विधुत्तीय शासन</strong>
													</Link>
												</div>
												<div class="media-right">
													<div class="text-center">
														<kbd class="bg-primary">15</kbd>
													</div>
												</div>
											</div>
										</li>
									</ul>
								</Card>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</Layout>
	);
};

export default Dashboard;

import React from 'react';
import { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
// import FileInput from '../../../../../../components/FileInput';
import AddResource from './AddResource';

function LessonAttachments({ setValue, errors, handleAttachment, lesson }) {
	const [hasAttachments, setHasAttachments] = useState(true);
	return (
		<Col md={12} className="mt-4" key={lesson.id}>
			<div>
				<h4>Attachment</h4>
			</div>
			{hasAttachments ? (
				<AddResource setValue={setValue} handleOnSubmit={handleAttachment} />
			) : (
				<div className="mt-3 ">
					<Button onClick={() => setHasAttachments(true)}>Add Attachments</Button>
				</div>
			)}
		</Col>
	);
}

export default LessonAttachments;

import React, { Fragment } from 'react';
import LessonHeader from '../../Layout/LessonLayout/LessonHeader';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getQuizFromChapter, submitQuizAnswer } from '../../_redux/slices/course';
import QuizStartCard from './components/QuizStartCard';
import { useState } from 'react';
import Question from '../../components/Question/Question';

import styled from 'styled-components';
import Sidebar from './components/Sidebar';
import QuizResultBanner from './components/QuizResultBanner';
import { slugToTitle } from '../../_helpers/_methods';
// import QuizResult from './components/QuizResult';

const StyledQuestionContainer = styled.div`
	width: 80%;
	margin: auto;
	margin-top: 1rem;
	box-shadow: 0px 6px 15px rgba(64, 83, 145, 0.25);
	padding: 2rem;
	@media screen and (max-width: 1024px) {
		width: 100%;
		box-shadow: none;
		padding: 1rem;
	}
`;
const Quiz = () => {
	const { chapterSlug, courseSlug } = useParams();
	const dispatch = useDispatch();
	const quiz = useSelector((state) => state.course.quizes[chapterSlug]);
	const course = useSelector(state => state?.course?.course);
	const [quizAnswers, setQuizAnswers] = useState([]);

	let currentChapter = course?.enrolled?.current_chapter;
	currentChapter = currentChapter ? slugToTitle(currentChapter) : ''

	const [question, setQuestion] = useState(null);
	const [questionIndex, setQuestionIndex] = useState(null);
	const [showResult, setShowResult] = useState(false);


	useEffect(() => {
		if (chapterSlug) {
			dispatch(getQuizFromChapter(chapterSlug));
		}
	}, [chapterSlug, dispatch]);

	useEffect(() => {
		if (quiz?.length) {
			setQuestion(quiz[questionIndex]);
		}
	}, [questionIndex, quiz]);

	const startQuiz = () => {
		setQuestionIndex(0);
	};

	const handleQuestionIndexCounterChange = (action) => {
		if (action === 'next') {
			let quizCount = Number(questionIndex);
			setQuestionIndex(quizCount + 1);
		}
	};

	const hasQuizEnded = Number(questionIndex) > quiz?.length - 1;

	useEffect(() => {
		if (hasQuizEnded) {
			async function submitQuiz() {
				dispatch(submitQuizAnswer(chapterSlug, quizAnswers, setShowResult));
			}
			submitQuiz();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chapterSlug, dispatch, hasQuizEnded]);

	const handleSetAnswersId = (answerIds) => {
		setQuizAnswers(prev => [...prev, ...answerIds]);
	}

	return (
		<>
			<LessonHeader />
			<Container>
				<Sidebar chapterSlug={chapterSlug} courseSlug={courseSlug} />

				<div className="content">
					{questionIndex === null ? (
						<QuizStartCard quiz={quiz} startQuiz={startQuiz} currentChapter={currentChapter} />
					) : showResult ? (
						// <QuizResult courseSlug={courseSlug} chapterSlug={chapterSlug} />
						<QuizResultBanner chapterSlug={chapterSlug} courseSlug={courseSlug} />
					) : (
						<Fragment>
							{question ? (
								<StyledQuestionContainer>
									<div className="question-header">
										<h3>
											QUESTION {questionIndex + 1} OF {quiz?.length}:
										</h3>
									</div>
									<Question
										question={question}
										handleQuestionIndexCounterChange={handleQuestionIndexCounterChange}
										chapterSlug={chapterSlug}
										handleSetAnswersId={handleSetAnswersId}
									/>
								</StyledQuestionContainer>
							) : (
								<QuizResultBanner />
							)}
						</Fragment>
					)}
				</div>
			</Container>
		</>
	);
};

export default Quiz;

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-template-areas: 'sidebar content';
	column-gap: 1rem;
	min-height: 90vh;
	.content {
		grid-area: 'content';
		padding: 2rem 0;
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-template-areas: 'content';
		.assignment-sidebar {
			display: none !important;
		}
	}
`;

import React, { Fragment, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useState } from "react";
// import styled from 'styled-components';
import { ContentState, convertToRaw, EditorState } from "draft-js";

import { FormGroup } from "../../../../components/FormGroup/FormGroup";

import draftToHtml from "draftjs-to-html";
import AddResource from "../../Courses/AddNewCourse/ChapterBuilder/LessonArea/AddResource";
import {
  StyledFormGroup,
  StyledFormLabel,
} from "../../../../components/Reuseables";
import { fetchWrapper } from "../../../../_helpers/fetchWrapper";
import { toast } from "react-toastify";
import { useEffect } from "react";
import htmlToDraft from "html-to-draftjs";
import Documentary from "./Documentary";

/**
 *
 */
export default function DocumentaryForm({
  documentary,
  hideForm,
  showPreview,
  setShowPreview,
}) {
  const isEdit = Boolean(documentary);
  const [documentaryContentState, setDocumentaryContentState] = useState(
    EditorState.createEmpty()
  );
  const [preview, setPreview] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [videoUri, setVideoUri] = useState("");

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      "banner-title": documentary?.title,
      is_public: documentary?.is_public ?? true,
      media: documentary?.uri,
    },
  });

  useEffect(() => {
    if (!documentary) return;

    const contentBlock = htmlToDraft(documentary.text);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const textState = EditorState.createWithContent(contentState);
      setDocumentaryContentState(textState);
    }
    setVideoUri(documentary?.uri);
  }, [setValue, documentary]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const body = {
        title: data["banner-title"],
        text: draftToHtml(
          convertToRaw(documentaryContentState.getCurrentContent())
        ),
        is_public: data.is_public,
        uri: videoUri,
      };

      if (isEdit) {
        await fetchWrapper.patch(`documentary/${documentary.id}/`, body);
        toast.success("Documentary updated Successfully!");
      } else {
        await fetchWrapper.post(`documentary/`, body);
        toast.success("Documentary uploaded Successfully!");
      }
      setIsLoading(false);

      hideForm();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleCancelForm = () => {
    setDocumentaryContentState(EditorState.createEmpty());
    hideForm();
    setShowPreview(false);
  };

  const handleShowPreview = useCallback(() => {
    let temp = {
      videoUri: videoUri,
      bannerTitle: getValues("banner-title"),
      content: draftToHtml(
        convertToRaw(documentaryContentState.getCurrentContent())
      ),
    };
    setPreview(true);
    setPreviewData(temp);
    setShowPreview(true);
  }, [documentaryContentState, getValues, setShowPreview, videoUri]);

  const handleResourceAdd = (resources) => {
    if (resources.length > 0) {
      setVideoUri(resources[0].media_url);
    }
  };

  const handleHidePreview = () => {
    setShowPreview(false);
  };

  useEffect(() => {
    if (showPreview) {
      handleShowPreview();
    }
  }, [handleShowPreview, showPreview]);

  return (
    <Fragment>
      <Button variant="warning" onClick={handleCancelForm}>
        Cancel
      </Button>

      <div className="card-sm course-tab mt-3" style={{ width: "100%" }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {preview && <Documentary previewData={previewData} />}

          {!preview && (
            <>
              <Row>
                <Col md={12}>
                  <FormGroup
                    id="banner-title"
                    errorName="Documentary Title"
                    label="Documentary Title"
                    type="text"
                    placeholder="Enter Documentary title"
                    register={register}
                    required={true}
                    // disabled={isLoading}
                    error={errors}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup
                    label="Documentary detail"
                    id="story-content"
                    type="editor"
                    // disabled={isLoading}
                    placeholder="Enter content"
                    defaultValue={documentaryContentState}
                    error={errors}
                    onChange={(e) => setDocumentaryContentState(e)}
                    editorStyle={{ minHeight: "200px" }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup
                    label="Make public?"
                    id="is_public"
                    register={register}
                    type="checkbox"
                    error={errors}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={3}>
                  <StyledFormGroup className="mb-1">
                    <StyledFormLabel>
                      <span>Documentary Video</span>
                    </StyledFormLabel>
                  </StyledFormGroup>

                  <AddResource
                    setValue={setValue}
                    isMulti={false}
                    fixedType="video"
                    handleOnSubmit={handleResourceAdd}
                    label="Click to select Documentary Video"
                  />
                </Col>
              </Row>
            </>
          )}

          {!showPreview && (
            <Row className="mt-3">
              <Col md={12} className="d-flex">
                {preview && (
                  <Button
                    variant="secondary"
                    className="mr-4"
                    onClick={handleHidePreview}
                    disabled={isLoading}
                  >
                    Edit
                  </Button>
                )}

                {!preview && (
                  <Button
                    variant="secondary"
                    className="mr-4"
                    onClick={handleShowPreview}
                    disabled={isLoading}
                  >
                    Preview
                  </Button>
                )}

                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </Fragment>
  );
}

// const ImgHolder = styled.div`
// 	padding: 0px 30%;
// 	img {
// 		max-width: 100%;
// 	}
// `;

import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DynamicIcon } from '../../_helpers/DynamicIcon';

const StyledDropdown = styled(Dropdown)`
	#dropdown-custom-components {
		background-color: transparent !important;
		border: none;
		color: #2460b9;
		width: 16px;
		height: 100%;
		display: grid;
		place-content: center;
		&:focus {
			box-shadow: none;
		}
		&::after {
			display: none;
		}
	}
	.dropdown-menu {
		right: 0 !important;
		left: unset !important;
		top: 0 !important;
		bottom: unset !important;
		.dropdown-item {
			margin: 0 !important;
			padding: 0.5rem 1rem !important;
			width: 100%;
			font-size: 16px !important;
			&:active,
			&:focus {
				color: white !important;
				background-color: $primary !important;
			}

			* {
				margin: 0 !important;
				padding: 0 !important;
				width: 100%;
				font-size: 16px !important;

				&:active {
					color: #ffffff !important;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.dropdown-menu {
			left: -105px !important;
			right: unset !important;
		}
	}
`;

function DropDown({ label, labelClasses, dropToggleBtnClasses, dropDownItems }) {
	return (
		<StyledDropdown>
			<Dropdown.Toggle id="dropdown-custom-components" className={`d-flex ${dropToggleBtnClasses ?? ''}`}>
				<span className={`menu-item d-flex align-items-center ${labelClasses ?? ''}`}>
					{label && <div className="mr-1">{label}</div>}
					<img src="/assets/icons/dropdown.svg" alt="dropdown" />
				</span>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{dropDownItems.map((item) => {
					if (item.action) {
						return (
							<Dropdown.Item as="button" key={item.title} disabled={item.disabled} onClick={item.action}>
								<DynamicIcon name={item.icon} />
								{item.title}
							</Dropdown.Item>
						);
					}
					if (item.path) {
						return (
							<Link to={item.path} key={item.title} className="dropdown-item" disabled={item.disabled}>
								<DynamicIcon name={item.icon} />
								{item.title}
							</Link>
						);
					}
					return (
						<Dropdown.Item key={item.title} disabled={item.disabled} className="text-ellipsis">
							<DynamicIcon name={item.icon} />
							{item.title}
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</StyledDropdown>
	);
}

export default DropDown;

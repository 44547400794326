import React from "react";
import { Tab } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { StyledTabs } from "../../components/Reuseables";
import Layout from "../../Layout/Layout";
import ContentList from "./ContentList";

const KnowledgeProducts = () => {
  const { subcategory } = useParams();
  const history = useHistory();

  return (
    <Layout>
      <KnowledgeProductContainer>
        <h1 className="main-title">Knowledge Product</h1>
        <StyledTabs defaultActiveKey={subcategory}>
          <Tab
            tabClassName="p-0"
            eventKey="story"
            title={
              <div
                className="w-100 p-3 btn-link text-dark text-decoration-none"
                onClick={() => {
                  history.push("/knowledge-products/story");
                }}
              >
                Success Stories
              </div>
            }
          >
            <ContentList url="story" path="/knowledge-products/story" />
          </Tab>
          <Tab
            eventKey="banner"
            tabClassName="p-0"
            title={
              <div
                className="w-100 p-3 btn-link text-dark text-decoration-none"
                onClick={() => {
                  history.push("/knowledge-products/banner");
                }}
              >
                Poster/Banner
              </div>
            }
          >
            <ContentList url="banner" path="/knowledge-products/banner" />
          </Tab>
          <Tab
            tabClassName="p-0"
            title={
              <div
                className="w-100 p-3 btn-link text-dark text-decoration-none"
                onClick={() => {
                  history.push("/knowledge-products/documentary");
                }}
              >
                Documentaries
              </div>
            }
            eventKey="documentary"
          >
            <ContentList
              url="documentary"
              path="/knowledge-products/documentary"
            />
          </Tab>
        </StyledTabs>
      </KnowledgeProductContainer>
    </Layout>
  );
};

export default KnowledgeProducts;

const KnowledgeProductContainer = styled.div`
  width: 95%;
  margin-inline: auto;
  padding: 0.5rem;
  min-height: 90vh;
  @media screen and (min-width: 720px) {
    width: 80%;
    padding: 1rem;
  }
`;

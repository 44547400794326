import styled from 'styled-components';

// Define a default UI for filtering
const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
  // const count = preFilteredRows.length;
  return (
    <StyledDefaultColumnFilter
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Type to Search...`}
    />
  );
};

export default DefaultColumnFilter;

const StyledDefaultColumnFilter = styled.input`
  width: 100%;
`;

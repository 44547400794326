import dayjs from "dayjs";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { HiCheckCircle } from "react-icons/hi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactTable from "../../../components/ReactTable";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import ResourcePreview from "./ResourcePreview";

function ContentTable({
  page,
  columns,
  title,
  isPendingView,
  isMyResourcesView,
  hideDelete,
}) {
  const [resources, setResources] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(page);
  const isDataOutDated = useSelector((state) => state.resources.isDataOutDated);

  useEffect(() => {
    try {
      const getCategoryContent = async () => {
        setIsLoading(true);

        let url = "";
        if (isMyResourcesView) {
          url += "resources/me/";
          url += `?offset=${(pageNo - 1) * 10}${
            title ? `&title=${title}` : ""
          }`;
        } else {
          url += "resources/";
          url += `?offset=${(pageNo - 1) * 10}${
            title ? `&title=${title}` : ""
          }`;
          url += `${isPendingView ? `&approved=false` : `&approved=true`}`;
        }

        const res = await fetchWrapper.get(url);
        let data = res?.data?.results?.map((resource, i) => ({
          sn: (pageNo - 1) * 10 + i + 1,
          title: resource?.title,
          type: resource?.type,
          created_by: resource?.username,
          status: resource?.approved ? "Approved" : "Pending",
          created_at: dayjs(resource?.created_at).format("YYYY-MM-DD, hh:mm A"),
          actions: (
            <div className="text-center d-flex align-items-center">
              <ResourcePreview id={resource?.id} />

              {hideDelete ? null : (
                <FaTrashAlt
                  className="ml-2 text-danger cursor-pointer"
                  onClick={async () => {
                    let res = await fetchWrapper.delete(
                      `/resources/${resource?.id}/`
                    );
                    toast.success(res.message);
                    getCategoryContent();
                  }}
                />
              )}

              {isPendingView && (
                <HiCheckCircle
                  className="ml-2 text-success cursor-pointer"
                  fontSize={24}
                  onClick={async () => {
                    await fetchWrapper.patch(`resources/${resource?.id}/`, {
                      approved: true,
                    });
                    toast.success("Resource Approved");
                    getCategoryContent();
                  }}
                />
              )}
            </div>
          ),
        }));

        setResources({
          data: data,
          count: res?.data?.count,
        });
        setIsLoading(false);
      };

      getCategoryContent();
    } catch (err) {
      setIsLoading(false);
    }
  }, [
    isPendingView,
    pageNo,
    isDataOutDated,
    title,
    isMyResourcesView,
    hideDelete,
  ]);

  return (
    <div>
      <ReactTable
        columns={columns}
        data={resources?.data ?? []}
        pageNo={pageNo}
        setPageNo={setPageNo}
        totalCount={resources?.count}
        loading={isLoading}
      />
    </div>
  );
}

export default ContentTable;

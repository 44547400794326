import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormGroup } from "../../../components/FormGroup/FormGroup";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";


import { ErrorMessageHandler } from "../../../_helpers/_methods";
import {
  addThematicAreas,
  editThematicAreas,
} from "../../../_redux/slices/thematicAreas";
import styled from "styled-components";
import ThematicAreaIconButton from "./ThematicAreaIconButton";
import { colorOption, IconNames } from "./data";

const ThematicAreaIconGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
  grid-gap: 10px;
  .grid-item {
    border: #adadadcc 2px solid;
    border-radius: 10px;
    color: #333;
    padding: 20px;
    font-size: 30px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 200ms, background-color 200ms;
    :hover {
      background: #2f6bbfeb;
      color: white;
    }
    &.selected {
      background: #2f6bbf;
      color: white;
    }
  }
`;
const ThematicAreaForm = ({
  thematicArea,
  setSelectedThematicArea,
  handleThematicAreaView,
}) => {
  const dispatch = useDispatch();
  const [thematicIcon, setThematicIcon] = useState(thematicArea?.icon);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    try {
      let body = {
        title: data.title,
        thematic_color: data.thematic_color,
        icon: thematicIcon,
      };
      if (thematicArea) {
        dispatch(editThematicAreas(body, thematicArea.slug));
        toast.success("Thematic area edited Successfully!");
      } else {
        dispatch(addThematicAreas(body));
        toast.success("Thematic area added Successfully!");
      }
      handleThematicAreaView(true);
    } catch (error) {
      ErrorMessageHandler(error);
    }
  };

  const handleColorChange = (e) => {
    setValue(`thematic_color`, e.value);
    clearErrors("thematic_color");
  };

  const handleCancelForm = () => {
    setSelectedThematicArea(null);
    handleThematicAreaView(true);
  };
  return (
    <Fragment>
      <Button variant="warning" onClick={handleCancelForm}>
        Cancel
      </Button>
      <div className="card-sm course-tab mt-3" style={{ width: "100%" }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <FormGroup
                id="title"
                errorName="Thematic Area Title"
                label="Thematic Area Title"
                type="text"
                placeholder="Enter thematic area title"
                register={register}
                required={true}
                defaultValue={thematicArea?.title}
                // disabled={isLoading}
                error={errors}
              />
            </Col>{" "}
            <Col md={6}>
              <FormGroup
                key={thematicArea?.thematic_color}
                id={`thematic_color`}
                label="Themantic area color"
                type="select"
                register={register}
                required={true}
                // disabled={isLoading}
                defaultValue={thematicArea?.thematic_color}
                error={errors}
                options={colorOption}
                onChange={handleColorChange}
              />
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Thumbnail Icon</Form.Label>
                <ThematicAreaIconGrid>
                  {IconNames.map((icon) => (
                    <ThematicAreaIconButton
                      name={icon}
                      className={"grid-item"}
                      selected={thematicIcon === icon}
                      handleClick={() => setThematicIcon(icon)}
                    />
                  ))}
                </ThematicAreaIconGrid>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Button variant="primary" type="submit">
                {thematicArea ? "Edit" : "Add"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Fragment>
  );
};

export default ThematicAreaForm;

import React, { Fragment } from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";

import NotFoundImg from "./not_found.svg";

const Card = ({
  list = false,
  dataList = [],
  imageField,
  Content = null,
  path,
  loading = false,
}) => {
  const Component = useMemo(() => {
    return list ? StyledCardList : StyledCardGrid;
  }, [list]);

  return (
    <>
      <Component>
        {!loading ? (
          !dataList?.length ? (
            <div className="vh-100 d-flex flex-column align-items-center mt-5">
              <div className="mb-5 mx-auto" style={{ width: "200px" }}>
                <img src={NotFoundImg} alt="not found" className="w-100" />
              </div>
              <h3>No Courses found</h3>
            </div>
          ) : (
            dataList?.map((data, index) => {
              let media = data.media || [];

              return (
                <Col
                  {...(list ? { sm: 12 } : { sm: 12, md: 6, lg: 4 })}
                  key={data?.id ? data?.id : index}
                >
                  <Link
                    to={
                      path
                        ? path?.withId
                          ? `${path.url}/${data?.id}`
                          : path?.withSlug
                          ? `${path.url}/${data?.slug}`
                          : `${path.url}`
                        : "#"
                    }
                    className={`card ${list ? "shadow-none mb-0" : ""}`}
                  >
                    <div className="card-body">
                      <img
                        src={
                          media[0]?.media_url ||
                          data[imageField] ||
                          "/assets/images/placeholder.jpg"
                        }
                        alt={data?.title}
                      />
                      <div className="content">
                        {Content && (
                          <Content {...(data ? { data: data } : {})} />
                        )}
                      </div>
                    </div>
                  </Link>
                  {list && <hr className="my-4 w-100" />}
                </Col>
              );
            })
          )
        ) : (
          <div className="card-loader">
            <VscLoading fontSize={64} color={"#2460b9"} />
          </div>
        )}
      </Component>
    </>
  );
};

export default Card;

const StyledCardGrid = styled(Row)`
  .card-loader {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card {
    width: 100%;
    .card-body {
      width: 100%;
      padding: 0;
      flex-direction: column;
      img {
        width: 100%;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        object-position: center;
      }
      .content {
        padding: 0.5rem;
      }
    }
  }
`;

const StyledCardList = styled.div`
  .card {
    height: 30vh;
    border: none;
    .card-body {
      width: 100%;
      padding: 0.25rem;
      overflow: hidden;
      display: flex;
      img {
        height: 100%;
        aspect-ratio: 16 / 9;
        max-width: 33%;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
      }
      .content {
        padding: 0.5rem 1rem;
      }
    }
  }
`;

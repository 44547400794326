import React from "react";

function AssessmentQA() {
  return (
    <div className="quiz-result-main-box mb-2">
      <div className="quiz-component-wrapper">
        <div className="quiz-preview-wrapper">
          <div className="quiz-question-type">Question 4 of 10</div>
          <div className="quiz-question-title">
            <span>Which part of a computer performs calculations?</span>
            <p className="text-primary">
              <small>Marks: 1</small>
            </p>
          </div>
          <div class="ans-slot d-flex">
            <span class="title">You Chose </span>
            <div class="ml-3">
              <kbd className="bg-danger">A. DVD</kbd>
            </div>
          </div>
          <div class="ans-slot d-flex">
            <span class="title">Correct Answer</span>
            <div class="ml-3">
              <kbd className="bg-success">B. CPU</kbd>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentQA;

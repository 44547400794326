import React from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { useState } from "react";

//
import SuccessStory from "../../pages/Admin/KnowledgeProducts/SuccessStories/SuccessStory";
import PosterBanner from "../../pages/Admin/KnowledgeProducts/PosterBanners/PosterBanner";
import Documentary from "../../pages/Admin/KnowledgeProducts/Documentaries/Documentary";

/**
 *
u */
const KnowledgeProductPreview = () => {
  const history = useHistory();
  const { subcategory, id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      let res = await fetchWrapper.get(`/${subcategory}/${id}/`);

      let data = res?.data ?? {};
      let newData = {
        storyTitle: data.title,
        bannerTitle: data.title,
        title: data.title,
        bannerImg: data.media["banner_image"],
        media: data.media,
        videoUri: data.uri,
        description: data.text,
        content: data.text,
        name: data.name,
        post: data.post,
      };
      setData(newData);
    };
    try {
      getData();
    } catch (error) {}
  }, [subcategory, id]);

  let render = null;

  switch (subcategory) {
    case "story":
      render = <SuccessStory previewData={data} />;
      break;
    case "banner":
      render = <PosterBanner previewData={data} />;
      break;
    case "documentary":
      render = <Documentary previewData={data} />;
      break;
    default:
      render = null;
  }

  return (
    <PreviewContainer>
      <div className="preview-header">
        <div
          className="d-flex align-items-center pointer"
          onClick={() => history.goBack()}
        >
          <img src="/assets/icons/LeftArrow.svg" alt="back" />
          <h4 className="m-0 pl-3">{data?.title}</h4>
        </div>
      </div>

      <div className="preview-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 pb-1 pt-2 px-3">
            <li class="breadcrumb-item">Knowledge Products</li>
            <li class="breadcrumb-item">
              {subcategory === "story"
                ? "Success Story"
                : subcategory === "banner"
                ? "Poster/Banners"
                : subcategory === "documentary"
                ? "Documentaries"
                : ""}
            </li>
          </ol>
        </nav>

        <div className="mt-4 px-3 w-full">{render}</div>
      </div>
    </PreviewContainer>
  );
};

export default KnowledgeProductPreview;

const PreviewContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-color: #f2f2f2;
  .preview-header {
    background-color: white;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 60px;
    box-shadow: 0px 0px 8px 5px #2460b920;
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .preview-content {
    background-color: white;
    width: 90%;
    min-height: 92vh;
    margin: auto;
    padding: 1rem;
    @media screen and (min-width: 1000px) {
      width: 80%;
    }
  }
  .preview-image {
    max-width: 1060px;
    height: 700px;
    margin: auto;
    margin-bottom: 2rem;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      background-color: #ccc;
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { useState } from 'react';
import LessonResourceContent from '../../Layout/LessonLayout/LessonResourceContent';
import LessonArticleContent from '../../Layout/LessonLayout/LessonArticleContent';
import { LessonResoucesListing } from '../../Layout/LessonLayout/LessonNav';
import { ImFileText2 } from 'react-icons/im';

const Preview = () => {
	const history = useHistory();
	const { subcategory, id } = useParams();

	const [data, setData] = useState(null);
	const [resource, setResource] = useState(null);
	const [activeContent, setActiveContent] = useState('description');

	let content;

	useEffect(() => {
		const getData = async () => {
			let res = await fetchWrapper.get(`capacity-support/${id}/`);
			let data = res?.data ?? {};

			let newData = {
				title: data.title,
				description: data.description,
				media: data.media,
			};
			setData(newData);
		};
		try {
			getData();
		} catch (error) {}
	}, [subcategory, id]);

	const handleActiveContentChange = (value, resource) => {
		setActiveContent(value);
		if (resource) {
			setResource(resource);
		} else {
			setResource(null);
		}
	};

	switch (activeContent) {
		case 'description':
			content = (
				<LessonArticleContent
					articleContent={{ content: data?.description }}
					handleActiveContentChange={handleActiveContentChange}
				/>
			);
			break;
		case 'resources':
			content = <LessonResourceContent resource={resource} />;
			break;
		default:
			content = null;
	}

	return (
		<PreviewContainer>
			<div className="preview-header">
				<div className="d-flex align-items-center pointer" onClick={() => history.goBack()}>
					<img src="/assets/icons/LeftArrow.svg" alt="back" />
					<h4 className="m-0 pl-3">{data?.title}</h4>
				</div>
			</div>

			<div className="preview-content">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb mb-0 pb-1 pt-2 px-3">
						<li class="breadcrumb-item">Capacity Support</li>

						<li class="breadcrumb-item">
							{subcategory === 'model-law'
								? 'Model Law'
								: subcategory === 'cd-plans'
								? 'CD Plans'
								: subcategory === 'mfef'
								? 'MFEF'
								: ''}
						</li>
					</ol>
				</nav>

				<div className="px-3 w-full">
					<h1 className="main-title">{data?.title}</h1>

					<Container>
						<div className="nav">
							<h6 className="mt-0">Content</h6>
							<h6
								className={`description-btn ${activeContent === 'description' ? 'active' : ''}`}
								onClick={() => {
									handleActiveContentChange('description');
								}}
							>
								<ImFileText2
									style={{ fontSize: '1.2rem' }}
									className="
                mr-2"
								/>{' '}
								Description
							</h6>
							<LessonResoucesListing
								resources={data?.media}
								activeResourceId={resource?.id}
								handleActiveContentChange={handleActiveContentChange}
							/>
						</div>

						<div className="content">{content}</div>
					</Container>
				</div>
			</div>
		</PreviewContainer>
	);
};

export default Preview;

const PreviewContainer = styled.div`
	min-height: 100vh;
	width: 100vw;
	background-color: #f2f2f2;
	.preview-header {
		background-color: white;
		position: sticky;
		top: 0;
		left: 0;
		z-index: 10;
		width: 100vw;
		height: 60px;
		box-shadow: 0px 0px 8px 5px #2460b920;
		padding: 0.5rem 2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.preview-content {
		background-color: white;
		width: 90%;
		min-height: 92vh;
		margin: auto;
		padding: 1rem;
		.image-container {
			img {
				width: 100%;
				object-fit: contain;
				object-position: center;
			}
		}
		@media screen and (min-width: 760px) {
			width: 80%;
		}
	}
`;

const Container = styled.div`
	/* width: 100vw; */
	display: grid;

	${({ fullScreen }) =>
		fullScreen
			? 'grid-template-columns:1fr;grid-template-areas:"content"; '
			: 'grid-template-columns: 1fr 4fr;grid-template-areas: "nav content";'}
	grid-gap: 1rem;
	min-height: 95vh;

	.nav {
		grid-area: nav;
		background-color: #f2f2f2;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas: 'course-intro' 'lesson-nav';
		grid-template-rows: max-content max-content;
		padding: 2rem 1rem;

		.description-btn {
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: 0.5rem;
			border-radius: 5px;
			&:hover {
				background-color: #2f80ed;
				color: white !important;
			}
		}
		.nav-item {
			cursor: pointer;
			position: relative;
			padding: 0.5rem;
			margin-bottom: 0.5rem;
			font-size: 1.5rem;
			display: flex;
			align-items: center;

			width: 15vw;
			span {
				width: 10vw;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			border-radius: 5px;
			svg {
				margin-right: 0.5rem;
			}
			span {
				font-size: 1rem;
			}
			&:hover {
				background-color: #2f80ed;
				color: white !important;
				a {
					color: white !important;
				}
			}
		}
		.active {
			background-color: #2f80ed;
			color: white;
			a {
				color: white !important;
			}
			svg {
				color: white !important;
			}
		}
	}

	.content {
		${({ bigFontSize }) => (bigFontSize ? `font-size:large !important;` : `font-size:normal !important;`)}
		grid-area: content;
		.course-introduction {
			display: none;
		}
		@media screen and (max-width: 1024px) and (min-width: 768px) {
			.course-introduction {
				display: block;
			}
		}
	}

	@media screen and (max-width: 1024px) {
		grid-template-columns: max-content auto;
		grid-template-areas: 'nav content';
	}
	@media screen and (max-width: 768px) {
		grid-template-columns: 100%;
		grid-template-areas: 'nav' 'content';
	}
`;

import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';

export const initialState = {
	lessonNotes: {},
	allLessonNotes: {},
	activeNote: null
};

const noteSlice = createSlice({
	name: 'notes',
	initialState,
	reducers: {
		setLessonNotes: (state, action) => {
			state.lessonNotes = {
				...state.lessonNotes,
				[action.payload.key]: [...action.payload.notes]
			}
		},
		addLessonNotes: (state, action) => {
			let prevLessonNotes = state.lessonNotes[action.payload.key] || [];
			state.lessonNotes = {
				...state.lessonNotes,
				[action.payload.key]: [action.payload.note, ...prevLessonNotes]
			};
		},
		setAllLessonNotes: (state, action) => {
			state.allLessonNotes = {
				...state.allLessonNotes,
				[action.payload.course]: [...action.payload.lessonNotes]
			};
		},
		setActiveNote: (state, action) => {
			state.activeNote = action.payload;
		},
		clearActiveNote: (state) => {
			state.activeNote = null;
		},
	},
});

const { setLessonNotes, addLessonNotes, setAllLessonNotes, setActiveNote, clearActiveNote } = noteSlice.actions;

export default noteSlice.reducer;


export const handleGetAllLessonNotes = (courseSlug) => async (dispatch) => {
	try {
		if (courseSlug === "undefined") return;

		let response = await fetchWrapper.get(`course/${courseSlug}/notes/`);
		dispatch(setAllLessonNotes({ course: courseSlug, lessonNotes: response.data }));
	} catch (error) {
		console.log(error);
	}
};

export const handleGetLessonNotes = (courseSlug, lessonSlug) => async (dispatch) => {
	try {
		if (lessonSlug === "undefined") return;
		let response = await fetchWrapper.get(`lesson/${lessonSlug}/notes/`);
		dispatch(setLessonNotes({
			key: `${courseSlug}_${lessonSlug}`,
			notes: response.data
		}));
	} catch (error) {
		console.log(error);
	}
};

export const handleAddLessonNote = (courseSlug, lessonSlug, body) => async (dispatch) => {
	try {
		if (lessonSlug === "undefined") return;

		let response = await fetchWrapper.post(`lesson/${lessonSlug}/notes/`, body);
		dispatch(addLessonNotes({ key: `${courseSlug}_${lessonSlug}`, note: response.data }));
	} catch (error) {
		console.log(error);
	}
};

export const handleEditLessonNote = (courseSlug, lessonSlug, body) => async (dispatch) => {
	try {
		if (lessonSlug === "undefined") return;

		await fetchWrapper.patch(`lesson/${lessonSlug}/notes/`, body);
		dispatch(handleGetLessonNotes(courseSlug, lessonSlug));
	} catch (error) {
		console.log(error);
	}
};

export const handleDeleteNote = (courseSlug, lessonSlug, noteId) => async (dispatch) => {
	try {
		if (lessonSlug === "undefined") return;

		await fetchWrapper.delete(`lesson/${lessonSlug}/notes/?id=${noteId}`);
		dispatch(handleGetLessonNotes(courseSlug, lessonSlug));
	} catch (error) {
		console.log(error);
	}
};

export const handleSetActiveNote = (activeNote) => (dispatch) => {
	dispatch(setActiveNote(activeNote))
}

export const handleClearActiveNote = () => (dispatch) => {
	dispatch(clearActiveNote())
}


import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaMinus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { FormGroup } from "../../components/FormGroup/FormGroup";
import { handleAddLessonNote, handleClearActiveNote, handleEditLessonNote } from "../../_redux/slices/notes";

export default function NoteModal({ courseSlug, lessonSlug, displayNote, setDisplayNote }) {
    const dispatch = useDispatch();
    const activeNote = useSelector(state => state.notes.activeNote);

    const [noteContentState, setNoteContentState] = useState(EditorState.createEmpty());

    const isEdit = activeNote ? Boolean(activeNote?.isEdit) : true;

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        const draftNote = localStorage.getItem(`notes_${lessonSlug}`);
        if (activeNote) {
            const contentBlock = htmlToDraft(activeNote.note);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const noteContent = EditorState.createWithContent(contentState);
                setNoteContentState(noteContent);
            }
            setValue('title', activeNote.title)
        }
        else if (draftNote) {
            const parsedDraftNote = JSON.parse(draftNote);
            setValue('title', parsedDraftNote.title);

            const contentBlock = htmlToDraft(parsedDraftNote.note);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const noteContent = EditorState.createWithContent(contentState);
                setNoteContentState(noteContent);
            }

        }
    }, [lessonSlug, setValue, activeNote]);


    const getNoteBody = (data) => {
        let body = {
            title: data.title,
            note: draftToHtml(convertToRaw(noteContentState.getCurrentContent()))
        }
        if (Boolean(activeNote?.isEdit)) {
            body.id = activeNote?.id
        }
        return body;
    }

    const saveNoteHandler = (data) => {
        const body = getNoteBody(data);
        if (!body.title) return toast.error('Title is required');
        if (body.note.length < 9) return toast.error('Note is required');

        if (Boolean(activeNote?.isEdit)) {
            dispatch(handleEditLessonNote(courseSlug, lessonSlug, body));
        }
        else {
            dispatch(handleAddLessonNote(courseSlug, lessonSlug, body));
        }
        clearNote();
    };

    const handleNoteContentChange = (e) => {
        setNoteContentState(e);
    };

    const saveAsDraft = (data) => {
        const body = getNoteBody(data);
        const stringifiedNote = JSON.stringify(body);

        localStorage.setItem(`notes_${lessonSlug}`, stringifiedNote);
    }

    const handleMinimize = (data) => {
        saveAsDraft(data);
        setDisplayNote(false);
    }

    const clearNote = () => {
        localStorage.removeItem(`notes_${lessonSlug}`);
        setValue('title', null);
        setNoteContentState(EditorState.createEmpty())
        setDisplayNote(false);
        dispatch(handleClearActiveNote());
    }

    useEffect(() => {
        if (activeNote) {
            setDisplayNote(true);
        }
    }, [activeNote, setDisplayNote])

    return (

        <Modal
            size="lg"
            show={displayNote}
            onHide={() => clearNote()}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <div className="add-note">
                <Modal.Header>
                    <Modal.Title className="w-100 add-note-header d-flex align-items-center justify-content-between">
                        <h3 className='mb-0'>Note</h3>

                        <FaMinus className="pointer" onClick={handleSubmit(handleMinimize)} />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="add-note-body">
                        <p>Some important content to remember note it here</p>
                        <FormGroup type="text" id="title" register={register} label="Title" error={errors}
                            disabled={!isEdit}
                        />

                        <FormGroup
                            label="Note"
                            id="note"
                            type="editor"
                            register={register}
                            // disabled={isLoading}
                            placeholder="Aa"
                            defaultValue={noteContentState}
                            error={errors}
                            onChange={handleNoteContentChange}
                            editorStyle={{ minHeight: '400px' }}
                            disabled={!isEdit}
                        />
                        <div className="d-flex">

                            {
                                isEdit ?
                                    <>
                                        <Button onClick={handleSubmit(saveNoteHandler)}>Save</Button>
                                        <Button className="ml-4" variant="outline-danger" onClick={() => {
                                            Swal.fire({
                                                title: 'Are you sure?',
                                                text: "This note will be cleared",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Yes, delete it!',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    clearNote();
                                                }
                                            })
                                        }}>Discard</Button>
                                    </>
                                    :
                                    <Button onClick={clearNote} variant="secondary">Close</Button>
                            }

                        </div>
                    </div>
                </Modal.Body>
            </div>
        </Modal>

    )
}
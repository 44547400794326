import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { jwtCheck, setToken } from '../../_helpers/jwt-check';
import { setUiError } from './ui';

export const initialState = {
	loading: false,
	hasErrors: false,
	users: [],
	user: null,
	enrolledCourses: [],
	isLoggedIn: jwtCheck() ? true : false,
};

// A slice
const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setUserLogin: (state, action) => {
			state.isLoggedIn = true;
			state.user = action.payload;
		},
		// setUserLogout: (state, action) => initialState,
		setUserLogout: (state, action) => {
			state.user = null;
			state.isLoggedIn = false;
		},
		addUser: (state, action) => {
			state.users.unshift(action.payload);
		},
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		setEnrolledCourses: (state, action) => {
			state.enrolledCourses = action.payload;
		},
		getUsersSuccess: (state, action) => {
			state.users = action.payload;
			state.loading = false;
			state.hasErrors = false;
		},
		getUsersFailure: (state) => {
			state.loading = false;
			//handling Errors
			state.hasErrors = true;
		},
	},
});

// Actions generated from the slice
const {
	setUser,
	setUserLogin,
	setUserLogout,
	addUser,
	setEnrolledCourses,
	startLoading,
	stopLoading,
	getUsersFailure,
	// getUsersSuccess,
} = usersSlice.actions;

// export user selector to get the slice in any component
export const usersSelector = (state) => state.users;
export const userSelector = (state) => state.users.user;

// export The reducer
const userReducer = usersSlice.reducer;

export default userReducer;

// Actions

export const createUser = (User) => async (dispatch) => {
	try {
		dispatch(addUser(User));
	} catch (error) {
		dispatch(getUsersFailure());
	}
};

export const loginUser = (credentials, history) => async (dispatch) => {
	try {
		dispatch(startLoading());
		let response = await fetchWrapper.post('cdmis/login/', credentials);
		let accessToken = {
			token: response.data.access,
		};
		let refreshToken = {
			token: response.data.refresh,
		};
		let role = response.data.role;
		setToken(accessToken, refreshToken, role);
		dispatch(stopLoading());
		dispatch(setUserLogin(response.data));
		let lastLink = localStorage.getItem('lastLink');
		if (lastLink) {
			localStorage.removeItem('lastLink');
			history.push(lastLink);
		} else {
			history.push('/dashboard');
		}
	} catch (err) {
		console.log(err, 'err');
		dispatch(stopLoading());
		let errorMessage = err.error?.non_field_error || err.message;
		dispatch(setUiError(errorMessage));
	}
};

export const getUserData = () => async (dispatch) => {
	try {
		const user = await fetchWrapper.get(`cdmis/me/`);
		dispatch(setUser(user.data));
	} catch (err) {
		if (err.code === 401) {
			dispatch(setUserLogout());
		}
	}
};

export const getUserEnrolledCourse = () => async (dispatch) => {
	try {
		const response = await fetchWrapper.get('user/course/');
		dispatch(setEnrolledCourses(response.data.results));
	} catch (err) {
		console.log(err);
	}
};

export const logoutUser = () => async (dispatch) => {
	localStorage.removeItem('kms_user');
	localStorage.removeItem('kms_userRefresh');
	localStorage.removeItem('kms_role');
	dispatch(setUserLogout());
};

import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { nextEntityAfterCompletion, nextEntityHandler } from '../../_helpers/nextEntityHandler';
import LessonArticleContent from './LessonArticleContent';
import LessonResourceContent from './LessonResourceContent';
import LessonVideoContent from './LessonVideoContent';
import { useSelector } from 'react-redux';
import LessonAudioContent from './LessonAudioContent';

const LessonContent = ({
	lesson,
	contentArray,
	contentIndex,
	activeContent,
	handleActiveContentChange,
	hasAudio,
	hasVideo,
	hasArticle,
	resource,
	bigFontSize,
}) => {
	let content;
	let hasPrevious = contentIndex > 0;
	const { courseSlug } = useParams();
	const history = useHistory();
	const { restartCourse } = useSelector((state) => state.course);

	const handleLessonComplete = () => {
		// If the user has already completed the course, and is re-reading then restartCourse will be true. When re-reading we willnot show quiz
		if (restartCourse) {
			nextEntityAfterCompletion(
				courseSlug,
				{
					entity: 'lesson',
					slug: lesson.slug,
				},
				history
			)
		}
		else {
			nextEntityHandler(
				courseSlug,
				{
					entity: 'lesson',
					id: lesson.id,
				},
				history
			)
		}
	};

	switch (activeContent) {
		case 'article':
			content = (
				<LessonArticleContent
					articleContent={lesson?.article_content}
					handleActiveContentChange={handleActiveContentChange}
					hasVideo={hasVideo}
					bigFontSize={bigFontSize}
				/>
			);
			break;
		case 'video':
			content = (
				<LessonVideoContent
					videoContent={lesson?.video_content}
					handleActiveContentChange={handleActiveContentChange}
					hasAudio={hasAudio}
					hasArticle={hasArticle}
				/>
			);
			break;
		case "audio":
			content = (
				<LessonAudioContent audioContent={lesson?.audio_content}
					handleActiveContentChange={handleActiveContentChange}
				/>
			);
			break;
		case 'resources':
			content = <LessonResourceContent resource={resource} />;
			break;
		default:
			content = null;
	}
	const handlePreviousContent = () => {
		console.log(hasPrevious);
		if (hasPrevious) {
			handleActiveContentChange('prev');
		} else if (lesson?.prev_lesson) {
			history.push(`/courses/${courseSlug}/enrolled/${lesson?.prev_lesson}`);
		}
	};

	const handleNextClick = () => {
		const contentInLessonHasFinished = contentIndex >= contentArray?.length - 1;

		if (contentInLessonHasFinished) {
			handleLessonComplete()
		}
		else {
			handleActiveContentChange('next')
		}
	}
	return (
		<Content id="previewComponentID" className="previewComponent">
			<div className="course-introduction">
				<p>Course Introduction</p>
				<h4 className="mb-4" style={{ color: '#2460b9' }}>
					{lesson?.title}
				</h4>
			</div>
			<div className="content">{content}</div>
			<div className="d-flex justify-content-between">
				<Button
					className="btn btn-primary btn-next "
					disabled={!(hasPrevious || lesson.prev_lesson)}
					tabIndex="0"
					onClick={handlePreviousContent}
				>
					<span className="MuiIconButton-label text-black">Previous</span>
				</Button>

				<Button variant="primary" className="btn " tabIndex="0" onClick={handleNextClick}>
					<span className="MuiIconButton-label">Next</span>
				</Button>
			</div>
		</Content>
	);
};

export default LessonContent;

const Content = styled.div`
	${({ bigFontSize }) => (bigFontSize ? `font-size:large !important;` : `font-size:normal !important;`)}
	grid-area: content;
	.course-introduction {
		display: none;
	}
	@media screen and (max-width: 1024px) and (min-width: 768px) {
		.course-introduction {
			display: block;
		}
	}
`;

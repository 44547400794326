import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { useParams, useHistory } from 'react-router-dom';
// import PaginationComp from '../../../components/Pagination/PaginationComp';
import { deleteThematicAreas, getThematicAreas } from '../../../_redux/slices/thematicAreas';
import { ThematicAreaBadge } from '../../../components/ThematicAreaBadge';
// import { Table } from '../Courses/MyCourses/MyCourses';
import { DynamicIcon } from '../../../_helpers/DynamicIcon';
import ReactTable from '../../../components/ReactTable';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { useAsyncDebounce } from 'react-table';

const IconHolder = styled.span`
	font-size: 26px;
	color: ${(props) => props.color || 'black'};
	background-color: ${(props) => (props.color ? `${props.color}1b` : 'white')};
	padding: 10px;
	width: 90px;
	height: 45px;
	border-radius: 10px;

	display: flex;
	justify-content: center;
`;
const ThematicAreaTable = ({ setSelectedThematicArea, handleThematicAreaView }) => {
	const { page } = useParams();
	const history = useHistory();
	const [pageNo, setPageNo] = useState(page);
	const [title, setTitle] = useState('');
	const dispatch = useDispatch();
	const thematicAreas = useSelector((state) => state.thematicAreas.thematicAreas);
	const count = useSelector((state) => state.thematicAreas.count);

	useEffect(() => {
		setPageNo(1);
	}, [title]);

	useEffect(() => {
		dispatch(getThematicAreas(pageNo, 10, title));
		history.push(`/dashboard/thematic-areas/${pageNo}`);
	}, [dispatch, history, pageNo, title, count]);

	const ManualFilterByTitle = ({ column: { filterValue, setFilter } }) => {
		const inputRef = useRef(null);
		const [name, setName] = useState(filterValue);
		const handleNameChange = useAsyncDebounce((value) => {
			setFilter(value || undefined);
			setTitle(value);
		}, 800);
		return (
			<input
				ref={inputRef}
				value={name || ''}
				onChange={(e) => {
					setName(e.target.value);
					handleNameChange(e.target.value);
				}}
				placeholder={'Search'}
			/>
		);
	};

	const handleDeleteThematicArea = useCallback(
		(thematic_area) => {
			let result = window.confirm('Are you sure you want to delete?');
			if (result) {
				try {
					dispatch(deleteThematicAreas(thematic_area.slug));
					// dispatch(setUiMessage("Thematic area deleted successfully"));
				} catch (err) { }
			}
		},
		[dispatch]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'S.N.',
				accessor: 'sn',
				disableFilters: true,
				width: 'auto',
			},
			{
				Header: 'Icon',
				accessor: 'icon',
				disableFilters: true,
				width: 'auto',
			},
			{
				Header: 'Title',
				accessor: 'title',
				width: 'auto',
				Filter: ManualFilterByTitle,
			},
			{
				Header: 'Created At',
				accessor: 'created_at',
				disableFilters: true,
				width: 'auto',
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				disableFilters: true,
				width: 'auto',
			},
		],
		[]
	);

	const data = useMemo(() => {
		if (thematicAreas.length > 0) {
			return thematicAreas.map((thematicArea, index) => ({
				sn: (pageNo - 1) * 10 + index + 1,
				icon: (
					<IconHolder color={thematicArea.thematic_color}>
						<DynamicIcon name={thematicArea.icon} />
					</IconHolder>
				),
				title: <ThematicAreaBadge color={thematicArea.thematic_color}>{thematicArea.title}</ThematicAreaBadge>,
				created_at: dayjs(thematicArea.created_at).format('YYYY-MM-DD, hh:mm A'),
				actions: (
					<>
						<FaEdit
							className="text-primary mx-1 cursor-pointer"
							onClick={() => {
								setSelectedThematicArea(thematicArea);
								handleThematicAreaView(false);
							}}
						/>
						<FaTrashAlt
							onClick={() => {
								handleDeleteThematicArea(thematicArea);
							}}
							className="text-danger cursor-pointer"
						/>
					</>
				),
			}));
		} else return [];
	}, [thematicAreas, pageNo, setSelectedThematicArea, handleThematicAreaView, handleDeleteThematicArea]);

	return (
		<Fragment>
			<ReactTable columns={columns} data={data} pageNo={pageNo} setPageNo={setPageNo} totalCount={count} />
		</Fragment>
	);
};

export default ThematicAreaTable;

import { fetchWrapper } from './fetchWrapper';
import { isEmpty } from './_methods';

export const nextEntityHandler = async (courseSlug, body, history) => {
	try {
		let response = await fetchWrapper.post(`course/${courseSlug}/next/`, body);

		const { active_lesson, can_assignment, can_chapter, can_quiz, current_chapter, upcoming_lessons } = response.data;
		if (!isEmpty(active_lesson)) {
			history.push(`/courses/${courseSlug}/enrolled/${active_lesson.slug}`);
		} else if (can_quiz) {
			history.push(`/courses/${courseSlug}/enrolled/${current_chapter}/assessment/quiz`);
		} else if (can_chapter) {
			let activeLessonSlug = active_lesson.slug;
			if (!activeLessonSlug && !upcoming_lessons.length) {
				history.push(`/courses/${courseSlug}/completed`);
			}
			else {
				activeLessonSlug = upcoming_lessons[0].slug;
				history.push(`/courses/${courseSlug}/enrolled/${activeLessonSlug}`);
			}
		} else if (can_assignment) {
			history.push(`/courses/${courseSlug}/enrolled/assessment/assignment`);
		} else {
			history.push(`/courses/${courseSlug}/enrolled`);
		}
	} catch (error) {
		console.log(error);
	}
};

export const nextEntityAfterCompletion = async (courseSlug, body, history) => {
	try {
		let response = await fetchWrapper.post(`course/${courseSlug}/next-entity/`, body);
		console.log(response, 'nextEntityAfterCompletion response');
		const nextEntity = response.data;
		// const { entity, slug, first_lesson } = await response.data;
		if (!isEmpty(nextEntity)) {
			if (nextEntity?.entity === 'lesson' && nextEntity.slug) {
				history.push(`/courses/${courseSlug}/enrolled/${nextEntity.slug}`);
			} else if (nextEntity.entity === 'chapter' && nextEntity.first_lesson) {
				history.push(`/courses/${courseSlug}/enrolled/${nextEntity.first_lesson}`);
			} else {
				history.push(`/courses/${courseSlug}/enrolled`);
			}
		} else {
			history.push(`/courses/${courseSlug}/enrolled`);
		}
	} catch (error) {
		console.log(error);
	}
};

import React from 'react';
import { Col } from 'react-bootstrap';
import { FormGroup } from '../FormGroup/FormGroup';
import { useState } from 'react';
import YoutubeEmbedPreview from './YoutubeEmbedPreview';

const YoutubeEmbed = ({ id, label, register, errors, defaultValue, isRequired }) => {
	const [youtubeLink, setYoutubeLink] = useState(defaultValue);

	return (
		<div className="pt-2">
			{
				label &&
				<Col>
					<h4 className='small'>{label}</h4>
				</Col>
			}
			<Col>
				<FormGroup
					type="text"
					formattedLabelId="videoContent"
					placeholder="Paste video link"
					errorName={'Video Link'}
					id={id}
					register={register}
					onChange={(e) => setYoutubeLink(e.target.value)}
					error={errors}
					defaultValue={youtubeLink}
					//   disabled={isLoading}
					required={isRequired}
				/>
			</Col>
			<Col key={youtubeLink}>
				<YoutubeEmbedPreview youtubeLink={youtubeLink} />
			</Col>
		</div>
	);
};

export default YoutubeEmbed;

import React from 'react';
import styled from 'styled-components';
import UserPPImage from '../../pages/Landing/components/UserPPImage';

const StyledUserInfo = styled.div`
	display: flex;
	align-items: center;
	.usernameHolder {
		display: flex;
		flex-direction: column;
		margin-left: 0.4rem;
		width: max-content;
	}
	.username {
		font-size: 0.8rem;
		font-weight: 600;
	}
	.role {
		font-size: 0.7rem;
		color: #2460b9;
	}
`;
function UserInfo({ user }) {
	return (
		<StyledUserInfo>
			<UserPPImage imgSrc={user?.image_url || '/assets/images/avatar-placeholder.png'} userName={user?.username} />
			<div className="usernameHolder">
				<span className="username">{user?.fullname}</span>
				<span className="role">{user?.active_role}</span>
			</div>
		</StyledUserInfo>
	);
}

export default UserInfo;

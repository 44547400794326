import dayjs from "dayjs";
import { Row, Col } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa";

//
import ReactTable from "../../../components/ReactTable";

//
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { ErrorMessageHandler } from "../../../_helpers/_methods";
import { useRef } from "react";
import { useAsyncDebounce } from "react-table";
import { useSelector } from "react-redux";
import SelectColumnFilter from "../../../components/ReactTable/Filters/SelectColumnFilter";

/**
 *
 */
export default function CapacitySupportTable() {
  const { page } = useParams();
  const history = useHistory();

  //
  const [pageNo, setPageNo] = useState(page);
  const [capacitySupportItems, setCapacitySupportItems] = useState({});
  const [title, setTitle] = useState("");
  const userRole = useSelector((state) => state?.user?.user?.active_role);

  const ManualFilterByTitle = ({ column: { filterValue, setFilter } }) => {
    const inputRef = useRef(null);
    const [name, setName] = useState(filterValue);
    const handleNameChange = useAsyncDebounce((value) => {
      setFilter(value || undefined);
      setTitle(value);
    }, 800);
    return (
      <input
        ref={inputRef}
        value={name || ""}
        onChange={(e) => {
          setName(e.target.value);
          handleNameChange(e.target.value);
        }}
        placeholder={"Search"}
      />
    );
  };

  useEffect(() => {
    setPageNo(1);
  }, [title]);

  const getCapacitySupportItems = useCallback(async () => {
    const response = await fetchWrapper.get(
      `capacity-support/?offset=${(pageNo - 1) * 10}${
        title ? `&title=${title}` : ""
      }`
    );

    setCapacitySupportItems(response.data || {});
    history.push(`/dashboard/capacity-support/${pageNo}`);
  }, [history, pageNo, title]);

  useEffect(() => {
    getCapacitySupportItems();
  }, [getCapacitySupportItems]);

  const handleEdit = useCallback(
    (id) => {
      history.push(`/dashboard/capacity-support-form/${id}`);
    },
    [history]
  );

  const handleDelete = useCallback(
    async (id) => {
      try {
        await fetchWrapper.delete(`capacity-support/${id}/`);
        getCapacitySupportItems();
      } catch (error) {
        ErrorMessageHandler(error);
      }
    },
    [getCapacitySupportItems]
  );

  const columns = useMemo(
    () => [
      {
        Header: "S.N.",
        accessor: "sn",
        disableFilters: true,
        width: "auto",
      },
      {
        Header: "Title",
        accessor: "title",
        Filter: ManualFilterByTitle,
        width: "auto",
      },
      {
        Header: "Short Description",
        accessor: "short_description",
        disableFilters: true,
        width: "auto",
      },
      {
        Header: "Type",
        accessor: "type",
        Filter: SelectColumnFilter,
        width: "auto",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        width: "auto",
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        width: "auto",
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (capacitySupportItems?.results?.length > 0) {
      let count = 0;
      return capacitySupportItems.results.map((item) => ({
        sn: ++count,
        title: item.title,
        type: item.category_title,
        short_description: item.short_description,
        active: item.is_active ? "True" : "False",
        created_at: dayjs(item.created_at).format("YYYY-MM-DD, hh:mm A"),
        actions: (
          <>
            <FaEye
              className="text-primary mx-1 cursor-pointer"
              onClick={() =>
                history.push(`/capacity-support/preview/${item?.id}`)
              }
            />
            {["superuser", "cd_admin"].includes(userRole) && (
              <>
                <FaEdit
                  className="text-primary mx-1 cursor-pointer"
                  onClick={() => {
                    handleEdit(item.id);
                  }}
                />
                <FaTrashAlt
                  className="text-danger cursor-pointer"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleDelete(item.id);
                      }
                    });
                  }}
                />
              </>
            )}
          </>
        ),
      }));
    } else return [];
  }, [
    capacitySupportItems.results,
    handleDelete,
    handleEdit,
    userRole,
    history,
  ]);

  return (
    <div className="main-content-inner">
      <Row className="mb-3">
        <Col sm={7}>
          <h2 className="main-title mb-2">Capacity Support</h2>
        </Col>
        <Col sm={5} className="d-flex justify-content-end align-items-center">
          {["superuser", "cd_admin"].includes(userRole) && (
            <Link
              to={`/dashboard/capacity-support-form/`}
              className="btn btn-primary"
            >
              Add Capacity Support
            </Link>
          )}
        </Col>
      </Row>

      <Row>
        <ReactTable
          columns={columns}
          data={data}
          pageNo={pageNo}
          setPageNo={setPageNo}
          totalCount={capacitySupportItems.count}
        />
      </Row>
    </div>
  );
}

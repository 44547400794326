import React, { useState } from 'react';
import { Search } from './Reuseables';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function Searchfield({ searchRef, handleSearch, displaySearch, courses, setDisplaySearch }) {
	const [showModal, setShowModal] = useState(false);
	return (
		<Search>
			<div className="sm-hidden">
				<label htmlFor="search">
					<img src="/assets/icons/search.svg" alt="" />
				</label>

				<FormattedMessage id="header.search.placeholder">
					{(placeholder) => (
						<input
							type="text"
							id="search"
							className="w-100"
							ref={searchRef}
							placeholder={placeholder}
							onChange={(e) => handleSearch(e)}
						/>
					)}
				</FormattedMessage>
				{displaySearch && (
					<div className="search-results">
						{courses?.map((course) => (
							<Link
								to={`/courses/${course.slug}`}
								onClick={() => {
									setDisplaySearch(false);
									searchRef.current.value = '';
								}}
							>
								{course.title}
							</Link>
						))}
					</div>
				)}
			</div>
			<div className="sm-visible">
				<img className="pointer" src="/assets/icons/search.svg" alt="" onClick={() => setShowModal(true)} />

				<StyledModal show={showModal} onHide={() => setShowModal(false)}>
					<Modal.Header>
						<div>
							<img src="/assets/icons/leftArrow.svg" alt="" onClick={() => setShowModal(false)} />
							<input
								type="text"
								id="search"
								ref={searchRef}
								placeholder="Search your Course Here..."
								onChange={(e) => handleSearch(e)}
							/>
						</div>
					</Modal.Header>
					<Modal.Body>
						{displaySearch &&
							courses?.map((course) => (
								<Link
									to={`/courses/${course.slug}`}
									onClick={() => {
										setDisplaySearch(false);
										setShowModal(false);
										searchRef.current.value = '';
									}}
								>
									{course.title}
								</Link>
							))}
					</Modal.Body>
				</StyledModal>
			</div>
		</Search>
	);
}

const StyledModal = styled(Modal)`
	.modal-dialog {
		margin: 0;
		padding: 0;
		max-width: 100vw;
		height: 100vh;
		.modal-content {
			height: 100%;
		}
		.modal-header {
			padding: 0;
			div {
				width: 100%;
				display: flex;
				justify-content: start;
				background-color: #f8f9fa;
				padding: 0 1rem;
				input {
					background-color: transparent;
					border: none;
					padding: 2rem 1rem;
					font-size: 16px;
					width: 100%;
				}
			}
		}
		.modal-body {
			padding: 1rem 0;
			a {
				display: block;
				padding: 0.5rem 1rem;
			}
		}
	}
`;

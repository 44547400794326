import React from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { handleRemoveCertificate } from "../../../../../../_redux/slices/courseData";
import CertificateBuilder from "../../../../../../components/CertificateBuilder/CertificateBuilder";
import { toast } from "react-toastify";
import {
  ErrorMessageHandler,
  isEmpty,
} from "../../../../../../_helpers/_methods";
import { fetchWrapper } from "../../../../../../_helpers/fetchWrapper";
import { useParams } from "react-router-dom";

const CertificateTypeDiv = styled.div`
  .label {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  .thresholdNumber {
    font-size: 20px;
    padding: 0.5rem 1rem;
  }
`;

const Card = styled.div`
  max-width: 400px;
  padding: 1rem 1.5rem;
  border: 1px solid black;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  h4 {
    margin-bottom: 0.6rem;
  }
  :hover {
    background-color: #2460b9;
    color: white;
  }
  &.active {
    background-color: #2460b9;
    color: white;
  }
`;

function CerticficateContent({ register, setValue, clearErrors, errors }) {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const [certificateType, setCertificateType] = useState(null);
  const [thresholdPercent, setThresholdPercent] = useState(70);

  const removeCertificate = () => {
    dispatch(handleRemoveCertificate());
  };

  const handleSubmitCertificate = async (data) => {
    if (!certificateType) {
      toast.error("Plese select a Certificate Type!");
    } else if (isEmpty(data)) {
      toast.error(
        "Please upload a certificate template, add labels and press save."
      );
    } else {
      try {
        console.log(data, "data");
        let formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        formData.append("course", courseId);
        formData.append("certificate_after", certificateType);
        formData.append("threshold_percentage", thresholdPercent);
        const response = await fetchWrapper.post(`/template/`, formData, true);
        toast.success("Certificate Template Saved!");
        console.log(response, "response");
      } catch (err) {
        ErrorMessageHandler(err);
      }
    }
  };

  return (
    <Col md={12}>
      <div>
        <div className="text-right">
          <Button variant="danger" onClick={removeCertificate}>
            Remove Certificate
          </Button>
        </div>
        <CertificateTypeDiv>
          <div className="label">Provide Certificate after:</div>
          <Row>
            <Col md={4}>
              <Card
                className={`${certificateType === "quiz" ? "active" : ""}`}
                onClick={() => setCertificateType("quiz")}
              >
                <h4>Quizes</h4>
                Completing course and securing average quiz marks above
                threshold percentage
              </Card>
            </Col>
            {/* <Col md={4}>
              <Card
                className={`${
                  certificateType === "assignment" ? "active" : ""
                }`}
                onClick={() => setCertificateType("assignment")}
              >
                <h4>Assignment</h4>
                Completing course and securing above threshold percentage in
                assignment
              </Card>
            </Col> */}
            <Col md={4}></Col>
          </Row>
          <Row>
            {certificateType && (
              <Col>
                <div className="d-flex flex-direction-column align-items-center py-4 ">
                  <div className="label">Threshold(Percentage):</div>
                  <div className="ml-2">
                    <input
                      className="thresholdNumber"
                      type="Number"
                      value={thresholdPercent}
                      max={100}
                      min={0}
                      onChange={(e) => {
                        setThresholdPercent(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </CertificateTypeDiv>

        <div>
          {certificateType && (
            <CertificateBuilder onSave={handleSubmitCertificate} />
          )}
        </div>
      </div>
    </Col>
  );
}

export default CerticficateContent;

import React, { useState, useEffect, useLayoutEffect } from "react";
// import Header from '../../Layout/Header';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { handleCourseList } from "../../_redux/slices/courseList";
import Loading from "../../components/Loading";
// import CourseCard, { CourseCardContainer } from '../../components/CourseCard';
import PaginationComp from "../../components/Pagination/PaginationComp";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { MdFilterList } from "react-icons/md";
import FilterByThematicAreas from "./FilterByThematicAreas";
import { Row, Col } from "react-bootstrap";
import Cards from "../../components/Cards";
import { BsPeople } from "react-icons/bs";
import StarRate from "../../components/StarRate";

const Courses = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState(window.innerWidth > 768 ? true : false);
  const { page } = useParams();
  const history = useHistory();
  const [pageNo, setPageNo] = useState(page ? page : 1);
  const courseList = useSelector((state) => state.courseList);
  const [displayFilter, setDisplayFilter] = useState(
    window.innerWidth > 1024 ? true : false
  );

  useLayoutEffect(() => {
    window.innerWidth > 1024 ? setDisplayFilter(true) : setDisplayFilter(false);
    window.innerWidth < 768 ? setList(false) : setList(true);
    const handleResize = () => {
      window.innerWidth > 1024
        ? setDisplayFilter(true)
        : setDisplayFilter(false);
      window.innerWidth < 768 ? setList(false) : setList(true);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const getCourseData = () => {
      try {
        setIsLoading(true);
        dispatch(
          handleCourseList({
            offset: (pageNo - 1) * 10,
          })
        );
        setIsLoading(false);
      } catch (error) {}
    };
    getCourseData();
    history.push(`/courselist/${pageNo}`);
  }, [dispatch, pageNo, history]);

  return (
    <Layout fluid={true}>
      <CourseContainer>
        <Col lg={2} className="courses_sidebar">
          <div className="sub-header">
            <h4 className="filter-title">Filter by Thematic Areas</h4>
          </div>
          <button
            className="filter-btn"
            onClick={() => setDisplayFilter(!displayFilter)}
          >
            <MdFilterList className="mr-2" />
            <p>Filter</p>
          </button>

          <FilterByThematicAreas
            setDisplayFilter={setDisplayFilter}
            displayFilter={displayFilter}
          />
        </Col>
        <Col lg={10} className="courses_content">
          <div className="d-flex justify-content-between">
            <div className="sub-header">
              <h2 className="main-title">Courses</h2>
            </div>
            <div className="view-switch">
              <img
                src="/assets/icons/list.svg"
                className="pointer mr-1"
                onClick={() => setList(true)}
                alt=""
              />
              <img
                src="/assets/icons/grid.svg"
                className="pointer"
                onClick={() => setList(false)}
                alt=""
              />
            </div>
          </div>
          <div className="courses_view">
            {isLoading ? (
              <Loading fontSize="5rem" color="#2f80ed" />
            ) : (
              <>
                <Cards
                  list={list}
                  dataList={courseList?.courses}
                  imageField="thumbnail_image"
                  Content={CardContent}
                  path={{ url: "/courses", withSlug: true }}
                />
                {Boolean(courseList?.count) && (
                  <PaginationComp
                    total={courseList?.count}
                    page={pageNo}
                    setPage={setPageNo}
                  />
                )}
              </>
            )}
          </div>
        </Col>
      </CourseContainer>
    </Layout>
  );
};

export default Courses;

const CardContent = ({ data: course }) => {
  return (
    <StyledCardContent>
      <h3 className="text-primary">{course?.title}</h3>
      <small className="course-desc">{course?.short_description}</small>
      <div className="d-flex align-items-center my-2">
        <StarRate rate={course?.ratings} />
      </div>
      <div className="d-flex align-items-center">
        <BsPeople className="text-primary mr-1" />
        <small className="text-primary">({course.enrolled_users})</small>
      </div>
    </StyledCardContent>
  );
};

const StyledCardContent = styled.div``;

const CourseContainer = styled(Row)`
  padding: 0 !important;
  margin: 0 !important;
  .divider {
    border-bottom: 1px solid #ccc;
    margin-block: 0.5rem;
  }
  min-height: 100vh;

  .courses {
    .sub-header {
      display: grid;
      place-content: end start;
    }
    &_sidebar {
      padding: 1rem;
      padding-right: 0.5rem;
      background-color: #f8f9fa;
      .filter-title {
        font-family: "Poppins", sans-serif !important;
        font-style: normal;
        font-weight: 500;
        font-size: 16px !important;
        line-height: 1.5 !important;
        color: #333333;
        margin-top: 1rem;
      }
      .filter-btn {
        background-color: #fff;
        padding: 0.25rem 0.75rem;
        border-radius: 0.5rem;
        border: 1px solid #ccc;
        display: none;
        color: #000 !important;
        font-size: 1.5rem !important;
      }

      .btn {
        display: none;
      }
    }
    &_content {
      /* width: 98%; */
      padding: 1rem 1.5rem;
      &_title {
        font-family: "Poppins", sans-serif !important;
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 1;
        color: #333333;
      }
    }
  }
  @media (max-width: 992px) {
    grid-template-areas: "sidebar" "content";
    .view-switch {
      display: none;
    }
    .sub-header {
      height: max-content;
    }
    .courses_sidebar {
      padding: 1rem 3rem;
      background-color: #fff;
      position: relative;
      h4 {
        display: none;
      }
      .thematic-area-filter {
        position: absolute;
        background-color: #030303;
        padding: 1rem;
        z-index: 1;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .btn {
          display: block;
        }
      }
      .filter-btn {
        display: flex;
        align-items: center;
      }
    }
    .courses_content {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    .courses_sidebar {
      display: flex;
      justify-content: end;
    }
    .thematic-area-filter {
      position: absolute;
      inset: 0;
      height: max-content;
      padding: 1rem;
      z-index: 1;

      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      .btn {
        display: block;
        margin: 1rem 2rem;
      }
    }
  }
`;

import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
// import { FaStar, FaStarHalf } from 'react-icons/fa';
import { BsPeople } from 'react-icons/bs';
import { Link } from 'react-router-dom';
const Cards = ({ course }) => {
	return (
		<Link to={`/courses/${course.slug}`}>
			<StyledCard>
				<div className="img-container">
					<img src={course?.thumbnail_image ? course.thumbnail_image : '/assets/images/placeholder.jpg'} alt="" />
					<div className="overlay">
						<h5>{course?.title}</h5>
					</div>
				</div>
				<div className="hidden-info">
					<h5>{course?.title}</h5>
					<small>M{course?.created_by?.username}</small>
					{/* <div className="d-flex align-items-center my-2">
					{course?.ratings?.average !== 0 &&
						Array.from({ length: Math.floor(course?.ratings?.average) }).map((_, i) => (
							<FaStar className="text-warning mr-1" />
						))}
					{course?.ratings?.average !== 0 && course?.ratings?.average % Math.floor(course?.ratings?.average) !== 0 && (
						<FaStarHalf className="text-warning mr-1" />
					)}

					<small>{course?.ratings?.users}</small>
				</div> */}
					<div className="d-flex align-items-center">
						<BsPeople className="text-primary mr-1" />
						<small className="text-primary">(30,000)</small>
					</div>
				</div>
			</StyledCard>
		</Link>
	);
};

const CardSlider = ({ courses }) => {
	const [start, setStart] = useState(1);
	const pageSize = useMemo(() => Math.ceil(courses?.courses?.length / 4), [courses.courses]);

	const handleNextPage = () => {
		if (start < pageSize) {
			setStart((prev) => prev + 1);
		} else {
			setStart(1);
		}
	};

	const handlePrevPage = () => {
		if (start > 1) {
			setStart((prev) => prev - 1);
		} else {
			setStart(pageSize);
		}
	};

	const courseList = useMemo(() => courses.courses.slice((start - 1) * 4, start * 4), [courses.courses, start]);

	return (
		<div className="course-list" key={courses.id}>
			<h3 className="course-title">{courses.title}</h3>

			<div className="cards-slider">
				{pageSize > 1 && (
					<div className="left-arrow" onClick={handlePrevPage}>
						<img src="/assets/icons/LeftChevron.svg" alt="" />
					</div>
				)}
				{pageSize > 1 && (
					<div className="right-arrow" onClick={handleNextPage}>
						<img src="/assets/icons/RightChevron.svg" alt="" />
					</div>
				)}
				{courseList.map((course) => (
					<Cards key={course.id} course={course} />
				))}
			</div>
			<div className="cards-slider-md">
				{courses.courses.map((course) => (
					<Cards key={course.id} course={course} />
				))}
			</div>
		</div>
	);
};

export default CardSlider;

const StyledCard = styled.div`
	padding: 2px;
	width: 100%;
	height: 100%;
	position: relative;
	.img-container {
		width: 100%;
		position: relative;
		img {
			width: 100%;
			aspect-ratio: 16 / 9;
			object-fit: cover;
			object-position: center;
		}
		.overlay {
			position: absolute;
			inset: 0;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6));
			display: flex;
			flex-direction: column;
			justify-content: end;
			h5 {
				text-align: center;
				color: white !important;
			}
		}
	}
	.hidden-info {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		background-color: white;
		width: 100%;
		padding: 0.5rem;
	}
	&:hover {
		transform: scale(1.025) translateY(-27.5%);
		z-index: 1000;
		box-shadow: 0px 6px 15px rgba(64, 83, 145, 0.25);

		.hidden-info {
			box-shadow: 0px 6px 15px rgba(64, 83, 145, 0.25);
		}
	}
	&:hover > .hidden-info {
		display: block;
	}
	@media screen and (max-width: 1024px) {
		img {
			width: 35vw;
			aspect-ratio: 16 / 9;
		}
	}
`;

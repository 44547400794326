import React from "react";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Loading from "../../../../../../components/Loading";
import BlankArea from "../_components/BlankArea";
import Quiz from "./Quiz";

const LoadingArea = styled.div`
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function QuizArea({ chapterId }) {
  const chapters = useSelector((state) => state.courseData.chapters);
  const questions = useSelector((state) => state.courseData.questions);
  const [quiz, setQuiz] = useState(null);
  const [chapter, setChapter] = useState(null);
  const [isQuizSubmitting, setIsQuizSubmitting] = useState(false);

  const handleQuizSubmitting = (isSubmitting) => {
    setIsQuizSubmitting(isSubmitting);
  };
  useEffect(() => {
    let chapterQuestions = questions[chapterId];
    if (chapterQuestions?.length) {
      setQuiz(chapterQuestions);
    }
  }, [chapterId, questions]);
  useEffect(() => {
    let chapter = chapters?.filter((chapter) => chapter.id === chapterId);
    if (chapter?.length) {
      setChapter(chapter[0]);
    }
  }, [chapterId, chapters]);

  return (
    <Fragment>
      {quiz ? (
        isQuizSubmitting ? (
          <LoadingArea>
            <Loading fontSize="50" color="#2460b9" />
          </LoadingArea>
        ) : (
          <Quiz
            key={quiz.id}
            chapter={chapter}
            chapterId={chapterId}
            quiz={quiz}
            handleQuizSubmitting={handleQuizSubmitting}
          />
        )
      ) : (
        <BlankArea area="QuizArea" />
      )}
    </Fragment>
  );
}

export default QuizArea;

import React from "react";
import { Fragment, useState } from "react";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Loading from "../../../../../../components/Loading";
import { isEmpty } from "../../../../../../_helpers/_methods";
// import { handleCreateCertificate } from "../../../../../../_redux/slices/courseData";
import BlankArea from "../_components/BlankArea";
import Certificate from "./Certificate";

const LoadingArea = styled.div`
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function CertificateArea() {
  const courseData = useSelector((state) => state.courseData);
  const [certificate, setCertificate] = useState(null);
  const [isCertificateSubmitting, setIsCertificateSubmitting] = useState(false);
  // const dispatch = useDispatch();

  const handleCertificateSubmitting = (isSubmitting) => {
    setIsCertificateSubmitting(isSubmitting);
  };

  // const createCertificate = async () => {
  //   await dispatch(handleCreateCertificate());
  // };

  useEffect(() => {
    let courseCertificate = courseData.certificate;
    console.log(courseCertificate, "courseCertificate");
    if (isEmpty(courseCertificate)) {
      setCertificate(null);
      return;
    }
    setCertificate(courseCertificate);
  }, [courseData.certificate]);

  console.log(certificate, "certificate");
  console.log(courseData.certificate, "courseData.certificate");
  return (
    <Fragment>
      {certificate ? (
        isCertificateSubmitting ? (
          <LoadingArea>
            <Loading fontSize="50" color="#2460b9" />
          </LoadingArea>
        ) : (
          <Certificate
            key={certificate.id}
            certificate={certificate}
            handleCertificateSubmitting={handleCertificateSubmitting}
          />
        )
      ) : (
        <BlankArea area="CertificateArea" 
        // action={createCertificate}
         />
      )}
    </Fragment>
  );
}

export default CertificateArea;

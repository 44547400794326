import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import LabelModal from './LabelModal';
import { v4 as uuidv4 } from 'uuid';
import { ImCross } from 'react-icons/im';
import { fileUploader } from '../../_helpers/_methods';

const StyledCertificateBuilder = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 1rem;
	border-radius: 10px;
	background-color: #f7f7f7bd;
	margin-top: 2rem;
`;
const CertificateImgContainer = styled.div`
	border: 2px solid black;
	margin-top: 1rem;
	position: relative;
	&.active {
		cursor: crosshair;
	}
`;
const CertificateImg = styled.img`
	width: 100%;
	height: 100%;
`;

const LabelBadge = styled.div`
	background: #2f6bbf;
	border-radius: 8px;
	font-size: 20px;
	font-weight: 400;
	text-align: center;
	padding: 0.8rem;
	color: white;
	margin-right: 0.5rem;
	cursor: pointer;
`;
const CertificateContainerMouseDiv = styled(LabelBadge)`
	background: #2f6bbf;
	transform: translate3d(-50%, -50%, 0);
	transition: transform 0.2s cubic-bezier(0.02, 1.23, 0.79, 1.08);
	position: absolute;
	pointer-events: none;
	transform: translate3d(${(props) => props.posX + 'px'}, ${(props) => props.posY + 'px'}, 0);
`;
const CertificatePositionLabelBadge = styled(LabelBadge)`
	background: #2f6bbfb1;
	pointer-events: none;
	position: absolute;
	transform: translate3d(${(props) => props.posX + 'px'}, ${(props) => props.posY + 'px'}, 0);
`;

const ResetTemplateButton = styled(Button)`
	position: absolute;
	right: 0;
	top: -45px;
`;

function CertificateBuilder({ onSave }) {
	const [templateImg, setTemplateImg] = useState(null);
	// eslint-disable-next-line
	const [templateImgFile, setTemplateImgFile] = useState(null);
	const [file, setFile] = useState(null);
	const [xPos, setXPos] = useState(0);
	const [yPos, setYPos] = useState(0);
	const [activeLabel, setActiveLabel] = useState(false);
	const [labels, setLabels] = useState([]);
	const [show, setShow] = useState(false);

	const [imageWidth, setImageWidth] = useState(0);
	const [imageHeight, setImageHeight] = useState(0);

	const [YOffSetCorrection, setYOffSetCorrection] = useState();
	const [isEditMode, setIsEditMode] = useState(false);

	useEffect(() => {
		setYOffSetCorrection(imageHeight + 25);
	}, [imageHeight]);
	const handleMouseMove = (e) => {
		if (!activeLabel) return;
		let nativeEvent = e.nativeEvent;
		console.log(nativeEvent.offsetX, nativeEvent.offsetY, 'aasdfasd');
		setXPos(nativeEvent.offsetX);
		setYPos(nativeEvent.offsetY);
	};

	const handleMouseClick = (e) => {
		let nativeEvent = e.nativeEvent;
		if (activeLabel) {
			if (isEditMode) {
				let tempLabels = [...labels];
				let labelIndex = tempLabels.findIndex((label) => label.id === activeLabel.id);
				console.log(labelIndex, 'labelIndex');
				tempLabels[labelIndex] = {
					id: activeLabel.id,
					label: activeLabel.label,
					xpos: nativeEvent.offsetX,
					ypos: nativeEvent.offsetY,
					displayXpos: nativeEvent.offsetX,
					displayYpos: nativeEvent.offsetY - YOffSetCorrection,
				};
				setLabels(tempLabels);
			} else {
				setLabels([
					...labels,
					{
						id: uuidv4(),
						label: activeLabel.label,
						xpos: nativeEvent.offsetX,
						ypos: nativeEvent.offsetY,
						displayXpos: nativeEvent.offsetX,
						displayYpos: nativeEvent.offsetY - YOffSetCorrection,
					},
				]);
			}
		}

		setTimeout(() => {
			setActiveLabel(false);
			setIsEditMode(false);
		});
	};
	const handleFileUpload = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			try {
				let file = e.target.files[0];
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function (e) {
					var image = new Image();

					image.src = e.target.result;

					image.onload = function () {
						var height = this.height;
						var width = this.width;
						setImageWidth(width);
						setImageHeight(height);
						console.log(width);
						console.log(height);
						return true;
					};
				};
				let uploadedFile = await fileUploader(e.target.files);
				setFile(file);
				let fileData = uploadedFile[0];
				setTemplateImg(fileData?.file_url);
				setTemplateImgFile(fileData);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const handleAddLabel = () => {
		setShow(true);
		setIsEditMode(false);
	};

	const handleSetActiveLabel = (label) => {
		setShow(false);
		if (isEditMode) {
			setActiveLabel({
				...activeLabel,
				label: label,
			});
		} else {
			setActiveLabel({
				id: uuidv4(),
				label: label,
				xpos: 0,
				ypos: 0,
				displayXpos: 0,
				displayYpos: 0,
			});
		}
	};

	const handleEditLabel = (label) => {
		setShow(true);
		setActiveLabel(label);
		setIsEditMode(true);
	};

	const resetTemplate = () => {
		let confirm = window.confirm('Are you sure you want to reset the template?');
		if (confirm) {
			setLabels([]);
			setIsEditMode(false);
			setActiveLabel(false);
			setTemplateImg(null);
		}
	};

	const handleSave = () => {
		let fieldPositionObject = {};
		labels.forEach((label) => {
			fieldPositionObject[label.label] = JSON.stringify([label.xpos, Math.abs(label.ypos)]);
		});
		let certificateData = {
			// image: templateImgFile.file_name,
			image: file,
			input_fields: JSON.stringify(labels.map((label) => label.label)),
			fields_position: JSON.stringify(fieldPositionObject),
		};
		onSave(certificateData);
	};
	return (
		<StyledCertificateBuilder>
			<h2 className="w-100 text-left my-4">Certificate Builder</h2>
			{templateImg ? (
				<Fragment>
					<div>
						<Button onClick={handleAddLabel}> + Add Label</Button>
					</div>
					<div className="mt-3 d-flex">
						{labels.map((label) => (
							<LabelBadge key={label.label} onClick={() => handleEditLabel(label)}>
								{label.label}
							</LabelBadge>
						))}
					</div>
					<CertificateImgContainer className={`${activeLabel ? 'active' : ''}`}>
						<Fragment>
							<CertificateImg
								onMouseMove={handleMouseMove}
								onMouseDownCapture={handleMouseClick}
								src={templateImg}
								alt="img"
								style={{
									width: imageWidth,
									height: imageHeight,
								}}
							/>
							<ResetTemplateButton variant="outline-danger" onClick={resetTemplate}>
								<ImCross />
							</ResetTemplateButton>
						</Fragment>

						{labels.map((label) => (
							<CertificatePositionLabelBadge key={label.label} posX={label.displayXpos} posY={label.displayYpos}>
								{label.label}
							</CertificatePositionLabelBadge>
						))}

						{Boolean(activeLabel) && (
							<CertificateContainerMouseDiv posX={xPos} posY={yPos - YOffSetCorrection}>
								{activeLabel?.label}
							</CertificateContainerMouseDiv>
						)}
					</CertificateImgContainer>
					<LabelModal
						show={show}
						activeLabel={activeLabel}
						handleClose={() => {
							setShow(false);
							setActiveLabel(false);
						}}
						handleSetActiveLabel={handleSetActiveLabel}
					/>
					<Button variant="success" className="mt-3" onClick={handleSave}>
						Save
					</Button>
				</Fragment>
			) : (
				<input accept="image/*" type="file" onChange={handleFileUpload} />
			)}
		</StyledCertificateBuilder>
	);
}

export default CertificateBuilder;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { handleCourseReset } from '../../_redux/slices/courseData';

const SidebarLink = styled(NavLink)`
	display: flex;
	color: #333;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px 20px 30px;
	list-style: none;
	height: 50px;
	letter-spacing: 0.3px;
	text-decoration: none;
	&:hover {
		color: #2f6bbf;
		opacity: 1;
	}
`;

const Submenu = styled.div`
	background-color: #f9fafb;
`;

const DropDownLink = styled(NavLink)`
	height: 50px;
	padding-left: 3rem;
	padding-right: 0.5rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #333;
	padding-top: 20px;
	padding-bottom: 20px;
	opacity: 0.7;
	.menu-items {
		span {
			font-size: 14px;
		}
	}
`;

const SubMenu = ({ user, item, currentPath, showMenu }) => {
	const dispatch = useDispatch();
	const resetCourse = () => {
		dispatch(handleCourseReset());
	};
	const isMenuOpen = React.useMemo(() => {
		if (!item.subNav) return false;
		const index = item.subNav.findIndex((nav) => nav.path === currentPath);
		if (index !== -1) return true;
		return false;
	}, [currentPath, item.subNav]);

	const [subnav, setSubnav] = useState(isMenuOpen);
	return (
		<>
			<SidebarLink
				to={item.path}
				onClick={() => item.subNav && setSubnav(!subnav)}
				activeClassName="navbar__link--active"
				exact={true}
			>
				<div className="menu-items d-flex" data-tip={showMenu ? null : item.title}>
					{item.icon}
					<span className="d-flex">{item.title}</span>
					{!showMenu}
				</div>
				<div className="chevron">{item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}</div>
			</SidebarLink>
			<Submenu>
				{subnav &&
					item.subNav.map((item, index) => {
						if (item.permittedRoles) {
							if (item.permittedRoles.includes(user?.active_role)) {
								return (
									<DropDownLink
										to={item.path}
										key={index}
										activeClassName="navbar__link--active"
										exact={true}
										onClick={() => {
											if (item.path === '/dashboard/course') {
												resetCourse();
											}
										}}
									>
										<div className="menu-items" data-tip={item.title}>
											{item.icon}
											<span>{item.title}</span>
										</div>
									</DropDownLink>
								);
							}
							return null;
						} else {
							return (
								<DropDownLink to={item.path} key={index} activeClassName="navbar__link--active" exact={true}>
									<div className="menu-items" data-tip={item.title}>
										{item.icon}
										<span>{item.title}</span>
									</div>
								</DropDownLink>
							);
						}
					})}
			</Submenu>
		</>
	);
};

export default SubMenu;

import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { createMarkup } from '../../_helpers/_methods';

const StyledOption = styled.div``;

function Option({ index, selectedOptions, option, handleSelectedOption }) {
	const [isSelected, setIsSelected] = useState(false);

	const handleOptionClick = () => {
		handleSelectedOption(option.id);
	};

	useEffect(() => {
		setIsSelected(selectedOptions.includes(option.id));
	}, [option.id, selectedOptions]);
	return (
		<StyledOption className={`quiz-play-op ${isSelected ? 'selected' : ''}`} onClick={handleOptionClick}>
			{/* <span
				style={{
					color: 'black',
					fontSize: '16px',
					marginLeft: '10px',
				}}
			>
				{index + 1}.
			</span> */}
			<input type="checkbox" checked={isSelected} />
			<div className="d-flex align-items-center" aria-disabled="false">
				<div
					style={{
						color: 'black',
						fontSize: '16px',
						marginLeft: '10px',
					}}
					dangerouslySetInnerHTML={createMarkup(option.option)}
				></div>
			</div>
		</StyledOption>
	);
}

export default Option;

import React from "react";
import styled from "styled-components";

// icons
import { GrChapterAdd } from "react-icons/gr";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { BsQuestionSquare } from "react-icons/bs";

import { Button } from "react-bootstrap";
import Loading from "../../../../../../components/Loading";

const StyledBlankArea = styled.div`
  text-align: center;
  margin-top: 5rem;
`;

const Title = styled.h2`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`;
const Info = styled.p`
  width: 600px;
  margin: auto;
  margin-bottom: 2.5rem;
`;

function BlankArea({ area, action, isLoading }) {
  // const [isLoading, setIsLoading] = useState(false)
  // console.log(isLoading)
  // setIsLoading(isLoadings);
  let Icon, title, info, buttonText;
  switch (area) {
    case "ChapterArea":
      Icon = <GrChapterAdd fontSize={58} />;
      title = "Let's build your course!";
      info = "Click 'Add Chapter' to create one.";
      buttonText = "Add Chapter";
      break;
    case "LessonArea":
      Icon = <GrChapterAdd fontSize={58} />;
      title = "No Lesson Selected!";
      info = `The 'Lessons' tab is where you will add Lessons to your Chapter. Lessons can include 
      article, video, or audio content. You will need Chapter to add Lessons. Go to 'Chapter' Tab and Click 'Add Chapters' to create one.`;
      break;
    case "QuizArea":
      Icon = <BsQuestionSquare fontSize={58} />;
      title = "Select Chapter for Quiz!";
      info = `The 'Quiz' tab is where you will add Quiz to your Chapter.
       You will need Chapter to add Quiz. Select a Chapter from your left, if you don't see a Chapter,
       Go to 'Chapter' Tab and Click 'Add Chapters' to create one.`;
      break;
    // case "AssignmentArea":
    //   Icon = <BsClipboardData fontSize={58} />;
    //   title = "Add Assignment!";
    //   info = `Assignment is your tool for evaluating the participant and awarding them with certificate,
    //    participants will be able to take this assignment after completing this course, and earn certificate. Click 'Add Assignment' to create one.`;
    //   buttonText = "Add Assignment";
    //   break;
    case "CertificateArea":
      Icon = <AiOutlineSafetyCertificate fontSize={58} />;
      title = "Certificate Coming soon !";
      info = `Certificate is the documented reward for your participant for completing the course, quiz and assignments. Click 'Add Certificate' to create one.`;
      buttonText = "Add Certificate";
      break;
    default:
      return null;
  }
  return (
    <StyledBlankArea>
      {Icon && Icon}
      <Title>{title}</Title>
      <Info>{info}</Info>
      {action && (
        <div>
          <Button onClick={action}>
            {isLoading ? <Loading /> : <span>{buttonText}</span>}
          </Button>
        </div>
      )}
    </StyledBlankArea>
  );
}

export default BlankArea;

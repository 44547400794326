import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  handleSaveQuiz,
} from "../../../../../../_redux/slices/courseData";

import CertificateContent from "./CertificateContent";
import { setUiMessage } from "../../../../../../_redux/slices/ui";


function Assignment({ chapter, chapterId, quiz, handleQuizSubmitting }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      handleQuizSubmitting(true);
      let ObjValues = Object.values(data.question);
      let body = {
        quiz_data: ObjValues,
      };
      await dispatch(handleSaveQuiz(body, chapter.slug));
      dispatch(setUiMessage("Certificate saved successfully!"));
      handleQuizSubmitting(false);
    } catch (error) {
      handleQuizSubmitting(false);
      console.log(error, "quiz save error");
    }
  };
  return (
    <Fragment>
      <Form key={chapterId} onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <CertificateContent
            errors={errors}
            register={register}
            setValue={setValue}
            clearErrors={clearErrors}
          />
        </Row>
      </Form>
    </Fragment>
  );
}

export default Assignment;

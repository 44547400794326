import styled from 'styled-components';

export const AssignmentCard = styled.div`
	width: 80%;
	margin: auto;
	margin-top: 1rem;
	box-shadow: 0px 6px 15px rgba(64, 83, 145, 0.25);
	padding: 2rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2rem;
	.assignment {
		&-description {
			line-height: 1.2;
			padding: 0.5rem 0;
			text-align: justify;
		}
		&-right {
			ul {
				margin: 0.5rem 0;
				li {
					list-style: inside;
					list-style-position: outside;
					font-size: 1rem !important;
					color: #333333;
				}
			}
		}
		.start-btn {
			background-color: #2460b9;
		}
	}
	@media screen and (max-width: 768px) {
		width: 100%;
		box-shadow: none;
		grid-template-columns: 1fr;
		.assignment-left {
			display: none;
		}
	}
`;

import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';

export const initialState = {
	reviews: [],
	review: {},
};

const ReviewSlice = createSlice({
	name: 'review',
	initialState,
	reducers: {
		addReview: (state, action) => {
			state.reviews = [action.payload, ...state.reviews];
		},
		setReview: (state, action) => {
			state.reviews = action.payload;
		},
	},
});

const { addReview, setReview } = ReviewSlice.actions;

export default ReviewSlice.reducer;

export const handleGetReview = (courseSlug) => async (dispatch) => {
	const response = await fetchWrapper.get(`course/${courseSlug}/review/`);
	console.log(response);
	dispatch(setReview(response?.data));
};

export const handleAddReview = (review, courseSlug) => async (dispatch) => {
	const response = await fetchWrapper.post(`course/${courseSlug}/review/`, review);
	dispatch(addReview(response?.data));
};

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { createMarkup } from "../../_helpers/_methods";
import { FaStar, FaStarHalf } from "react-icons/fa";
import { BsPeople } from "react-icons/bs";
import StarRate from "../StarRate";

const CourseCard = ({ list, course, style }) => {
  return (
    <>
      {list ? (
        <List style={{ style }}>
          <div className="list_left">
            <Link to={`/courses/${course?.slug}`}>
              <Img
                src={
                  course?.thumbnail_image || "/assets/images/placeholder.jpg"
                }
              />
            </Link>
            <p className="duration">2 hours</p>
          </div>
          <div className="list_right">
            <Link className="title" to={`/courses/${course?.slug}`}>
              <h3 className="list_right_title">{course?.title}</h3>
            </Link>
            {/* <span
							style={{
								color: 'black',
								fontSize: '32px',
								fontWeight: '400',
							}}
							dangerouslySetInnerHTML={createMarkup(course?.short_description)}
						></span> */}
            <small
              className="desc"
              dangerouslySetInnerHTML={createMarkup(course?.short_description)}
            ></small>
            <small className="author">{course?.created_by?.username}</small>
            <div className="d-flex align-items-center my-2">
              <StarRate rate={course?.ratings} />
            </div>
            <div className="d-flex align-items-center">
              <BsPeople className="text-primary mr-1" />
              <small className="text-primary">({course?.enrolled_users})</small>
            </div>
          </div>
        </List>
      ) : (
        <Grid className="card">
          <div className="grid_top">
            <Link to={`/courses/${course?.slug}`}>
              <Img
                src={
                  course?.thumbnail_image || "/assets/images/placeholder.jpg"
                }
              />
            </Link>
            <p className="duration">2 hours</p>
          </div>
          <div className="grid_bottom">
            <Link to={`/courses/${course?.slug}`}>
              <h3 className="grid-bottom_title">{course?.title}</h3>
            </Link>
            <small className="author">{course?.created_by?.username}</small>
            <div className="d-flex align-items-center my-2">
              <StarRate rate={course?.ratings} />
            </div>
            <div className="d-flex align-items-center">
              <BsPeople className="text-primary mr-1" />
              <small className="text-primary">({course?.enrolled_users})</small>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
};
export const EnrolledCourseCard = ({
  list,
  course,
  style,
  course__title,
  course__thumbnail_image,
  course__slug,
  enrolled_users,
  created_by,
}) => {
  return (
    <>
      {list ? (
        <List style={{ style }}>
          <div className="list_left">
            <Link
              to={`/courses/${course__slug || course?.course_slug}/enrolled`}
            >
              <Img
                src={
                  course?.thumbnail_image ||
                  course__thumbnail_image ||
                  "/assets/images/placeholder.jpg"
                }
              />
            </Link>
            <p className="duration">2 hours</p>
          </div>
          <div className="list_right">
            <Link to={`/courses/${course?.course_slug}/enrolled`}>
              <h3 className="list_right_title">
                {course?.course_title || course__title}
              </h3>
            </Link>
            <small className="desc">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus
              dolorem magnam cupiditate quas nemo in sint perspiciatis
              voluptates numquam voluptatibus.
            </small>
            <small className="author">
              {course?.created_by?.username || created_by}
            </small>
            <div className="d-flex align-items-center my-2">
              <FaStar className="text-warning mr-1" />
              <FaStar className="text-warning mr-1" />
              <FaStar className="text-warning mr-1" />
              <FaStar className="text-warning mr-1" />
              <FaStarHalf className="text-warning mr-1" />
              <small>(20,000)</small>
            </div>
            <div className="d-flex align-items-center">
              <BsPeople className="text-primary mr-1" />
              <small className="text-primary">
                ({enrolled_users ? enrolled_users : 30000})
              </small>
            </div>
          </div>
        </List>
      ) : (
        <Grid>
          <div className="grid_top">
            <Link
              to={`/courses/${course?.course_slug || course__slug}/enrolled`}
            >
              <Img
                src={
                  course?.thumbnail_image ||
                  course__thumbnail_image ||
                  "/assets/images/placeholder.jpg"
                }
              />
            </Link>
            <p className="duration">2 hours</p>
          </div>
          <div className="grid_bottom">
            <Link
              to={`/courses/${course?.course_slug || course__slug}/enrolled`}
            >
              <h3 className="grid_bottom_title">
                {course?.course_title || course__title}
              </h3>
            </Link>
            <small className="author">
              {course?.created_by?.username || created_by}
            </small>
            <div className="d-flex align-items-center my-2">
              <FaStar className="text-warning mr-1" />
              <FaStar className="text-warning mr-1" />
              <FaStar className="text-warning mr-1" />
              <FaStar className="text-warning mr-1" />
              <FaStarHalf className="text-warning mr-1" />
              <small>(20,000)</small>
            </div>
            <div className="d-flex align-items-center">
              <BsPeople className="text-primary mr-1" />
              <small className="text-primary">
                ({enrolled_users ? enrolled_users : 30000})
              </small>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
};

export default CourseCard;

export const CourseCardContainer = styled.div`
  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    width: 100%;
    margin: auto;
  }
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }

  ${(props) =>
    props.list
      ? `
			display: flex;
			flex-direction:column;
		`
      : `
		display:grid;
		padding:2rem 0;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 2.5rem;
		.divider {
			display:none;
		}
		`};
`;

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 30vh;
  grid-gap: 2rem;
  padding: 0.5rem 0;
  /* border-bottom: 1px solid #ccc; */
  .title {
    &:hover {
      color: #333333;
    }
  }
  .list {
    &_left {
      position: relative;
      .duration {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: #2f80ed;
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        font-family: "Poppins", sans-serif !important;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 17px;
        color: #ffffff;
      }
    }
    &_right {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      &_title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }
      text-align: justify;
      .desc {
        width: 80%;
        margin-bottom: 0.5rem;
        overflow: hidden;
        max-height: 66px;
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        * {
          font-family: "Roboto", sans-serif !important;
          font-weight: 400 !important;
          font-size: 16px !important;
          line-height: 22px !important;
          color: #4f4f4f !important;
        }
      }
      .author {
        font-family: "Roboto", sans-serif !important;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 22px;
        color: #828282;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 30vw 70vw;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0px 6px 30px rgba(64, 83, 145, 0.25);
  /* border-radius: 0.5rem; */

  .grid {
    &_top {
      position: relative;
      width: 100%;
      .duration {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: #2f80ed;
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        font-family: "Poppins", sans-serif !important;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 17px;
        color: #ffffff;
      }
      div {
        height: 25vh;
        margin-bottom: 1rem;
        border-radius: 5px;
      }
    }
    &_bottom {
      &_title {
        font-family: "Poppins", sans-serif !important;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: #333333;
      }
      width: 100%;
      height: 100%;
      .author {
        font-family: "Roboto", sans-serif !important;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #828282;
      }
    }
  }
`;

const Img = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: #002;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ccc;
  border-radius: 5px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 16 / 9;
    content: "";
    background-color: rgba(0, 0, 64, 0.1);
    border-radius: 5px;
  }
  @media screen and (max-width: 1024px) {
    aspect-ratio: 4 / 3;
  }
`;

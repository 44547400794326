import { authHeader } from "./auth-header";
import { ErrorMessageHandler } from "./_methods";

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

async function get(url) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  try {
    let URL = COMMON_URL + url;
    let response = await fetch(URL, requestOptions);
    let handledResponse = await handleResponse(response);
    // console.log(handledResponse, 'HandleResponse')
    return handledResponse;
  } catch (err) {
    ErrorMessageHandler(err);
  }
}

function post(url, body, contentType) {
  let user = JSON.parse(localStorage.getItem("kms_user"));

  const requestOptions = {
    method: "POST",
    headers: contentType
      ? { Authorization: `Bearer ${user.token}` }
      : authHeader(),
    body: contentType ? body : JSON.stringify(body),
  };
  return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

function put(url, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

function patch(url, body, contentType) {
  let user = JSON.parse(localStorage.getItem("kms_user"));
  const requestOptions = {
    method: "PATCH",
    headers: contentType
      ? { Authorization: `Bearer ${user.token}` }
      : authHeader(),
    body: contentType ? body : JSON.stringify(body),
  };
  return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

// helper functions
function handleResponse(response) {
  return response.text().then((text) => {
    try {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        const error = (data && data) || response;
        return Promise.reject(error);
      }
      return data;
    } catch (e) {
      return Promise.reject(response);
    }
  });
}

export const fetchWrapper = {
  get,
  post,
  put,
  patch,
  delete: _delete,
};

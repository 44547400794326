import styled from "styled-components";
import { createMarkup } from "../../../../_helpers/_methods";

/**
 * 
 */
export default function PosterBanner({ previewData }) {

    return (
        <StyledPosterBanner style={{
            backgroundImage: `url(${previewData?.bannerImg || '/assets/images/avatar-placeholder.png'})`,
        }}>
            <div className="text-container">
                <h4 className="mb-0">{previewData?.bannerTitle}</h4>

                <div dangerouslySetInnerHTML={createMarkup(previewData?.content)}></div>
            </div>
        </StyledPosterBanner >
    )
}

const StyledPosterBanner = styled.div`
    display:flex;
    align-items: flex-end;
    height: 500px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    :hover .text-container {
        background-color: #e6e6e6ff;
        opacity: 1;
    }
            
    .text-container {
        width: 100%;
        background-color: #e6e6e630;
        opacity: 0.4;
        padding: 0.8rem;
        transition: opacity 300ms, background-color 300ms;
    }
`;
import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
// import { ErrorMessageHandler } from '../../_helpers/_methods';

export const initialState = {
	loading: false,
	courseGroup: [],
};

const courseGroupSlice = createSlice({
	name: 'courseGroup',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setCourseGroup: (state, action) => {
			state.courseGroup = action.payload;
		},
	},
});

const { setLoading, setCourseGroup } = courseGroupSlice.actions;

export default courseGroupSlice.reducer;

export const getCourseGroup = () => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.get('thematic-area/grouped/');
		dispatch(setCourseGroup(response?.data));
	} catch (error) {
		console.log(error);
	}
	dispatch(setLoading(false));
};

import React from 'react';
import SidebarData from './SidebarData';
import SubMenu from './SubMenu';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Sidebar = () => {
	const user = useSelector((state) => state.user.user);
	const location = useLocation();
	return (
		<div className="sidebar">
			<div className="sidebar_inner">
				<div className="sidebar-wrapper">
					<ul className="sidebar-menu">
						{SidebarData.map((item, index) => {
							if (item.permittedRoles) {
								if (item.permittedRoles.includes(user?.active_role)) {
									return <SubMenu user={user} item={item} key={item.id} currentPath={location.pathname} />;
								} else {
									return null;
								}
							} else {
								return <SubMenu user={user} item={item} key={item.id} currentPath={location.pathname} />;
							}
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;

import React from 'react';
// import { FaEnvelope, FaMapMarkedAlt, FaPhone } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Footer = () => {
	return (
		<StyledFooter>
			<div className="sub-footer">
				<div className="footer-left">
					<img src="/assets/images/emblem.png" style={{ width: '60px' }} alt="" />
					<h3 className="my-2">Learn in a fun Way</h3>
				</div>
				<div className="footer-center">
					<h4 className="widget_title">Useful Links</h4>
					<ul className="footer-menu">
						<li>
							<Link to="#">Home</Link>
						</li>
						<li>
							<Link to="#">About</Link>
						</li>
						<li>
							<Link to="#">Service </Link>
						</li>
						<li>
							<Link to="#">Contact </Link>
						</li>
					</ul>
				</div>
				<div className="footer-right">
					<h4 className="widget_title">Contact Us</h4>
					<ul className="footer-menu">
						<li>
							{/* <FaMapMarkedAlt /> */}
							<span>Kathmandu, Nepal</span>
						</li>
						<li>
							{/* <FaPhone /> */}
							<span>+977-08129291</span>
						</li>
						<li>
							{/* <FaEnvelope /> */}
							<span>info@plgsp.gov.np</span>
						</li>
					</ul>
				</div>
			</div>

			{/* <div class="footer-bottom">
					<div class="container">
						<div class="row align-items-center">
							<div class="col-lg-12 col-md-12 text-center">
								<p class="mb-0">© 2021. Powered By PLGSP</p>
							</div>
						</div>
					</div>
				</div> */}
		</StyledFooter>
	);
};

export default Footer;

const StyledFooter = styled.footer`
	z-index: 0;
	background-color: #2460b9;
	color: white;

	.sub-footer {
		width: 80%;
		margin: 0 auto;
		display: grid;
		padding: 2rem 0;
		grid-template-columns: 2fr 1fr 2fr;

		h3 {
			font-family: 'Poppins', sans-serif !important;
			font-weight: 500;
			font-size: 24px;
			line-height: 34px;

			color: #ffffff;
		}
		h4 {
			font-family: 'Poppins', sans-serif !important;
			font-weight: 700 !important;
			font-size: 21px;
			line-height: 28px;
			color: #ffffff;
		}
		.footer-menu {
			a {
				font-family: 'Poppins', sans-serif !important;
				font-weight: 400;
				font-size: 18px;
				line-height: 22px;
				color: #ffffff;
			}
		}
		li {
			line-height: 2;
			color: white;
			span {
				font-family: 'Poppins', sans-serif !important;
				font-weight: 400;
				font-size: 18px;
				line-height: 22px;
				color: #ffffff;
			}
		}
	}
	@media screen and (max-width: 768px) {
		/* display: none; */
		.sub-footer {
			grid-template-columns: 1fr;
			.footer-left,
			.footer-center {
				margin-bottom: 2rem;
			}
		}
	}
`;

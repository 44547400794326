import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const AssignmentResultBanner = () => {
	return (
		<StyledAssignmentResultBanner>
			<h2 className="text-center my-4">Well Done!</h2>
			<div className="d-flex justify-content-center mb-4">
				<img src="/assets/icons/FilledStar.svg" alt="" />
				<img src="/assets/icons/FilledStar.svg" alt="" />
				<img src="/assets/icons/FilledStar.svg" alt="" />
				<img src="/assets/icons/FilledStar.svg" alt="" />
				<img src="/assets/icons/OutlineStar.svg" alt="" />
			</div>
			<div className="result">
				<img src="/assets/images/celebrate.svg" alt="" />
				<div className="marks">
					<h1>35</h1>
					<h3>
						<h3>Correct</h3>
						<h3>out of 40</h3>
					</h3>
				</div>
			</div>
			<div style={{ width: 'max-content', margin: 'auto' }}>
				<img src="/assets/images/thumbsup.svg" alt="" />
			</div>
			<Button className="mx-auto my-4">Check Your Answer</Button>
		</StyledAssignmentResultBanner>
	);
};

export default AssignmentResultBanner;

const StyledAssignmentResultBanner = styled.div`
	.result {
		width: max-content;
		margin: auto;
		position: relative;
		.marks {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			h1 {
				font-family: 'Poppins' !important;
				font-style: normal;
				font-weight: 500 !important;
				font-size: 80px !important;
				line-height: 67px !important;
				color: #00c48c;
			}
			h3 {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 500;
				font-size: 24px;
				line-height: 1;
				color: #333333;
			}
		}
	}
`;

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { createMarkup } from '../../_helpers/_methods';

function LessonArticleContent({ articleContent, handleActiveContentChange, hasVideo, bigFontSize }) {
	return (
		<Fragment>
			<div className="previewComponent-content">
				{articleContent?.content && (
					<P bigFontSize={bigFontSize} dangerouslySetInnerHTML={createMarkup(articleContent?.content)}></P>
				)}
			</div>
		</Fragment>
	);
}

export default LessonArticleContent;

const P = styled.p`
	${({ bigFontSize }) =>
		bigFontSize
			? `
	* {
		font-size: large !important;
	}
`
			: `
	* {
		font-size: medium !important;
	}`}
`;

import React, { useState } from 'react';
import { useEffect } from 'react';
// import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CourseCard, { CourseCardContainer } from '../../../../components/CourseCard';
import Loading from '../../../../components/Loading';
import { handleCourseList } from '../../../../_redux/slices/courseList';
import PaginationComp from '../../../../components/Pagination/PaginationComp';
import { useParams, useHistory } from 'react-router-dom';

function AdminAllCourses() {
	const courseList = useSelector((state) => state.courseList);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const [list, setList] = useState(true);
	const { page } = useParams();
	const history = useHistory();
	const [pageNo, setPageNo] = useState(page ? page : 1);

	useEffect(() => {
		const getCourseData = async () => {
			try {
				setIsLoading(true);
				await dispatch(handleCourseList({ offset: (pageNo - 1) * 10 }));
				setIsLoading(false);
				history.push(`/dashboard/all-courses/${pageNo}`);
			} catch (error) {}
		};
		getCourseData();
	}, [dispatch, pageNo, history]);

	return (
		<>
			{/* <div className="main-content-inner">
      <h2 className="main-title mb-2">All Courses</h2>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          courses.map((course) => {
            return (
              <div key={course.id} className="col-sm-12 col-md-4 ">
                <CourseCard
                  title={course.title}
                  slug={course.slug}
                  short_desc={course.short_description}
                  thumbnailImg={course.thumbnail_image}
                  thematicAreas={course.thematic_area}
                />
              </div>
            );
          })
        )}
      </Row>
    </div> */}
			<div className="courses_content">
				<div className="d-flex justify-content-between">
					<h2 className="courses_content_title">All Courses</h2>
					<div className="view-switch">
						<img src="/assets/icons/list.svg" className="pointer mr-1" onClick={() => setList(true)} alt="" />
						<img src="/assets/icons/grid.svg" className="pointer" onClick={() => setList(false)} alt="" />
					</div>
				</div>
				<div className="courses_view">
					{isLoading ? (
						<Loading />
					) : (
						<>
							<CourseCardContainer list={list}>
								{courseList?.courses?.map((course) => (
									<CourseCard key={course.id} course={course} list={list} />
								))}
							</CourseCardContainer>
							<PaginationComp total={courseList?.count} page={pageNo} setPage={setPageNo} />
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default AdminAllCourses;

import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { VscLoading } from 'react-icons/vsc';
import { FormGroup } from '../components/FormGroup/FormGroup';
import { loginUser } from '../_redux/slices/user';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Login = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.user.loading);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		defaultValues: {},
	});

	const handleLogin = (user) => {
		try {
			dispatch(loginUser(user, history));
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<StyledLoginWrapper className="login">
			<div className="login__wrapper">
				<div className="login__form">
					<div className="logo">
						<img src="/assets/images/emblem.png" alt="logo" />
						<h3>KMS</h3>
					</div>
					<h1 className="main-title">Login</h1>
					<Form onSubmit={handleSubmit(handleLogin)}>
						<FormGroup
							type="text"
							label="Username"
							placeholder="Enter Username/Email"
							id="username"
							register={register}
							error={errors}
							disabled={loading}
							required={true}
						/>
						<FormGroup
							label="Password"
							type="password"
							id="password"
							placeholder="Enter Password"
							register={register}
							disabled={loading}
							error={errors}
							required={true}
						/>

						<Link to="/forgot" className="forgot-password">
							<p>Forgot your password?</p>
						</Link>
						<Form.Group>
							<Form.Check
								name="checkbox"
								label="Remember me"
								style={{
									fontSize: '13px',
								}}
							/>
						</Form.Group>
						<Button className="btn-primary my-2 d-block btn-round" disabled={loading} type="submit">
							{loading ? <VscLoading className="spin" /> : 'Login'}
						</Button>
					</Form>
					<div className="copyright">
						<p>Designed and Powered by</p>
						<a href="https://ideabreed.net/" target="_blank" rel="noreferrer">
							Idea Breed IT Solution Pvt. Ltd.
						</a>
					</div>
				</div>
			</div>
		</StyledLoginWrapper>
	);
};

export default Login;

const StyledLoginWrapper = styled.div`
	.login__wrapper {
		&:before {
			background-image: url('/assets/images/nepal-map.svg');
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			background-color: #bee0f6;
			opacity: 0.4;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			content: '';
			/* z-index: -1; */
		}
	}
`;

import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//
import ReactTable from '../ReactTable';
import DefaultColumnFilter from '../ReactTable/Filters/DefaultColumnFilter';

//
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

/**
 *
 */
export default function SuccessStoriesTable({ handleSetEditSuccessStoryData, handleShowPreview }) {
	const { page } = useParams();
	const history = useHistory();

	const [pageNo, setPageNo] = useState(page);
	const [totalCount, setTotalCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [successStoriesData, setSuccessStoriesData] = useState([]);
	const userRole = useSelector((state) => state?.user?.user?.active_role);

	const getSuccessStories = useCallback(
		async (page) => {
			setIsLoading(true);
			const response = await fetchWrapper.get(
				`story/?offset=${(page - 1) * 10}${
					['resource_person', 'participant'].includes(userRole) ? '&approved=true' : ''
				}`
			);
			setTotalCount(response.data.count);
			setSuccessStoriesData(response.data.results);
			setIsLoading(false);
		},
		[userRole]
	);

	useEffect(() => {
		getSuccessStories(pageNo);
		history.push(`/dashboard/success-stories/${pageNo}`);
	}, [history, pageNo, getSuccessStories]);

	const handleDelete = useCallback(
		async (id) => {
			try {
				await fetchWrapper.delete(`story/${id}/`);
				Swal.fire('Deleted!', 'The content has been deleted.', 'success');
				getSuccessStories();
			} catch (e) {}
		},
		[getSuccessStories]
	);

	const columns = useMemo(() => {
		let cols = [
			{
				Header: 'S.N.',
				accessor: 'sn',
				disableFilters: true,
				width: '100px',
			},
			{
				Header: 'Title',
				accessor: 'title',
				Filter: DefaultColumnFilter,
				width: '150px',
			},
			{
				Header: 'Short Description',
				accessor: 'short_description',
				width: 'auto',
				disableFilters: true,
				Cell: ({ row: { original }, cell: { value } }) => {
					const fomratedString = value?.slice(0, 200);
					return <span>{fomratedString}</span>;
				},
			},
			{
				Header: 'Created by',
				accessor: 'created_by',
				Filter: DefaultColumnFilter,
				width: '150px',
			},
			{
				Header: 'Created at',
				accessor: 'created_at',
				disableFilters: true,
				width: '150px',
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				disableFilters: true,
				width: '150px',
			},
		];

		if (!['resource_person', 'participant'].includes(userRole)) {
			const visiblityColumn = {
				Header: 'Visibility',
				accessor: 'visibility',
				disableFilters: true,
				width: '150px',
			};

			cols.splice(4, 0, visiblityColumn);
		}
		return cols;
	}, [userRole]);

	const data = useMemo(() => {
		if (successStoriesData?.length > 0) {
			let count = 0;
			const results = successStoriesData.map((successStory) => ({
				sn: ++count,
				title: successStory.title,
				short_description: successStory.short_description,
				created_by: successStory.created_by,
				created_at: dayjs(successStory.created_at).format('YYYY-MM-DD'),
				visibility: successStory.is_public ? 'Public' : 'Private',
				actions: (
					<>
						<FaEye
							className="text-primary mx-1 cursor-pointer"
							onClick={() => {
								handleShowPreview(successStory);
							}}
						/>
						{['superuser', 'cd_admin'].includes(userRole) && (
							<>
								<FaEdit
									className="text-primary mx-1 cursor-pointer"
									onClick={() => {
										handleSetEditSuccessStoryData(successStory);
									}}
								/>
								<FaTrashAlt
									className="text-danger cursor-pointer"
									onClick={() => {
										Swal.fire({
											title: 'Are you sure?',
											text: "You won't be able to revert this!",
											icon: 'warning',
											showCancelButton: true,
											confirmButtonColor: '#3085d6',
											cancelButtonColor: '#d33',
											confirmButtonText: 'Yes, delete it!',
										}).then((result) => {
											if (result.isConfirmed) {
												handleDelete(successStory.id);
											}
										});
									}}
								/>
							</>
						)}
					</>
				),
			}));

			return results;
		} else return [];
	}, [handleDelete, handleSetEditSuccessStoryData, handleShowPreview, successStoriesData, userRole]);

	return (
		<div className="w-100">
			<ReactTable
				columns={columns}
				data={data}
				pageNo={pageNo}
				setPageNo={setPageNo}
				totalCount={totalCount}
				loading={isLoading}
			/>
		</div>
	);
}

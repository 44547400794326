import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchWrapper } from '../../../../_helpers/fetchWrapper';
import { AssignmentCard } from '../AssignmentCard';

function AssignmentResult() {
	const { courseSlug } = useParams();
	const certificate = useSelector((state) => state.course?.course?.certificate);
	const user = useSelector((state) => state.user?.user);
	// eslint-disable-next-line no-unused-vars
	const [getsCertificate, setGetsCertificate] = useState(true);
	const [showCertificateModal, setShowCertificateModal] = useState(false);
	const [myCertificate, setMyCertificate] = useState(null);

	const handleClose = () => {
		setShowCertificateModal(false);
	};
	console.log(certificate, 'certificate');
	const generateCertificate = async () => {
		try {
			if (certificate) {
				let inputFieldObj = {};
				certificate?.input_fields &&
					JSON.parse(certificate.input_fields).forEach((field) => {
						inputFieldObj[field] = user.username;
					});
				let body = {};
				body.template_id = certificate.id;
				body.input_fields = JSON.stringify(inputFieldObj);
				const response = await fetchWrapper.post(`/course/${courseSlug}/certificate/`, body);
				setMyCertificate(response.data.certificate_url);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		setShowCertificateModal(!!myCertificate);
	}, [myCertificate]);
	return (
		<AssignmentCard>
			<div className="assignment-left">
				<h3>Java Course for Beginners</h3>
				<h4 style={{ color: '#2f80ed', marginTop: '0.5rem' }}>Assignment Completion</h4>
				<div className="mt-2">
					<p>Congratulations on completing the assignment!</p>
					<p>Thank you for taking this course.</p>
				</div>
			</div>
			<div className="assignment-right">
				<h4>You have learnt</h4>
				<ul>
					<li>Do not use Search Engine.</li>
					<li>You can check your previous answers and make changes.</li>
					<li>You may exit the assignment anytime.</li>
				</ul>
			</div>
			{myCertificate ? (
				<div>
					<Button onClick={() => setShowCertificateModal(true)}>Show Certificate</Button>
				</div>
			) : (
				<div>
					{
						// gets certificate after passing the assignment?
						getsCertificate ? <Button onClick={generateCertificate}>Get Certificate</Button> : null
					}
				</div>
			)}
			{showCertificateModal && (
				<Modal size="xl" show={showCertificateModal} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Certificate</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<img className="w-100" src={myCertificate} alt="certificate" />
						</div>
					</Modal.Body>
				</Modal>
			)}
		</AssignmentCard>
	);
}

export default AssignmentResult;

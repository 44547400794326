import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PrivateRoute } from './_helpers/auth-route';

import MyCourses from './pages/Admin/Courses/MyCourses/MyCourses';
import EnrolledCourses from './pages/Admin/Courses/EnrolledCourses/EnrolledCourses';
import ThematicArea from './pages/Admin/ThematicAreas/ThematicAreas';

import ScrollToTop from './_helpers/ScrollToTop';
import { useDispatch } from 'react-redux';
import { handleCourseList } from './_redux/slices/courseList';
import { getUserData } from './_redux/slices/user';
// import { Suspense, lazy } from "react";
import LanguageWrapper from './components/LanguageWrapper';

// pages
import Dashboard from './pages/Dashboard';
import Homepage from './pages/Landing/Homepage';
// pages/auth
import Forgot from './auth/Forgot';
import Login from './auth/Login';
import Support from './pages/Support';

import ParticipantDashboard from './pages/Participants/Dashboard';

// course
import Assignment from './pages/Assignment/Assignment';
import AssessmentResult from './pages/Quiz/AssessmentResult/AssessmentResult';

import Quiz from './pages/Quiz/Quiz';
import SingleLesson from './pages/Participants/SingleLesson';
import SingleCourse, { SingleCoursePage } from './pages/SingleCourse/SingleCourse';
// import Courses from './pages/Courses/Courses';
import Courses from './pages/Courses';
import KnowledgeProducts from './pages/knowledgeProducts';
import CapacitySupport from './pages/CapacitySupport';
import CapacitySupportPreview from './pages/CapacitySupport/Preview';
// pages/admin
import Chapters from './pages/Admin/Courses/AddNewCourse/ChapterBuilder';
import Library from './pages/Admin/Library/Library';
import AdminAllCourses from './pages/Admin/Courses/AllCourses/AllCourses';
import AddCourse from './pages/Admin/Courses/AddNewCourse';
import Layout from './Layout/Admin/Layout';
import EnrolledCourse from './pages/SingleCourse/EnrolledCourse';
import ThematicAreas from './pages/ThematicAreas';
import Setting from './pages/Admin/Setting';
import QuizResultPreview from './pages/Quiz/QuizResultPreview';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import SuccessStories from './pages/Admin/KnowledgeProducts/SuccessStories/SuccessStories';
import PostersBanners from './pages/Admin/KnowledgeProducts/PosterBanners/PostersBanners';
import PosterBanner from './pages/Admin/KnowledgeProducts/PosterBanners/PosterBanner';
import PosterBannerForm from './pages/Admin/KnowledgeProducts/PosterBanners/PosterBannerForm';
import Documentaries from './pages/Admin/KnowledgeProducts/Documentaries';
import Documentary from './pages/Admin/KnowledgeProducts/Documentaries/Documentary';
import DocumentaryForm from './pages/Admin/KnowledgeProducts/Documentaries/DocumentaryForm';
import KnowledgeProductPreview from './pages/knowledgeProducts/Preview';

//
import CapacitySupportTable from './pages/Admin/CapacitySupport/CapacitySupportTable';
import CapacitySupportForm from './pages/Admin/CapacitySupport/CapacitySupportForm';
import CapacitySupportTypesTable from './pages/Admin/CapacitySupport/CapacitySupportTypesTable';
import CompletedCourse from './pages/SingleCourse/CompletedCourse';

/**
 * 
 */
export default function App() {
	const message = useSelector((state) => state.ui.message);
	const local = useSelector((state) => state.local.local);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const dispatch = useDispatch();
	useEffect(() => {
		if (isLoggedIn) {
			dispatch(getUserData());
			// dispatch(getThematicAreas());
		}
		dispatch(handleCourseList({ page: 1 }));
	}, [dispatch, isLoggedIn]);

	useEffect(() => {
		if (message) {
			if (message.error) {
				toast.error(message.message);
			} else {
				toast.success(message.message);
			}
		}
	}, [message]);

	return (
		<Router>
			<LanguageWrapper local={local}>
				<ScrollToTop />

				{/* <AuthRoute exact path="/login" component={Login} />
      <AuthRoute exact path="/forgot" component={Forgot} /> */}
				<Route exact path="/login" component={Login} />
				<Route exact path="/forgot" component={Forgot} />
				<Route exact path="/" component={Homepage} />
				<Route exact path="/about-us" component={AboutUs} />
				<Route exact path="/contact" component={Contact} />
				{/* <Route exact path="/coursesold" component={Courses} /> */}
				<Route exact path="/courselist/:page" component={Courses} />

				<Route exact path="/knowledge-products/:subcategory" component={KnowledgeProducts} />
				<Route exact path="/knowledge-products/:subcategory/:id" component={KnowledgeProductPreview} />
				<Route exact path="/capacity-support/:pageNo" component={CapacitySupport} />
				<Route exact path="/capacity-support/preview/:id" component={CapacitySupportPreview} />

				{/* <Route exact path="/courses/id" component={CourseDetail} /> */}
				<Route exact path="/courses/:courseSlug" component={SingleCoursePage} />
				<PrivateRoute exact path="/courses/:courseSlug/enrolled" component={EnrolledCourse} />
				<PrivateRoute exact path="/courses/:courseSlug/completed" component={CompletedCourse} />
				<PrivateRoute exact path="/courses/:courseSlug/enrolled/:lessonSlug" component={SingleLesson} />
				<PrivateRoute exact path="/courses/:courseSlug/enrolled/:chapterSlug/assessment/quiz" component={Quiz} />
				<PrivateRoute
					exact
					path="/courses/:courseSlug/enrolled/:chapterSlug/quiz/answer"
					component={AssessmentResult}
				/>
				<PrivateRoute
					exact
					path="/courses/:courseSlug/enrolled/:chapterSlug/quiz/preview"
					component={QuizResultPreview}
				/>
				<PrivateRoute exact path="/courses/:courseSlug/enrolled/assessment/assignment" component={Assignment} />
				<Route exact path="/thematic_area/" component={ThematicAreas} />

				<Route path="/dashboard">
					<Layout>
						<PrivateRoute exact path="/dashboard" component={Dashboard} />
						<PrivateRoute exact path="/dashboard/student" component={ParticipantDashboard} />
						<PrivateRoute exact path="/dashboard/thematic-areas/:page" component={ThematicArea} />
						<PrivateRoute exact path="/dashboard/all-courses/:page" component={AdminAllCourses} />
						<PrivateRoute exact path="/dashboard/courses/:page" component={MyCourses} />
						<PrivateRoute exact path="/dashboard/enrolled-courses/:page" component={EnrolledCourses} />
						<PrivateRoute exact path="/dashboard/settings" component={Setting} />
						<PrivateRoute exact path="/dashboard/library/:page" component={Library} />
						<PrivateRoute exact path="/dashboard/courses/course/:courseSlug" component={SingleCourse} />
						<PrivateRoute exact path="/dashboard/course" component={AddCourse} />
						<PrivateRoute exact path="/dashboard/course/:courseSlug" component={Chapters} />
						<PrivateRoute exact path="/dashboard/support" component={Support} />

						<PrivateRoute exact path="/dashboard/success-stories/:page" component={SuccessStories} />
						<PrivateRoute exact path="/dashboard/success-story/:id" component={SuccessStories} />
						<PrivateRoute exact path="/dashboard/success-story-form/:id" component={SuccessStories} />

						<PrivateRoute exact path="/dashboard/banners/:page" component={PostersBanners} />
						<PrivateRoute exact path="/dashboard/banner/:id" component={PosterBanner} />
						<PrivateRoute exact path="/dashboard/banner-form/:id" component={PosterBannerForm} />


						<PrivateRoute exact path="/dashboard/capacity-support/:page" component={CapacitySupportTable} />
						<PrivateRoute exact path={["/dashboard/capacity-support-form", "/dashboard/capacity-support-form/:id"]} component={CapacitySupportForm} />
						<PrivateRoute exact path="/dashboard/capacity-support-type/:page" component={CapacitySupportTypesTable} />
						<PrivateRoute exact path="/dashboard/capacity-support/preview/:id" component={CapacitySupportPreview} />

						<PrivateRoute exact path="/dashboard/documentaries/:page" component={Documentaries} />
						<PrivateRoute exact path="/dashboard/documentary/:id" component={Documentary} />
						<PrivateRoute exact path="/dashboard/documentary-form/:id" component={DocumentaryForm} />
					</Layout>
				</Route>

				<ToastContainer
					position="top-right"
					autoClose={3000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</LanguageWrapper>
		</Router>
	);
}

import React from "react";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import { FaEye, FaTimes } from "react-icons/fa";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const ResourcePreview = ({ id }) => {
  const [show, setShow] = React.useState(false);
  const [resource, setResource] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  React.useLayoutEffect(() => {
    const getResource = async () => {
      const res = await fetchWrapper.get(`resources/${id}/`);
      console.log(res);
      setResource(res?.data);
    };

    setIsLoading(true);
    try {
      if (show) {
        getResource();
      }
    } catch (error) {}
    setIsLoading(false);
  }, [id, show]);

  return (
    <>
      <FaEye
        className="text-info icon-btn"
        fontSize={24}
        onClick={() => setShow(true)}
      />
      {show && (
        <StyledModal show={show} onHide={() => setShow(false)} centered>
          <Modal.Header className="d-flex justify-content-between align-items-center">
            <Modal.Title>Resource Preview</Modal.Title>
            <FaTimes className="pointer" onClick={() => setShow(false)} />
          </Modal.Header>
          <Modal.Body>
            <div className="preview-resource">
              {!isLoading && resource && resource?.type === "video" ? (
                <div className="sub-preview-container">
                  <iframe
                    width="400"
                    height="250"
                    src={`https://www.youtube.com/embed/${
                      resource?.media_url?.split("/")[3]
                    }`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    className="preview-video"
                  />
                </div>
              ) : (
                <div className="preview-docs">
                  <DocViewer
                    documents={[{ uri: resource?.media_url }]}
                    pluginRenderers={DocViewerRenderers}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={() => setShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </StyledModal>
      )}
    </>
  );
};

export default ResourcePreview;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 60vw;
    .preview-resource {
      height: 70vh;
      overflow-y: auto;
      .sub-preview-container {
        width: 100%;
        height: 100%;
        .preview-video {
          width: 100%;
          height: 100%;
        }
        image {
          height: 100%;
          width: 100%;
        }
      }

      .preview-docs {
        height: 100%;

        #react-doc-viewer {
          height: 100%;
        }
      }
    }
  }
`;

import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { ErrorMessageHandler } from '../../_helpers/_methods';
// import { setUiError, setUiMessage } from './ui';

export const initialState = {
	loading: false,
	count: 0,
	resources: [],
	categories: { results: [] },
	categorizedResources: [],
	isDataOutDated: 0, // increment this count at every event when the api needs to refetch. 
};

const resourceSlice = createSlice({
	name: 'resources',
	initialState,
	reducers: {
		setResources: (state, action) => {
			state.resources = action.payload;
		},
		setCategory: (state, action) => {
			state.categories = action.payload;
		},
		setCount: (state, action) => {
			state.count = action.payload;
		},
		addResource: (state, action) => {
			state.resources = [...action.payload, ...state.resources];
		},
		updateResource: (state) => {
			state.isDataOutDated = state.isDataOutDated + 1;
		}
	},
});

export default resourceSlice.reducer;

// eslint-disable-next-line
const { setResources, setCount, setCategory, updateResource } = resourceSlice.actions;

//Actions

export const getResources = () => async (dispatch) => {
	try {
		const response = await fetchWrapper.get('/resources/');
		dispatch(setResources(response?.data?.results));

		dispatch(setCount(response?.data?.count));
	} catch (error) { }
};

export const getCategories = () => async (dispatch) => {
	try {
		const categories = await fetchWrapper.get('/resource/category/');
		dispatch(setCategory(categories?.data));
	} catch (error) {
	}
};

export const postResource = (data, onSuccess, onError) => async (dispatch) => {
	try {
		await fetchWrapper.post('/resources/', data, 'multipart/form-data');
		dispatch(updateResource());
		if (onSuccess) {
			onSuccess();
		}
	} catch (error) {
		ErrorMessageHandler(error);
		if (onError) {
			onError();
		}
	}
};

import React, { useMemo } from 'react';
import { Pagination } from 'react-bootstrap';
// import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from 'react-icons/hi';

const PaginationComp = ({ total, page, setPage }) => {
	const totalCount = useMemo(() => Array.from({ length: Math.ceil(total / 10) }, (v, i) => i + 1), [total]);
	return (
		<div className="pagination-wrapper">
			<Pagination>
				{/* <Pagination.Item disabled={page <= 5} onClick={() => (page > 1 ? setPage(page - 5) : null)}>
					<HiOutlineChevronDoubleLeft />
				</Pagination.Item> */}
				<Pagination.Item disabled={page <= 1} onClick={() => (page > 1 ? setPage(page - 1) : null)}>
					{/* <HiOutlineChevronLeft /> */}
					<img src="/assets/icons/LeftChevron.svg" alt="" />
				</Pagination.Item>
				{page < 6 ? (
					totalCount
						.filter((count, index) => index < 5)
						.map((count) => (
							<>
								<Pagination.Item active={count === page} key={count} onClick={() => setPage(count)}>
									{count}
								</Pagination.Item>
							</>
						))
				) : (
					<>
						<Pagination.Ellipsis />
						<Pagination.Item onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>
						<Pagination.Item onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>

						<Pagination.Item active={true}>{page}</Pagination.Item>

						<Pagination.Item onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>

						<Pagination.Item onClick={() => (page < totalCount.length ? setPage(page + 2) : null)}>
							{page + 2}
						</Pagination.Item>

						<Pagination.Ellipsis />
					</>
				)}

				<Pagination.Item
					disabled={page >= totalCount.length}
					onClick={() => (page <= totalCount.length ? setPage(page + 1) : null)}
				>
					{/* <HiOutlineChevronRight /> */}
					<img src="/assets/icons/RightChevron.svg" alt="" />
				</Pagination.Item>
				{/* <Pagination.Item
					disabled={page >= totalCount.length - 9}
					onClick={() => (page < totalCount.length ? setPage(page + 5) : null)}
				>
					<HiOutlineChevronDoubleRight />
				</Pagination.Item> */}
			</Pagination>
			{/* <small className="text-secondary mt-1">
				Showing {Number(page) === Math.ceil(total / 10) ? total % 10 : 10} of {total} entries
			</small> */}
		</div>
	);
};

export default PaginationComp;

import React, { useMemo, useState } from 'react';
import DefaultColumnFilter from '../../../components/ReactTable/Filters/DefaultColumnFilter';
import SelectColumnFilter from '../../../components/ReactTable/Filters/SelectColumnFilter';
import AddResource from './AddResource';
import { Button } from 'react-bootstrap';
import ContentTable from './ContentTable';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import { useAsyncDebounce } from 'react-table';

const Library = () => {
	const { page } = useParams();
	const userRole = useSelector((state) => state.user?.user?.active_role);
	const [isPendingView, setIsPendingView] = useState(false);
	const [title, setTitle] = useState('');
	const [isMyResourcesView, setIsMyResourcesView] = useState(false);


	const ManualFilterByTitle = ({ column: { filterValue, setFilter } }) => {
		const inputRef = useRef(null);
		const [name, setName] = useState(filterValue);
		const handleNameChange = useAsyncDebounce((value) => {
			setFilter(value || undefined);
			setTitle(value);
		}, 800);
		return (
			<input
				ref={inputRef}
				value={name || ''}
				onChange={(e) => {
					setName(e.target.value);
					handleNameChange(e.target.value);
				}}
				placeholder={'Search'}
			/>
		);
	};

	const columns = useMemo(
		() => [
			{
				Header: 'S.N.',
				accessor: 'sn',
				disableFilters: true,
				width: '6%',
			},
			{
				Header: 'Resource Title',
				accessor: 'title',
				Filter: ManualFilterByTitle,
				width: '20%',
			},

			{
				Header: 'Type',
				accessor: 'type',
				Filter: SelectColumnFilter,
				// disableFilters: true,
				width: '15%',
			},
			{
				Header: 'Added Date',
				accessor: 'created_at',
				Filter: DefaultColumnFilter,
				disableFilters: true,
				width: '15%',
			},
			{
				Header: isMyResourcesView ? 'Status' : 'Published By',
				accessor: isMyResourcesView ? 'status' : 'created_by',
				Filter: SelectColumnFilter,
				// disableFilters: true,
				width: '15%',
			},
			{
				Header: 'Action',
				accessor: 'actions',
				disableFilters: true,
				width: '10%',
			},
		],
		[isMyResourcesView]
	);

	return (
		<div className="main-content-inner">
			<div className="d-flex justify-content-between align-items-center mb-4">
				<h2 className="main-title">
					{isPendingView && 'Pending '}
					{isMyResourcesView && 'My '}
					Resources
				</h2>

				{['superuser', 'cd_admin'].includes(userRole) && (
					<div className="d-flex">
						<Button
							className="mr-3"
							variant="outline-primary"
							onClick={() => {
								setIsPendingView((prev) => !prev);
							}}
						>
							{isPendingView ? 'View Approved Resources' : 'View Pending Resources'}
						</Button>

						{!isPendingView && <AddResource />}
					</div>
				)}

				{['resource_person'].includes(userRole) && (
					<div className="d-flex">
						<Button
							className="mr-3"
							variant={isMyResourcesView ? "outline-primary" : "primary"}
							onClick={() => {
								setIsMyResourcesView((prev) => !prev);
							}}
						>
							{isMyResourcesView ? 'View Approved Resources' : 'View My Resources'}
						</Button>

						{isMyResourcesView && <AddResource />}
					</div>
				)}
			</div>

			<ContentTable columns={columns} page={page} isPendingView={isPendingView}
				isMyResourcesView={isMyResourcesView} title={title} hideDelete={['participant', 'resource_person'].includes(userRole)} />
		</div>
	);
};

export default Library;

import React from 'react';
import ChapterBox from './ChapterBox';
import { useDispatch, useSelector } from 'react-redux';
import { ChapterBar, ChapterBuilderContainer, MainArea } from './style/StyledChapterBuilder';
import { useState } from 'react';
import ChapterBuildNavbar from './_components/ChapterBuildNavbar';
import ChapterArea from './ChapterArea';
import LessonArea from './LessonArea';
import { useEffect } from 'react';
import {
	handleChapterSet,
	handleCourseReset,
	handleCourseSet,
	handleLessonSet,
	handleQuizSet,
	handleAssignmentSet,
} from '../../../../../_redux/slices/courseData';
import QuizArea from './QuizArea';
import CertificateArea from './CertificateArea';
import { useParams } from 'react-router-dom';
import { fetchWrapper } from '../../../../../_helpers/fetchWrapper';
import { ErrorMessageHandler } from '../../../../../_helpers/_methods';


function Chapter() {
	const { courseSlug } = useParams();
	const [activeTab, setActiveTab] = useState('Chapter');
	const dispatch = useDispatch();
	const chapters = useSelector((state) => state.courseData.chapters);
	const course = useSelector((state) => state.courseData.course);
	const assignmentQuestions = useSelector((state) => state.courseData.assignment_questions);

	const [hasAssignment, setHasAssignment] = useState(assignmentQuestions?.length);
	const [chapterId, setChapterId] = useState(null);
	const [lessonId, setLessonId] = useState(null);
	console.log(hasAssignment, 'hasAssignment')

	const handleActiveTabChange = (active, chapterId, lessonId) => {
		setActiveTab(active);
		setChapterId(chapterId);
		setLessonId(lessonId);
	};

	useEffect(() => {
		return () => {
			dispatch(handleCourseReset());
		};
	}, [dispatch]);

	useEffect(() => {
		setHasAssignment(Boolean(assignmentQuestions?.length));
	}, [assignmentQuestions?.length, dispatch]);

	useEffect(() => {
		const setCourseData = async () => {
			try {
				let response = await fetchWrapper.get(`course/${courseSlug}/`);
				let course = response?.data;
				dispatch(handleCourseSet(course));
				// let courseSlug = course.slug;
				let chaptersArray = await dispatch(handleChapterSet(courseSlug));
				await dispatch(handleAssignmentSet(courseSlug));
				chaptersArray?.forEach(async (chapter) => {
					console.log(chapter, 'chapter');
					await dispatch(handleLessonSet(chapter.slug));
					await dispatch(handleQuizSet(chapter.slug));
				});
			} catch (error) {
				console.log(error, 'error');
				ErrorMessageHandler(error);
			}
		};
		if (courseSlug) {
			setCourseData();
		}
	}, [courseSlug, dispatch]);

	let Area;
	switch (activeTab) {
		case 'Chapter':
			Area = <ChapterArea />;
			break;
		case 'Lessons':
			Area = <LessonArea chapterId={chapterId} lessonId={lessonId} />;
			break;
		case 'Quiz':
			Area = <QuizArea chapterId={chapterId} />;
			break;
		// case 'Assignment':
		// 	Area = <AssignmentArea courseSlug={course.slug} />;
		// 	break;
		case 'Certificate':
			Area = <CertificateArea courseSlug={course.slug} />;
			break;
		default:
			Area = null;
	}
	return (
		<div className="main-content-inner">
			<h3 className="main-title">{course.title}</h3>
			<ChapterBuildNavbar activeTab={activeTab} handleActiveTabChange={handleActiveTabChange} />
			<ChapterBuilderContainer className="card-sm course-tab mt-3">
				<ChapterBar>
					{chapters.length
						? chapters.map((chapter) => {
							return (
								<ChapterBox
									key={chapter.id}
									chapter={chapter}
									activeTab={activeTab}
									handleActiveTabChange={handleActiveTabChange}
								/>
							);
						})
						: null}
					{/* {hasAssignment && (
						<StyledAssignmentButton
							className={`${activeTab === 'Assignment' ? 'glow' : ''}`}
							onClick={() => {
								handleActiveTabChange('Assignment');
							}}
						>
							Assignment
						</StyledAssignmentButton>
					)} */}
				</ChapterBar>
				<MainArea>{Area}</MainArea>
			</ChapterBuilderContainer>
		</div>
	);
}

export default Chapter;

import React from "react";
import styled from "styled-components";

// icons
import { GiSpeaker } from "react-icons/gi";
import { RiArticleFill } from "react-icons/ri";
import { AiOutlineVideoCamera } from "react-icons/ai";

const StyledLessonTypeButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 5px;
  height: 80px;
  width: 80px;
  cursor: pointer;
  color: #2f6bbf;
  transition: background-color 250ms, color 250ms;
  &:hover {
    background-color: #2f6bbf;
    color: #fff;
  }
`;

function LessonTypeButton({ type, handleClick }) {
  let typeButtonIcon;
  switch (type) {
    case "Audio":
      typeButtonIcon = <GiSpeaker fontSize={25} />;
      break;
    case "Video":
      typeButtonIcon = <AiOutlineVideoCamera fontSize={25} />;
      break;
    default:
      typeButtonIcon = <RiArticleFill fontSize={25} />;
  }
  return (
    <StyledLessonTypeButton onClick={handleClick}>
      <div>{typeButtonIcon}</div>
      <div className="mt-2">{type}</div>
    </StyledLessonTypeButton>
  );
}

export default LessonTypeButton;

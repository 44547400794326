import styled from 'styled-components';
import { Col, Form } from 'react-bootstrap';
import { Tabs } from 'react-bootstrap';

export const StyledCol = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const StyledFormGroup = styled(Form.Group)`
	.form-control {
		font-size: 1.2rem;
		border: 1px solid #b8bcb8;
		margin-bottom: 5px;
	}
	margin-bottom: 22px;
	width: 100%;
`;

export const StyledFormLabel = styled(Form.Label)`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	color: #333333;
	.info {
		font-size: 0.6rem;
		color: #333333;
		margin: 0 0.1rem;
		text-transform: none;
	}
	.isMulti {
		font-size: 0.9rem;
		color: #1b635c;
		font-weight: 900;
		margin-left: 0.1rem;
	}
`;

export const StyledFileInput = styled.div`
	border: 2px dashed #eef3fe;
	position: relative;

	:hover {
		background-color: #eef3fe;
		border: 4px dashed #ffffff;
	}
	.drag-text {
		text-align: center;
		width: 100%;
		cursor: pointer;
	}
	.drag-text h3 {
		font-weight: 100;
		// text-transform: uppercase;
		color: $primary;
		padding: 30px 0;
		font-size: 16px;
	}
`;

export const StyledEditorContainer = styled.div`
	border: 1px solid #b8bcb8;
	border-radius: 5px;
	/* overflow: hidden; */
	padding: 0 0.75rem;
	background: white;
	min-height: 295px;
`;

export const Search = styled.div`
	width: 100%;
	.sm-visible {
		display: none;
	}
	position: relative;
	font-size: 1rem !important;
	label {
		position: absolute;
		top: 50%;
		right: 5%;
		transform: translateY(-50%);
		img {
			width: 1.2rem;
			height: 1.2rem;
		}
	}
	input {
		height: 41px;
		width: 15vw;
		padding: 0.25rem 0.5rem;
		padding-right: 2rem;
		border-radius: 5px;
		border: 1px solid #333333;
		font-size: 1rem;
		color: #828282;
		font-family: $Poppins;
	}
	.search-results {
		position: absolute;
		width: 100%;
		top: 110%;
		background-color: white;
		box-shadow: 2px 2px 2px 2px #2460b920;
		a {
			display: block;
			width: 100%;
			padding: 0.5rem;
			cursor: pointer;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&:hover {
				background-color: #e9f2ff;
			}
		}
	}
	@media screen and (max-width: 768px) {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: end;
		.sm-hidden {
			display: none;
		}
		.sm-visible {
			display: inline-flex;
			/* margin-right: 1rem; */
		}
	}
`;

export const StyledTabs = styled(Tabs)`
	width: 100%;
	background-color: #fff;
	display: flex;
	/* flex-wrap: wrap; */
	.nav-link,
	.nav-link:hover {
		flex-grow: 1;
		border: none;
		border-radius: 0;
		/* width: 100%; */
		margin: 0;
		text-align: center;
		background: transparent;
		font-weight: bold;
		border-bottom: 1px solid #bdbdbd;
		&.active {
			background: transparent;
			color: #000;
			border: none;
			border-bottom: 2px solid #2460b9;
			border-radius: 0;
		}
	}
`;

import dayjs from 'dayjs';
import { createMarkup } from '../../../../_helpers/_methods';
import { FaCalendarAlt } from 'react-icons/fa';
import Slider from 'react-styled-carousel';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import styled from 'styled-components';

/**
 *
 */
const PhotoItem = ({ image, thumb, group }) => (
	<div style={{
		minWidth: 300,
		width: 300,
		height: 150,
		border: '1px solid #ddd',
		borderRadius: 15,
		overflow: 'hidden',
		cursor: 'pointer'
	}}>
		<LightgalleryItem group={group} src={image} thumb={thumb}>
			<img src={image} style={{ width: "100%" }} alt={thumb} />
		</LightgalleryItem>
	</div>
);

/**
 *
 */
export default function SuccessStory({ previewData }) {
	return (
		<div>
			<h3 >{previewData?.storyTitle}</h3>
			<div className="mb-3">
				<span className='small d-flex align-items-center'><FaCalendarAlt className='mr-1' />  {dayjs(previewData?.date).format('D MMMM, YYYY')}</span>
			</div>


			{
				previewData?.media && <StyledSliderContainer className='mb-1'>
					<LightgalleryProvider>
						<Slider cardsToShow={4} autoSlide={10000}>
							{
								previewData.media.map(data => <PhotoItem key={data.id} image={data.media_url} thumb={data.media_url} group="story" />)
							}
						</Slider>
					</LightgalleryProvider>

				</StyledSliderContainer>
			}

			<p className='mt-3' dangerouslySetInnerHTML={createMarkup(previewData?.content)}>
			</p>
		</div>
	);
}

const StyledSliderContainer = styled.div`
	max-width: 1400px;
	button{
		padding: 0;
	}
`
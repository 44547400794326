import React from 'react';
import { VscLoading } from 'react-icons/vsc';

function Loading({ fontSize = '1rem', color }) {
	return (
		<div className=" d-flex justify-content-center align-items-center w-100 h-auto">
			<VscLoading className="spin" fontSize={fontSize} style={{ fontSize: fontSize, color: color }} />
		</div>
	);
}

export default Loading;

import React from "react";
import Account from "./Account";

const Setting = () => {
  return (
    <div className="dashboard-setting p-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="main-title">Settings</h2>
      </div>
	  
      <Account />
    </div>
  );
};

export default Setting;

import React from "react";
import { DynamicIcon } from "../../../_helpers/DynamicIcon";

function ThematicAreaIconButton({ name, className, selected, handleClick }) {
  return (
    <div
      className={`${className} ${selected ? "selected" : ""}`}
      onClick={handleClick}
    >
      <DynamicIcon name={name} />
    </div>
  );
}

export default ThematicAreaIconButton;

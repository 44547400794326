import React, { useState } from 'react';
// import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import PaginationComp from '../../../../components/Pagination/PaginationComp';
import { getUserEnrolledCourse } from '../../../../_redux/slices/user';
import Loading from '../../../../components/Loading';
// import EnrolledCourseCard from '../../../../components/CourseCard/EnrolledCourseCard';
import { CourseCardContainer, EnrolledCourseCard } from '../../../../components/CourseCard';
import { FaBook } from 'react-icons/fa';

const EnrolledCourses = () => {
	const { page } = useParams();
	const [pageNo, setPageNo] = useState(page);
	const [list, setList] = useState(true);

	const dispatch = useDispatch();
	const enrolledCourses = useSelector((state) => state.user.enrolledCourses);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		dispatch(getUserEnrolledCourse());
		setIsLoading(false);
	}, [dispatch]);

	return (
		<div className="courses_content">
			<div className="d-flex justify-content-between">
				<h2 className="courses_content_title">Enrolled Courses</h2>
				<div className="view-switch">
					<img src="/assets/icons/list.svg" className="pointer mr-1" onClick={() => setList(true)} alt="" />
					<img src="/assets/icons/grid.svg" className="pointer" onClick={() => setList(false)} alt="" />
				</div>
			</div>
			<div className="courses_view">
				{isLoading ? (
					<Loading />
				) : (
					<>
						<CourseCardContainer list={list}>
							{
								enrolledCourses?.length === 0 ?
									<div className='w-100 flex-column d-flex align-items-center justify-content-center py-5'>
										<div className="mb-4">
											<FaBook fontSize={64} className="text-secondary" />
										</div>

										<h4>You have not enrolled in any courses yet.</h4>

										<p>You can enrolled in courses from <Link className="text-secondary" to="/dashboard/all-courses/1">Here</Link></p>
									</div>
									:
									enrolledCourses?.map((course) => (
										<EnrolledCourseCard key={course.id} course={course} list={list} />
									))}
						</CourseCardContainer>
						{
							enrolledCourses?.length > 10 &&
							<PaginationComp total={enrolledCourses?.count} page={pageNo} setPage={setPageNo} />
						}
					</>
				)}
			</div>
		</div>
	);
};

export default EnrolledCourses;

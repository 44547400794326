import { FaRegStar, FaStar, FaStarHalf } from "react-icons/fa";

export default function StarRate({ rate }) {
  const MAX_RATE = 5;

  if (!rate) return null;

  const value = rate.total;
  const users = rate.users;
  const total = users * MAX_RATE;
  const average = total === 0 ? 0 : (value / total) * MAX_RATE;
  const fullStar = Math.floor(average);
  const halfStar = average % 1;

  return (
    <div className="d-flex align-items-center my-2">
      {Array.from({ length: Math.trunc(fullStar) }, (v, i) => i).map((v) => (
        <FaStar key={v} className="text-warning mr-1" />
      ))}

      {Boolean(halfStar) && <FaStarHalf className="text-warning mr-1" />}

      {Array.from({ length: 5 - Math.trunc(fullStar) }, (v, i) => i + 1).map(
        (v) => (
          <FaRegStar key={v} className="text-warning mr-1" />
        )
      )}

      <small>({users})</small>
    </div>
  );
}

import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormGroup } from '../../../../components/FormGroup/FormGroup';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { StyledFormLabel } from '../../../../components/Reuseables';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchWrapper } from '../../../../_helpers/fetchWrapper';
import { handleCourseSet } from '../../../../_redux/slices/courseData';
import { ErrorMessageHandler, fileUploader, isEmpty } from '../../../../_helpers/_methods';
import YoutubeEmbed from '../../../../components/YoutubeEmbed';
import styled from 'styled-components';
import Loading from '../../../../components/Loading';
import { useSelector } from 'react-redux';
import EditorInput from '../../../../components/EditorInput';
import { FormattedMessage } from 'react-intl';
import { FaPlusCircle, FaTrash } from 'react-icons/fa';

const ImgHolder = styled.div`
	padding: 0px 30%;
	img {
		max-width: 100%;
	}
`;
const Introduction = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const course = useSelector((state) => state.courseData.course);
	const user = useSelector((state) => state.user.user);

	const [thumbnailImg, setThumbnailImg] = useState();
	const [selectedThemanticAreas, setSelectedThemanticAreas] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [thematicAreas, setThematicAreas] = useState([]);
	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		defaultValues: { objective: course.meta?.objective?.map((v) => ({ objs: v })) },
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'objective',
	});

	useEffect(() => {
		const getThematicAreas = async () => {
			try {
				let response = await fetchWrapper.get('thematic-area/?limit=1000');
				setThematicAreas(response.results);
			} catch (error) {
				console.log(error);
			}
		};
		getThematicAreas();
	}, []);

	useEffect(() => {
		if (course?.thumbnail_image) {
			setThumbnailImg(course.thumbnail_image);
		}
	}, [course?.thumbnail_image]);

	useEffect(() => {
		if (course?.thematic_area) {
			setSelectedThemanticAreas(course.thematic_area.map((area) => area.title));
		}
	}, [course?.thematic_area]);

	const onSubmit = async (data) => {
		try {
			setIsLoading(true);
			let body = {
				title: data.title,
				introductory_video: data.introductory_video,
				lang: 'en',
				long_description: data.long_description,
				short_description: data.short_description,
				is_active: user.role === 'superuser' ? true : false,
				meta: {
					objective: data.meta.objective?.map((v) => v.objs),
				},
			};
			if (thumbnailImg) {
				body.thumbnail_image = thumbnailImg;
			}
			// add thumbnail image
			if (data.thematic_area) {
				body.thematic_area = [...data.thematic_area];
			} else {
				setIsLoading(false);
				return toast.error('Please select atleast one related Thematic area.');
			}
			let response;
			if (isNew) {
				response = await fetchWrapper.post('course/', body);
				toast.success('Course added Successfully!');
			} else {
				response = await fetchWrapper.patch(`course/${course.slug}/`, body);
				toast.success('Course updated Successfully!');
			}
			await dispatch(handleCourseSet(response.data));
			history.push(`/dashboard/course/${response.data.slug}`);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			ErrorMessageHandler(error);
		}
	};
	const [isNew, setIsNew] = useState(false);
	useEffect(() => {
		setIsNew(isEmpty(course));
	}, [course]);

	useEffect(() => {
		if (fields.length < 1) {
			append({});
		}
	}, [fields, append]);

	return (
		<div className="main-content-inner">
			<h3 className="main-title">{isNew ? <FormattedMessage id="addNewCourse" /> : 'Edit'}</h3>
			<div className="card-sm course-tab mt-3">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<h3 className="mb-3 text-primary">
						<FormattedMessage id="introduction" />
					</h3>
					<Row>
						<Col md={12}>
							<FormGroup
								id="title"
								errorName="Course title"
								// label="Course title"
								formattedLabelId="title"
								type="text"
								placeholder="Enter course title"
								register={register}
								required={true}
								// disabled={isLoading}
								error={errors}
								defaultValue={course.title}
							/>
						</Col>
						<Col md={12}>
							<FormGroup
								type="textarea"
								formattedLabelId="shortdescription"
								id="short_description"
								placeholder="Enter short description about the course"
								register={register}
								defaultValue={course.short_description}
								setValue={setValue}
								errors={errors}
								maxlength="600"
							/>
						</Col>
						<Col md={12}>
							<StyledFormLabel>Objectives</StyledFormLabel>
							{fields.map((value, index) => (
								<div className="d-flex align-items-center flex-grow-0">
									<FormGroup
										id={`meta.objective[${index}].objs`}
										placeholder="Enter Objective of this course"
										error={errors}
										register={register}
										type="text"
										defaultValue={value.objs}
									/>
									<FaTrash className="ml-2 text-danger" onClick={() => remove(index)} />
								</div>
							))}
							<Button className="mt-2 mb-4" onClick={() => append({})}>
								<FaPlusCircle className="mr-2" />
								Add more..
							</Button>
						</Col>

						<Col md={6}>
							<FormGroup
								type="file"
								fileType="img"
								id="courseImg"
								errorName="Course image"
								label="Upload course Thumbnail image"
								formattedLabelId="uploadCourseImage"
								info="(.jpeg, .jpg, .png)"
								register={register}
								// disabled={isLoading}
								error={errors}
								// required={true}
								acceptedFormats={['image/jpeg', 'image/jpg', 'image/png']}
								onChange={async (e) => {
									try {
										let response = await fileUploader(e.target.files);
										setThumbnailImg(process.env.REACT_APP_MEDIA_URL + response[0].file_name);
									} catch (error) {
										console.log(error);
									}
								}}
								placeholder="SELECT OR DROP AN IMAGE"
							/>
							{thumbnailImg && (
								<ImgHolder>
									<img src={thumbnailImg} alt="img" />
								</ImgHolder>
							)}
						</Col>
						<Col md={6}>
							<YoutubeEmbed
								label="Course Introduction Video"
								register={register}
								id="introductory_video"
								errors={errors}
								defaultValue={course.introductory_video}
								isRequired={false}
							/>
						</Col>
					</Row>
					<h3 className="mb-3 mt-4 text-primary">
						<FormattedMessage id="description" />
					</h3>
					<Row>
						<Col md={12}>
							<EditorInput
								label="Long description"
								formattedLabelId="longDescription"
								id="long_description"
								register={register}
								defaultValue={course.long_description}
								setValue={setValue}
								errors={errors}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<StyledFormLabel>
									<FormattedMessage id="thematicareas" />
								</StyledFormLabel>
								<div className="scroll">
									{thematicAreas
										// .filter((thematic) => thematic.is_active)
										.map((thematic) => (
											<Form.Check
												type="checkbox"
												id={thematic.id}
												key={thematic.id}
												label={thematic.title}
												value={thematic.id}
												defaultChecked={selectedThemanticAreas.includes(thematic.title)}
												{...register('thematic_area[]')}
											/>
										))}
								</div>
							</Form.Group>
						</Col>
						<Col md={6}>
							<span>
								<FormattedMessage id="certificate" /> <input type="checkbox" />
							</span>
						</Col>
					</Row>

					<Row className="mt-3">
						<Col md={12}>
							<Button variant="success" type="submit">
								{isLoading ? <Loading /> : <span>Continue</span>}
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};

export default Introduction;

import React, { useEffect, useState } from 'react';
import { getThematicAreas } from '../../_redux/slices/thematicAreas';
import { handleCourseList } from '../../_redux/slices/courseList';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import Loading from '../../components/Loading';
import styled from 'styled-components';

const FilterByThematicAreas = ({ setDisplayFilter, displayFilter }) => {
	const thematicAreas = useSelector((state) => state.thematicAreas.thematicAreas);
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [filter, setFilter] = useState([]);
	const [title, setTitle] = useState('');

	const handleTitleChange = (e) => {
		setTitle(e.target.value)
	}

	useEffect(() => {
		setFilter(thematicAreas.map((v) => ({ checked: false, value: v.title })));
	}, [thematicAreas]);

	const handleChange = (e) => {
		setFilter((prev) => prev.map((v) => (v.value === e.target.value ? { ...v, checked: !v.checked } : v)));
	};

	useEffect(() => {
		// console.log(filter);
		const temp = filter.filter((v) => v.checked).map((v) => v.value);
		dispatch(handleCourseList({ thematic_area: temp }));
	}, [filter, dispatch]);

	useEffect(() => {
		const getThematicAreaData = async () => {
			try {
				setIsLoading(true);
				dispatch(getThematicAreas(1, 1000, title));
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		};
		getThematicAreaData();
	}, [dispatch, title]);
	return (
		<StyledFilterByThematicAreas>
			{isLoading ? (
				<Loading />
			) : (
				<div>
					<input type="text" value={title} onChange={handleTitleChange} className="title-input" placeholder="Search for Thematic areas" />
					{displayFilter && (
						<div className="mt-2 thematic-area-filter">
							{
								!filter?.length ? <div className="small px-2">No thematic area found for "{title}". Please try other key words </div> :
									filter?.map((item) => (
										<div className="mb-2 form-check" key={item.id}>
											<input
												type="checkbox"
												id={item.value}
												className="mr-3 pointer form-check-input"
												value={item.value}
												checked={item.checked}
												onChange={handleChange}
											/>
											<Label htmlFor={item.value} className="pointer w-100 form-check-label">
												{item.value}
											</Label>
										</div>
									))}
							<Button onClick={() => setDisplayFilter(false)}>Done</Button>
						</div>
					)}
				</div>
			)}
		</StyledFilterByThematicAreas>
	);
};

export default FilterByThematicAreas;

const Label = styled.label`
	font-family: 'Poppins', sans-serif !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 22 / 16;
	color: #4f4f4f !important;
`;

const StyledFilterByThematicAreas = styled.div`
	.thematic-area-filter{
		max-height: 500px;
		overflow-y: auto;
	}

	.title-input{
		border-radius: 5px;
		border-style: solid;
		border-color: #e7e7e7;
		width: 100%;
		padding: 2px 5px;
		margin-bottom: 8px;
	}
`;
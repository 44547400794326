import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import ReactTable from '../../components/ReactTable';
import Layout from '../../Layout/Layout';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { useHistory, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { FaEye } from 'react-icons/fa';
import DefaultColumnFilter from '../../components/ReactTable/Filters/DefaultColumnFilter';
import SelectColumnFilter from '../../components/ReactTable/Filters/SelectColumnFilter';

const CapacitySupport = () => {
	const { pageNo } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNo);
	const [capacitySupports, setCapacitySupport] = useState(null);

	useEffect(() => {
		const getCapacitySupports = async () => {
			let response = await fetchWrapper.get(`capacity-support/?page=${page}`);
			console.log('capacity support', response);
			setCapacitySupport(response?.data);
		};
		try {
			getCapacitySupports();
		} catch (error) {}
	}, [page]);

	const columns = useMemo(() => {
		return [
			{
				Header: 'S.N.',
				accessor: 'sn',
				disableFilters: true,
				width: '6%',
			},
			{
				Header: 'Title',
				accessor: 'title',
				Filter: DefaultColumnFilter,
			},
			{
				Header: 'Description',
				accessor: 'short_description',
				Filter: DefaultColumnFilter,
				width: '30%',
			},
			{
				Header: 'Category',
				accessor: 'category_title',
				Filter: SelectColumnFilter,
				width: '15%',
			},
			{
				Header: 'Created At',
				accessor: 'created_at',
				disableFilters: true,
				width: '10%',
			},
			{
				Header: 'Public',
				accessor: 'is_public',
				// disableFilters: true,
				Filter: SelectColumnFilter,
				width: '10%',
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				disableFilters: true,
				width: '10%',
			},
		];
	}, []);

	const data = useMemo(() => {
		return capacitySupports?.results?.length > 0
			? capacitySupports?.results?.map((cs, i) => ({
					sn: ++i,
					title: cs?.title,
					short_description: cs?.short_description,
					category_title: cs?.category_title,
					created_at: cs?.created_at?.split('T')[0],
					is_public: cs?.is_public ? 'Public' : 'Private',
					actions: (
						<div className="text-center d-flex align-items-center">
							<FaEye
								onClick={() => history.push(`/capacity-support/preview/${cs?.id}`)}
								className="text-primary cursor-pointer"
							/>
						</div>
					),
			  }))
			: [];
	}, [capacitySupports, history]);

	return (
		<Layout>
			<StyledCapacitySupport>
				<h1 className="main-title">Capacity Support</h1>
				<ReactTable
					columns={columns}
					data={data}
					pageNo={page}
					setPageNo={setPage}
					totalCount={capacitySupports?.count}
				/>
			</StyledCapacitySupport>
		</Layout>
	);

};

export default CapacitySupport;

const StyledCapacitySupport = styled.div`
	width: 95%;
	margin-inline: auto;
	padding: 0.5rem;
	min-height: 90vh;
	@media screen and (min-width: 720px) {
		width: 80%;
		padding: 1rem;
	}
`;

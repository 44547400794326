import React, { useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { AiOutlineFile } from "react-icons/ai";
import { MdOndemandVideo } from "react-icons/md";
import { HiDownload } from "react-icons/hi";
import { VscListSelection } from "react-icons/vsc";
import { FaHeadphones, FaTimes } from "react-icons/fa";

export const LessonResoucesListing = ({
  resources,
  activeResourceId,
  handleActiveContentChange,
}) => {
  return (
    <div>
      <h6 className="my-3">Resources</h6>
      {resources?.length ? (
        resources?.map((resource) => (
          <div
            className={`nav-item nav-item-download mb-2 ${
              resource.id === activeResourceId ? "active" : ""
            }`}
          >
            <div
              className="d-flex align-items-center file-title"
              onClick={() => {
                handleActiveContentChange("resources", resource);
              }}
            >
              <AiOutlineFile style={{ fontSize: "1.5rem" }} className="mr-2" />
              <span>{resource.title}</span>
            </div>

            <DownloadBtn
              href={resource.media_url}
              download
              target={"_blank"}
              rel="noreferrer"
            >
              <HiDownload fontSize="24" />
            </DownloadBtn>
          </div>
        ))
      ) : (
        <span className="sm-white">No shared Resources</span>
      )}
    </div>
  );
};

const LessonNav = ({
  lesson,
  activeContent,
  handleActiveContentChange,
  handleToggleNote,
  hasAudio,
  hasVideo,
  hasArticle,
}) => {
  const [displayNav, setDisplayNav] = useState(
    window.innerWidth > 768 ? true : false
  );

  useLayoutEffect(() => {
    window.innerWidth > 768 ? setDisplayNav(true) : setDisplayNav(false);
    const handleResize = () => {
      window.innerWidth > 768 ? setDisplayNav(true) : setDisplayNav(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Nav>
      <div className="course-introduction">
        <p>Lesson Title</p>
        <h4 className="mb-4" style={{ color: "#2460b9" }}>
          {lesson?.title}
        </h4>
      </div>
      <div className="lesson-nav-list">
        <button className=" list-icon" onClick={() => setDisplayNav(true)}>
          <VscListSelection style={{ fontSize: "2rem" }} />
        </button>
        {displayNav && (
          <>
            <div className="lesson-nav-items">
              <h6 className="mb-3">Topics</h6>
              {hasArticle && (
                <div
                  className={`nav-item ${
                    activeContent === "article" ? "active" : ""
                  }`}
                  onClick={() => handleActiveContentChange("article")}
                >
                  <AiOutlineFile className="nav-icon" />
                  <span>Article Content</span>
                </div>
              )}
              {hasVideo && (
                <div
                  className={`nav-item ${
                    activeContent === "video" ? "active" : ""
                  }`}
                  onClick={() => handleActiveContentChange("video")}
                >
                  <MdOndemandVideo className="nav-icon" />
                  <span>Video Content</span>
                </div>
              )}
              {hasAudio && (
                <div
                  className={`nav-item ${
                    activeContent === "audio" ? "active" : ""
                  }`}
                  onClick={() => handleActiveContentChange("audio")}
                >
                  <FaHeadphones className="nav-icon" />
                  <span>Audio Content</span>
                </div>
              )}
              <LessonResoucesListing
                resources={lesson?.meta?.resources}
                handleActiveContentChange={handleActiveContentChange}
              />
              <button
                className="list-close-btn"
                onClick={() => setDisplayNav(false)}
              >
                <FaTimes />
              </button>
            </div>
          </>
        )}
      </div>
    </Nav>
  );
};

export default LessonNav;

const Nav = styled.nav`
  grid-area: nav;
  background-color: #f2f2f2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "course-intro" "lesson-nav";
  grid-template-rows: max-content max-content;
  padding: 2rem 1rem;
  .course-introduction {
    display: flex;
    flex-direction: column;
    grid-area: course-intro;
    h4 {
      max-width: 15vw;
    }
  }
  .lesson-nav-list {
    grid-area: lesson-nav;
    .list-icon {
      display: none;
    }
    .list-close-btn {
      display: none;
    }
  }
  .nav-item {
    cursor: pointer;
    position: relative;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;

    width: 15vw;
    span {
      width: 10vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    border-radius: 5px;
    svg {
      margin-right: 0.5rem;
    }
    span {
      font-size: 1rem;
    }
    &:hover {
      background-color: #2f80ed;
      color: white !important;
      a {
        color: white !important;
      }
    }
  }
  .active {
    background-color: #2f80ed;
    color: white;
  }
  @media screen and (max-width: 1024px) {
    .course-introduction {
      display: none;
    }
    .lesson-nav-items {
      h6 {
        display: none;
      }
    }

    .nav-item {
      position: relative;
      width: 40px !important;
      .nav-icon {
        margin: 0;
      }
      span {
        display: none;
      }

      &:hover {
        background-color: transparent;
        color: #333 !important;
      }
      &:hover > span {
        position: absolute;
        top: 0;
        left: calc(100% + 10px);
        z-index: 1;
        background-color: #2f80ed !important;
        color: #fff !important;
        height: 100%;
        width: max-content;
        display: grid;
        place-content: center;
        border-radius: 5px;
        padding-inline: 1rem;
      }
      &:hover {
        &::before {
          display: block;
          content: "";
          position: absolute;
          z-index: 100;
          top: 50%;
          left: calc(100% - 10px);
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-right: 15px solid #2f80ed;
          transform: translateY(-50%);
        }
      }
    }
    .nav-item-download {
      position: relative;
      .file-title {
        display: none !important;
      }
      a {
        &:hover {
          color: #333 !important;
        }
      }
      &:hover > .file-title {
        display: flex !important;
        position: absolute;
        z-index: 1;
        left: calc(100% + 10px);
        background-color: #2f80ed !important;
        color: #fff !important;
        padding-inline: 1rem;
        padding-block: 0.5rem;
        border-radius: 5px;
        span {
          display: inline;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    position: relative;
    background-color: white;
    padding-bottom: 1rem;
    grid-template-areas: "lesson-nav" "course-intro";
    .course-introduction {
      text-align: center;
      p {
        font-size: 16px !important;
      }
      h4 {
        font-size: 24px !important;
        max-width: 100%;
      }
    }
    .lesson-nav-list {
      .list-icon {
        display: inline;
        float: right;
        color: #2f80ed;
        border: none;
        background-color: white;
      }
      .lesson-nav-items {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background-color: #2f80ed;
        padding: 1.5rem;
        height: 50vh;
        h6 {
          display: block;
          color: white;
          font-size: 24px;
        }
        .nav-item {
          width: max-content !important;
          margin: 1rem 0;
          color: white !important;
          span {
            position: relative;
            display: block;
            margin-left: 1rem;
            width: max-content;
            overflow: visible;
            white-space: pre-wrap;
            text-overflow: unset;
            font-size: 16px !important;
          }
          .file-title {
            display: inline !important;
            color: white !important;
            span {
              color: white !important;
              display: inline !important;
            }
          }
        }
        .list-close-btn {
          display: block;
          position: absolute;
          width: 50px;
          aspect-ratio: 1 / 1;
          display: grid;
          place-content: center;
          border-radius: 50%;
          border: none;
          background-color: #2f80ed;
          color: white;
          top: calc(100% + 50px);
          left: calc(50% - 25px);
        }
        &::before {
          content: "";
          height: 100%;
          width: 100vw;
          background-color: #2f80ed;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          border-radius: 0 0 20rem 0;
        }
        &::after {
          content: "";
          height: calc(100vh - 3rem);
          width: calc(100vw);
          background-color: white;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -2;
        }
      }
      .sm-white {
        color: white;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .sm-white {
      display: none;
    }
  }
`;

const DownloadBtn = styled.a`
  @media screen and (max-width: 1024px) {
    width: 50px !important;
  }
  @media screen and (max-width: 768px) {
    color: white;
  }
`;

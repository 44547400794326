import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from './Sidebar';

const Layout = (props) => {
	return (
		<div className="main-wrapper">
			<Header fluid={true} />
			<div className="content-wrapper">
				<Sidebar />
				<div className="main-content">{props.children}</div>
			</div>
			<Footer />
		</div>
	);
};

export default Layout;

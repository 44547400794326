import React, { useEffect, useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import { readingTime, slugToTitle } from '../../_helpers/_methods';
import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'react-tiny-fab/dist/styles.css';
import { useSelector } from 'react-redux';
import { BiNote } from 'react-icons/bi';
import { FiMinimize } from 'react-icons/fi'
const LessonHeader = ({ activeContent, lesson, handleFontChange, fullScreen, handleFullScreen, showNote, handleToggleNote }) => {
	const { courseSlug } = useParams();

	const [contentDuration, setContentDuration] = useState(null);

	const progress = useSelector((state) => state.course.progress);
	const courseProgress = progress?.course_progress ? Math.round(progress?.course_progress) : 0;

	useEffect(() => {
		if (activeContent === "article") {
			let content = lesson?.article_content?.content || '';
			let time = readingTime(content);
			setContentDuration(`${time} mins read`);
		}
		else if (activeContent === "video") {
			console.log(lesson, 'lesson');
			let time;
			if (lesson?.video_content) {
				if (Array.isArray(lesson?.video_content?.video)) {
					time = lesson?.video_content?.video[0].duration;
				}
				else {
					time = lesson?.video_content?.time;
				}
			}
			setContentDuration(time || null)
		}
		else {
			setContentDuration(null)
		}
	}, [activeContent, lesson]);

	return (
		<Header>
			<nav className="d-flex justify-content-between align-items-center">
				<Link to={`/courses/${courseSlug}/enrolled`} className="d-flex">
					<img style={{ width: '25px' }} src="/assets/icons/LeftArrow.svg" alt="" />
					<div className="ml-2 sm-hidden">
						<h4>{slugToTitle(courseSlug)}</h4>
						{
							contentDuration &&

							<div className="d-flex align-items-center">
								<AiOutlineClockCircle className="mr-2" />
								<small>
									{contentDuration}</small>
							</div>
						}
					</div>
				</Link>
				<div className="d-flex align-items-center">
					<div className="mr-4 d-flex">
						<AddNotes>
							<BiNote
								fontSize={28}
								className={`mr-3 pointer sm-hidden ${showNote ? ' bg-secondary text-white rounded' : ''}`}
								onClick={handleToggleNote}
							/>
						</AddNotes>
						<img
							src="/assets/icons/textIncrease.svg"
							className="pointer mr-3 sm-hidden"
							alt=""
							onClick={handleFontChange}
						/>
						{
							fullScreen ?
								<FiMinimize
									className="pointer sm-hidden"
									onClick={handleFullScreen}
									fontSize={28} />
								:
								<img src="/assets/icons/fullscreen.svg" className="pointer sm-hidden" alt="" onClick={handleFullScreen} />

						}
					</div>

					<div style={{ width: '50px', height: '50px' }}>
						<CircularProgressbar
							className="circularprogressbar"
							value={courseProgress}
							text={`${courseProgress}%`}
						/>
					</div>
					<span className="ml-2">Course Progress</span>
				</div>
			</nav>
		</Header>
	);
};

export default LessonHeader;

const Header = styled.header`
	background-color: white;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	box-shadow: 0px 0px 8px 5px #2460b920;
	padding: 0.5rem 2rem;
	a {
		&:hover {
			color: #000;
		}
	}
	.circularprogressbar {
		.CircularProgressbar-path {
			stroke: #27ae60;
		}
		.CircularProgressbar-text {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 400;
			font-size: 30px !important;
			fill: #333333;
		}
	}
`;

const AddNotes = styled.div`
	position: relative;
	.add-note {
		position: absolute;
		width: max-content;
		top: 100%;
		right: 0;
		background-color: white;
		box-shadow: 0px 0px 8px 5px #2460b920;
		padding: 1rem;
	}
	@media screen and (max-width: 768px) {
		position: unset;
		.add-note {
			width: 100%;
			top: 110%;
			left: 0;
			right: 0;
		}
	}
`;

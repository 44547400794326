import { useEffect, useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormGroup } from "../../../components/FormGroup/FormGroup";
import YoutubeEmbed from "../../../components/YoutubeEmbed";
import {
  // useSelector,
  useDispatch,
} from "react-redux";
import { postResource } from "../../../_redux/slices/resources";
import styled from "styled-components";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { toast } from "react-toastify";
import { useState } from "react";
import { VscLoading } from "react-icons/vsc";

const AddResource = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  const [type, setType] = useState("image");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    unregister,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "image",
    },
  });

  const watchType = watch("type");

  useEffect(() => {
    if (watchType === "video") {
      unregister("file");
    }
  }, [watchType, unregister]);

  const handleFile = (e) => {
    if (e.target.files.length > 0) setFile(e.target.files[0]);
  };

  const onSuccess = () => {
    toast.success("Resource created successfully");
    setIsLoading(false);
    handleHide();
  };

  const onError = (error) => {
    setIsLoading(false);
    toast.error(error);
  };

  const onSubmit = (data) => {
    try {
      setIsLoading(true);

      let duration = data.duration;
      if (duration) {
        duration = duration
          .split(":")
          .map((t) => t.padStart(2, "0"))
          .join(":");
        data.duration = duration;
      }

      let formdata = new FormData();
      for (let key in data) {
        if (key !== "file") {
          formdata.append(key, data[key]);
        }
      }
      if (file) {
        formdata.append("file", file);
      } else if (type === "video" && !getValues("url")) {
        toast.error("Please select resource content");
        return;
      }

      dispatch(postResource(formdata, onSuccess, onError));
    } catch (error) {
      setIsLoading(false);
    }
  };

  const watchedType = watch("type");

  const acceptType = useMemo(() => {
    switch (watchedType) {
      case "pdf":
        return ".pdf";
      case "doc":
        return ".doc,.docx";
      case "ppt":
        return ".ppt,.pptx";
      case "image":
        return ".jpeg,.jpg,.png";
      case "audio":
        return ".mp3,.mp4";
      default:
        return ".pdf,.doc,.docx,.ppt,.pptx,.jpeg,.jpg,.png";
    }
  }, [watchedType]);

  const handleHide = () => {
    reset();
    setShow(false);
    setFile(null);
    setType("image");
  };

  const handleTypeChange = (e) => {
    setType(e);
    setFile(null);
  };
  return (
    <>
      <Button onClick={() => setShow(true)}>Add Resource</Button>
      {show && (
        <Modal show={show} onHide={handleHide} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Resource</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup
                type="text"
                register={register}
                id="title"
                error={errors}
                required
                label="Resource Title"
                placeholder="Enter Resource title"
              />
              <div>
                <legend>Type</legend>
                <fieldset className="d-flex justify-content-between">
                  <FormGroup
                    type="radio"
                    id="type"
                    value="pdf"
                    checked={"pdf" === type}
                    onChange={handleTypeChange}
                    register={register}
                    checkboxLabel="Upload PDF"
                    disabled={isLoading}
                  />
                  <FormGroup
                    type="radio"
                    id="type"
                    value="doc"
                    checked={"doc" === type}
                    register={register}
                    onChange={handleTypeChange}
                    checkboxLabel="Upload DOC"
                    disabled={isLoading}
                  />
                  <FormGroup
                    type="radio"
                    id="type"
                    value="ppt"
                    checked={"ppt" === type}
                    register={register}
                    onChange={handleTypeChange}
                    checkboxLabel="Upload PPT"
                    disabled={isLoading}
                  />
                  <FormGroup
                    type="radio"
                    id="type"
                    checked={"video" === type}
                    value="video"
                    register={register}
                    onChange={handleTypeChange}
                    checkboxLabel="Add Video Link"
                    disabled={isLoading}
                  />
                  <FormGroup
                    type="radio"
                    id="type"
                    checked={"audio" === type}
                    value="audio"
                    register={register}
                    onChange={handleTypeChange}
                    checkboxLabel="Add Audio"
                    disabled={isLoading}
                  />
                  <FormGroup
                    type="radio"
                    id="type"
                    checked={"image" === type}
                    value="image"
                    register={register}
                    onChange={handleTypeChange}
                    checkboxLabel="Add Image"
                    disabled={isLoading}
                  />
                </fieldset>

                {watch("type") === "video" ? (
                  <div>
                    <YoutubeEmbed
                      register={register}
                      id="url"
                      errors={errors}
                      // defaultValue={course.introductory_video}
                      isRequired={false}
                    />
                    <FormGroup
                      id="duration"
                      errorName="Video time"
                      label="Video time"
                      type="time"
                      placeholder="Enter Length of Video"
                      register={register}
                      setValue={setValue}
                      required={true}
                      // disabled={isLoading}
                      error={errors}
                    />
                  </div>
                ) : (
                  <StyledFileInput>
                    <label htmlFor="file-upload">
                      <h3> Choose a file</h3>
                    </label>
                    <input
                      type="file"
                      key={watch("type")}
                      {...register("file")}
                      onChange={handleFile}
                      id="file-upload"
                      hidden
                      accept={acceptType}
                    />
                    {Boolean(file) && (
                      <div className="preview">
                        {["ppt", "image"].includes(type) ? (
                          <DocViewer
                            documents={[
                              {
                                uri: URL.createObjectURL(file),
                                fileType: file.type,
                              },
                            ]}
                            pluginRenderers={DocViewerRenderers}
                            style={{ maxHeight: "300px" }}
                          />
                        ) : (
                          <p>Uploaded File: {file.name}</p>
                        )}
                      </div>
                    )}
                  </StyledFileInput>
                )}
              </div>

              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  variant="danger mr-2"
                  onClick={handleHide}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button variant="success" type="submit" disabled={isLoading}>
                  {isLoading ? <VscLoading className="spin" /> : "Submit"}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default AddResource;

const StyledFileInput = styled.div`
  border: 2px dashed #eef3fe;
  padding: 1rem;
  text-align: center;
  label > h3 {
    color: #2460b9;
    cursor: pointer;
  }
  .preview {
    width: 100%;
    /* max-height: 100px; */
  }
`;

import React, { useCallback, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { FaEye, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { handleCourseList, handleDeleteCourse } from '../../../../_redux/slices/courseList';
import { useDispatch, useSelector } from 'react-redux';
import { setUiMessage } from '../../../../_redux/slices/ui';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
// import PaginationComp from '../../../../components/Pagination/PaginationComp';
import { ErrorMessageHandler } from '../../../../_helpers/_methods';
import dayjs from 'dayjs';
import { handleCourseSet } from '../../../../_redux/slices/courseData';
import ReactTable from '../../../../components/ReactTable';
import DefaultColumnFilter from '../../../../components/ReactTable/Filters/DefaultColumnFilter';
import SelectColumnFilter from '../../../../components/ReactTable/Filters/SelectColumnFilter';

const MyCourses = () => {
	const { page } = useParams();
	const history = useHistory();
	const [pageNo, setPageNo] = useState(page);
	const dispatch = useDispatch();
	const courses = useSelector((state) => state.courseList.courses);
	const count = useSelector((state) => state.courseList.count);
	const user = useSelector((state) => state.user.user);

	useEffect(() => {
		if (user?.id) {
			dispatch(handleCourseList({ offset: (pageNo - 1) * 10, type: 'all', created_by: user?.id }));
		}
		history.push(`/dashboard/courses/${pageNo}`);
	}, [dispatch, history, pageNo, count, user?.id]);

	const deleteCourse = useCallback(
		async (courseSLug) => {
			try {
				let confirm = window.confirm('Are you sure you want to delete?');
				if (confirm) {
					await dispatch(handleDeleteCourse(courseSLug));
					dispatch(setUiMessage('Course deleted successfully'));
				}
			} catch (err) { }
		},
		[dispatch]
	);
	// const setPage = (page) => {
	// 	setPageNo(page);
	// };

	const handleEditCourse = useCallback(
		async (course) => {
			try {
				await dispatch(handleCourseSet(course));
				history.push('/dashboard/course');
			} catch (error) {
				ErrorMessageHandler(error);
			}
		},
		[dispatch, history]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'S.N.',
				accessor: 'sn',
				disableFilters: true,
				width: '80px',
			},
			{
				Header: 'Title',
				accessor: 'title',
				Filter: DefaultColumnFilter,
				width: '20%',
			},
			{
				Header: 'Description',
				accessor: 'description',
				Filter: DefaultColumnFilter,
				width: 'auto',
			},
			{
				Header: 'Created At',
				accessor: 'created_at',
				disableFilters: true,
				width: '10%',
			},
			{
				Header: 'Created by',
				accessor: 'created_by',
				Filter: SelectColumnFilter,
				width: '10%',
			},
			{
				Header: 'Action',
				accessor: 'actions',
				disableFilters: true,
				width: '10%',
			},
		],
		[]
	);

	const data = useMemo(() => {
		if (courses.length > 0) {
			let count = 0;
			return courses.map((course) => ({
				sn: ++count,
				title: course.title,
				description: (
					<p
						style={{
							maxHeight: '12rem',
							overflow: 'hidden',
							display: 'block',
							marginBottom: '1rem',
						}}>
						{course.short_description}
					</p>
				),
				created_by: course.created_by.username,
				created_at: dayjs(course.created_at).format('YYYY-MM-DD hh:mm A'),
				actions: (
					<div className="text-center d-flex align-items-center">
						<Link to={`/dashboard/courses/course/${course.slug}`}>
							<FaEye className="text-info" />
						</Link>
						<FaEdit className="text-primary mx-1 cursor-pointer" onClick={() => handleEditCourse(course)} />
						<FaTrashAlt className="text-danger cursor-pointer" onClick={() => deleteCourse(course.slug)} />
					</div>
				),
			}));
		} else return [];
	}, [courses, deleteCourse, handleEditCourse]);

	return (
		<div className="main-content-inner">
			<h2 className="main-title mb-2">My Courses</h2>
			<Row>
				<ReactTable columns={columns} data={data} pageNo={pageNo} setPageNo={setPageNo} totalCount={count} />
				{/* <Table>
					<thead>
						<tr>
							<th>S.N.</th>
							<th>Title</th>
							<th style={{ width: '600px' }}>Description</th>
							<th>Created At</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{courses.map((course, index) => (
							<tr key={course.id}>
								<td>{index + 1}</td>
								<td>{course.title}</td>
								<td
									style={{
										maxHeight: '12rem',
										overflow: 'hidden',
										display: 'block',
										marginBottom: '1rem',
									}}
									dangerouslySetInnerHTML={createMarkup(course.short_description)}
								></td>
								<td>{dayjs(course.created_at).format('YYYY-MM-DD hh:mm:ss')}</td>
								<td>{course.is_active ? 'Active' : 'Pending'}</td>
								<td>
									<Link to={`/dashboard/courses/course/${course.slug}`}>
										<FaEye className="text-info" />
									</Link>
									<FaEdit className="text-primary mx-1 cursor-pointer" onClick={() => handleEditCourse(course)} />
									<FaTrashAlt className="text-danger cursor-pointer" onClick={() => deleteCourse(course.slug)} />
								</td>
							</tr>
						))}
					</tbody>
				</Table> */}
			</Row>
			{/* <PaginationComp total={count} page={pageNo} setPage={setPage} /> */}
		</div>
	);
};

export default MyCourses;

export const Table = styled.table`
	width: 100%;
	& th,
	& td {
		padding: 1rem;
	}
	& th {
		background: #2f6bbf22;
		font-weight: 800;
		font-size: 20px;
	}
`;

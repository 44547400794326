import { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { NoteItem } from "../../../Layout/LessonLayout/LessonSidebar";
import NoteModal from "../../../Layout/LessonLayout/NoteModal";
import { handleGetAllLessonNotes } from "../../../_redux/slices/notes";

export default function NotesTab() {
  const { courseSlug } = useParams();
  const dispatch = useDispatch();
  const { allLessonNotes } = useSelector((state) => state.notes);

  const courseLessonNotes = allLessonNotes[courseSlug] || [];

  const [displayNote, setDisplayNote] = useState(false);

  useEffect(() => {
    try {
      dispatch(handleGetAllLessonNotes(courseSlug));
    } catch (error) {
      console.log(error);
    }
  }, [courseSlug, dispatch]);

  return (
    <Lesson className="content notes_content">
      {/* {user?.active_role === 'participant' ? ( */}
      <Accordion defaultActiveKey="0" flush>
        {courseLessonNotes?.map((lesson) => (
          <>
            {lesson?.notes?.length > 0 && (
              <Card key={lesson.lesson}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={lesson.lesson}
                  className="toggle"
                >
                  <small className="text-secondary">Lesson</small>
                  <h4>{lesson.lesson}</h4>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={lesson.lesson}>
                  <Card.Body>
                    {lesson.notes?.map((note, index) => (
                      <NoteItem note={note} key={note.id} viewOnly />
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
          </>
        ))}

        <p className="text-dark text-center">No more Notes</p>
      </Accordion>

      <NoteModal displayNote={displayNote} setDisplayNote={setDisplayNote} />
    </Lesson>
  );
}

const Lesson = styled.div`
  .toggle {
    cursor: pointer;
  }
  .lesson {
    margin: 1rem 0;
    box-shadow: 0px 6px 15px rgba(64, 83, 145, 0.25);
    width: 100%;
    padding: 0.5rem;
  }
`;

import { Link } from "react-router-dom";
import AssessmentQA from "./AssessmentQA";

const AssesmentResult = () => {
  return (
    <>
      <div className="d-flex">
        <div className="previewComponent-content">
          <div className="quiz-preview-section">
            <AssessmentQA />
            <AssessmentQA />
            <AssessmentQA />
            <AssessmentQA />
            <AssessmentQA />
            <AssessmentQA />
            <AssessmentQA />
          </div>
        </div>
        <div className="buttom-wrapper d-flex justify-content-between">
          <div></div>
          <Link
            to="/courses/id/enrolled/lesson/quiz/result"
            class="btn btn-primary btn-next"
            tabIndex="0"
            type="button"
          >
            <span class="MuiIconButton-label">Get Certificate</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default AssesmentResult;

import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import styled from "styled-components";

const StyledLinkButton = styled(Link)`
  padding: 0.5rem 1em;
  background: #3f3f3f;
  color: white;
  border-radius: 10px;
  :hover {
    color: white;
  }
`;

function BackButton({ to }) {
  const history = useHistory();
  return (
    <div className="mb-3">
      {to ? (
        <StyledLinkButton to={to}>Back</StyledLinkButton>
      ) : (
        <Button variant="secondary" onClick={() => history.goBack()}>Back</Button>
      )}
    </div>
  );
}

export default BackButton;

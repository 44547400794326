import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from "../../../components/FormGroup/FormGroup";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { toast } from "react-toastify";
import { fileUploader } from "../../../_helpers/_methods";
import { getUserData } from "../../../_redux/slices/user";

const Account = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [profileImg, setProfileImg] = useState(user?.image_url);
  useEffect(() => {
    setProfileImg(user?.image_url);
  }, [user]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      let updatedData = {
        username: data.username,
        email: data.email,
        phone: data.phone,
      };
      if (Boolean(data.password)) {
        if (data.password === data.repassword) {
          updatedData.password = data.password;
        } else {
          alert("password incorrect");
        }
      }
      // console.log(updatedData);
      let res = await fetchWrapper.patch("/user/me/", updatedData);
      console.log(res);
      toast.success(res.message);
    } catch (error) {}
  };

  const changeProfilePicture = async (e) => {
    try {
      let res = await fileUploader(e.target.files);
      const response = await fetchWrapper.patch("/cdmis/me/", {
        image_url: res[0].file_url,
      });
      setProfileImg(response?.data?.image_url);
      dispatch(getUserData());
      toast.success("Profile Image Updated Successfully");
    } catch (error) {}
  };

  const removeProfilePicture = async () => {
    try {
      console.log("remove profile");
      await fetchWrapper.patch("/cdmis/me/", {
        image_url: null,
      });
      setProfileImg(null);
      dispatch(getUserData());
      toast.success("Profile Image Updated Successfully");
    } catch (error) {}
  };

  return (
    <div className="account-setting">
      <h3 style={{ borderBottom: "1px solid #E0E0E0" }} className="pb-2">
        Profile Picture
      </h3>
      <div className="d-flex align-items-center py-2 mb-4">
        <img
          className="profile-image mr-4"
          src={profileImg || "/assets/images/avatar-placeholder.png"}
          alt=""
        />
        <div className="d-flex align-items-center ml-4">
          <div>
            <input
              type="file"
              id="image_upload"
              multiple={false}
              onChange={changeProfilePicture}
              hidden
            />
            <Button
              as="label"
              htmlFor="image_upload"
              className="setting_image_upload"
              variant="primary"
            >
              Change Profile Picture
            </Button>
          </div>
          <Button
            variant="outline-danger"
            className="ml-4"
            onClick={removeProfilePicture}
          >
            Remove
          </Button>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <h3
              style={{ borderBottom: "1px solid #E0E0E0", marginTop: "3rem" }}
              className="pb-2 mb-4"
            >
              Basic Information
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup
              type="text"
              id="username"
              register={register}
              error={errors}
              label="Username"
              placeholder="Enter Your Username"
              defaultValue={user?.username}
            />
          </Col>
          <Col md={6}>
            <FormGroup
              type="email"
              id="email"
              register={register}
              error={errors}
              label="Email Address"
              placeholder="Enter Your Email"
              defaultValue={user?.email}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup
              type="number"
              id="phone"
              register={register}
              error={errors}
              label="Phone Number"
              placeholder="Enter Your Phone Number"
              defaultValue={user?.phone}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3
              style={{ borderBottom: "1px solid #E0E0E0" }}
              className="pb-2 my-4"
            >
              Security
            </h3>
            <p className="text-sm text-gray-400">
              *Leave below fields empty if password doesn't need to be changed
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup
              id="password"
              type="password"
              label="New Password"
              register={register}
              error={errors}
              placeholder="Enter New Password"
            />
          </Col>

          <Col md={6}>
            <FormGroup
              id="repassword"
              type="password"
              label="Re-enter New Password"
              register={register}
              error={errors}
              placeholder="Enter New Password"
            />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <Button
              type="submit"
              variant="primary"
              style={{ width: "max-content" }}
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default Account;

import React from 'react';
import styled from 'styled-components';

const StyledUserPPImage = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 1000px;
	overflow: hidden;
	background-color: #aaa;
	.pp_image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
`;
function UserPPImage({ imgSrc }) {
	return (
		<StyledUserPPImage>
			<img className="pp_image" src={imgSrc} alt="pp" />
		</StyledUserPPImage>
	);
}

export default UserPPImage;

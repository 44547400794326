import React from 'react';
import { createMarkup } from '../../_helpers/_methods';
import EditorInput from '../../components/EditorInput';
import { StyledSubmitButton } from './styles';
import { useForm } from 'react-hook-form';

function LongQuestion({ question, handleQuestionIndexCounterChange }) {
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		console.log(data);
		handleQuestionIndexCounterChange('next');
	};
	return (
		// <div className="quiz-preview-section">
		// <div className="quiz-result-main-box">
		// <div className="quiz-component-wrapper">
		<div className="quiz-preview-wrapper">
			{/* <div className="quiz-question-type"> */}
			{/* <span className="quiz-question-marks">1 Marks</span> */}
			{/* </div> */}
			<div className="quiz-question-title">
				<span className="question" dangerouslySetInnerHTML={createMarkup(question?.question)}></span>
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="quiz-play-box">
					<EditorInput label={''} id={`answer`} register={register} setValue={setValue} errors={errors} />
				</div>
				<div className="d-flex justify-content-end align-items-center my-5">
					<StyledSubmitButton type="submit">Next</StyledSubmitButton>
				</div>
			</form>
		</div>
		// </div>
		// </div>
		// </div>
	);
}

export default LongQuestion;

import React from "react";
import { StyledTabs } from "../../../components/Reuseables";
import { ProgressBar, Tab } from "react-bootstrap";
import styled from "styled-components";
import NotesTab from "./NotesTab";
import { useSelector } from "react-redux";

const EnrolledTabs = () => {
  const progress = useSelector((state) => state.course.progress);

  return (
    <Container>
      <StyledTabs defaultActiveKey="progress">
        <Tab eventKey="progress" title="Progress">
          <div className="content">
            <h6 style={{ fontWeight: "bold" }} className="mb-2">
              My Stats
            </h6>
            <div>
              <div className="d-flex justify-content-between">
                <small>Articles</small>
                <small>{progress?.article_progress?.toFixed(2)}%</small>
              </div>
              <ProgressBar now={progress?.article_progress} />
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <small>Video</small>
                <small>{progress?.video_progress?.toFixed(2)}%</small>
              </div>
              <ProgressBar now={progress?.video_progress} />
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <small>Audio</small>
                <small>{progress?.audio_progress?.toFixed(2)}%</small>
              </div>
              <ProgressBar now={progress?.audio_progress} />
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <small>Quizzes</small>
                <small>{progress?.quiz_progress?.toFixed(2)}%</small>
              </div>
              <ProgressBar now={progress?.quiz_progress} />
            </div>

            <h6 style={{ fontWeight: "bold" }} className="mb-2">
              Quiz Score
            </h6>
            <div>
              <div className="d-flex justify-content-between">
                <small>Overall</small>
                <small>30%</small>
              </div>
              <ProgressBar now={30} />
            </div>
          </div>
        </Tab>
        <Tab eventKey="notes" title="Notes">
          <NotesTab />
        </Tab>
        {/* <Tab eventKey="assignments" title="Assignments">
          <div className="content">
            <h4 style={{ color: "#2f80ed" }} className="mb-2">
              ASSIGNMENT
            </h4>
            <p>
              You are now in the assignment section you will have assignment
              regarding the course content you have just enrolled
            </p>
            <Link to={`/courses/${courseSlug}/enrolled/assessment/assignment`}>
              <Button className="w-100 my-3">
                <span className="text-center w-100">Start Assignment</span>
              </Button>
            </Link>
          </div>
        </Tab> */}
      </StyledTabs>
    </Container>
  );
};

export default EnrolledTabs;

const Container = styled.div`
  box-shadow: 0px 6px 30px rgba(64, 83, 145, 0.25);
  margin-bottom: 2.5rem;
  .content {
    padding: 1rem;
  }
  .notes_content {
    height: 50vmin;
    overflow: auto;
  }
  .progress {
    height: 10px;
    .progress-bar {
      background-color: #00c48c;
      border-radius: 0.5rem;
    }
  }
`;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { createMarkup } from '../../_helpers/_methods';
import Option from './Option';
import { StyledSubmitButton } from './styles';

function MCQ({ question, chapterSlug, handleQuestionIndexCounterChange, isAssignment, handleSetAnswersId }) {
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [showHint, setShowHint] = useState(false);
	let isMultiSelect = question.quiz_type === 'multiple_true';
	const handleSelectedOption = (option) => {
		let tempSelectedOptions = [...selectedOptions];
		let selectedOptionIndex = tempSelectedOptions.findIndex((arrayOption) => arrayOption === option);

		if (selectedOptionIndex < 0) {
			if (isMultiSelect) {
				tempSelectedOptions.push(option);
			} else {
				tempSelectedOptions = [option];
			}
		} else {
			tempSelectedOptions.splice(selectedOptionIndex, 1);
		}
		setSelectedOptions(tempSelectedOptions);
	};
	const handleAnswerSubmit = async () => {
		if (selectedOptions.length) {
			if (isAssignment) {
				handleQuestionIndexCounterChange('next');
			} else {
				// dispatch(submitQuizAnswer(chapterSlug, selectedOptions));
				handleSetAnswersId(selectedOptions)
				handleQuestionIndexCounterChange('next');
			}
		} else {
			toast.error('You must select atleast one Option');
		}
	};

	return (
		// <div className="quiz-preview-section">
		//   <div className="quiz-result-main-box">
		//     <div className="quiz-component-wrapper">
		<div className="quiz-preview-wrapper">
			{/* <div className="quiz-question-type">
				{isMultiSelect ? 'There can be multiple correct answer' : 'Choose a single option'}
				<span className="quiz-question-marks">1 Marks</span>
			</div> */}
			<div className="quiz-question-title">
				<p className="question" dangerouslySetInnerHTML={createMarkup(question?.question)}></p>
				{
					question?.hint && (showHint ? (
						<div style={{ backgroundColor: '#F6FAFD' }} className="p-2">
							<h4 className="text-primary">HINT</h4>
							<p dangerouslySetInnerHTML={createMarkup(question?.hint)}></p>
						</div>
					) : (
						<div className="info d-flex align-items-center">
							<img src="/assets/icons/info.svg" style={{ height: '18px' }} alt="" />

							<div>
								<label className="text-primary font-poppins my-2 pointer" htmlFor="hint">
									See Hint
								</label>
								<input type="checkbox" id="hint" hidden onChange={() => setShowHint(true)} />
							</div>
						</div>
					))
				}
			</div>
			<div>
				{question?.option_set?.map((option, index) => (
					<Option
						selectedOptions={selectedOptions}
						option={option}
						key={option.id}
						index={index}
						handleSelectedOption={handleSelectedOption}
					/>
				))}
			</div>
			<div className="d-flex justify-content-end align-items-center my-5">
				<StyledSubmitButton
					onClick={() => {
						setShowHint(false);
						handleAnswerSubmit();
					}}
				>
					Next
				</StyledSubmitButton>
			</div>
		</div>
		// </div>
		//   </div>
		// </div>
	);
}

export default MCQ;

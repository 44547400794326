import { ThematicAreaBadge } from "../../../components/ThematicAreaBadge";

export const IconNames = [
  "FaCode",
  "FaWindowRestore",
  "FaLeaf",
  "FaHeartbeat",
  "FaLandmark",
  "FaPhotoVideo",
  "FaStamp",
  "FaSchool",
];

export const colorOption = [
  {
    label: <ThematicAreaBadge color={"#ec8d01"}>Orange</ThematicAreaBadge>,
    value: "#ec8d01",
  },
  {
    label: <ThematicAreaBadge color={"#e23a31"}>Red</ThematicAreaBadge>,
    value: "#e23a31",
  },
  {
    label: <ThematicAreaBadge color={"#4caf50"}>Green</ThematicAreaBadge>,
    value: "#4caf50",
  },
  {
    label: <ThematicAreaBadge color={"#03a9f4"}>Blue</ThematicAreaBadge>,
    value: "#03a9f4",
  },
  {
    label: <ThematicAreaBadge color={"#bd2bd6"}>Pink</ThematicAreaBadge>,
    value: "#bd2bd6",
  },
  {
    label: <ThematicAreaBadge color={"#824f3c"}>Brown</ThematicAreaBadge>,
    value: "#824f3c",
  },
];

import React, { useEffect } from "react";
import styled from "styled-components";
import { BsPencil, BsTrash } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  handleGetLessonNotes,
  handleSetActiveNote,
  handleDeleteNote,
} from "../../_redux/slices/notes";
import { createMarkup } from "../../_helpers/_methods";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { BiPlus } from "react-icons/bi";
import NoteModal from "./NoteModal";
import { useState } from "react";

const LessonSidebar = () => {
  const { courseSlug, lessonSlug } = useParams();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { lessonNotes } = useSelector((state) => state.notes);

  const [displayNote, setDisplayNote] = useState(false);

  useEffect(() => {
    dispatch(handleGetLessonNotes(courseSlug, lessonSlug));
  }, [lessonSlug, dispatch, user, courseSlug]);

  const notes = lessonNotes[`${courseSlug}_${lessonSlug}`] || [];

  return (
    <Sidebar>
      <h4 className="d-flex justify-content-between">
        Notes
        <Button onClick={() => setDisplayNote(true)}>
          <BiPlus />
        </Button>
        <NoteModal
          displayNote={displayNote}
          courseSlug={courseSlug}
          lessonSlug={lessonSlug}
          setDisplayNote={setDisplayNote}
        />
      </h4>

      <div className="scroll-view">
        {!notes?.length ? (
          <div className="bg-white text-center rounded py-4">
            No notes added
          </div>
        ) : (
          notes.map((note) => <NoteItem key={note?.id} note={note} />)
        )}
      </div>
    </Sidebar>
  );
};

export default LessonSidebar;

const Sidebar = styled.aside`
  grid-area: sidebar;
  background-color: #f2f2f2;
  padding: 1rem;
  width: 350px;

  .scroll-view {
    max-height: 700px;
    overflow-y: auto;
    padding: 12px 0;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NoteItem = ({ note, viewOnly }) => {
  const dispatch = useDispatch();
  const { courseSlug, lessonSlug } = useParams();

  const onEditClick = () => {
    let tempNote = {
      ...note,
      isEdit: true,
    };
    dispatch(handleSetActiveNote(tempNote));
  };

  const onViewClick = () => {
    dispatch(handleSetActiveNote(note));
  };

  const onDeleteClick = () => {
    dispatch(handleDeleteNote(courseSlug, lessonSlug, note.id));
  };

  return (
    <StyledNoteItem>
      <div className="d-flex align-items-center justify-content-between note-header">
        <h4 className="mb-0 text-secondary note-title " onClick={onViewClick}>
          {note?.title}
        </h4>

        {!viewOnly && (
          <div>
            <BsPencil className="text-primary pointer" onClick={onEditClick} />
            <BsTrash
              className="text-danger pointer ml-2"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "This note will be deleted",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    onDeleteClick();
                  }
                });
              }}
            />
          </div>
        )}
      </div>

      <div
        className="note-body"
        dangerouslySetInnerHTML={createMarkup(note?.note)}
      />
    </StyledNoteItem>
  );
};

const StyledNoteItem = styled.div`
  margin-bottom: 2rem;
  box-shadow: 0px 0px 8px 5px #2460b920;
  background-color: white;

  .note-header {
    padding: 0.5rem;
    background-color: #f9f9f9;

    .note-title {
      cursor: pointer;
    }
  }

  .note-body {
    padding: 0.5rem;
    word-break: break-word;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { AiFillYoutube } from "react-icons/ai";
import { useEffect } from "react";

const StyledYoutubeEmbed = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  border: 1px solid #b8bcb8;
  border-radius: 5px;
  height: 0;
  background: #efefef;
  background: url(./loading.gif) center center no-repeat;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .InfoYoutube {
    width: 100%;
    position: absolute;
    top: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
function YoutubeEmbedPreview({ youtubeLink }) {
  const [youtubeEmbedId, setYoutubeEmbedId] = useState(null);
  useEffect(() => {
    if (youtubeLink) {
      let embedId = youtubeLink.split("/")[3];
      setYoutubeEmbedId(embedId);
    } else {
      setYoutubeEmbedId(null);
    }
  }, [youtubeLink]);

  return (
    <StyledYoutubeEmbed>
      {youtubeEmbedId ? (
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${youtubeEmbedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      ) : (
        <div className="InfoYoutube">
          <div className="text-center">
            <AiFillYoutube fontSize="58" />
          </div>
          <div className="h6 text-center mt-3">Paste youtube's shared link</div>
        </div>
      )}
    </StyledYoutubeEmbed>
  );
}

export default YoutubeEmbedPreview;

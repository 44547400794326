import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useState } from "react";
import styled from "styled-components";
import { ContentState, convertToRaw, EditorState } from "draft-js";

import { FormGroup } from "../../../../components/FormGroup/FormGroup";


import SuccessStory from './SuccessStory';
import draftToHtml from "draftjs-to-html";
import { useSelector } from "react-redux";
import AddResource from "../../Courses/AddNewCourse/ChapterBuilder/LessonArea/AddResource";
import { StyledFormGroup, StyledFormLabel } from "../../../../components/Reuseables";
import { fetchWrapper } from "../../../../_helpers/fetchWrapper";
import { toast } from "react-toastify";
import { useEffect } from "react";
import htmlToDraft from "html-to-draftjs";
import { VscLoading } from "react-icons/vsc";
import { useCallback } from "react";
import { ErrorMessageHandler } from "../../../../_helpers/_methods";

/**
 * 
 */
export default function SuccessStoryForm({
    successStory,
    hideForm,
    showPreview,
    setShowPreview
}) {
    const isEdit = Boolean(successStory);
    const user = useSelector(state => state.user.user)
    const [storyTextState, setStoryTextState] = useState(EditorState.createEmpty());
    const [preview, setPreview] = useState(false);

    const [previewData, setPreviewData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [media, setMedia] = useState(null);

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            'story-title': successStory?.title,
            short_description: successStory?.short_description,
            text: successStory?.text,
            is_public: successStory?.is_public ?? true,
            name: successStory?.name || user?.fullname,
            post: successStory?.post || user?.active_role,
            media: successStory?.media
        }
    });


    useEffect(() => {
        if (!successStory) return;

        const contentBlock = htmlToDraft(successStory.text);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const textState = EditorState.createWithContent(contentState);
            setStoryTextState(textState);
        }
        setMedia(successStory.media);

    }, [setValue, successStory])


    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            const body = {
                title: data["story-title"],
                short_description: data.short_description,
                text: draftToHtml(convertToRaw(storyTextState.getCurrentContent())),
                is_public: data.is_public,
                name: data.name,
                post: data.post,
                media: media
            }

            if (isEdit) {
                await fetchWrapper.patch(`story/${successStory.id}/`, body);
                toast.success('Story updated Successfully!');
            }
            else {
                await fetchWrapper.post(`story/`, body);
                toast.success('Story uploaded Successfully!');
            }

            setIsLoading(false);
            hideForm();
        }
        catch (error) {
            setIsLoading(false);
            ErrorMessageHandler(error);
        }
    }

    const handleCancelForm = () => {
        setStoryTextState(EditorState.createEmpty());
        setShowPreview(false);
        hideForm();
    };

    const handleResourceAdd = (resources) => {
        if (resources.length > 0) {
            const formattedResources = resources.map((resource) => ({
                title: resource.title,
                media_url: resource.media_url,
                type: resource.type,
                id: resource.id,
            }));
            setMedia(formattedResources);
        }
    }

    const handleShowPreview = useCallback(() => {
        let temp = {
            storyTitle: getValues('story-title'),
            name: getValues('name'),
            post: getValues('post'),
            content: draftToHtml(convertToRaw(storyTextState.getCurrentContent())),
            date: new Date().toISOString(),
            media: media,
        }

        setPreviewData(temp);
        setPreview(true)
    }, [getValues, media, storyTextState]);

    const handleHidePreview = () => {
        setPreview(false)
    }

    useEffect(() => {
        if (showPreview) {
            handleShowPreview();
        }
    }, [handleShowPreview, showPreview])

    return (
        <Fragment>
            <Button variant="warning" onClick={handleCancelForm}>
                Cancel
            </Button>

            <div className="card-sm course-tab mt-3" style={{ width: "100%" }}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {
                        preview && <SuccessStory previewData={previewData} />
                    }

                    {
                        !preview &&
                        <>
                            <Row>
                                <Col md={12}>
                                    <FormGroup
                                        id="story-title"
                                        errorName="Story Title"
                                        label="Story Title"
                                        type="text"
                                        placeholder="Enter Story title"
                                        register={register}
                                        required={true}
                                        disabled={isLoading}
                                        error={errors}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <FormGroup
                                        id="short_description"
                                        errorName="Short description"
                                        label="Short Description"
                                        type="textarea"
                                        placeholder="Enter short description"
                                        register={register}
                                        required={true}
                                        disabled={isLoading}
                                        error={errors}
                                        maxlength={100}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <FormGroup
                                        label="Story Content"
                                        id="story-content"
                                        type="editor"
                                        disabled={isLoading}
                                        placeholder="Enter content"
                                        defaultValue={storyTextState}
                                        error={errors}
                                        onChange={(e) => setStoryTextState(e)}
                                        editorStyle={{ minHeight: '200px' }}
                                    />
                                </Col>
                            </Row>


                            <Row>
                                <Col md={12}>
                                    <FormGroup
                                        label="Make public?"
                                        id="is_public"
                                        register={register}
                                        type="checkbox"
                                        error={errors}
                                        disabled={isLoading}
                                    />
                                </Col>
                            </Row>

                            <Row className='mb-3'>
                                <Col md={3}>
                                    <StyledFormGroup className="mb-1">
                                        <StyledFormLabel>
                                            <span>
                                                Images
                                            </span>
                                        </StyledFormLabel>
                                    </StyledFormGroup>

                                    <AddResource
                                        defaultValues={media}
                                        disabled={isLoading}
                                        setValue={setValue}
                                        fixedType="image"
                                        handleOnSubmit={handleResourceAdd}
                                        label="Click to select Images" />

                                    <div className="d-flex mb-5 mt-2">
                                        {Boolean(media?.length) && media.map(medi => {
                                            return <ImgHolder>
                                                <img src={medi.media_url} alt="img" />
                                            </ImgHolder>
                                        })
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col><div className="font-weight-bold text-uppercase mb-3">Author's Detail</div></Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <FormGroup
                                        id="name"
                                        errorName="Name"
                                        label="Name"
                                        type="text"
                                        placeholder="Enter Name"
                                        register={register}
                                        required={true}
                                        error={errors}
                                        disabled={isLoading}
                                    />
                                </Col>

                                <Col md={6}>
                                    <FormGroup
                                        id="post"
                                        errorName="Post"
                                        label="Post"
                                        type="text"
                                        placeholder="Enter post"
                                        register={register}
                                        required={true}
                                        disabled={isLoading}
                                        error={errors}
                                    />
                                </Col>
                            </Row>


                        </>
                    }

                    {!showPreview &&
                        <Row className="mt-3">
                            <Col md={12} className="d-flex">
                                {
                                    preview && <Button variant="secondary" className="mr-4" onClick={handleHidePreview}
                                        disabled={isLoading}>
                                        Edit
                                    </Button>
                                }

                                {
                                    !preview && <Button variant="secondary" className="mr-4" onClick={handleShowPreview}
                                        disabled={isLoading}>
                                        Preview
                                    </Button>
                                }

                                <Button variant="primary" type="submit"
                                    disabled={isLoading}>
                                    {
                                        isLoading ? <VscLoading className="spin" /> : "Submit"
                                    }
                                </Button>
                            </Col>
                        </Row>
                    }

                </Form>
            </div>
        </Fragment >
    );
};

const ImgHolder = styled.div`
    min-width: 150px;
    border: 2px solid #d1d1d1;
    margin-right: 1rem;
	img {
		max-width: 100%;
        height: 100%;
        object-fit: cover;
	}
`;
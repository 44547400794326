import { toast } from 'react-toastify';
import { fetchWrapper } from './fetchWrapper';

export const fileUploader = async (files) => {
  try {
    let formData = new FormData();

    for (const key of Object.keys(files)) {
      let type = null;
      let fileExt = files[key].name.split('.')[files[key].name.split('.').length - 1];
      switch (fileExt) {
        case 'pdf':
          type = 'pdfs';
          break;
        case 'docx':
          type = 'docs';
          break;
        case 'pptx':
          type = 'ppts';
          break;
        case 'mp3':
        case 'mp4':
          type = 'videos';
          break;
        default:
          type = 'pictures';
          break;
      }
      formData.append(type, files[key]);
    }
    const response = await fetchWrapper.post('media_upload/', formData, true);
    return response.data;
  } catch (err) {
    ErrorMessageHandler(err);
  }
};

export const ErrorMessageHandler = (error) => {
  let errorData = error.error;
  if (errorData) {
    if (Array.isArray(errorData)) {
      errorData.map((errorValue) => {
        return toast.error(errorValue);
      });
    } else {
      Object.keys(errorData).map((errorKey) => {
        if (errorKey !== 'non_field_errors' && errorKey !== 'non_field_error') {
          return toast.error(`${errorKey}: ${errorData[errorKey][0]}`);
        }
        else if (errorKey === 'non_field_error') {
          return toast.error(`${errorData[errorKey][0]}`);
        }
        return null;
      });
    }
  } else {
    return toast.error('Something went wrong!');
  }
};

export const createMarkup = (html) => {
  return {
    __html: html,
  };
};

export const slugToTitle = (slug) => {
  if (!slug) return;
  let title = slug.split('-').join(' ');
  return title.replace(title[0], title[0].toUpperCase());
};

export const titleToSlug = (title) => {
  let slug = title.replace(' ', '-');
  return slug.toLowerCase();
};

export const capitalize = (title) => {
  title = title.split('_').join(' ');
  return title.replace(title[0], title[0].toUpperCase());
};

export const isEmpty = (obj) => {
  if (obj) {
    if (Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype) return true;
    return false;
  }
  return true;
};

export const SuperscriptOrdinalizer = (number) => {
  switch (number) {
    case 1:
      return (
        <span>
          {number}
          <sup>st</sup>
        </span>
      );
    case 2:
      return (
        <span>
          {number}
          <sup>nd</sup>
        </span>
      );
    case 3:
      return (
        <span>
          {number}
          <sup>rd</sup>
        </span>
      );
    default:
      return (
        <span>
          {number}
          <sup>th</sup>
        </span>
      );
  }
};

export const debounce = (func, delay = 500) => {
  let timeout;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const readingTime = (text) => {
  const wpm = 225;
  const words = text.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  return time;
}

export function numFormatter(num) {
  if (!num) return;
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}
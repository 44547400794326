import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { ErrorMessageHandler } from '../../_helpers/_methods';

export const initialState = {
	loading: false,
	course: {},
	chapters: [],
	lessons: {},
	lesson: {},
	quizes: {},
	assignments: [],
	restartCourse: false,
	progress: {}
};

const courseSlice = createSlice({
	name: 'course',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setCourse: (state, action) => {
			state.course = action.payload;
		},
		setChapters: (state, action) => {
			state.chapters = action.payload;
		},
		setLessons: (state, action) => {
			let tempLessons = { ...state.lessons };
			tempLessons[action.payload.chapterSlug] = action.payload.lessons;
			state.lessons = tempLessons;
		},
		setLesson: (state, action) => {
			state.lesson = action.payload;
		},
		setQuizes: (state, action) => {
			let tempQuizes = { ...state.quizes };
			tempQuizes[action.payload.chapterSlug] = action.payload.quiz;
			state.quizes = tempQuizes;
		},
		setAssignment: (state, action) => {
			state.assignments = action.payload;
		},
		setRestartCourse: (state, action) => {
			state.restartCourse = action.payload;
		},
		setProgress: (state, action) => {
			state.progress = action.payload;
		}
	},
});

const { setCourse, setChapters, setLessons, setLesson, setQuizes, setAssignment, setLoading, setRestartCourse, setProgress } =
	courseSlice.actions;

export default courseSlice.reducer;

export const getCourse = (courseSlug) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		let response = await fetchWrapper.get(`course/${courseSlug}/`);
		dispatch(setCourse(response?.data));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
		console.log(error);
		ErrorMessageHandler(error);
	}
};
export const getChaptersFromCourse = (courseSlug) => async (dispatch) => {
	try {
		let response = await fetchWrapper.get(`course/${courseSlug}/chapters/`);
		dispatch(setChapters(response.data));
	} catch (error) {
		console.log(error);
		ErrorMessageHandler(error);
	}
};
export const enrollParticipant = (courseSlug) => async (dispatch) => {
	await fetchWrapper.get(`course/${courseSlug}/enroll/`);
};

export const getLessonsFromChapter = (chapterSlug) => async (dispatch) => {
	try {
		let response = await fetchWrapper.get(`chapter/${chapterSlug}/lessons/`);
		dispatch(setLessons({ chapterSlug: chapterSlug, lessons: response.data }));
	} catch (error) {
		console.log(error);
	}
};
export const getLesson = (lessonSlug) => async (dispatch) => {
	try {
		let response = await fetchWrapper.get(`lesson/${lessonSlug}/`);
		dispatch(setLesson(response.data));
	} catch (error) {
		console.log(error);
	}
};
export const getQuizFromChapter = (chapterSlug) => async (dispatch) => {
	try {
		// console.log(chapterSlug, "chapterSlug");
		let response = await fetchWrapper.get(`chapter/${chapterSlug}/quiz/`);
		dispatch(setQuizes({ chapterSlug: chapterSlug, quiz: response.data }));
	} catch (error) {
		console.log(error);
	}
};
export const getCourseAssignment = (courseSlug) => async (dispatch) => {
	try {
		let response = await fetchWrapper.get(`course/${courseSlug}/assignment/`);
		const { assignment_data, quiz_data } = response.data;
		let questions = [...quiz_data, ...assignment_data];
		dispatch(setAssignment(questions));
	} catch (error) {
		console.log(error);
	}
};

export const submitQuizAnswer = (chapterSlug, options, setShowResult) => async (dispatch) => {
	try {
		let body = {
			options: options,
		};
		await fetchWrapper.post(`chapter/${chapterSlug}/answer/`, body);
		setShowResult(true);
		// dispatch(setQuizes({ chapterSlug: chapterSlug, quiz: response.data }));
	} catch (error) {
		console.log(error);
	}
};
export const getQuizResult = (chapterSlug) => async (dispatch) => {
	try {
		let response = await fetchWrapper.get(`chapter/${chapterSlug}/quiz-result/`);
		return response;
	} catch (error) {
		console.log(error);
	}
};

export const restartCourse = (restart) => (dispatch) => {
	dispatch(setRestartCourse(restart));
};



export const getCourseProgress = (courseSlug) => async (dispatch) => {
	try {
		const response = await fetchWrapper.get(
			`/course/${courseSlug}/progress/`
		);

		dispatch(setProgress(response?.data));
	}
	catch (err) {
		console.log(err)
	}
};

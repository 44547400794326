import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useState } from "react";
import styled from "styled-components";
import { ContentState, convertToRaw, EditorState } from "draft-js";

import { FormGroup } from "../../../../components/FormGroup/FormGroup";


import draftToHtml from "draftjs-to-html";
import AddResource from "../../Courses/AddNewCourse/ChapterBuilder/LessonArea/AddResource";
import { StyledFormGroup, StyledFormLabel } from "../../../../components/Reuseables";
import { fetchWrapper } from "../../../../_helpers/fetchWrapper";
import { toast } from "react-toastify";
import { useEffect } from "react";
import htmlToDraft from "html-to-draftjs";
import PosterBanner from "./PosterBanner";
import { VscLoading } from "react-icons/vsc";
import { useCallback } from "react";

/**
 * 
 */
export default function PosterBannerForm({
    posterBanner,
    hideForm,
    showPreview,
    setShowPreview
}) {
    const isEdit = Boolean(posterBanner);
    const [bannerContentState, setBannerContentState] = useState(EditorState.createEmpty());
    const [preview, setPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [previewData, setPreviewData] = useState({});
    const [bannerImg, setBannerImg] = useState('');

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            'banner-title': posterBanner?.title,
            is_public: posterBanner?.is_public ?? true,
            media: posterBanner?.media?.banner_image
        }
    });


    useEffect(() => {
        if (!posterBanner) return;

        const contentBlock = htmlToDraft(posterBanner.text);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const textState = EditorState.createWithContent(contentState);
            setBannerContentState(textState);
        }
        setBannerImg(posterBanner?.media?.banner_image);

    }, [setValue, posterBanner])

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            const body = {
                title: data["banner-title"],
                text: draftToHtml(convertToRaw(bannerContentState.getCurrentContent())),
                is_public: data.is_public,
                media: {
                    banner_image: bannerImg
                }
            }


            if (isEdit) {
                await fetchWrapper.patch(`banner/${posterBanner.id}/`, body);
                toast.success('Poster/Banner updated Successfully!');
            }
            else {
                await fetchWrapper.post(`banner/`, body);
                toast.success('Poster/Banner uploaded Successfully!');

            }

            setIsLoading(false);
            hideForm();
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    const handleCancelForm = () => {
        setBannerContentState(EditorState.createEmpty());
        hideForm();
        setShowPreview(false);
    };

    const handleResourceAdd = (resources) => {
        if (resources.length > 0) {
            setBannerImg(resources[0].media_url);
        }
    }

    const handleShowPreview = useCallback(() => {
        let temp = {
            bannerImg: bannerImg,
            bannerTitle: getValues('banner-title'),
            content: draftToHtml(convertToRaw(bannerContentState.getCurrentContent())),
        }

        setPreviewData(temp);
        setPreview(true)
    }, [bannerContentState, bannerImg, getValues]);

    const handleHidePreview = () => {
        setPreview(false)
    }

    useEffect(() => {
        if (showPreview) {
            handleShowPreview();
        }
    }, [handleShowPreview, showPreview])

    return (
        <Fragment>
            <Button variant="warning" onClick={handleCancelForm}>
                Cancel
            </Button>

            <div className="card-sm course-tab mt-3" style={{ width: "100%" }}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {
                        preview && <PosterBanner previewData={previewData} />
                    }

                    {
                        !preview &&
                        <>
                            <Row>
                                <Col md={12}>
                                    <FormGroup
                                        id="banner-title"
                                        errorName="Poster/Banner Title"
                                        label="Poster/Banner Title"
                                        type="text"
                                        placeholder="Enter Poster/Banner title"
                                        register={register}
                                        required={true}
                                        disabled={isLoading}
                                        error={errors}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <FormGroup
                                        label="Poster/Banner detail"
                                        id="story-content"
                                        type="editor"
                                        disabled={isLoading}
                                        placeholder="Enter content"
                                        defaultValue={bannerContentState}
                                        error={errors}
                                        onChange={(e) => setBannerContentState(e)}
                                        editorStyle={{ minHeight: '200px' }}
                                    />
                                </Col>
                            </Row>


                            <Row>
                                <Col md={12}>
                                    <FormGroup
                                        label="Make public?"
                                        id="is_public"
                                        register={register}
                                        type="checkbox"
                                        error={errors}
                                        disabled={isLoading}
                                    />
                                </Col>
                            </Row>

                            <Row className='mb-3'>
                                <Col md={3}>
                                    <StyledFormGroup className="mb-1">
                                        <StyledFormLabel>
                                            <span>
                                                Poster/Banner Image
                                            </span>
                                        </StyledFormLabel>
                                    </StyledFormGroup>

                                    <AddResource setValue={setValue} isMulti={false}
                                        fixedType="image" handleOnSubmit={handleResourceAdd} label="Click to select Poster/Banner Image" />

                                    <div className="my-5">
                                        {bannerImg && (
                                            <ImgHolder>
                                                <img src={bannerImg} alt="img" />
                                            </ImgHolder>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }

                    {!showPreview &&
                        <Row className="mt-3">
                            <Col md={12} className="d-flex">
                                {
                                    preview && <Button variant="secondary" className="mr-4" onClick={handleHidePreview}
                                        disabled={isLoading}>
                                        Edit
                                    </Button>
                                }

                                {
                                    !preview && <Button variant="secondary" className="mr-4" onClick={handleShowPreview}
                                        disabled={isLoading}>
                                        Preview
                                    </Button>
                                }

                                <Button variant="primary" type="submit"
                                    disabled={isLoading}
                                >
                                    {
                                        isLoading ? <VscLoading className="spin" /> :
                                            "Submit"
                                    }
                                </Button>
                            </Col>
                        </Row>
                    }

                </Form>
            </div>
        </Fragment>
    );
};

const ImgHolder = styled.div`
	padding: 0px 30%;
	img {
		max-width: 100%;
	}
`;
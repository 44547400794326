import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiMessage } from './ui';

export const initialState = {
	loading: false,
	thematicAreas: [],
	count: 0,
};

const thematicAreaSlice = createSlice({
	name: 'thematicAreas',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setThematicArea: (state, action) => {
			state.thematicAreas = [...action.payload.results];
			state.count = action.payload.count;
		},
		addThematicArea: (state, action) => {
			let tempThematicAreas = [...state.thematicAreas];
			tempThematicAreas = [action.payload, ...tempThematicAreas];
			state.thematicAreas = tempThematicAreas;
			state.count = state.count + 1;
		},
		editdThematicArea: (state, action) => {
			let editTempThematicAreas = [...state.thematicAreas];
			let editTempThematicIndex = editTempThematicAreas.findIndex(
				(thematicArea) => thematicArea.id === action.payload.id
			);
			editTempThematicAreas[editTempThematicIndex] = action.payload;
			state.thematicAreas = editTempThematicAreas;
		},

		deleteThematicArea: (state, action) => {
			state.count = state.count - 1;
		},
	},
});

const { setThematicArea, addThematicArea, editdThematicArea, deleteThematicArea, setLoading } =
	thematicAreaSlice.actions;

export default thematicAreaSlice.reducer;

export const getThematicAreas = (page = 1, limit, title) => async (dispatch) => {
	try {
		setLoading(true);
		let response = await fetchWrapper.get(`thematic-area/?offset=${(page - 1) * 10}${limit ? `&limit=${limit}` : ''}${title ? `&title=${title}` : ""}`);
		dispatch(setThematicArea(response));
		setLoading(false);
	} catch (error) {
		setLoading(false);
	}
};
export const addThematicAreas = (body) => async (dispatch) => {
	try {
		setLoading(true);
		let response = await fetchWrapper.post(`thematic-area/`, body);
		dispatch(addThematicArea(response));
		setLoading(false);
	} catch (error) {
		setLoading(false);
	}
};
export const editThematicAreas = (body, thematic_slug) => async (dispatch) => {
	try {
		setLoading(true);
		let response = await fetchWrapper.patch(`thematic-area/${thematic_slug}/`, body);
		dispatch(editdThematicArea(response));
		setLoading(false);
	} catch (error) {
		setLoading(false);
	}
};
export const deleteThematicAreas = (thematic_slug) => async (dispatch) => {
	try {
		setLoading(true);
		await fetchWrapper.delete(`thematic-area/${thematic_slug}/`);
		dispatch(deleteThematicArea());
		dispatch(setUiMessage('Thematic Area is deleted successfully'));
		setLoading(false);
	} catch (error) {
		setLoading(false);
	}
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaStar, FaRegStar } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
// import { fetchWrapper } from '../../_helpers/fetchWrapper';
// import { handleAddRating, handleGetComment, handleAddComment } from '../../_redux/slices/RateCommentNote';
import { handleAddReview, handleGetReview } from "../../_redux/slices/review";

const Reviews = ({ isEnrolled, courseSlug }) => {
  // const { comment } = useSelector((state) => state.rateComment);
  const { reviews } = useSelector((state) => state.review);
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const [errors, setErrors] = useState({
    comment: "",
    rating: "",
  });
  const { register, handleSubmit, reset, setValue } = useForm();
  const handleClick = async (v) => {
    setRating(v);
    setValue("rate", v);
  };

  const handleSubmitComment = async (data) => {
    let errorObj = {};
    let hasError = false;

    if (!data.comment) {
      errorObj.comment = "Message is required!";
      hasError = true;
    } else if (!rating) {
      errorObj.rating = "Rating is required!";
      hasError = true;
    }
    setErrors(errorObj);

    if (hasError) return;

    dispatch(handleAddReview(data, courseSlug));
    reset();
    setRating(0);
  };

  useEffect(() => {
    dispatch(handleGetReview(courseSlug));
  }, [courseSlug, dispatch]);

  return (
    <StyledReviews>
      <div className="recent-reviews">
        {reviews?.map((comment) => (
          <div key={comment?.id} className="review">
            <div className="image">
              <img
                src={
                  comment?.image ? comment.image : "/assets/images/avatar-2.jpg"
                }
                alt=""
              />
            </div>
            <div className="details">
              <h3>{comment?.fullname}</h3>
              <div className="rating d-flex align-items-center">
                <span className="d-flex align-items-center">
                  {Array.from({ length: comment?.rate }, (v, i) => i).map(
                    (i) => (
                      <FaStar key={i} className="text-warning" />
                    )
                  )}
                </span>
                <span className="ml-2 created_at d-flex align-items-center">
                  {comment?.created_at.split("T")[0]}
                </span>
              </div>
              <p className="comment">{comment?.comment}</p>
            </div>
          </div>
        ))}
      </div>
      {isEnrolled && (
        <>
          <form
            className="add-review"
            onSubmit={handleSubmit(handleSubmitComment)}
          >
            <input
              type="text"
              {...register("comment")}
              placeholder="Write your Review...."
            />
            <div className="rate">
              {Array.from({ length: rating }, (v, i) => i + 1).map((v) => (
                <FaStar
                  key={v}
                  className="pointer text-warning"
                  onClick={() => handleClick(v)}
                />
              ))}
              {Array.from({ length: 5 - rating }, (v, i) => i + 1 + rating).map(
                (v) => (
                  <FaRegStar
                    key={v}
                    className="pointer"
                    onClick={() => handleClick(v)}
                  />
                )
              )}
            </div>
          </form>
          <div className="text-danger pt-2 pl-3">
            {errors["comment"] && errors["comment"]}
            {errors["rating"] && errors["rating"]}
          </div>
        </>
      )}
      <button className="see-more-btn">See More Reviews</button>
    </StyledReviews>
  );
};

export default Reviews;

const StyledReviews = styled.div`
  width: 100%;
  .recent-reviews {
    max-height: 500px;
    overflow-y: auto;
    .review {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 1rem;
      padding: 1rem 0;
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 1rem;
      .image {
        display: flex;
        justify-content: center;
        align-items: start;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }
      .rating span {
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 1 !important;
        color: #828282 !important;
      }
    }
  }
  .see-more-btn {
    margin: 2rem auto;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    background-color: transparent;
    border: 1px solid #2460b9;
    border-radius: 5px;
  }
  .add-review {
    position: relative;
    input {
      width: 100%;
      padding: 0.5rem 1rem;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 22px;
      text-align: justify;
      color: #828282 !important;
    }

    .rate {
      position: absolute;
      /* top: 0.75rem; */
      inset-block: 0;
      right: 0.5rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      svg {
        color: #bdbdbd;
      }
      .rating_view {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        height: 100%;
        width: 10px;
        background-color: red;
        background-clip: border-box;
      }
    }
  }
`;

import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";

export function AccordionHeader({ title }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Accordion.Toggle
      as={"div"}
      variant="link"
      eventKey="0"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="d-flex justify-content-between align-items-center pointer">
        <h3 className="d-inline">{title}</h3>
        <span>
          {isOpen ? (
            <BsCaretUpFill fontSize="20" />
          ) : (
            <BsCaretDownFill fontSize="20" />
          )}
        </span>
      </div>
    </Accordion.Toggle>
  );
}

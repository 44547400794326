import React from 'react';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../../Layout/Footer';
// import LessonAside from '../../Layout/LessonAside';
import LessonContent from '../../Layout/LessonLayout/LessonContent';
import LessonHeader from '../../Layout/LessonLayout/LessonHeader';
import LessonNav from '../../Layout/LessonLayout/LessonNav';
import LessonSidebar from '../../Layout/LessonLayout/LessonSidebar';
import { isEmpty } from '../../_helpers/_methods';
import { getCourseProgress, getLesson } from '../../_redux/slices/course';

const SingleLesson = () => {
	const dispatch = useDispatch();
	const { courseSlug, lessonSlug } = useParams();
	const lesson = useSelector((state) => state.course.lesson);
	const [activeContent, setActiveContent] = useState();
	const [hasArticle, setHasArticle] = useState();
	const [hasVideo, setHasVideo] = useState();
	const [hasAudio, setHasAudio] = useState();
	const [contentArray, setContentArray] = useState([]);
	const [contentIndex, setContentIndex] = useState(0);
	const [resource, setResource] = useState(null);

	useLayoutEffect(() => {
		let tempContentArray = [];
		let hasArticle = Boolean(lesson?.article_content?.content || lesson?.article_content?.length > 8);
		let hasVideo = Boolean(lesson?.video_content?.link || !isEmpty(lesson?.video_content));
		let hasAudio = Boolean(!isEmpty(lesson?.audio_content));
		console.log(hasAudio, '', lesson)

		setHasArticle(hasArticle);
		setHasVideo(hasVideo);
		setHasAudio(hasAudio);

		if (hasArticle) {
			tempContentArray.push('article');
		}
		if (hasVideo) {
			tempContentArray.push('video');
		}
		if (hasAudio) {
			tempContentArray.push('audio');
		}
		setContentArray(tempContentArray);
		setContentIndex(0);
		setActiveContent(tempContentArray[0]);

		dispatch(getCourseProgress(courseSlug))
	}, [dispatch, lesson, courseSlug]);

	useLayoutEffect(() => {
		setActiveContent(contentArray[contentIndex]);
	}, [contentArray, contentIndex]);

	useLayoutEffect(() => {
		if (lessonSlug !== "undefined") {
			dispatch(getLesson(lessonSlug));
		}
	}, [dispatch, lessonSlug]);

	const handleActiveContentChange = (value, resource) => {
		if (value === 'next') {
			setContentIndex(contentIndex + 1);
		} else if (value === 'prev') {
			setContentIndex(contentIndex - 1);
		} else {
			setActiveContent(value);
			if (resource) {
				setResource(resource);
			}
		}
	};
	const [bigFontSize, setBigFontSize] = useState(false);
	const handleFontChange = () => setBigFontSize(!bigFontSize);

	const [fullScreen, setFullScreen] = useState(false);
	const handleFullScreen = () => setFullScreen(!fullScreen);

	const [showNote, setShowNote] = useState(false);
	const handleToggleNote = () => setShowNote(prev => !prev);

	return (
		<>
			<LessonHeader
				activeContent={activeContent}
				lesson={lesson}
				handleFontChange={handleFontChange}
				fullScreen={fullScreen}
				handleFullScreen={handleFullScreen}
				showNote={showNote} handleToggleNote={handleToggleNote} />
			<Container fullScreen={fullScreen} showNote={showNote}>
				{!fullScreen && (
					<LessonNav
						lesson={lesson}
						hasArticle={hasArticle}
						hasVideo={hasVideo}
						hasAudio={hasAudio}
						handleActiveContentChange={handleActiveContentChange}
						handleToggleNote={handleToggleNote}
						activeContent={activeContent}
					/>
				)}
				<LessonContent
					lesson={lesson}
					contentArray={contentArray}
					contentIndex={contentIndex}
					activeContent={activeContent}
					handleActiveContentChange={handleActiveContentChange}
					hasArticle={hasArticle}
					hasVideo={hasVideo}
					hasAudio={hasAudio}
					resource={resource}
					bigFontSize={bigFontSize}
				/>
				{
					showNote &&
					<LessonSidebar />
				}
			</Container>
			<Footer />
		</>
	);
};

export default SingleLesson;

const Container = styled.div`
	/* width: 100vw; */
	display: grid;

	${({ fullScreen, showNote }) =>
		fullScreen
			? `grid-template-columns:1fr;grid-template-areas:"content ${showNote ? "sidebar" : ""}"; `
			: `grid-template-columns: max-content 1fr;grid-template-areas: "nav content  ${showNote ? "sidebar" : ""}";`}
	grid-gap: 1rem;
	min-height: 95vh;
	@media screen and (max-width: 1024px) {
		grid-template-columns: max-content auto;
		grid-template-areas: 'nav content';
	}
	@media screen and (max-width: 768px) {
		grid-template-columns: 100%;
		grid-template-areas: 'nav' 'content';
	}
`;

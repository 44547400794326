import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import EditorInput from "./EditorInput";
import {
  handleAddAssignmentQuestionOption,
  handleAddQuestionOption,
} from "../_redux/slices/courseData";
import { FormGroup } from "./FormGroup/FormGroup";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";

const OptionHolder = styled.div`
  background: #f4f8fa;
  padding: 1rem;
  padding-bottom: 0.2rem;
  margin-bottom: 0.8rem;
  border-radius: 5px;
  .form-check-label {
    font-size: 12px;
  }
`;
const StyledQuestionContent = styled.div`
  .head {
    display: flex;
    justify-content: space-between;
  }
  .toggleBtn {
    background: white;
    color: #0069d9;
    padding: 8px 12px;
    margin-right: 0.8rem;
    transition: background-color 300ms, color 300ms;
    :hover {
      color: white;
      background: #0069d9;
    }
  }
`;

function QuestionContent({
  index,
  chapterId,
  question,
  register,
  setValue,
  watch,
  clearErrors,
  errors,
  fieldName,
  isAssignment,
}) {
  const optionset = useSelector((state) => {
    if (isAssignment) {
      return state.courseData.assignment_optionset;
    } else {
      return state.courseData.optionset;
    }
  });
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [hasHint, setHasHint] = useState(false);
  const [hasExplanation, setHasExplanation] = useState(false);

  const toggleHasHint = () => {
    setHasHint(!hasHint);
  };
  const toggleHasExplanation = () => {
    setHasExplanation(!hasExplanation);
  };
  const watchQuizTypes = watch(`${fieldName}.quiz_type`);

  useEffect(() => {
    setOptions(optionset[question.id]);
  }, [question.id, optionset]);

  const dispatch = useDispatch();
  const handleAddOption = () => {
    if (isAssignment) {
      dispatch(handleAddAssignmentQuestionOption(question.id));
    } else {
      dispatch(handleAddQuestionOption(question.id));
    }
  };

  let questionOptions = [
    {
      label: "One correct answer",
      value: "single_true",
    },
    // {
    //   label: "One or more correct answer",
    //   value: "multiple_true",
    // },
  ];

  if (isAssignment) {
    questionOptions.push({
      label: "long answer",
      value: "long_answer",
    });
  }
  const handleQuizTypeChange = (e) => {
    setValue(`${fieldName}.quiz_type`, e.value);
    clearErrors(`${fieldName}.quiz_type`);
  };
  return (
    <StyledQuestionContent>
      <Accordion defaultActiveKey="0">
        <div className="head">
          <Accordion.Toggle
            as="div"
            eventKey="0"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <h3 className="cursor-pointer">Question #{index}</h3>
          </Accordion.Toggle>
          <div>
            <Accordion.Toggle
              as={Button}
              className="toggleBtn"
              eventKey="0"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {isOpen ? <BsFillCaretDownFill /> : <BsFillCaretUpFill />}
            </Accordion.Toggle>
            <Button variant="outline-danger">Delete</Button>
          </div>
        </div>

        <Accordion.Collapse eventKey="0">
          <>
            <input
              type="text"
              {...register(`${fieldName}.order`)}
              value={index}
              readOnly
              hidden
            />
            <Row>
              <Col md={6}>
                {" "}
                <FormGroup
                  key={question.quiz_type}
                  id={`${fieldName}.quiz_type`}
                  label="Question type"
                  type="select"
                  register={register}
                  required={true}
                  // disabled={isLoading}
                  defaultValue={question.quiz_type}
                  error={errors}
                  options={questionOptions}
                  onChange={handleQuizTypeChange}
                />
              </Col>
              <Col md={6}>
                <FormGroup
                  key={question.marks}
                  id={`${fieldName}.marks`}
                  label="Marks"
                  type="number"
                  register={register}
                  required={false}
                  // disabled={isLoading}
                  defaultValue={question.marks}
                  error={errors}
                />
              </Col>
            </Row>

            <EditorInput
              label={"Question"}
              id={`${fieldName}.question`}
              register={register}
              defaultValue={question.question}
              setValue={setValue}
              errors={errors}
            />

            {watchQuizTypes !== "long_answer" && (
              <Fragment>
                {options?.map((option, index) => (
                  <OptionHolder key={option.id}>
                    <span>
                      <input
                        type="text"
                        {...register(`${fieldName}.options[${index}].order`)}
                        value={index}
                        readOnly
                        hidden
                      />
                      <EditorInput
                        label={`Option # ${index + 1}`}
                        id={`${fieldName}.options[${index}].option`}
                        register={register}
                        defaultValue={option.option}
                        setValue={setValue}
                        errors={errors}
                      />
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          id={`${fieldName}.options[${index}].is_correct`}
                          type={"checkbox"}
                          label="This is the correct answer"
                          {...register(
                            `${fieldName}.options[${index}].is_correct`
                          )}
                          defaultChecked={option.is_correct}
                        />
                      </Form.Group>
                    </span>
                  </OptionHolder>
                ))}
                <Button className="mb-4" onClick={handleAddOption}>
                  Add Option
                </Button>
              </Fragment>
            )}

            {hasHint ? (
              <Fragment>
                <EditorInput
                  label={"Hint"}
                  id={`${fieldName}.hint`}
                  register={register}
                  defaultValue={question.hint}
                  setValue={setValue}
                  errors={errors}
                />
                <Button onClick={toggleHasHint} variant="outline-danger mb-4">
                  Remove hint
                </Button>
              </Fragment>
            ) : (
              <button onClick={toggleHasHint} className="d-block btn btn-link">
                Add hint
              </button>
            )}
            {watchQuizTypes !== "long_answer" &&
              (hasExplanation ? (
                <Fragment>
                  <EditorInput
                    label={"Explanation"}
                    id={`${fieldName}.explanation`}
                    register={register}
                    defaultValue={question.explanation}
                    setValue={setValue}
                    errors={errors}
                  />
                  <Button
                    onClick={toggleHasExplanation}
                    variant="outline-danger mb-4"
                  >
                    Remove explanation
                  </Button>
                </Fragment>
              ) : (
                <button
                  onClick={toggleHasExplanation}
                  className="d-block btn btn-link"
                >
                  Add explanation
                </button>
              ))}
          </>
        </Accordion.Collapse>
      </Accordion>
    </StyledQuestionContent>
  );
}

export default QuestionContent;

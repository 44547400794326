import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, ...rest }) => {
	return (
		<div style={{ minHeight: '100vh !important', width: '100vw !important' }}>
			<Header {...rest} />
			<div className="w-100">{children}</div>
			<Footer />
		</div>
	);
};

export default Layout;

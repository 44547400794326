import React, { useEffect, useState } from 'react';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import Banner from './Banner';
// import Featured from './Featured';
// import FeaturedCourses from './FeaturedCourses';
// import ResourcePerson from "./ResourcePerson";
import { useDocumentTitle } from '../../_helpers/hooks/useDocumentTitle';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import styled from 'styled-components';

const Homepage = () => {
	useDocumentTitle('KMS');
	const [latestCourses, setLatestCourses] = useState([]);
	// const [latestThematicAreas, setLatestThematicArea] = useState([]);

	// const [totalCoursesCount, setTotalCoursesCount] = useState(0);
	// const [totalUserCount, setTotalUserCount] = useState(0);
	const [totalThematicAreaCount, setTotalThematicAreaCount] = useState(0);
	const [totalResourcePersonCount, setTotalResourcePersonCount] = useState(0);
	const [totalParticipantCount, setTotalParticipantCount] = useState(0);

	const getHomePageData = async () => {
		try {
			let response = await fetchWrapper.get('home/');
			let homepageData = response.data;
			setLatestCourses(homepageData.latest_courses);
			// setLatestThematicArea(homepageData.latest_thematic_areas);

			// setTotalCoursesCount(homepageData.total_courses);
			// setTotalUserCount(homepageData.total_user);
			setTotalParticipantCount(homepageData.total_particpant);
			setTotalResourcePersonCount(homepageData.total_resource_person);
			setTotalThematicAreaCount(homepageData.total_thematic_areas);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getHomePageData();
	}, []);

	return (
		<StyledHomePage>
			<Header fluid={true} />
			<Banner
				thematicAreaCount={totalThematicAreaCount}
				participantCount={totalParticipantCount}
				resourcePersonCount={totalResourcePersonCount}
				latestCourses={latestCourses}
			/>
			{/* <Featured thematicAreas={latestThematicAreas}/> */}
			{/* <FeaturedCourses latestCourses={latestCourses}/> */}
			{/* <ResourcePerson /> */}
			<Footer />
		</StyledHomePage>
	);
};

export default Homepage;

const StyledHomePage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	position: relative;
`;

import React from 'react';
import YoutubeEmbedPreview from '../../components/YoutubeEmbed/YoutubeEmbedPreview';

const LessonVideoContent = ({ videoContent }) => {
	const content = videoContent?.video || videoContent?.link;
	if (!content) return null;
	const video = Array.isArray(content) ? content[0] : { media_url: content };
	if (!video) return null;

	let duration = video.duration || video.time;
	return (
		<div id="previewComponentID" className="previewComponent">
			<div>
				<YoutubeEmbedPreview key={video.media_url} youtubeLink={video.media_url} />
				{
					duration &&
					<p>Length of Video: {duration}</p>
				}
			</div>
		</div>
	);
};

export default LessonVideoContent;

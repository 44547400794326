import React, { Fragment } from "react";

function FormError({ error }) {
  return (
    <Fragment>
      {error && <p className="text-danger mt-1">{error?.message}</p>}
    </Fragment>
  );
}

export default FormError;

import React from 'react';
import styled from 'styled-components';
import Layout from '../../Layout/Layout';
import { Form, Button } from 'react-bootstrap';
import { StyledFormLabel } from '../../components/Reuseables';

const Contact = () => {
	return (
		<Layout>
			<ContactContainer>
				<div className="contact d-flex">
					<div className="contact-left">
						<h1>Anything that you have to say to us</h1>
						<p className="mb-4">let’s create something together</p>
						<div>
							<div className="infogroup">
								<img src="/assets/images/mail.svg" alt="" />
								<div>
									<p>Send us Mail at</p>
									<p>info@plgsp.gov.np</p>
								</div>
							</div>
							<div className="infogroup">
								<img src="/assets/images/mail.svg" alt="" />
								<div>
									<p>Give us a call</p>
									<p>+977-08129291</p>
								</div>
							</div>
						</div>
					</div>
					<div className="contact-right">
						<h2 className="contact-right-title">Send us a message</h2>
						<form>
							<Form.Group>
								<StyledFormLabel>Full Name</StyledFormLabel>
								<Form.Control type="text" placeholder="Your full name" />
							</Form.Group>
							<Form.Group>
								<StyledFormLabel>Email</StyledFormLabel>
								<Form.Control type="email" placeholder="Your email address" />
							</Form.Group>
							<Form.Group>
								<StyledFormLabel>Your Message</StyledFormLabel>
								<textarea rows="5"></textarea>
							</Form.Group>
							<Button variant="primary">Send message</Button>
						</form>
					</div>
				</div>
			</ContactContainer>
		</Layout>
	);
};

export default Contact;

const ContactContainer = styled.div`
	margin-block-end: 64px;
	background-color: #e9f2ff;
	padding-block: 80px;
	padding-inline: 10vw;

	.contact {
		gap: 80px;
		&-left {
			display: flex;
			flex-direction: column;
			justify-content: end;
			h1 {
				font-size: 56px !important;
				line-height: 1.32 !important;
				max-width: 64%;
				color: #2460b9;
			}
			.infogroup {
				display: flex;
				gap: 1rem;
				padding: 20px;
				background-color: #ffffff;
				box-shadow: 0px 6px 30px rgba(64, 83, 145, 0.25);
				border-radius: 5px;
				margin-bottom: 20px;
				width: 50%;
			}
		}
		&-right {
			width: 50%;
			border: 1px solid #828282;
			border-radius: 20px;
			padding: 28px;
			background-color: #fff;
			&-title {
				margin-bottom: 22px;
				font-size: 40px !important;
			}
			.form-group {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: stretch;
				.form-control {
					width: 100%;
				}
				textarea {
					background-color: #f8f9fa;
					border: none;
					border-radius: 5px;
				}
			}
		}
	}
`;

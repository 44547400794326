import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import CardSlider from './Cards';
import { useDispatch, useSelector } from 'react-redux';
import { getCourseGroup } from '../../_redux/slices/coursesByThematicAreas';

const ThematicAreas = () => {
	const dispatch = useDispatch();
	const courseGroup = useSelector((state) => state.courseGroup.courseGroup);
	useEffect(() => {
		dispatch(getCourseGroup());
	}, [dispatch]);
	console.log(courseGroup);

	return (
		<>
			<Header />
			<StyledThematicAreas>
				<h2 className="thematic-area-title">Thematic Areas</h2>
				<select className="filter">
					<option value="">Sort By</option>
				</select>

				{courseGroup.map((courses) => courses?.courses.length > 0 && <CardSlider key={courses.id} courses={courses} />)}
			</StyledThematicAreas>
			<Footer />
		</>
	);
};

export default ThematicAreas;

const StyledThematicAreas = styled.div`
	min-height: 90vh;
	width: 80%;
	margin: auto;
	margin-bottom: 5rem;
	.thematic-area-title {
		font-size: 36px !important;
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
	select {
		margin-bottom: 1.5rem;
		padding: 0.25rem 0.5rem;
		border-radius: 5px;
	}
	.cards-slider-md {
		display: none;
	}
	.cards-slider {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1.5rem;
		position: relative;
		margin-bottom: 1rem;
		.left-arrow,
		.right-arrow {
			position: absolute;
			display: grid;
			place-content: center;
			height: 100%;
		}
		.left-arrow {
			top: 0;
			right: 100%;
			padding-right: 1rem;
		}
		.right-arrow {
			top: 0;
			left: 100%;
			padding-left: 1rem;
		}
	}
	.course-list {
		width: 100%;
		.course-title {
			margin-bottom: 0.5rem;
			font-size: 20px !important;
		}
	}
	@media screen and (max-width: 1024px) {
		width: calc(100% - 4rem);
		.cards-slider {
			display: none;
		}
		.cards-slider-md {
			height: max-content;
			display: flex;
			gap: 1.5rem;
			overflow-x: auto;
			margin-bottom: 2rem;
		}
	}

	@media screen and (max-width: 768px) {
		.thematic-area-title {
			font-family: 'Poppins';
			font-weight: 500;
			font-size: 18px !important;
			text-align: center;
			line-height: 25px;
			color: #333333;
		}
		.course-title {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 600;
			font-size: 16px !important;
			line-height: 22px;
			color: #333333;
		}
	}
`;

import React from 'react';
const DashboardIcon = () => {
	return (
		<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.5833 5.41667V7.58333H16.25V5.41667H20.5833ZM9.75 5.41667V11.9167H5.41667V5.41667H9.75ZM20.5833 14.0833V20.5833H16.25V14.0833H20.5833ZM9.75 18.4167V20.5833H5.41667V18.4167H9.75ZM22.75 3.25H14.0833V9.75H22.75V3.25ZM11.9167 3.25H3.25V14.0833H11.9167V3.25ZM22.75 11.9167H14.0833V22.75H22.75V11.9167ZM11.9167 16.25H3.25V22.75H11.9167V16.25Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default DashboardIcon;

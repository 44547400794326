import { toast } from "react-toastify";
import React, { Fragment } from "react";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ContentState, convertToRaw, EditorState } from "draft-js";

//
import { VscLoading } from "react-icons/vsc";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { FormGroup } from "../../../components/FormGroup/FormGroup";
import AddResource from "../Courses/AddNewCourse/ChapterBuilder/LessonArea/AddResource";
import { ErrorMessageHandler } from "../../../_helpers/_methods";

/**
 *
 */
export default function CapacitySupportForm() {
  const history = useHistory();
  const { id } = useParams();
  const isEdit = Boolean(id);
  const [TextState, setTextState] = useState(EditorState.createEmpty());
  const [isLoading, setIsLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const [data, setData] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategoryTypes = async () => {
      const response = await fetchWrapper.get("/capacity-support/category/");
      const results = response.data.results;
      let categories = results.map((result) => ({
        label: result.title,
        value: result.id,
      }));
      setCategories(categories);
    };
    getCategoryTypes();
  }, []);

  const handleCategoryChange = (e) => {
    setValue(`category`, e.value);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      is_public: true,
    },
  });

  useEffect(() => {
    if (!data) return;

    setValue("title", data.title);
    setValue("description", data.description);
    setValue("is_public", data.is_public);
    setValue("short_description", data.short_description);
    setValue("category", data.category);
    setValue("media", data.media);
    const contentBlock = htmlToDraft(data.description);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const textState = EditorState.createWithContent(contentState);
      setTextState(textState);
    }

    setMedia(data.media);
  }, [setValue, data]);

  useEffect(() => {
    const getCapacitySupport = async () => {
      try {
        const response = await fetchWrapper.get(`/capacity-support/${id}/`);
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (id) {
      getCapacitySupport();
    }
  }, [id]);

  const onSubmit = async (form_data) => {
    try {
      setIsLoading(true);
      const body = {
        title: form_data["title"],
        description: draftToHtml(convertToRaw(TextState.getCurrentContent())),
        short_description: form_data.short_description,
        is_public: form_data.is_public,
        approved: true,
        media: media,
        category: form_data.category,
      };

      if (isEdit) {
        await fetchWrapper.patch(`capacity-support/${data.id}/`, body);
        toast.success("Capacity support updated Successfully!");
      } else {
        await fetchWrapper.post(`capacity-support/`, body);
        toast.success("Capacity support created Successfully!");
      }

      handleClearForm();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      ErrorMessageHandler(error);
    }
  };

  const handleClearForm = () => {
    history.push("/dashboard/capacity-support/1");
  };

  const handleResourceAdd = (resources) => {
    if (resources.length > 0) {
      const formattedResources = resources.map((resource) => ({
        title: resource.title,
        media_url: resource.media_url,
        type: resource.type,
        id: resource.id,
      }));
      setMedia(formattedResources);
    }
  };

  return (
    <Fragment>
      <Button variant="warning" onClick={handleClearForm}>
        Back
      </Button>

      <div className="card-sm course-tab mt-3" style={{ width: "100%" }}>
        <Row>
          <h3 className="mx-3">{isEdit ? "Edit" : "Add"} Capacity Support</h3>
        </Row>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <FormGroup
                id="title"
                errorName="Title"
                label="Title"
                type="text"
                placeholder="Enter title"
                register={register}
                required={true}
                disabled={isLoading}
                error={errors}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup
                label="Short Description"
                id="short_description"
                type="textarea"
                disabled={isLoading}
                placeholder="Enter short Description"
                error={errors}
                errorName="Short description"
                required={true}
                register={register}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup
                label="Description"
                id="description"
                type="editor"
                disabled={isLoading}
                placeholder="Enter Description"
                defaultValue={TextState}
                error={errors}
                onChange={(e) => setTextState(e)}
                required={true}
                editorStyle={{ minHeight: "200px" }}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={3}>
              <FormGroup
                defaultValue={data?.category}
                label="Category Type"
                id="category"
                type="select"
                disabled={isLoading}
                options={categories}
                error={errors}
                onChange={handleCategoryChange}
                required={true}
                register={register}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={3}>
              <AddResource
                defaultValues={media}
                setValue={setValue}
                label="Click to select Media Resource"
                required={true}
                handleOnSubmit={handleResourceAdd}
              />
              <div className="mb-5 mt-2">
                {media && <div>{media?.length} resources selected</div>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup
                label="Make public?"
                id="is_public"
                register={register}
                type="checkbox"
                error={errors}
                disabled={isLoading}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12} className="d-flex">
              <Button variant="primary" disabled={isLoading} type="submit">
                {isLoading ? <VscLoading className="spin" /> : "Submit"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Fragment>
  );
}

import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourse, getCourseProgress } from "../../_redux/slices/course";
import YoutubeEmbedPreview from "../../components/YoutubeEmbed/YoutubeEmbedPreview";
import { createMarkup } from "../../_helpers/_methods";
import CourseContent from "./CourseContent";
import { getUserEnrolledCourse } from "../../_redux/slices/user";
import styled from "styled-components";
import EnrolledTabs from "./EnrolledComponents/EnrolledTabs";
import EnrolledResults from "./EnrolledComponents/EnrolledResults";
import Footer from "../../Layout/Footer";

import EnrollHeader from "./EnrolledComponents/EnrollHeader";
import Loading from "../../components/Loading";
import Reviews from "./Reviews";
import { isEmpty } from "../../_helpers/_methods";
// import { nextEntityAfterCompletion } from '../../_helpers/nextEntityHandler';
import { restartCourse } from "../../_redux/slices/course";

const EnrolledCourse = () => {
  const history = useHistory();
  const { courseSlug } = useParams();
  const { course, loading } = useSelector((state) => state.course);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourse(courseSlug));
    dispatch(getUserEnrolledCourse());
    dispatch(getCourseProgress(courseSlug));
  }, [courseSlug, dispatch]);

  useEffect(() => {
    if (course?.id) {
      if (!course?.enrolled) {
        history.push(`/courses/${courseSlug}`);
      }
    }
  }, [course?.enrolled, course?.id, courseSlug, history]);

  return (
    <div>
      {loading ? (
        <div style={{ height: "100vh" }} className="py-4">
          <Loading fontSize="5rem" color="#2f80ed" />
        </div>
      ) : (
        <>
          <EnrollHeader course={course} />

          <Container>
            <div className="thumbnail">
              {course?.introductory_video ? (
                <YoutubeEmbedPreview youtubeLink={course?.introductory_video} />
              ) : (
                <img
                  src={
                    course?.thumbnail_image || "/assets/images/placeholder.jpg"
                  }
                  alt=""
                  className="course-inner-img"
                />
              )}
            </div>
            <div className="content">
              <Content>
                <div>
                  <CourseContent
                    totalLesson={course?.lessons_count}
                    courseSlug={courseSlug}
                  />
                </div>
                <div className="mt-5">
                  <h3 className="mb-2" style={{ color: "#2f80ed" }}>
                    Description
                  </h3>
                  <p
                    className="text-justify"
                    dangerouslySetInnerHTML={createMarkup(
                      course?.long_description
                    )}
                  ></p>
                </div>
              </Content>
              <StyledReviews>
                <h3 className="mb-2" style={{ color: "#2f80ed" }}>
                  Recent Reviews
                </h3>
                <Reviews isEnrolled={true} courseSlug={courseSlug} />
              </StyledReviews>
            </div>
            <div className="enroll_sidebar">
              <Sidebar>
                <div className="start-now">
                  {!isEmpty(course?.enrolled?.active_lesson) ? (
                    <Button
                      className="w-100 text-center"
                      onClick={() => {
                        dispatch(restartCourse(false));
                        history.push(
                          `/courses/${courseSlug}/enrolled/${course?.enrolled?.active_lesson?.slug}`
                        );
                      }}
                    >
                      <span className="w-100 text-center">Continue</span>
                    </Button>
                  ) : course?.enrolled?.upcoming_lessons?.length > 0 ? (
                    <Button
                      className="w-100 text-center"
                      onClick={() => {
                        dispatch(restartCourse(false));
                        history.push(
                          `/courses/${courseSlug}/enrolled/${course?.enrolled?.upcoming_lessons[0]?.slug}`
                        );
                      }}
                    >
                      <span className="w-100 text-center">Continue</span>
                    </Button>
                  ) : (
                    <Button
                      className="w-100 text-center"
                      onClick={() => {
                        history.push(
                          `/courses/${courseSlug}/enrolled/${course?.enrolled?.completed_lessons[0]?.slug}`
                        );
                        dispatch(restartCourse(true));
                      }}
                    >
                      <span className="w-100 text-center">Start Again</span>
                    </Button>
                  )}
                </div>

                <EnrolledTabs />
                <EnrolledResults courseSlug={courseSlug} />
              </Sidebar>
            </div>
          </Container>
          <Footer />
        </>
      )}
    </div>
  );
};

export default EnrolledCourse;

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "thumbnail sidebar" "content sidebar";
  grid-template-rows: max-content max-content;
  width: 80%;
  margin: 2rem auto;
  grid-gap: 3rem;
  .thumbnail {
    grid-area: thumbnail;
  }
  .enroll_sidebar {
    grid-area: sidebar;
  }
  .content {
    grid-area: content;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 2rem;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    max-width: 100vw;
    grid-template-columns: 1fr;
    grid-template-areas: "thumbnail" "sidebar" "content";
  }
`;

const Content = styled.div`
  margin: auto;
  margin-bottom: 2rem;
`;

const StyledReviews = styled.div`
  margin: auto;
  margin-bottom: 2rem;
`;

const Sidebar = styled.div`
  .start-now {
    width: 100%;
    box-shadow: 0px 0px 8px 5px #2460b920;
    padding: 1.5rem;
    margin-bottom: 2.5rem;
  }
`;

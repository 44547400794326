import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { BsBook } from 'react-icons/bs';
import { VscListSelection } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { getChaptersFromCourse, getLessonsFromChapter } from '../../../_redux/slices/course';
import { useDispatch, useSelector } from 'react-redux';

const Lesson = ({ chapterSlug, courseSlug }) => {
	const dispatch = useDispatch();
	const lessons = useSelector((state) => state.course.lessons[chapterSlug]);
	useEffect(() => {
		try {
			dispatch(getLessonsFromChapter(chapterSlug));
		} catch (error) {
			console.log(error);
		}
	}, [chapterSlug, dispatch]);

	return (
		<>
			{lessons?.map((lesson) => (
				<Link className="lesson-link" to={`/courses/${courseSlug}/enrolled/${lesson.slug}`}>
					<></>
					<img className="md-visible" src="/assets/icons/notesBlack.svg" alt="" />
					<span className="md-hidden">{lesson.title}</span>
				</Link>
			))}
		</>
	);
};

const Sidebar = ({ courseSlug }) => {
	const dispatch = useDispatch();
	const chapters = useSelector((state) => state.course.chapters);

	useEffect(() => {
		try {
			dispatch(getChaptersFromCourse(courseSlug));
		} catch (error) {
			console.log(error);
		}
	}, [courseSlug, dispatch]);

	const [showContents, setShowContents] = useState(true);
	return (
		<StyledSidebar className="assignment-sidebar">
			<div className="mt-4">
				<Button
					className="w-100 d-flex align-items-center justify-content-between contents-btn"
					onClick={() => setShowContents(!showContents)}
				>
					<div className="d-flex align-items-center" style={{ fontSize: '1.1rem' }}>
						<BsBook className="mr-2" style={{ fontSize: '1.2rem' }} />
						<p>Contents</p>
					</div>
					<span>
						{showContents ? (
							<FaChevronUp style={{ fontSize: '1.2rem' }} />
						) : (
							<FaChevronDown style={{ fontSize: '1.2rem' }} />
						)}
					</span>
				</Button>
				<button className="content-list-btn" onClick={() => setShowContents(!showContents)}>
					<VscListSelection />
				</button>
				{showContents && (
					<div className="sidebar-contents">
						<div className="side-line"></div>
						<div className="sidebar-nav">
							{chapters.map((chapter) => (
								<Lesson courseSlug={courseSlug} chapterSlug={chapter.slug} key={chapter.slug} />
							))}
						</div>
					</div>
				)}
			</div>
		</StyledSidebar>
	);
};

export default Sidebar;

const StyledSidebar = styled.div`
	grid-area: sidebar;
	background-color: #f2f2f2;
	padding: 1rem;
	.contents-btn {
		background-color: #2f80ed;
		color: white !important;
		div > p {
			color: white;
		}
	}
	.content-list-btn {
		display: none;
	}
	.sidebar-contents {
		margin: 1rem 0;
		display: grid;
		grid-template-columns: max-content auto;
		.side-line {
			width: 10px;
			height: 100%;
			border: 1px solid #333;
			border-right: transparent;
			border-top: transparent;
			border-radius: 0px 0px 0px 10px;
			margin: -1.25rem 1rem;
		}
		.sidebar-nav {
			a {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0.5rem;
				&:hover {
					background-color: #e9f2ff;
				}
				svg {
					display: none;
				}
				&:hover > svg {
					display: block;
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		width: max-content;
		.contents-btn {
			display: none !important;
		}
		.content-list-btn {
			display: block;
			border: 0;
			padding: 0.5rem;
			margin: 0;
			color: #2f80ed;
			font-size: 2rem !important;
		}
		.sidebar-contents {
			.side-line {
				display: none;
			}
		}
		.lesson-link {
			position: relative;
			&:hover > .md-hidden {
				display: inline-flex;
				align-items: center;
				width: max-content;
				height: 100%;
				position: absolute;
				top: 0;
				left: 100%;
				background-color: #2f80ed;
				padding: 0.25rem 0.5rem;
				color: white;
				border-radius: 5px;
			}
			&:hover {
				&::before {
					content: '';
					position: absolute;
					width: 1px;
					height: 1px;
					border: 10px solid transparent;
					border-right: 15px solid #2f80ed;
					top: 50%;
					transform: translateY(-50%);
					left: calc(100% - 20px);
					z-index: 10;
				}
			}
		}
	}
`;

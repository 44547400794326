import React from 'react';
import { IoIosStats } from 'react-icons/io';
import {
	FaCode,
	FaHeartbeat,
	FaLandmark,
	FaLeaf,
	FaPhotoVideo,
	FaSchool,
	FaStamp,
	FaWindowRestore,
} from 'react-icons/fa';

export const DynamicIcon = ({ name }) => {
	switch (name) {
		case 'IoIosStats':
			return <IoIosStats />;
		case 'FaCode':
			return <FaCode />;
		case 'FaHeartbeat':
			return <FaHeartbeat />;
		case 'FaLandmark':
			return <FaLandmark />;
		case 'FaLeaf':
			return <FaLeaf />;
		case 'FaPhotoVideo':
			return <FaPhotoVideo />;
		case 'FaSchool':
			return <FaSchool />;
		case 'FaStamp':
			return <FaStamp />;
		case 'FaWindowRestore':
			return <FaWindowRestore />;
		default:
			return null;
	}
};

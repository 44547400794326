import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import styled from "styled-components";

const Label = styled.span`
  font-weight: 600;
`;
const Input = styled(Form.Control)`
  font-weight: 500;
  font-size: 18px;
`;
function LabelModal({ show, handleClose, handleSetActiveLabel, activeLabel }) {
  const [label, setLabel] = useState("");
  useEffect(() => {
    setLabel(activeLabel?.label);
  }, [activeLabel?.label]);
  return (
    <Modal centered size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Label Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Label>Label</Label>
          <Input
            value={label}
            placeholder="Event Name, Signaturee1 ..."
            defaultValue={activeLabel?.label}
            onChange={(e) => setLabel(e.target.value)}
          />
          <div className="mt-2">
            {["Event Name", "Signaturee1", "Signaturee1post"].map(
              (label, index) => {
                return (
                  <Button
                    className="mr-2"
                    key={index}
                    variant="outline-primary"
                    onClick={() => setLabel(label)}
                  >
                    {label}
                  </Button>
                );
              }
            )}
          </div>
        </div>
        <p className="text-center mt-4">
          Click 'Select Position' and move your cursor over the certificate
          template, and click on the position where you want to place the label.
          The Data will dynamically populate at the selected label.
        </p>
        <div className="text-center my-4">
          <Button
            onClick={() => {
              handleSetActiveLabel(label);
            }}
          >
            Select Position
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LabelModal;

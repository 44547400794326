import { useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//
import ReactTable from '../ReactTable';
import DefaultColumnFilter from '../ReactTable/Filters/DefaultColumnFilter';

//
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import dayjs from 'dayjs';
import { createMarkup } from '../../_helpers/_methods';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

/**
 *
 */
export default function PosterBannersTable({ handleSetEditPosterBannerData, handleShowPreview }) {
	const { page } = useParams();
	const history = useHistory();
	const userRole = useSelector((state) => state?.user?.user?.active_role);

	const [pageNo, setPageNo] = useState(page);
	const [totalCount, setTotalCount] = useState(0);
	const [posterBannersData, setPosterBannersData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getPosterBanners = useCallback(
		async (page) => {
			setIsLoading(true);
			const response = await fetchWrapper.get(
				`banner/?offset=${(page - 1) * 10}${
					['resource_person', 'participant'].includes(userRole) ? '&approved=true' : ''
				}`
			);
			setTotalCount(response.data.count);
			setPosterBannersData(response.data.results);
			setIsLoading(false);
		},
		[userRole]
	);

	useEffect(() => {
		getPosterBanners(pageNo);
		history.push(`/dashboard/banners/${pageNo}`);
	}, [history, pageNo, getPosterBanners]);

	const columns = useMemo(() => {
		let cols = [
			{
				Header: 'S.N.',
				accessor: 'sn',
				disableFilters: true,
				width: 'auto',
			},
			{
				Header: 'Title',
				accessor: 'title',
				width: 'auto',
				Filter: DefaultColumnFilter,
			},
			{
				Header: 'Content',
				accessor: 'content',
				width: 'auto',
				disableFilters: true,
				Cell: ({ row: { original }, cell: { value } }) => {
					const fomratedString = value.slice(0, 200) + '</p>';
					return (
						<span
							style={{
								color: 'black',
								fontSize: '32px',
								fontWeight: '400',
							}}
							dangerouslySetInnerHTML={createMarkup(fomratedString)}
						></span>
					);
				},
			},
			{
				Header: 'Created by',
				accessor: 'created_by',
				width: 'auto',
				Filter: DefaultColumnFilter,
			},
			{
				Header: 'Created at',
				accessor: 'created_at',
				disableFilters: true,
				width: '150px',
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				disableFilters: true,
				width: '150px',
			},
		];

		if (!['resource_person', 'participant'].includes(userRole)) {
			const visiblityColumn = {
				Header: 'Visibility',
				accessor: 'visibility',
				disableFilters: true,
				width: '150px',
			};

			cols.splice(4, 0, visiblityColumn);
		}
		return cols;
	}, [userRole]);

	const handlePosterDelete = useCallback(
		async (posterId) => {
			try {
				await fetchWrapper.delete(`/banner/${posterId}/`, posterId);
				Swal.fire('Deleted!', 'The content has been deleted.', 'success');
				getPosterBanners();
			} catch (err) {
				console.log(err);
			}
		},
		[getPosterBanners]
	);

	const data = useMemo(() => {
		if (posterBannersData?.length > 0) {
			let count = 0;
			const results = posterBannersData.map((posterBanner) => ({
				sn: ++count,
				title: posterBanner.title,
				content: posterBanner.text,
				created_by: posterBanner.created_by,
				created_at: dayjs(posterBanner.created_at).format('YYYY-MM-DD'),
				visibility: posterBanner.is_public ? 'Public' : 'Private',
				actions: (
					<>
						<FaEye
							className="text-primary mx-1 cursor-pointer"
							onClick={() => {
								handleShowPreview(posterBanner);
							}}
						/>
						{['superuser', 'cd_admin'].includes(userRole) && (
							<>
								<FaEdit
									className="text-primary mx-1 cursor-pointer"
									onClick={() => {
										handleSetEditPosterBannerData(posterBanner);
									}}
								/>
								<FaTrashAlt
									className="text-danger cursor-pointer"
									onClick={() =>
										Swal.fire({
											title: 'Are you sure?',
											text: "You won't be able to revert this!",
											icon: 'warning',
											showCancelButton: true,
											confirmButtonColor: '#3085d6',
											cancelButtonColor: '#d33',
											confirmButtonText: 'Yes, delete it!',
										}).then((result) => {
											if (result.isConfirmed) {
												handlePosterDelete(posterBanner.id);
											}
										})
									}
								/>
							</>
						)}
					</>
				),
			}));

			return results;
		} else return [];
	}, [posterBannersData, handleShowPreview, handleSetEditPosterBannerData, handlePosterDelete, userRole]);

	return (
		<div className="w-100">
			<ReactTable
				columns={columns}
				data={data}
				pageNo={pageNo}
				setPageNo={setPageNo}
				totalCount={totalCount}
				loading={isLoading}
			/>
		</div>
	);
}

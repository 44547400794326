import styled from 'styled-components';
import { createMarkup } from '../../../../_helpers/_methods';

/**
 *
 */
export default function Documentary({ previewData }) {
	return (
		<StyledDocumentary
		>
			<iframe
				width=""
				height=""
				src={`https://www.youtube.com/embed/${previewData?.videoUri?.split('/')[3]}`}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
				className="documentary-preview"
			/>
			<div className="text-container">
				<h4 className="mb-0">{previewData?.bannerTitle}</h4>

				<div dangerouslySetInnerHTML={createMarkup(previewData?.content)}></div>
			</div>
		</StyledDocumentary>
	);
}

const StyledDocumentary = styled.div`
	position: relative;
	background-color: black;

	.documentary-preview {
		height: 500px;
		position: relative;
		left: 15%;
		right: 15%;
		margin-inline: auto;
		width: 70%;
	}

	:hover .text-container {
		background-color: #e6e6e6ff;
		opacity: 1;
	}

	.text-container {
		width: 100%;
		background-color: #e6e6e630;
		opacity: 0.4;
		padding: 0.8rem;
		transition: opacity 300ms, background-color 300ms;
		z-index: 10;
	}
`;

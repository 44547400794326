import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';

export const initialState = {
	courses: [],
	count: 0,
};

const courseListSlice = createSlice({
	name: 'courseList',
	initialState,
	reducers: {
		setCourses: (state, action) => {
			state.courses = [...action.payload.results];
			state.count = action.payload.count;
		},
		removeCourse: (state) => {
			state.count = state.count - 1;
		},
	},
});

const { setCourses, removeCourse } = courseListSlice.actions;

export default courseListSlice.reducer;

export const handleCourseList =
	({ offset = 0, thematic_area, title }) =>
	async (dispatch) => {
		try {
			let response = await fetchWrapper.get(
				`course/?offset=${offset}${title ? `&title=${title}` : ''}${
					thematic_area ? thematic_area.map((v) => (v ? `&thematic_area=${v}` : '')).join('') : ''
				}`
			);
			dispatch(setCourses(response.data));
		} catch (error) {
			console.log(error);
		}
	};
export const handleDeleteCourse = (courseSlug) => async (dispatch) => {
	try {
		await fetchWrapper.delete(`course/${courseSlug}/`);
		await dispatch(removeCourse());
	} catch (error) {
		console.log(error);
	}
};
export const handlePublishCourse = (courseSlug) => async (dispatch) => {
	try {
		let body = {
			is_active: true,
		};
		await fetchWrapper.patch(`course/${courseSlug}/`, body);
		await dispatch(removeCourse());
	} catch (error) {
		console.log(error);
	}
};
export const handleTakeDownCourse = (courseSlug) => async (dispatch) => {
	try {
		let body = {
			is_active: false,
		};
		await fetchWrapper.patch(`course/${courseSlug}/`, body);
		await dispatch(removeCourse());
	} catch (error) {
		console.log(error);
	}
};

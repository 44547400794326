import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

//
import DocumentaryForm from './DocumentaryForm';
import DocumentariesTable from '../../../../components/@Documentaries/DocumentariesTable';

/**
 *
 */
export default function Documentaries() {
	const [showForm, setShowForm] = useState(false);
	const [documentary, setDocumentary] = useState();
	const [showPreview, setShowPreview] = useState(false);
	const userRole = useSelector((state) => state?.user?.user?.active_role);

	const handleShowForm = () => {
		setShowForm(true);
	};
	const handleHideForm = () => {
		setShowForm(false);
		setDocumentary(null);
	};

	const handleSetEditDocumentaryData = (data) => {
		setDocumentary(data);
		setShowForm(true);
	};

	const handleShowPreview = (data) => {
		setDocumentary(data);
		setShowForm(true);
		setShowPreview(true);
	};

	return (
		<div className="main-content-inner">
			<Row>
				<Col md={10}>
					<h2 className="main-title mb-2">Documentaries</h2>
				</Col>

				<Col md={2} className="d-flex justify-content-center align-items-center">
					{!showForm && ['superuser', 'cd_admin'].includes(userRole) && (
						<Button onClick={handleShowForm}>Add Documentaries</Button>
					)}
				</Col>
			</Row>

			<Row>
				{showForm ? (
					<DocumentaryForm
						hideForm={handleHideForm}
						documentary={documentary}
						showPreview={showPreview}
						setShowPreview={setShowPreview}
					/>
				) : (
					<DocumentariesTable
						handleSetEditDocumentaryData={handleSetEditDocumentaryData}
						handleShowPreview={handleShowPreview}
					/>
				)}
			</Row>
		</div>
	);
}

import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

//
import PosterBannerForm from './PosterBannerForm';
import PosterBannersTable from '../../../../components/@PosterBanners/PosterBannersTable';
import { useSelector } from 'react-redux';

/**
 *
 */
export default function PostersBanners() {
	const [showForm, setShowForm] = useState(false);
	const [posterBanner, setPosterBanner] = useState();
	const [showPreview, setShowPreview] = useState(false);
	const userRole = useSelector((state) => state?.user?.user?.active_role);

	const handleShowForm = () => {
		setShowForm(true);
	};
	const handleHideForm = () => {
		setShowForm(false);
		setPosterBanner(null);
	};

	const handleShowPreview = (data) => {
		setPosterBanner(data);
		setShowForm(true);
		setShowPreview(true);
	};

	const handleSetEditPosterBannerData = (data) => {
		setPosterBanner(data);
		setShowForm(true);
	};

	return (
		<div className="main-content-inner">
			<Row>
				<Col md={10}>
					<h2 className="main-title mb-2">Posters/Banners</h2>
				</Col>

				<Col md={2} className="d-flex justify-content-center align-items-center">
					{!showForm && ['superuser', 'cd_admin'].includes(userRole) && (
						<Button onClick={handleShowForm}>Add Poster/Banners</Button>
					)}
				</Col>
			</Row>

			<Row>
				{showForm ? (
					<PosterBannerForm
						hideForm={handleHideForm}
						posterBanner={posterBanner}
						showPreview={showPreview}
						setShowPreview={setShowPreview}
					/>
				) : (
					<PosterBannersTable
						handleSetEditPosterBannerData={handleSetEditPosterBannerData}
						handleShowPreview={handleShowPreview}
					/>
				)}
			</Row>
		</div>
	);
}

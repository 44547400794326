import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Loading from "../../components/Loading";
import EnrollHeader from "./EnrolledComponents/EnrollHeader";

import CourseCompletedImg from "../../assets/images/celebrate.svg";
import { Link, useParams } from "react-router-dom";

export default function CompletedCourse() {
  const { courseSlug } = useParams();
  const { course, loading } = useSelector((state) => state.course);

  return (
    <div>
      {loading ? (
        <div style={{ height: "100vh" }} className="py-4">
          <Loading fontSize="5rem" color="#2f80ed" />
        </div>
      ) : (
        <StyledCourseContainer>
          <EnrollHeader course={course} hideProgress={true} />

          <Container className="content">
            <div className="celebrate-container">
              <img
                src={CourseCompletedImg}
                alt="course completed"
                className="celebrate"
              />
            </div>
            <h2 className="text-center mt-4">Congratulation !</h2>

            <div className="congratulation-div">
              <h5>You have successfully completed this course.</h5>
            </div>

            <div className="d-flex text-center justify-content-center">
              <Link to={"/courselist/1"} className="btn btn-primary text-white">
                View other course
              </Link>
              <Link
                to={`/courses/${courseSlug}/enrolled`}
                className="btn btn-primary ml-4 text-white"
              >
                Go to Course
              </Link>
            </div>
          </Container>
        </StyledCourseContainer>
      )}
    </div>
  );
}

const StyledCourseContainer = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  .content {
    background-color: white;
    height: 100%;
    padding: 46px 0;

    .celebrate-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .celebrate {
      width: 160px;
    }

    .congratulation-div {
      max-width: 400px;
      text-align: center;
      margin: 40px auto 30px;
    }
  }
`;

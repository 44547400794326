import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { FormGroup } from '../../../../../../components/FormGroup/FormGroup';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { handleLessonEdit, handleLessonDelete } from '../../../../../../_redux/slices/courseData';
import Loading from '../../../../../../components/Loading';
import { FaSave, FaTrashAlt } from 'react-icons/fa';
import { useEffect } from 'react';
import LessonContent from './LessonContent';
import { toast } from 'react-toastify';
import LessonAttachments from './LessonAttachments';

const StyledLesson = styled.div`
	.lesson_head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.5rem;
	}
	.lesson_body {
		padding: 1rem;
		margin-bottom: 1rem;
		border: 1px solid gray;
		border-radius: 5px;
		box-shadow: 6px 6px 10px 0px #dbdbdb;
	}
`;
function Lesson({ chapterId, lesson }) {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [shortDescriptionState, setShortDescriptionState] = useState(EditorState.createEmpty());
	const [articleContentState, setArticleContentState] = useState(EditorState.createEmpty());
	const [attachmentFiles, setAttachmentFiles] = useState([]);

	const [hasArticleContent, setHasArticleContent] = useState(Boolean(lesson.article_content?.content));
	const [hasVideoContent, setHasVideoContent] = useState(Boolean(lesson.video_content));
	const [hasAudioContent, setHasAudioContent] = useState(Boolean(lesson.audio_content));

	const handleAttachment = (attachments) => {
		setAttachmentFiles(attachments);
	};

	const deleteLesson = async () => {
		try {
			dispatch(handleLessonDelete({ chapterId, lesson }));
		} catch (errors) {
			console.log(errors);
			toast.error('Failed to delete Lesson!');
		}
	};
	useEffect(() => {
		if (lesson.short_description) {
			const contentBlock = htmlToDraft(lesson.short_description);
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const shortDescriptionState = EditorState.createWithContent(contentState);
				setShortDescriptionState(shortDescriptionState);
			}
		}
	}, [lesson.short_description]);
	useEffect(() => {
		if (lesson.article_content?.content) {
			const contentBlock = htmlToDraft(lesson.article_content?.content);
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const articleContentState = EditorState.createWithContent(contentState);
				setArticleContentState(articleContentState);
			}
		}
	}, [lesson.article_content]);

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const handleArticleContentChange = (e) => {
		setArticleContentState(e);
	};
	const onSubmit = async (data) => {
		try {
			setIsLoading(true);
			let tempLesson = {
				...lesson,
				order: data.order,
				title: data.title,
				short_description: draftToHtml(convertToRaw(shortDescriptionState.getCurrentContent())),
				article_content: {
					content: draftToHtml(convertToRaw(articleContentState.getCurrentContent())),
				},
			};
			if (hasArticleContent) {
				tempLesson.article_content = {
					content: draftToHtml(convertToRaw(articleContentState.getCurrentContent())),
				}
			}
			else {
				tempLesson.article_content = null;
			}

			if (data.video_file) {
				tempLesson.video_content = {
					video: data.video_file,
				}
			}
			else {
				tempLesson.video_content = null;
			}

			if (data.audio_file) {
				tempLesson.audio_content = {
					audio: data.audio_file,
					title: data.audio_title,
					description: data.audio_description
				}
			}
			else {
				tempLesson.audio_content = null;
			}

			if (attachmentFiles.length) {
				tempLesson.meta = {
					resources: attachmentFiles,
				};
			}
			dispatch(handleLessonEdit(tempLesson));
			setIsLoading(false);
		} catch (err) {
			console.log(err, 'err');
			toast.error('Failed to save Lesson!');
			setIsLoading(false);
		}
	};
	return (
		<StyledLesson>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<div className="lesson_head">
					<h3>{lesson.title}</h3>
					<Button variant="success" type="submit">
						<FaSave className="mr-2" />
						Save
					</Button>
				</div>
				<div className="lesson_body">
					<Row>
						<Col md={12}>
							<FormGroup
								type="number"
								label="Lesson Order"
								placeholder="Enter Order of this Lesson"
								id="order"
								register={register}
								error={errors}
								defaultValue={lesson.order}
								//   disabled={isLoading}
								required={true}
							/>
						</Col>
						<Col md={12}>
							<FormGroup
								type="text"
								label="Lesson Title"
								placeholder="Enter Lesson Title"
								id="title"
								register={register}
								error={errors}
								defaultValue={lesson.title}
								//   disabled={isLoading}
								required={true}
							/>
						</Col>
						<Col md={12}>
							<FormGroup
								label="Short Description"
								id="description"
								type="editor"
								// disabled={isLoading}
								placeholder="Enter lesson Description"
								defaultValue={shortDescriptionState}
								error={errors}
								onChange={(e) => setShortDescriptionState(e)}
								editorStyle={{ minHeight: '200px' }}
							/>
						</Col>
						<LessonContent
							key={`${lesson.id}-${articleContentState}`}
							lesson={lesson}
							register={register}
							setValue={setValue}
							errors={errors}
							hasVideoContent={hasVideoContent}
							hasAudioContent={hasAudioContent}
							hasArticleContent={hasArticleContent}
							articleContentState={articleContentState}
							handleArticleContentChange={handleArticleContentChange}
							setHasArticleContent={setHasArticleContent}
							setHasVideoContent={setHasVideoContent}
							setHasAudioContent={setHasAudioContent}
							setArticleContentState={setArticleContentState}
						/>
						<LessonAttachments
							lesson={lesson}
							setValue={setValue}
							errors={errors}
							handleAttachment={handleAttachment}
						/>

						<Col md={12} className="mt-4 border-top pt-3">
							<div className="d-flex justify-content-between">
								<Button variant="success" type="submit">
									{isLoading ? (
										<Loading />
									) : (
										<span>
											<FaSave className="mr-2" />
											Save
										</span>
									)}
								</Button>
								<Button variant="outline-danger" onClick={deleteLesson}>
									<FaTrashAlt className="mr-2" /> Delete
								</Button>
							</div>
						</Col>
					</Row>
				</div>
			</Form>
		</StyledLesson>
	);
}

export default Lesson;

import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

function LessonResourceContent({ resource }) {
	const docs = [{ uri: resource.media_url, fileType: resource.type }];
	let content;

	switch (resource.type) {
		case "video":
			content = <div className="w-full d-flex justify-content-center">
				<iframe
					width="853"
					height="480"
					className="mx-auto"
					src={`https://www.youtube.com/embed/${resource.media_url.split('/')[3]}`}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					title="Embedded youtube"
				/>
			</div>
			break;
		case "audio":
			content = <div className="w-full d-flex justify-content-center">
				<ReactAudioPlayer
					className='w-100'
					src={resource.media_url}
					controls />
			</div>
			break;
		case "image":
			content = <div className="w-full d-flex justify-content-center">
				<img
					style={{
						width: '100%',
						height: '700px',
						objectFit: "contain"
					}}
					className="mx-auto"
					src={resource.media_url}
					alt={resource.title}
				/>
			</div>
			break;
		default:
			content = <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} style={{ minHeight: '800px' }} />
	}
	return (
		<div className="previewComponent-content mb-4">
			{content}
		</div>
	);
}

export default LessonResourceContent;

import { Searchfield } from '../components/Searchfield';
import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { handleLocalChange } from '../_redux/slices/local';
import { logoutUser } from '../_redux/slices/user';
import DropDown from './components/DropDown';
import UserInfo from './components/UserInfo';
// import SignUp from './components/SignUp';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { handleCourseList } from '../_redux/slices/courseList';
import { Row, Col, Button } from 'react-bootstrap';

const Header = ({ fluid, withBack }) => {
	const user = useSelector((state) => state.user);
	const local = useSelector((state) => state.local.local);
	const courses = useSelector((state) => state.courseList.courses);
	const [showMenu, setShowMenu] = useState(false);
	const [displaySearch, setDisplaySearch] = useState(false);
	const searchRef = useRef(null);

	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	const handleSearch = async (e) => {
		await dispatch(handleCourseList({ title: e.target.value }));
		if (!location.pathname.includes('courselist')) {
			setDisplaySearch(true);
		}
		if (e.target.value === '') {
			setDisplaySearch(false);
		}
	};

	return (
		<StyledHeader className="row">
			<StyledContainer className="col-12" show={showMenu}>
				<Row className="py-2 position-relative m-0">
					<Col xs={3} md={3} lg={1} className="d-flex align-items-center">
						{withBack ? (
							<img
								src="/assets/icons/LeftArrow.svg"
								className=""
								onClick={() => history.push('/courselist/1')}
								alt=""
							/>
						) : (
							<Link className="nav-brand" to="/">
								<img src="/assets/images/emblem.png" className="logo" alt="" />
							</Link>
						)}
					</Col>

					<Col xs={6} md={6} lg={2} className={`d-flex align-items-center`}>
						<Searchfield
							searchRef={searchRef}
							handleSearch={handleSearch}
							displaySearch={displaySearch}
							courses={courses}
							setDisplaySearch={setDisplaySearch}
						/>
					</Col>

					{/* hidden in big screensize */}
					<Col xs={3} className="menu-icon cursor-pointer">
						<img src="/assets/icons/menu.svg" alt="" onClick={() => setShowMenu((prev) => !prev)} />
					</Col>

					{/* hidden in small screensize */}
					<Col xs={12} md={3} lg={9} as={Row} className="d-flex justify-content-between collapse-menu">
						<Col md={12} lg={8} className="menus d-flex align-items-center">
							<div className="d-flex align-items-center justify-content-center flex-grow-1">
								<div className="close-icon">
									<FaTimes onClick={() => setShowMenu(false)} />
								</div>
								<ul className="d-flex flex-grow-1 justify-content-around align-items-center linkcontainer">
									<li>
										<NavLink to="/about-us">
											<FormattedMessage id="aboutus" />
										</NavLink>
									</li>

									<DropDown
										label="Knowledge Modules"
										dropToggleBtnClasses="menu-toggler padding-0 custom-dropdown-menu"
										dropDownItems={[
											{
												title: 'E-Learning',
												path: '/courselist/1',
											},
											{
												title: 'Knowledge Products',
												path: '/knowledge-products/story',
											},
											{
												title: 'Capacity Support',
												path: '/capacity-support/1',
											},
										]}
									/>

									<li>
										<NavLink to="/thematic_area">
											<FormattedMessage id="thematicareas" />
										</NavLink>
									</li>
									<li>
										<NavLink to="/contact">
											<FormattedMessage id="contact" />
										</NavLink>
									</li>
								</ul>
							</div>
						</Col>

						<Col md={12} lg={3} className="nav-actions d-flex justify-content-end align-items-center ">
							<div className="">
								{user.isLoggedIn ? (
									<div className="d-flex align-items-center">
										<UserInfo user={user?.user} />
										<DropDown
											dropDownItems={[
												{
													title: 'Dashboard & Settings',
													path: '/dashboard',
												},
												{
													title: 'Notifications',
												},
												{
													title: 'Log out',
													action: () => {
														dispatch(logoutUser());
														history.push('/');
													},
												},
											]}
											dropToggleBtnClasses="px-4"
										/>
									</div>
								) : (
									<Button className="px-3 mr-1" onClick={() => history.push('/login')}>
										Log In
									</Button>
								)}
							</div>
							<Select onChange={(e) => dispatch(handleLocalChange(e.target.value))} defaultValue={local}>
								<option value="en-US">En</option>
								<option value="ne">Np</option>
							</Select>
						</Col>
					</Col>
				</Row>
			</StyledContainer>
		</StyledHeader>
	);
};

export default Header;

const StyledHeader = styled.header`
	box-shadow: 0px -2px 15px rgba(64, 83, 145, 0.25);
	position: sticky;
	top: 0;
	z-index: 1000;
	width: 100vw;
	background: white;
	@media (min-width: 992px) {
		width: 100%;
	}
`;

const Select = styled.select`
	padding: 0.2rem !important;
	padding-right: 0 !important;
	border-radius: 5px;
	width: max-content;
	font-size: 0.8rem;
	@media (max-width: 992px) {
		margin: 1rem 0;
	}
	@media screen and (max-width: 768px) {
		display: inline-block;
		align-self: start;
		margin: 0;
	}
`;

const StyledContainer = styled(Col)`
	height: max-content;

	.menu-icon,
	.close-icon {
		display: none;
	}
	.nav-brand {
		padding: 0 !important;
	}
	.linkcontainer {
		max-width: 670px;
	}
	.menus {
		.menu-item,
		a {
			height: 100%;
			padding: 0.5rem 0;
			font-size: 1rem !important;
			color: #333333;
			font-family: 'Poppins', sans-serif;
			font-weight: 400;
		}
		.menu-toggler {
			padding: 0;
		}
		.menu-toggler:hover {
			opacity: 0.7;
		}
		.active {
			border-bottom: 4px solid #2f80ed;
		}
		@media screen and (max-width: 1024px) {
			.menu-item {
				a {
					font-size: 0.8rem;
				}
			}
		}
	}
	@media (max-width: 992px) {
		.nav-brand .logo {
			width: 60px;
		}
		.menu-icon {
			display: flex;
			align-items: center;
			justify-content: end;
		}
		.collapse-menu {
			background-color: white;
			padding: 1rem;
			margin: 0;
			position: absolute;
			top: 60px;
			right: -5rem;
			width: 30vw;
			.menus {
				flex-direction: column;
				align-items: start !important;
				padding: 0;
				height: max-content;
				div {
					flex-direction: column;
					align-items: start !important;
					position: relative;
					width: 100%;
					.close-icon {
						display: block;
						position: absolute;
						top: 0;
						left: calc(100% - 1rem);
						width: max-content;
					}

					ul {
						flex-direction: column;
						align-items: start !important;

						gap: 0.5rem;
					}
				}
			}
			${({ show }) => (show ? 'display: block;' : 'display: none;')}
		}
		.nav-actions {
			margin: 0;
			padding: 0.5rem;
			flex-direction: column;
			align-items: start !important;
			justify-content: start !important;
			gap: 0.5rem;
		}
	}
	@media (max-width: 768px) {
		.collapse-menu {
			height: max-content;
			width: 100vw;
			right: 0;
			top: 100%;

			.menus {
				flex-direction: column;
				align-items: start !important;
				padding: 0;
				div {
					flex-direction: column;
					align-items: start !important;
					position: relative;
					width: 100%;
					.close-icon {
						display: block;
						position: absolute;
						top: 0;
						left: calc(100% - 1rem);
						width: max-content;
					}

					ul {
						flex-direction: column;
						align-items: start !important;
						gap: 0.5rem;
					}
				}
			}
			.nav-actions {
				margin: 0;
				padding: 0.5rem;
				flex-direction: column;
				align-items: start !important;
				gap: 0.5rem;
			}
		}
	}
`;

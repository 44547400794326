import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CourseCard from '../../../components/CourseCard';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const CourseSlider = ({ latestCourses }) => {
	const [index, setIndex] = useState({ start: 0, offset: 3 });
	useEffect(() => {
		if (window.innerWidth < 768) {
			setIndex({ start: 0, offset: 1 });
		} else if (window.innerWidth < 1024) {
			setIndex({ start: 0, offset: 2 });
		} else setIndex({ start: 0, offset: 3 });
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setIndex({ start: 0, offset: 1 });
			} else if (window.innerWidth < 1024) {
				setIndex({ start: 0, offset: 2 });
			} else setIndex({ start: 0, offset: 3 });
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			<Slider>
				<div className="slider-left">
					<FaChevronLeft
						className="pointer"
						onClick={() =>
							setIndex((prev) => ({
								...prev,
								start: prev.start < prev.offset ? latestCourses?.length - prev.offset : prev.start - prev.offset,
							}))
						}
					/>
				</div>
				<div className="slider-grid">
					{latestCourses?.slice(index.start, index.start + index.offset).map((course) => (
						<CourseCard key={course.id} course={course} />
					))}
				</div>
				<div className="slider-right">
					<FaChevronRight
						className="pointer"
						onClick={() =>
							setIndex((prev) => ({
								...prev,
								start: prev.start >= latestCourses?.length - prev.offset ? 0 : prev.start + prev.offset,
							}))
						}
					/>
				</div>
			</Slider>
			<SliderSmall>
				{latestCourses?.map((course) => (
					<CourseCard key={course.id} course={course} />
				))}
			</SliderSmall>
		</>
	);
};

export default CourseSlider;

const Slider = styled.div`
	display: grid;
	padding: 2rem;
	width: 80%;
	margin: auto;
	place-content: center;
	grid-gap: 0.5rem;
	position: relative;
	.slider-left,
	.slider-right {
		position: absolute;
		height: 100%;
		display: grid;
		place-content: center;
		font-size: 2.5rem;
	}
	.slider-left {
		right: 100%;
	}
	.slider-right {
		left: 100%;
	}
	.slider-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 2rem;
	}
	@media screen and (max-width: 1024px) {
		.slider-left,
		.slider-right {
			font-size: 1.5rem;
		}
		.slider-grid {
			grid-template-columns: 1fr 1fr;
			grid-gap: 1rem;
		}
	}
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const SliderSmall = styled.div`
	.card {
		min-width: 60vw;
	}
	display: flex;
	flex-wrap: nowrap;
	gap: 1.5rem;
	margin: 2rem 1rem;
	overflow-x: auto;
	@media screen and (min-width: 768px) {
		display: none;
	}
`;

import { Row, Col, Button } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

//
import ReactTable from "../../../components/ReactTable";

//
import DefaultColumnFilter from "../../../components/ReactTable/Filters/DefaultColumnFilter";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { FormGroup } from "../../../components/FormGroup/FormGroup";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { VscLoading } from "react-icons/vsc";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

/**
 *
 */
export default function CapacitySupportTypesTable() {
  const { page } = useParams();
  const history = useHistory();

  //
  const [pageNo, setPageNo] = useState(page);
  const [showForm, setShowForm] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [capacitySupportTypes, setCapacitySupportTypes] = useState({});

  const getCapacitySupportTypes = useCallback(async () => {
    const response = await fetchWrapper.get(
      `capacity-support/category/?offset=${(pageNo - 1) * 10}`
    );

    setCapacitySupportTypes(response.data || {});
    history.push(`/dashboard/capacity-support-type/${pageNo}`);
  }, [history, pageNo]);

  const handleDelete = useCallback(
    async (id) => {
      try {
        await fetchWrapper.delete(`capacity-support/category/${id}/`);
        toast.success("Type deleted Successfully");
        getCapacitySupportTypes();
      } catch (err) {
        console.log(err);
      }
    },
    [getCapacitySupportTypes]
  );

  useEffect(() => {
    getCapacitySupportTypes();
  }, [getCapacitySupportTypes, history, pageNo]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  const columns = useMemo(
    () => [
      {
        Header: "S.N.",
        accessor: "sn",
        disableFilters: true,
        width: "auto",
      },
      {
        Header: "Title",
        accessor: "title",
        Filter: DefaultColumnFilter,
        width: "auto",
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        width: "auto",
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (capacitySupportTypes?.results?.length > 0) {
      let count = 0;
      return capacitySupportTypes.results.map((item) => ({
        sn: ++count,
        title: item.title,
        actions: (
          <>
            <FaEdit
              className="text-primary mx-1 cursor-pointer"
              onClick={() => {
                setEditItem(item);
                setShowForm(true);
                setValue("title", item.title);
              }}
            />
            <FaTrashAlt
              className="text-danger cursor-pointer"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleDelete(item.id);
                  }
                });
              }}
            />
          </>
        ),
      }));
    } else return [];
  }, [capacitySupportTypes.results, handleDelete, setValue]);

  const [isLoading, setIsLoading] = useState(false);

  const handleToggleShowForm = () => {
    reset();
    setEditItem(null);
    setShowForm((prev) => !prev);
  };

  const onSubmit = async (form_data) => {
    try {
      setIsLoading(true);
      if (editItem) {
        await fetchWrapper.patch(`capacity-support/category/${editItem.id}/`, {
          title: form_data.title,
        });
      } else {
        await fetchWrapper.post("capacity-support/category/", {
          title: form_data.title,
        });
      }

      getCapacitySupportTypes();
      setShowForm(false);
      setEditItem(null);
      setIsLoading(false);
      reset();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="main-content-inner">
      <Row className="mb-3">
        <Col sm={7}>
          <h2 className="main-title mb-2">Capacity Support Types</h2>
        </Col>

        <Col sm={5} className="d-flex justify-content-end align-items-center">
          {!showForm && (
            <Button onClick={handleToggleShowForm}>
              Add Capacity Support Type
            </Button>
          )}
        </Col>
      </Row>

      {showForm && (
        <div className="mb-5">
          <h3 className="mb-2">
            {editItem ? "Edit" : "Add"} Capacity Support Types
          </h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <FormGroup
                  id="title"
                  errorName="Title"
                  label="Title"
                  type="text"
                  placeholder="Enter title"
                  register={register}
                  required={true}
                  disabled={isLoading}
                  error={errors}
                />
              </Col>
            </Row>

            <Row>
              <Col className="d-flex">
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? <VscLoading className="spin" /> : "Submit"}
                </Button>

                <Button
                  type="button"
                  className="ml-3"
                  variant="outline-secondary"
                  onClick={handleToggleShowForm}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      )}

      <Row>
        <ReactTable
          columns={columns}
          data={data}
          pageNo={pageNo}
          setPageNo={setPageNo}
          totalCount={capacitySupportTypes.count}
        />
      </Row>
    </div>
  );
}

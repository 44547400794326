import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { handleLessonAdd, handleCreateQuiz } from '../../../../../../_redux/slices/courseData';
import { StyledChapterActions, StyledChapterBox } from '../style/StyledChapterBuilder';
import LessonBox from '../LessonBox';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import Loading from '../../../../../../components/Loading';

function ChapterAction({ chapter, hasQuiz, activeTab, handleActiveTabChange }) {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const handleAddLesson = async () => {
		try {
			setIsLoading(true);
			let response = await dispatch(handleLessonAdd(chapter.id));
			handleActiveTabChange('Lessons', response.chapter, response.id);
			setIsLoading(false);
		}
		catch (error) {
			console.log(error)
		}
	};
	const handleAddQuiz = async () => {
		await dispatch(handleCreateQuiz(chapter.slug));
		handleActiveTabChange('Quiz', chapter.id);
	};

	let ActionButton;
	switch (activeTab) {
		case 'Lessons':
			ActionButton = <Button onClick={handleAddLesson}>{isLoading ? <Loading /> : <span>+ Add Lesson</span>}</Button>;
			break;
		case 'Quiz':
			ActionButton = (
				<Fragment>
					{!hasQuiz && (
						<Button variant="warning" onClick={handleAddQuiz}>
							+ Add Quiz
						</Button>
					)}
				</Fragment>
			);
			break;
		default:
			ActionButton = <Button onClick={handleAddLesson}>{isLoading ? <Loading /> : <span>+ Add Lesson</span>}</Button>;
	}

	return <StyledChapterActions>{ActionButton}</StyledChapterActions>;
}

const StyledQuizButton = styled.div`
	border: 1px solid gold;
	padding: 0.5rem;
	border-radius: 5px;
	text-align: center;
	cursor: pointer;
	font-weight: 500;
	transition: background-color 300ms;
	:hover {
		background-color: gold;
	}
	&.glow {
		background-color: gold;
	}
`;

function ChapterBox({ chapter, activeTab, handleActiveTabChange }) {
	const questions = useSelector((state) => state.courseData.questions);

	const [hasQuiz, setHasQuiz] = useState(Boolean(questions[chapter.id]));

	useEffect(() => {
		setHasQuiz(Boolean(questions[chapter.id]));
	}, [chapter.id, questions]);

	return (
		<StyledChapterBox>
			<h4>{chapter.title}</h4>
			{chapter?.lessons &&
				chapter.lessons.map((lesson) => (
					<LessonBox
						key={lesson.id}
						lesson={lesson}
						handleClick={() => {
							handleActiveTabChange('Lessons', chapter.id, lesson.id);
						}}
					/>
				))}
			{hasQuiz && (
				<StyledQuizButton
					className={`${activeTab === 'Quiz' ? 'glow' : ''}`}
					onClick={() => {
						handleActiveTabChange('Quiz', chapter.id);
					}}
				>
					Quiz
				</StyledQuizButton>
			)}

			<ChapterAction
				chapter={chapter}
				activeTab={activeTab}
				hasQuiz={hasQuiz}
				handleActiveTabChange={handleActiveTabChange}
			/>
		</StyledChapterBox>
	);
}

export default ChapterBox;

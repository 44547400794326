import React from "react";
import ChapterBuildNav from "./ChapterBuildNav";

function ChapterBuildNavbar({ activeTab, handleActiveTabChange }) {
  return (
    <nav className="mb-3 nav nav-tabs" role="tablist">
      <ChapterBuildNav
        tab="Chapter"
        activeTab={activeTab}
        handleActiveTabChange={handleActiveTabChange}
      />
      <ChapterBuildNav
        tab="Lessons"
        activeTab={activeTab}
        handleActiveTabChange={handleActiveTabChange}
      />
      <ChapterBuildNav
        tab="Quiz"
        activeTab={activeTab}
        handleActiveTabChange={handleActiveTabChange}
      />
      {/* <ChapterBuildNav
        tab="Assignment"
        activeTab={activeTab}
        handleActiveTabChange={handleActiveTabChange}
      /> */}
      <ChapterBuildNav
        tab="Certificate"
        activeTab={activeTab}
        handleActiveTabChange={handleActiveTabChange}
      />
    </nav>
  );
}

export default ChapterBuildNavbar;

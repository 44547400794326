import React from "react";
import { StyledLessonBox } from "../style/StyledChapterBuilder";

function LessonBox({ lesson, handleClick }) {
  return (
    <StyledLessonBox onClick={handleClick}>{lesson.title}</StyledLessonBox>
  );
}

export default LessonBox;

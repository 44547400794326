import React, { useEffect } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
// import { ImBook } from 'react-icons/im';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getLessonsFromChapter, getQuizFromChapter } from '../../_redux/slices/course';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

function ChapterCard({ chapter }) {
	const { courseSlug } = useParams();
	const lessons = useSelector((state) => state.course.lessons[chapter.slug]);
	const user = useSelector((state) => state.user.user);
	const quiz = useSelector((state) => state.course.quizes[chapter.slug]);
	const dispatch = useDispatch();
	useEffect(() => {
		try {
			if (chapter.slug && user) {
				dispatch(getLessonsFromChapter(chapter.slug));
			}
		} catch (error) {
			console.log(error, 'error in lesson get');
		}
		try {
			if (chapter.slug && user) {
				dispatch(getQuizFromChapter(chapter.slug));
			}
		} catch (error) {
			console.log(error, 'error in quiz get');
		}
	}, [chapter.slug, dispatch, user]);

	return (
		<StyledCard>
			<Accordion.Toggle as={Card.Header} eventKey={chapter.id}>
				<div className="d-flex align-items-center justify-content-between cursor-pointer">
					<div className="d-flex align-items-center">
						<FaChevronDown className="mr-3" />
						<p>{chapter.title}</p>
					</div>
					<div className="md-hidden" style={{ color: '#828282' }}>
						<span>{lessons?.length} lessons</span>
					</div>
				</div>
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={chapter.id}>
				<Card.Body>
					{lessons?.map((lesson) => (
						<Link key={lesson.id} to={`/courses/${courseSlug}/enrolled/${lesson.slug}`}>
							<div className="single-lesson d-flex align-items-center justify-content-between mb-2">
								<div className="d-flex align-items-center">
									<img src="/assets/icons/paperPrimary.svg" className="mr-2" alt="lesson" />
									<p className="text-primary lesson-titles">{lesson.title}</p>
								</div>
							</div>
						</Link>
					))}
					{quiz && (
						<div className="single-lesson d-flex align-items-center justify-content-between mb-2">
							<Link to={`/courses/${courseSlug}/enrolled/${chapter.slug}/assessment/quiz`}>
								<div className="d-flex align-items-center">
									<img src="/assets/icons/paperPrimary.svg" className="mr-2" alt="quiz" />
									<p className="text-primary">Quiz</p>
								</div>
							</Link>
						</div>
					)}
				</Card.Body>
			</Accordion.Collapse>
		</StyledCard>
	);
}

export default ChapterCard;

const StyledCard = styled(Card)`
	margin: 0 !important;
	.card-body {
		background-color: #f2f2f2;
	}
	.lesson-title {
		font-family: 'Poppins', sans-serif !important;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: #2460b9;
	}
	@media screen and (max-width: 768px) {
		.single-lesson {
			span {
				display: none;
			}
		}
		.sm-hidden {
			display: none;
		}
	}
`;

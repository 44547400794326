import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ThematicAreaForm from "./ThematicAreaForm";
import ThematicAreaTable from "./ThematicAreaTable";

const ThematicAreas = () => {
  const [isPreview, setIsPreview] = useState(true);
  const handleThematicAreaView = (value) => setIsPreview(value);
  const [thematicArea, setThematicArea] = useState(null);
  const setSelectedThematicArea = (selectedThematicArea) => {
    setThematicArea(selectedThematicArea);
  };
  return (
    <div className="main-content-inner">
      <Row className="mb-3">
        <Col sm={7}>
          <h2 className="main-title mb-2">Thematic Areas</h2>
        </Col>
        {isPreview && (
          <Col
            sm={5}
            className="d-flex justify-content-end align-items-center"
          >
            <Button onClick={() => setIsPreview(false)}>
              Add Thematic Area
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        {isPreview ? (
          <ThematicAreaTable
            setSelectedThematicArea={setSelectedThematicArea}
            handleThematicAreaView={handleThematicAreaView}
          />
        ) : (
          <ThematicAreaForm
            thematicArea={thematicArea}
            setSelectedThematicArea={setSelectedThematicArea}
            handleThematicAreaView={handleThematicAreaView}
          />
        )}
      </Row>
    </div>
  );
};

export default ThematicAreas;

import { React, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import BlankArea from '../_components/BlankArea';

// redux
import { handleChapterAdd } from '../../../../../../_redux/slices/courseData';
import Chapter from './Chapter';
// import { useParams } from 'react-router-dom';
import Loading from '../../../../../../components/Loading';
function ChapterArea() {
	const chapters = useSelector((state) => state.courseData.chapters);
	const { course } = useSelector((state) => state.courseData);
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const addChapter = async () => {
		setIsLoading(true);
		await dispatch(handleChapterAdd(course.id));
		setIsLoading(false);
	};
	return (
		<div>
			{chapters.length ? (
				<div>
					{chapters.map((chapter, index) => {
						return <Chapter key={chapter.id} chapter={chapter} index={index} />;
					})}
					<Button onClick={addChapter}>{isLoading ? <Loading /> : <span>Add Chapter</span>}</Button>
				</div>
			) : (
				<BlankArea area={'ChapterArea'} action={addChapter} isLoading={isLoading} />
			)}
		</div>
	);
}

export default ChapterArea;

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import BlankArea from "../_components/BlankArea";
import Lesson from "./Lesson";

function LessonArea({ chapterId, lessonId }) {
  const courseData = useSelector((state) => state.courseData);
  const [lesson, setLesson] = useState(null);
  useEffect(() => {
    let chapter = courseData.chapters.filter(
      (chapter) => chapter.id === chapterId
    );
    if (chapter.length) {
      let lesson = (chapter[0]?.lessons || []).filter(
        (lesson) => lesson.id === lessonId
      );
      if (lesson.length) {
        setLesson(lesson[0]);
      }
    }
  }, [chapterId, courseData.chapters, lessonId]);

  return (
    <div>
      {lesson ? (
        <Lesson key={lesson.id} chapterId={chapterId} lesson={lesson} />
      ) : (
        <BlankArea area="LessonArea" />
      )}
    </div>
  );
}

export default LessonArea;

import { EditorState } from 'draft-js';
import React from 'react';
import { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { FormGroup } from '../../../../../../components/FormGroup/FormGroup';
import LessonTypeButton from '../_components/LessonTypeButton';
import AddResource from './AddResource';

function ArticleContent({ articleContentState, handleArticleContentChange, register, errors, lesson, removeContent }) {
	return (
		<div className="py-2 content-section">
			<Col md={12}>
				<FormGroup
					label="Article Content"
					id="content"
					type="editor"
					register={register}
					// disabled={isLoading}
					placeholder="Aa"
					defaultValue={articleContentState}
					error={errors}
					onChange={handleArticleContentChange}
					editorStyle={{ minHeight: '400px' }}
				/>
				<div className="d-flex justify-content-end">
					<Button variant="danger" onClick={removeContent}>
						<FaTrash className="mr-2" />
						Delete
					</Button>
				</div>
			</Col>
		</div>
	);
}

function VideoContent({ register, setValue, errors, lesson, removeContent }) {
	const [video, setVideo] = useState(lesson.video_content?.video);

	const handleVideoChange = async (selectedFile) => {
		try {
			setVideo(selectedFile);
			setValue('video_file', selectedFile)
		} catch (err) {
			console.log(err);
		}
	};

	const onDeleteClick = () => {
		removeContent();
		setValue('video_file', null);
	}
	return (
		<div className="p-2 mt-4 content-section">
			<h4>Video Resource:</h4>
			<div className='w-50 mb-4'>
				<AddResource
					defaultValues={video}
					setValue={setValue}
					isMulti={false}
					label="Click to select Media Resource"
					required={true}
					fixedType="video"
					handleOnSubmit={handleVideoChange}
				/>
			</div>

			<div className="d-flex justify-content-end">
				<Button variant="danger" onClick={onDeleteClick}>
					<FaTrash className="mr-2" />
					Delete
				</Button>
			</div>
		</div>
	);
}

function AudioContent({ register, setValue, errors, lesson, removeContent }) {
	const [audio, setAudio] = useState(lesson.audio_content?.audio);

	const handleAudioChange = async (selectedFile) => {
		try {
			setAudio(selectedFile);
			setValue('audio_file', selectedFile)
		} catch (err) {
			console.log(err);
		}
	};

	const onDeleteClick = () => {
		removeContent();
		setValue('audio_file', null);
		setValue('audio_title', '');
		setValue('audio_description', '');
	}
	return (
		<div className="p-2 mt-4 content-section">
			<h4>Audio Resource:</h4>
			<div className='w-50 mb-4'>
				<AddResource
					defaultValues={audio}
					setValue={setValue}
					label="Click to select Media Resource"
					required={true}
					isMulti={false}
					fixedType="audio"
					handleOnSubmit={handleAudioChange}
				/>
			</div>
			<input
				{...register('audio_file')}
				defaultValue={audio} hidden />
			<FormGroup
				id="audio_title"
				label="Audio Title"
				type="text"
				errorName={'Audio Title'}
				defaultValue={lesson?.audio_content?.title}
				error={errors}
				register={register}
				required={true}
			/>
			<FormGroup
				id="audio_description"
				label="Audio Description"
				type="textarea"
				errorName={'Audio Description'}
				defaultValue={lesson?.audio_content?.description}
				error={errors}
				register={register}
				required={true}
			/>
			<div className="d-flex justify-content-end">
				<Button variant="danger" onClick={onDeleteClick}>
					<FaTrash className="mr-2" />
					Delete
				</Button>
			</div>
		</div>
	);
}

function LessonContent({ register, setValue, errors, lesson, articleContentState, hasArticleContent,
	hasVideoContent,
	hasAudioContent,
	handleArticleContentChange, setHasArticleContent, setHasVideoContent, setHasAudioContent, setArticleContentState }) {
	return (
		<Col md={12} key={lesson.id}>
			<div>
				<h4>Content</h4>
				{hasArticleContent && (
					<ArticleContent
						lesson={lesson}
						register={register}
						errors={errors}
						articleContentState={articleContentState}
						handleArticleContentChange={handleArticleContentChange}
						removeContent={() => {
							setArticleContentState(EditorState.createEmpty())
							setHasArticleContent(false);
						}}
					/>
				)}
				{hasVideoContent && (
					<VideoContent
						register={register}
						setValue={setValue}
						lesson={lesson}
						errors={errors}
						removeContent={() => setHasVideoContent(false)}
					/>
				)}
				{hasAudioContent && (
					<AudioContent
						register={register}
						lesson={lesson}
						setValue={setValue}
						errors={errors}
						removeContent={() => setHasAudioContent(false)}
					/>
				)}
				<div className="d-flex justify-content-around mt-4 mb-2">
					{!hasArticleContent && <LessonTypeButton type="Article" handleClick={() =>
						setHasArticleContent(true)
					} />}
					{!hasVideoContent && <LessonTypeButton type="Video" handleClick={() => setHasVideoContent(true)} />}
					{!hasAudioContent && <LessonTypeButton type="Audio" handleClick={() => setHasAudioContent(true)} />}
				</div>
			</div>
		</Col>
	);
}

export default LessonContent;

import {
	FaBook,
	FaChevronDown,
	FaChevronUp,
	// FaCogs,
	FaFolderPlus,
	// FaHome,
	FaList,
	FaQuestionCircle,
	FaStore,
	FaVideo,
} from 'react-icons/fa';
import { GiStarsStack } from 'react-icons/gi';
import { IoBookSharp, IoHelpBuoySharp } from 'react-icons/io5';
import { BsImages } from 'react-icons/bs';
// import { VscLaw } from 'react-icons/vsc';
// import { GrPlan } from 'react-icons/gr';
import { BiShareAlt } from 'react-icons/bi';

import DashboardIcon from './DashboardIcon';
import SettingIcon from './SettingIcon';

import { FormattedMessage } from 'react-intl';

const SidebarData = [
	{
		id: 1,
		title: 'Dashboard',
		icon: <DashboardIcon />,
		path: '/dashboard',
	},
	{
		id: 2,
		title: <FormattedMessage id="e-learning" />,
		icon: <FaList className="fs-26" />,
		path: false,
		iconClosed: <FaChevronDown className="fs-26" />,
		iconOpened: <FaChevronUp className="fs-26" />,
		subNav: [
			{
				title: 'All Courses',
				path: '/dashboard/all-courses/1',
				icon: <FaBook className="fs-26" />,
				permittedRoles: ['participant', 'resource_person'],
			},
			{
				title: 'Enrolled Course',
				path: '/dashboard/enrolled-courses/1',
				icon: <FaFolderPlus className="fs-26" />,
				permittedRoles: ['participant', 'resource_person'],
			},
			{
				title: <FormattedMessage id="myCourses" />,
				path: '/dashboard/courses/1',
				icon: <FaBook className="fs-26" />,
				permittedRoles: ['superuser', 'cd_admin'],
			},
			{
				title: <FormattedMessage id="addNewCourse" />,
				path: '/dashboard/course',
				icon: <FaFolderPlus className="fs-26" />,
				permittedRoles: ['superuser', 'cd_admin'],
			},
		],
	},
	{
		id: 3,
		title: <FormattedMessage id="knowledgeProduct" />,
		icon: <IoBookSharp className="fs-26" />,
		path: false,
		iconClosed: <FaChevronDown className="fs-26" />,
		iconOpened: <FaChevronUp className="fs-26" />,
		permittedRoles: ['superuser', 'cd_admin', 'participant', 'resource_person'],
		subNav: [
			{
				title: <FormattedMessage id="knowledgeProduct.successStories" />,
				path: '/dashboard/success-stories/1',
				icon: <GiStarsStack className="fs-26" />,
			},
			{
				title: <FormattedMessage id="knowledgeProduct.banner" />,
				path: '/dashboard/banners/1',
				icon: <BsImages className="fs-26" />,
			},
			{
				title: <FormattedMessage id="knowledgeProduct.documentaries" />,
				path: '/dashboard/documentaries/1',
				icon: <FaVideo className="fs-26" />,
			},
		],
	},
	{
		id: 8,
		title: <FormattedMessage id="CapacitySupport" />,
		icon: <IoHelpBuoySharp className="fs-26" />,
		path: false,
		iconClosed: <FaChevronDown className="fs-26" />,
		iconOpened: <FaChevronUp className="fs-26" />,
		permittedRoles: ['superuser', 'cd_admin', 'participant', 'resource_person'],
		subNav: [
			{
				title: <FormattedMessage id="CapacitySupport" />,
				path: '/dashboard/capacity-support/1',
			},
			{
				title: <FormattedMessage id="CapacitySupportType" />,
				path: '/dashboard/capacity-support-type/1',
				permittedRoles: ['superuser'],
			},
		],
	},
	{
		id: 4,
		title: <FormattedMessage id="thematicareas" />,
		icon: <BiShareAlt className="fs-26" />,
		path: '/dashboard/thematic-areas/1',
		permittedRoles: ['superuser', 'cd_admin'],
	},
	{
		id: 5,
		title: <FormattedMessage id="resources" />,
		icon: <FaStore className="fs-26" />,
		path: '/dashboard/library/1',
		permittedRoles: ['superuser', 'cd_admin', 'resource_person'],
	},
	{
		id: 6,
		title: <FormattedMessage id="support" />,
		icon: <FaQuestionCircle className="fs-26" />,
		path: '/dashboard/support',
	},
	{
		id: 7,
		title: <FormattedMessage id="settings" />,
		icon: <SettingIcon />,
		path: '/dashboard/settings',
	},
];

export default SidebarData;

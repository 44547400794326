import { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import { fetchWrapper } from "../../../../../../_helpers/fetchWrapper";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import PaginationComp from '../../../../../../components/Pagination/PaginationComp';

//
// import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
// import { FaFileAlt, FaVideo } from 'react-icons/fa';
import { VscOpenPreview } from "react-icons/vsc";
import { debounce } from "../../../../../../_helpers/_methods";
// import DefaultColumnFilter from '../../../../../../components/ReactTable/Filters/DefaultColumnFilter';
// import SelectColumnFilter from '../../../../../../components/ReactTable/Filters/SelectColumnFilter';
import ReactTable from "../../../../../../components/ReactTable";
import { useCallback } from "react";
import ReactAudioPlayer from "react-audio-player";

const AddResource = ({
  setValue,
  isMulti = true,
  fixedType,
  handleOnSubmit,
  label,
  defaultValues,
}) => {
  const [show, setShow] = useState(false);
  const [resources, setResources] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({
    type: fixedType ?? "",
    category: "",
    title: "",
  });
  const [selectedFile, setSelectedFile] = useState([]);
  const [previewItem, setPreviewItem] = useState(null);

  useEffect(() => {
    if (defaultValues) {
      if (Array.isArray(defaultValues)) {
        setSelectedFile(defaultValues);
      }
    }
  }, [defaultValues]);

  useEffect(() => {
    const getResources = async () => {
      const response = await fetchWrapper.get(
        `resources/?type=${filter.type}&category=${filter.category}${
          filter.title ? `&title=${filter.title}` : ""
        }${!filter.title ? `&offset=${(page - 1) * 10}` : ""}`
      );
      setResources(response?.data?.results);
      setCount(response?.data?.count);
    };

    try {
      getResources();
    } catch (error) {}
  }, [filter.type, filter.category, filter.title, page]);

  const onCheck = useCallback(
    (resource) => {
      try {
        let tempSelectedFile = selectedFile;
        const isAlreadySelected = tempSelectedFile.find(
          (file) => file.id === resource.id
        );
        if (isMulti) {
          if (isAlreadySelected) {
            let tempSelected = tempSelectedFile.filter(
              (v) => v.id !== resource.id
            );
            setSelectedFile(tempSelected);
          } else {
            setSelectedFile((prev) => [...prev, resource]);
          }
        } else {
          if (isAlreadySelected) {
            setSelectedFile([]);
          } else {
            setSelectedFile((prev) => [resource]);
          }
        }
        setPreviewItem(resource);
      } catch (error) {
        console.log(error);
      }
    },
    [isMulti, selectedFile]
  );

  const onSubmit = () => {
    const selectedFileIds = selectedFile.map((file) => file.id);
    setValue("attachment", selectedFileIds);

    if (handleOnSubmit) {
      handleOnSubmit(selectedFile);
    }
    setShow(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.N.",
        accessor: "sn",
        disableFilters: true,
        width: "6%",
      },
      {
        Header: "Resource Title",
        accessor: "title",
        // Filter: DefaultColumnFilter,
        disableFilters: true,
        width: "20%",
      },
      {
        Header: "Type",
        accessor: "type",
        // Filter: DefaultColumnFilter,
        disableFilters: true,
        width: "15%",
      },
      {
        Header: "Uploaded Date",
        accessor: "created_at",
        // Filter: SelectColumnFilter,
        disableFilters: true,
        width: "15%",
      },
    ],
    []
  );

  const data = useMemo(() => {
    let tableData = resources.map((resource, i) => ({
      sn: (
        <div>
          <span>{i + 1}</span>
          <input
            key={selectedFile}
            checked={
              Array.isArray(selectedFile)
                ? selectedFile?.find((file) => file.id === resource.id)
                : undefined
            }
            type="checkbox"
            onChange={() => onCheck(resource)}
            className="ml-2"
          />
        </div>
      ),
      title: (
        <span
          onClick={() => {
            onCheck(resource);
          }}
          className="pointer hover-blue"
        >
          {resource?.title}
        </span>
      ),
      type: resource?.type,
      created_at: resource?.created_at?.split("T")[0],
    }));
    return tableData;
  }, [resources, selectedFile, onCheck]);

  return (
    <div>
      <Button
        variant="primary"
        className="w-100 d-flex justify-content-center"
        onClick={() => setShow(true)}
      >
        {label ? label : "Add Resources"}
      </Button>

      {Boolean(selectedFile?.length) && (
        <div className="my-2">{selectedFile?.length} files selected</div>
      )}

      {show && (
        <StyledModal show={show} onHide={() => setShow(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Choose Resources</Modal.Title>
            <div className="ml-4">
              {!fixedType && (
                <select
                  name=""
                  id=""
                  onChange={(e) =>
                    setFilter((prev) => ({ ...prev, type: e.target.value }))
                  }
                >
                  <option value="">--Select Type--</option>
                  <option value="pdf">PDF</option>
                  <option value="doc">DOC</option>
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                  <option value="audio">Audio</option>
                </select>
              )}

              <label className="ml-2">
                <span className="mr-3">Search:</span>
                <input
                  onChange={debounce(
                    (e) =>
                      setFilter((prev) => ({ ...prev, title: e.target.value })),
                    800
                  )}
                  placeholder="Search by Title here..."
                />
              </label>
            </div>
          </Modal.Header>

          <Modal.Body className="main-grid">
            <div className="scroll-container">
              <div className="d-flex align-items-start flex-wrap resource-display">
                {resources?.length < 1 ? (
                  <div>No RESOURCES FOUND</div>
                ) : (
                  <>
                    <ReactTable
                      columns={columns}
                      data={data}
                      pageNo={page}
                      setPageNo={setPage}
                      totalCount={count}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="p-2">
              <div className="preview-container">
                {previewItem?.media_url ? (
                  previewItem?.type === "image" ? (
                    <div className="sub-container">
                      <img
                        className="preview-image"
                        src={previewItem.media_url}
                        alt={previewItem.title}
                      />
                    </div>
                  ) : previewItem?.type === "video" ? (
                    <div className="sub-container">
                      <iframe
                        width="400"
                        height="250"
                        src={`https://www.youtube.com/embed/${
                          previewItem?.media_url?.split("/")[3]
                        }`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        className="preview-video"
                      />
                    </div>
                  ) : previewItem?.type === "audio" ? (
                    <div className="sub-container">
                      <ReactAudioPlayer src={previewItem?.media_url} controls />
                    </div>
                  ) : (
                    <div className="preview-docs">
                      <DocViewer
                        documents={[{ uri: previewItem?.media_url }]}
                        pluginRenderers={DocViewerRenderers}
                        className="custom-docviewer-style"
                      />
                    </div>
                  )
                ) : (
                  <div className="null-preview">
                    <VscOpenPreview fontSize={100} />
                    <p>Select resource to preview</p>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>

          {/* <PaginationComp page={page} setPage={setPage} total={count} /> */}
          <Modal.Footer>
            <Button
              variant="success"
              onClick={onSubmit}
              disabled={selectedFile.length < 1}
            >
              Select
            </Button>
          </Modal.Footer>
        </StyledModal>
      )}
    </div>
  );
};

export default AddResource;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 80vw;
  }
  .main-grid {
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    .scroll-container {
      height: 100%;
      width: 100%;
      overflow-y: scroll;
    }
    .resource-display {
      height: max-content;
      width: 100%;
    }
  }
  .resource-item {
    width: 100px;
    margin-bottom: 0.5rem;
    position: relative;
    .resource-preview {
      width: 100px;
      height: 100px;
      display: grid;
      place-content: center;
      border: 1px solid #2f80ed;
      overflow: hidden;

      img {
        width: 99px;
        height: 99px;
        object-fit: contain;
        object-position: center;
        background: black;
        color: white;
      }
    }
    p {
      margin-top: 0.25rem;
      line-height: 1.2 !important;
      font-size: 0.8rem;
    }
    .check-item {
      position: absolute;
      cursor: pointer;
      right: 0;
      top: 0;
      background-color: white;
    }
  }
  .preview-container {
    width: 100%;
    height: 60vh;
    background: #eee;
    /* display: flex; */
    /* align-items: center; */
    overflow-y: auto;
    .sub-container {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .preview-image {
      width: 100%;
      object-position: center;
      object-fit: contain;
    }
    .preview-video {
      width: 100%;
    }
    .preview-docs {
      width: 100%;
      height: 100%;
      .custom-docviewer-style {
        width: 100%;
        height: 100%;
      }
    }
    .null-preview {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #b4b4b4;
      & > p {
        color: #b4b4b4;
      }
    }
  }
`;

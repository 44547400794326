import styled from 'styled-components';

export const ThematicAreaBadge = styled.small`
	padding: 0.25rem 0.5rem;
	margin: 0.5rem;
	margin-left: 0;
	border-radius: 5px;
	background-color: #00c48c;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #ffffff;
`;

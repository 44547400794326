import { Form } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useState } from "react";

const FormPassword = ({
  placeholder,
  id,
  isValidate,
  validation,
  disabled,
  defaultValue,
  register,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  return (
    <div style={{ position: "relative" }}>
      <Form.Control
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        {...register(id, isValidate && validation)}
        disabled={disabled}
        defaultValue={defaultValue}
      />
      {showPassword ? (
        <FaEyeSlash className="password-eye" onClick={handleShowPassword} />
      ) : (
        <FaEye className="password-eye" onClick={handleShowPassword} />
      )}
    </div>
  );
};

export default FormPassword;

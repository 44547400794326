import React, { useState, useMemo } from 'react';
import { ProgressBar } from 'react-bootstrap';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	PieChart,
	Pie,
} from 'recharts';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchWrapper } from '../_helpers/fetchWrapper';
import { EnrolledCourseCard } from '../components/CourseCard';
import { useDispatch } from 'react-redux';
import { getThematicAreas } from '../_redux/slices/thematicAreas';

const Dashboard = () => {
	const [userStats, setUserStats] = useState({ stats: {}, courses: [], inProgress: [], created_by: '' });
	const [graphStats, setGraphStats] = useState([])
	const [courseReviewStats, setCourseReviewStats] = useState([]);
	const user = useSelector((state) => state.user.user);
	const thematicAreas = useSelector((state) => state.thematicAreas.thematicAreas);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getThematicAreas(1, 1000));

		const getStats = async () => {
			const response = await fetchWrapper.get('user/dashboard/stats/');
			setUserStats((prev) => ({ ...prev, stats: response?.data }));
		};
		const getParticipantCourses = async () => {
			const response = await fetchWrapper.get('participant/courses/');
			setUserStats((prev) => ({ ...prev, courses: response?.data }));
		};
		const getMostEnrolledCourses = async () => {
			const response = await fetchWrapper.get('most-enrolled-courses/');
			setUserStats((prev) => ({
				...prev,
				courses: response?.data?.individual_course,
				created_by: response?.data?.created_by,
			}));
		};
		const getDashboardGraphData = async () => {
			try {
				const response = await fetchWrapper.get('stat/quiz-lesson/');
				let tempData = response?.data?.map(d => ({
					...d,
					month: d.month.slice(0, 3)
				}))
				setGraphStats(tempData)
			}
			catch (error) {

			}
		}

		const getCourseReviewStats = async () => {
			const response = await fetchWrapper.get('course-reviews-stats/');
			setCourseReviewStats(response?.data);
		};
		const getParticipantInProgress = async () => {
			const response = await fetchWrapper.get('participant/courses/inprogress/');
			setUserStats((prev) => ({ ...prev, inProgress: response?.data }));
		};
		getStats();
		getDashboardGraphData();
		if (user?.active_role === 'participant') {
			getParticipantCourses();
			getParticipantInProgress();
		} else if (user?.active_role === 'resource_person') {
			getMostEnrolledCourses();
			getCourseReviewStats();
		}
	}, [dispatch, user]);

	const userStatsTitle = useMemo(() => {
		switch (user?.active_role) {
			case 'participant':
				return [
					{ label: 'Course Enrolled', value: 'enrolled_courses', className: 'text-danger' },
					{ label: 'Course Completed', value: 'completed_courses', className: 'text-secondary' },
					{ label: 'Quiz Completed', value: 'completed_quiz', className: 'text-success' },
					// { label: 'Assignment Completed', value: 'completed_assignment', style: { color: '#FAA54E' } },
				];
			case 'resource_person':
				return [
					{ label: 'My Courses', value: 'created_courses', className: 'text-danger' },
					{ label: 'Active Courses', value: 'active_courses', className: 'text-secondary' },
					{ label: 'Pending Courses', value: 'pending_courses', className: 'text-success' },
					{ label: 'Enrolled Participant', value: 'participant_enrolled', style: { color: '#faa54e' } },
				];
			case 'superuser':
				return [
					{ label: 'Total Courses', value: 'courses', className: 'text-danger' },
					{ label: 'Thematic Areas', value: 'thematic_area', className: 'text-secondary' },
					{ label: 'Resource Person', value: 'resource_persons', className: 'text-success' },
					{ label: 'Participants', value: 'participants', style: { color: '#faa54e' } },
				];
			default:
				return [];
		}
	}, [user]);
	let tempThematicAreas = [...(thematicAreas || [])];
	return (
		<div className="dashboard p-4">
			<div>
				<h2 className=" main-title">Dashboard</h2>
			</div>
			<div className="my-4 dashboard-box-wrapper">
				{userStatsTitle.map((title) =>
					<div className="dashboard-box" key={title.value}>
						<h4>{title.label}</h4>
						<h1 className={title?.className} style={title?.style}>
							{userStats?.stats && userStats?.stats[title.value]}
						</h1>
					</div>
				)}
			</div>
			{user?.active_role !== 'superuser' && (
				<div style={{ margin: '4rem 0' }}>
					<h3>
						{user?.active_role === 'participant'
							? 'My Courses'
							: user?.active_role === 'resource_person' && 'Most Enrolled Courses'}
					</h3>
					<div style={{ width: '75vw', overflowX: 'auto', marginInline: 'auto' }}>
						<div className="course-flex">
							{userStats?.courses.map((course) =>
								user?.active_role === 'participant' ? (
									<EnrolledCourseCard key={course.id} course={course} />
								) : (
									user?.active_role === 'resource_person' && (
										<EnrolledCourseCard
											key={course.id}
											course__slug={course?.course__slug}
											course__thumbnail_image={course?.course__thumbnail_image}
											course__title={course?.course__title}
											enrolled_users={course?.enrolled_users}
											created_by={userStats?.created_by}
										/>
									)
								)
							)}
						</div>
					</div>
				</div>
			)}
			<div className="dashboard-progress row">
				<div className={`learning-activity ${user?.active_role === 'superuser' ? 'col-md-7' : 'col-md-6 '}`}>
					<h3 style={{ marginBottom: '2rem' }}>
						{user?.active_role === 'participant' ? 'Learning Activity' : 'Enrollment Stats'}
					</h3>
					<ResponsiveContainer width="100%" height={400}>
						<BarChart
							width={500}
							height={300}
							data={graphStats}
							margin={{
								top: 20,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<Legend wrapperStyle={{ bottom: '100%', left: 0, width: 'max-content' }} />
							<XAxis dataKey="month" />
							<YAxis />
							<Tooltip />
							<Bar dataKey="lesson" stackId="a" barSize={30} fill="#FAA54E" />
							<Bar dataKey="quiz" stackId="a" barSize={30} fill="#4C9EFF" />
						</BarChart>
					</ResponsiveContainer>
				</div>
				{user?.active_role === 'participant' && (
					<div className="col-md-6">
						<h3 className="mb-4">In Progress</h3>
						<div className="course_wrapper">
							{userStats?.inProgress?.map((course) => (
								<div key={course.id} className="course-progress">
									<div className="d-flex justify-content-between align-items-center mb-2">
										<h4>{course.course_title}</h4>
										<h4 className="text-success">60%</h4>
									</div>
									<ProgressBar now={60} />
									<h6 className="text-primary pointer">Continue</h6>
								</div>
							))}
						</div>
					</div>
				)}
				{user?.active_role === 'resource_person' && (
					<div className=" col-md-6 shadow d-flex flex-column justify-content-between align-items-center p-4">
						<h4>REVIEWS</h4>
						<div className="d-flex justify-content-center">
							<PieChart width={250} height={250}>
								<Pie
									data={courseReviewStats}
									innerRadius={100}
									outerRadius={120}
									fill="#00c48c"
									dataKey={'user_count'}
									nameKey={'rate'}
								/>
							</PieChart>
						</div>
						<div className="d-flex justify-content-around flex-wrap w-100">
							{courseReviewStats.map((stat) => (
								<div key={stat.rate}>
									<h5 className="mb-0">Total Ratings: {stat.user_count}</h5>
									<p style={{ fontSize: '0.9rem', lineHeight: 1.1, color: 'rgba(51, 51, 51, 1)' }}>
										{stat.rate} Star Reviews
									</p>
								</div>
							))}
						</div>
					</div>
				)}
				{user?.active_role === 'superuser' && (
					<div className="col-md-5 shadow p-3 thematic_areas">
						<h3 className="mb-3">Thematic Areas</h3>
						<div className="scroll">
							{(tempThematicAreas?.sort((a, b) => a.courses - b.courses ? 1 : -1)).map((thematic_area) => (
								<div
									key={thematic_area.id}
									className="d-flex align-items-center justify-content-between mb-2 shadow p-2 rounded-sm"
								>
									<h4 className="m-0">{thematic_area.title}</h4>
									<div
										style={{ backgroundColor: '#E9F2FF', border: '2px solid #2F80ED' }}
										className="d-flex align-items-center justify-content-center px-3 rounded-circle ratio-1x1"
									>
										<h4 className="m-0">{thematic_area.courses}</h4>
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Dashboard;

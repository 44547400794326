import React, { Fragment, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import { Table, Pagination } from 'react-bootstrap';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import UpDownArrow from '../../updown.svg';
import { FormattedMessage } from 'react-intl';
import { VscLoading } from 'react-icons/vsc';

const ReactTable = ({
	columns,
	data: initialData,
	pageNo,
	setPageNo,
	totalCount,
	pagination = true,
	loading = false,
	showGoto = false,
	collapseAt = null,
}) => {
	const [data, setData] = React.useState([]);
	const [collapsed, setCollapsed] = React.useState(collapseAt ? true : false);
	useEffect(() => {
		if (collapseAt) {
			if (collapsed) {
				const collapsedData = initialData?.slice(0, collapseAt);
				setData(collapsedData);
			} else {
				setData(initialData);
			}
		} else {
			setData(initialData);
		}
	}, [initialData, collapseAt, collapsed]);
	const {
		headerGroups,
		getTableProps,
		getTableBodyProps,
		page,
		canNextPage,
		canPreviousPage,
		previousPage,
		nextPage,
		gotoPage,
		pageCount,
		prepareRow,
		// setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			manualPagination: true,
			defaultCanFilter: false,
			initialState: { pageIndex: pageNo },

			// defaultColumn,
			// manualFilters: true
		},
		useFilters,
		useSortBy,
		usePagination
	);
	const pageCountArray = useMemo(
		() => Array.from({ length: Math.ceil(totalCount / pageSize) }, (v, i) => i + 1),
		[totalCount, pageSize]
	);
	useEffect(() => {
		setPageNo(pageIndex);
	}, [pageIndex, setPageNo]);
	return (
		<TableContainer>
			<StyledTable {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						// eslint-disable-next-line react/jsx-key
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								// eslint-disable-next-line react/jsx-key
								<th
									{...column.getHeaderProps({
										style: { minWidth: column.minWidth, width: column.width },
									})}
								>
									{column.render('Header')}
									{column.canSort ? (
										<span {...column.getHeaderProps(column?.getSortByToggleProps ? column.getSortByToggleProps() : {})}>
											{column.isSorted ? (
												<span className="ms-1">
													{column.isSortedDesc ? <AiFillCaretDown color={'#555'} /> : <AiFillCaretUp color={'#555'} />}{' '}
												</span>
											) : (
												<span className="ms-1">
													<img src={UpDownArrow} width="16" style={{ padding: '0 3px' }} alt="" />
												</span>
											)}
										</span>
									) : null}
									<div style={{ height: column.canFilter && '30px' }}>
										{column.canFilter ? column.render('Filter') : null}
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{loading && (
						<tr>
							<td colSpan={columns?.length} className="text-center">
								<VscLoading className="spin" fontSize={36} />
							</td>
						</tr>
					)}

					{!loading &&
						page.map((row) => {
							prepareRow(row);

							return (
								// eslint-disable-next-line react/jsx-key
								<tr {...row.getRowProps()}>
									{row.cells.length > 0 ? (
										row.cells.map((cell) => {
											return (
												// eslint-disable-next-line react/jsx-key
												<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
											);
										})
									) : (
										<td>
											<FormattedMessage id={'no_data'} />{' '}
										</td>
									)}
								</tr>
							);
						})}

					{!loading && page.length === 0 && (
						<tr>
							<td colSpan={columns?.length} className="text-center">
								No Data
							</td>
						</tr>
					)}
				</tbody>
			</StyledTable>

			{collapseAt && (
				<CollapseButton className="btn btn-link" onClick={() => setCollapsed(!collapsed)}>
					{collapsed ? 'See more' : 'See less'}
				</CollapseButton>
			)}

			{pagination && !collapsed && (
				<div className="d-flex align-items-center justify-content-between">
					<div>
						{!loading && (
							<span className="text-secondary">
								Showing {page.length} of ~ {totalCount} items
							</span>
						)}
					</div>
					<PaginationComp
						gotoPage={gotoPage}
						canPreviousPage={canPreviousPage}
						previousPage={previousPage}
						pageCount={pageCount}
						pageCountArray={pageCountArray}
						pageNum={pageNo}
						nextPage={nextPage}
						canNextPage={canNextPage}
						showGoto={showGoto}
						setPageNo={setPageNo}
					/>
				</div>
			)}
		</TableContainer>
	);
};

export default ReactTable;

export const TableContainer = styled.div`
	width: 100%;
	overflow-x: auto;
`;

const StyledTable = styled(Table)`
	thead {
		/* box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%); */
		border-bottom: 2px solid #333;
		th {
			font-weight: 400;
		}
	}
	tbody {
		border-top: none !important;
	}
`;

const PaginationComp = ({
	gotoPage,
	canPreviousPage,
	previousPage,
	pageCount,
	pageCountArray,
	pageNum,
	nextPage,
	canNextPage,
	showGoto,
	setPageNo,
}) => {
	return (
		<StyledPagination>
			<Pagination>
				<Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
				<Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
				{pageNum < 5 ? (
					pageCountArray
						.filter((count, index) => index < 5)
						.map((count) => (
							<Fragment key={count}>
								<Pagination.Item
									active={count === pageNum}
									key={count}
									onClick={() => {
										gotoPage(count);
										setPageNo(count);
									}}
								>
									{count}
								</Pagination.Item>
							</Fragment>
						))
				) : (
					<>
						<Pagination.Item>...</Pagination.Item>
						<Pagination.Item onClick={() => gotoPage(pageNum - 2)}>{pageNum - 2}</Pagination.Item>
						<Pagination.Item onClick={() => gotoPage(pageNum - 1)}>{pageNum - 1}</Pagination.Item>

						<Pagination.Item active>{pageNum}</Pagination.Item>

						<Pagination.Item onClick={() => gotoPage(pageNum + 1)}>{pageNum + 1}</Pagination.Item>

						<Pagination.Item onClick={() => (pageNum < pageCountArray.length ? gotoPage(pageNum + 2) : null)}>
							{pageNum + 2}
						</Pagination.Item>

						<Pagination.Item>...</Pagination.Item>
					</>
				)}
				<Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
				<Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
			</Pagination>

			{showGoto ? (
				<div className="pagination-input_div text-muted">
					<span className="mx-2">
						Go to :{' '}
						<input
							className="pagination-input"
							type="number"
							defaultValue={pageNum}
							style={{
								maxWidth: '45px',
								textAlign: 'center',
							}}
							onChange={(e) => {
								const page = e.target.value ? Number(e.target.value) : 0;
								gotoPage(page);
							}}
						/>
					</span>
					<span> of {pageCountArray.length} page</span>
				</div>
			) : null}
		</StyledPagination>
	);
};

const StyledPagination = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	.pagination {
		margin: 0;
	}
	.pagination li:first-child a {
		background: none;
		border: transparent;
		border-radius: none;
		color: #2460b9;
	}
	.pagination .page-item .page-link {
		border: 1px solid #c7c7c7;
		border-radius: 50% !important;
	}
	.pagination-input_div {
		margin-left: 0.8rem;
	}
	.pagination-input {
		width: 25%;
		border: 1px solid #c7c7c7;
		text-align: center;
		padding: 0.2rem;
		border-radius: 5px;
	}
`;

const CollapseButton = styled.button`
	margin-top: -1.5rem;
`;

import { useEffect, useState } from 'react';
import LessonHeader from '../../Layout/LessonLayout/LessonHeader';
import styled from 'styled-components';
import Sidebar from './components/Sidebar';
import { useHistory, useParams } from 'react-router-dom';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { createMarkup } from '../../_helpers/_methods';
import { Button } from 'react-bootstrap';
import { nextEntityAfterCompletion, nextEntityHandler } from '../../_helpers/nextEntityHandler';
import { useSelector } from 'react-redux';

const QuizResultPreview = () => {
	const { courseSlug, chapterSlug } = useParams();
	const [quizPreview, setQuizPreview] = useState([]);
	const { restartCourse } = useSelector((state) => state.course);
	const history = useHistory();
	const quizes = useSelector(state => state.course.quizes);

	useEffect(() => {
		const getQuizResultPreview = async () => {
			if (chapterSlug) {
				const response = await fetchWrapper.get(`chapter/${chapterSlug}/quiz-result-preview/`);
				setQuizPreview(response?.data);
			}
		};
		try {
			getQuizResultPreview();
		} catch (error) {
			console.log(error);
		}
	}, [chapterSlug]);

	console.log(quizPreview);


	const handleNextClick = () => {
		if (restartCourse) {

			nextEntityAfterCompletion(
				courseSlug,
				{
					entity: 'quiz',
					id: [],
				},
				history
			)
		}
		else {
			const thisChapterQuiz = quizes[chapterSlug];
			const quizIdArray = thisChapterQuiz?.map(quiz => quiz.id)
			nextEntityHandler(
				courseSlug,
				{
					entity: 'quiz',
					id: quizIdArray,
				},
				history
			)
		}
	}

	return (
		<>
			<LessonHeader />
			<Container>
				<Sidebar chapterSlug={chapterSlug} courseSlug={courseSlug} />
				<div className="content">
					<div>
						{quizPreview?.map((question, index) => (
							<StyledQuestionContainer>
								<div className="question-header">
									<h3>
										QUESTION {index + 1} OF {quizPreview?.length}:
									</h3>
								</div>
								<div className="quiz-preview-wrapper">
									<div className="quiz-question-title">
										<p className="question" dangerouslySetInnerHTML={createMarkup(question?.question)}></p>
										{/* <div className="info d-flex align-items-center">
									<img src="/assets/icons/info.svg" style={{ height: '18px' }} alt="" />
									<small className="text-primary font-poppins my-2 pointer">See Hint</small>
								</div> */}
									</div>
									<div>
										{question?.all_options?.map((option, index) => (
											<StyledOption
												className={`quiz-play-op ${option === question?.selected_option &&
													(option === question?.correct_option ? 'correct' : 'wrong')
													} ${option === question?.correct_option && 'correct'}`}
											>
												<input type="checkbox" checked={option === question?.selected_option ? true : false} />
												<div className="d-flex align-items-center" aria-disabled="false">
													<div
														style={{
															color: 'black',
															fontSize: '16px',
															marginLeft: '10px',
														}}
														dangerouslySetInnerHTML={createMarkup(option)}
													></div>
													{option === question?.selected_option ? (
														option === question?.correct_option ? (
															<img className="ml-4" src="/assets/icons/correctAnswer.svg" alt="" />
														) : (
															<img className="ml-4" src="/assets/icons/wrongAnswer.svg" alt="" />
														)
													) : (
														''
													)}
												</div>
											</StyledOption>
										))}
									</div>

								</div>
							</StyledQuestionContainer>
						))}
					</div>

					<div className="d-flex justify-content-center align-items-center mt-4">
						<Button onClick={handleNextClick}>Next Chapter</Button>
					</div>
				</div>
			</Container>
		</>
	);
};

export default QuizResultPreview;

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-template-areas: 'sidebar content';
	column-gap: 1rem;
	min-height: 90vh;
	.content {
		grid-area: 'content';
		padding: 2rem 0;
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-template-areas: 'content';
		.assignment-sidebar {
			display: none !important;
		}
	}
`;

const StyledQuestionContainer = styled.div`
	width: 80%;
	margin: auto;
	margin-top: 1rem;
	box-shadow: 0px 6px 15px rgba(64, 83, 145, 0.25);
	padding: 2rem;
	@media screen and (max-width: 1024px) {
		width: 100%;
		box-shadow: none;
		padding: 1rem;
	}
`;

const StyledOption = styled.div``;

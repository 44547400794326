import { IntlProvider } from 'react-intl';
import Nepali from '../_lang/ne.json';
import English from '../_lang/en-US.json';

const LanguageWrapper = ({ local, children }) => {
  const messages = local === 'ne' ? Nepali : English;
  return (
    <IntlProvider locale={local} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default LanguageWrapper;

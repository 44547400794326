import React from 'react';
import LongQuestion from './LongQuestion';
import MCQ from './MCQ';

function Question({ chapterSlug, question, handleQuestionIndexCounterChange, isAssignment = false, handleSetAnswersId }) {
	const questionType = question.quiz_type;

	if (!questionType) {
		return (
			<LongQuestion
				question={question}
				isAssignment={isAssignment}
				handleQuestionIndexCounterChange={handleQuestionIndexCounterChange}
			/>
		);
	} else {
		return (
			<MCQ
				isAssignment={isAssignment}
				question={question}
				chapterSlug={chapterSlug}
				handleQuestionIndexCounterChange={handleQuestionIndexCounterChange}
				handleSetAnswersId={handleSetAnswersId}
			/>
		);
	}
}

export default Question;

import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CourseSlider from './components/CourseSlider';

const Banner = ({ latestCourses, thematicAreaCount, participantCount }) => {
	return (
		<>
			<HeroBanner>
				<div className="banner-content">
					<p className="font-lg">E-Learning for Better Governance</p>
					<h1 className="banner-title">
						<FormattedMessage id="home.banner.title" />
					</h1>
					<h5 className="banner-title-desc">
						<FormattedMessage id="home.banner.subtitle" />
					</h5>
					<Link to="/courselist/1">
						<Button variant="primary" className=" w-50 mx-auto my-4 d-inline-block">
							<FormattedMessage id="enrollNow" />
						</Button>
					</Link>
				</div>

				<div className="side-block">
					<img src="/assets/img/banner.svg" alt="" />
				</div>
			</HeroBanner>
			<LearningProcess>
				<div className="lp-title">
					<h2>
						<FormattedMessage id="learningProcess" />
					</h2>
					<p>
						<FormattedMessage id="learningProcess.subtitle.line1" />
					</p>
					<p>
						<FormattedMessage id="learningProcess.subtitle.line2" />
					</p>
				</div>
				<div className="cards">
					<div className="card">
						<img src="/assets/images/searchImage.svg" alt="" />
						<h5>
							<FormattedMessage id="findCourse" />
						</h5>
						<small>
							<FormattedMessage id="findCourse.text" />
						</small>
					</div>
					<div className="card">
						<img src="/assets/images/play.svg" alt="" />
						<h5>
							<FormattedMessage id="watchVideo" />
						</h5>
						<small>
							<FormattedMessage id="watchVideo.text" />
						</small>
					</div>
					<div className="card">
						<img src="/assets/images/book.svg" alt="" />
						<h5>
							<FormattedMessage id="assignment" />
						</h5>
						<small>
							<FormattedMessage id="assignment.text" />
						</small>
					</div>
					<div className="card">
						<img src="/assets/images/mic.svg" alt="" />
						<h5>
							<FormattedMessage id="audioContent" />
						</h5>
						<small>
							<FormattedMessage id="audioContent.text" />
						</small>
					</div>
				</div>
			</LearningProcess>
			<BrowseCourse>
				<div className="bc-title">
					<h2>Browse Our Top Courses</h2>
				</div>
				<CourseSlider latestCourses={latestCourses} />
				<Link to="/courselist/1">
					<Button className="d-block mx-auto">Explore all courses</Button>
				</Link>
			</BrowseCourse>
			<Benefits>
				<div>
					<h2>Benefits From Our</h2>
					<h2 style={{ color: '#2460b9', lineHeight: '1.2' }}>Online Learning Platform</h2>
					<div className="benefit-flex">
						<img src="/assets/images/packages.svg" alt="" />
						<div>
							<h3>{thematicAreaCount}+ Thematic areas</h3>
							<small>We have a wide range of Thematic areas to choose from</small>
						</div>
					</div>
					<div className="benefit-flex">
						<img src="/assets/images/access.svg" alt="" />
						<div>
							<h3>Lifetime Access</h3>
							<small>You can access our content for as long as you want</small>
						</div>
					</div>
					<div className="benefit-flex">
						<img src="/assets/images/enrolled.svg" alt="" />
						<div>
							<h3>{participantCount}+ Enrolled</h3>
							<small>A large number of participants have enrolled in our different courses</small>
						</div>
					</div>
				</div>
				<div className="side-block">
					<img src="/assets/img/side-3.svg" alt="" />
				</div>
			</Benefits>
		</>
	);
};

export default Banner;

const HeroBanner = styled.div`
	height: calc(100vh);
	display: grid;
	margin: auto;
	width: 80%;
	grid-template-columns: 1fr 1fr;
	place-items: center;
	place-content: center;
	.banner-content {
		width: 100%;
		p:first-child {
			color: #2460b9;
			font-family: 'Poppins', sans-serif !important;
			font-weight: 300;
		}
		.banner-title {
			font-size: 3rem !important;
			line-height: 67.2px;
			font-weight: 500;
		}
		.banner-title-desc {
			font-family: 'Poppins', sans-serif !important;
			font-weight: 500;
			font-size: 18px;
			line-height: 25px;
			text-align: justify;
			color: #4f4f4f;
			width: 75%;
			margin: 15px 0px;
		}
	}
	.side-block {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 90%;
		}
	}

	@media (max-width: 1024px) {
		height: max-content;
		padding-top: 4.5rem;
	}
	@media (max-width: 768px) {
		width: 90%;
		grid-template-columns: 1fr;
		.banner-content {
			text-align: center;
			.btn {
				width: 100% !important;
			}
			P:first-child {
				font-size: 16px !important;
			}
			.banner-title {
				font-size: 1.5rem !important;
				line-height: 1.375;
			}
			.banner-title-desc {
				text-align: center;
				width: 100%;
			}
		}
	}
`;

const LearningProcess = styled.div`
	padding: 2rem 0;
	.lp-title {
		text-align: center;
		h2 {
			line-height: 1.5;
		}
		p {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 25px;
			text-align: center;
			color: #4f4f4f;
			margin: auto;
			line-height: 1.5;
		}
	}
	.cards {
		width: 75%;
		margin: 2rem auto;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 2.5rem;
		.card {
			box-shadow: 0px 6px 30px rgba(64, 83, 145, 0.25);
			padding: 1.5rem;
			img {
				width: 3rem;
				height: 3rem;
				margin-bottom: 1rem;
			}
			h6 {
				font-family: 'Poppins', sans-serif !important;
				font-style: normal;
				font-weight: 600;
				font-size: 1rem;
				line-height: 22px;
				text-align: center;
				color: #333333;
			}
			p {
				font-family: 'Roboto', sans-serif !important;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 22px;
				text-align: justify !important;
				color: #4f4f4f;
			}
		}
	}

	@media (max-width: 1024px) {
		.lp-title {
			p {
				width: 50%;
			}
		}
		.cards {
			grid-template-columns: 1fr 1fr;
		}
	}
	@media (max-width: 768px) {
		position: relative;
		width: 100%;
		overflow: auto;
		.lp-title {
			h2 {
				font-size: 1.5rem !important;
				line-height: 1.4;
			}
			p {
				width: 75%;
			}
			position: sticky;
			left: 0;
			top: 0;
		}
		.cards {
			width: max-content;
			padding: 1rem;
			display: flex;
			overflow: auto;
			.card {
				width: 60vw;
			}
		}
	}
`;

const BrowseCourse = styled.div`
	width: 100%;
	padding: 2rem 0;
	.bc-title {
		h2 {
			color: #333333;
			text-align: center;
		}
	}
	/* .cards-slider {
		display: flex;
		.slider-btn {
			width: 15%;
			display: grid;
			place-content: center;
		}
		.courses {
			width: 70%;
			padding: 2rem;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 2rem;
		}
	} */
	@media screen and (max-width: 768px) {
		.bc-title {
			h2 {
				font-size: 1.5rem !important;
				line-height: 1.4;
			}
		}
	}
`;

const Benefits = styled.div`
	padding: 2rem 0;
	height: 100vh;
	width: 80%;
	margin: auto;
	display: grid;
	grid-template-columns: 0.5fr 1fr;
	place-content: center;
	place-items: center;

	.side-block {
		height: 100%;
		width: 100%;
		img {
			height: 90%;
			width: 100%;
		}
	}
	.benefit-flex {
		display: flex;
		height: 4rem;
		margin: 2.5rem 0;
		img {
			height: 100%;
			margin-right: 1rem;
		}
	}

	@media (max-width: 1024px) {
		height: max-content;
		grid-template-columns: 1fr 1fr;
	}
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		h2:first-child,
		h2:nth-child(2) {
			/* display: inline-block; */
			font-size: 1.5rem !important;
			line-height: 1.4;
			text-align: center;
		}
		.benefit-flex {
			h3 {
				font-size: 1.5rem;
				line-height: 1.4;
			}
		}
	}
`;

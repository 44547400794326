import React from 'react';
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import BackButton from '../components/BackButton';
import { FormGroup } from '../components/FormGroup/FormGroup';
import { useForm } from 'react-hook-form';
import { VscLoading } from 'react-icons/vsc';
import { useHistory } from 'react-router';
import { fetchWrapper } from '../_helpers/fetchWrapper';
import { useDispatch } from 'react-redux';
import { setUiError, setUiMessage } from '../_redux/slices/ui';

const Forgot = ({ forgetPassword }) => {
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
	});

	const onSubmit = async (data) => {
		try {
			setIsLoading(true);
			let response = await fetchWrapper.post('user/reset_password/', data);
			setIsLoading(false);
			dispatch(setUiMessage(response.data.info));
			history.push('/');
		} catch (err) {
			dispatch(setUiError(err.error));
			setIsLoading(false);
		}
	};
	return (
		<div className="login">
			<div className="login__wrapper">
				<div className="login__form">
					<div className="logo">
						<img src="/assets/images/emblem.png" alt="logo" />
						<h3>KMS</h3>
					</div>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<h4 className="forgotpassword-title">Forgot Password</h4>
						<div className="back-button">
							<BackButton />
						</div>
						<Form.Group>
							<FormGroup
								id="email"
								label="Email"
								type="email"
								placeholder="Enter Email"
								register={register}
								required={true}
								disabled={isLoading}
								error={errors}
							/>
						</Form.Group>
						<Button className="btn-primary my-2 d-block btn-round" type="submit" disabled={isLoading}>
							{isLoading ? <VscLoading className="spin" /> : 'Submit'}
						</Button>
					</Form>
					<div className="copyright">
						<p>Designed and Powered by</p>
						<a href="https://ideabreed.net/" target="_blank" rel="noreferrer">
							Idea Breed IT Solution Pvt. Ltd.
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

// export default connect(null, { forgetPassword })(Forgot);
export default Forgot;
